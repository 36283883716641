import {useState} from 'react';
import {useAdmin} from '../../../app/hooks';
import {UserProfile} from '../../../assets/images';
import {IoIosCheckmark} from 'react-icons/io';

interface ISideMenu {
  id: any;
  name: string;
  alias: string;
  count: number;
}

interface ChatListProps {
  onSelectUser: (user: string) => void;
}

const customer: ISideMenu[] = [
  {
    id: '1',
    name: 'Henry Damilola',
    alias: 'H',
    count: 0,
  },
  {
    id: '2',
    name: 'Ayomide Tolani',
    alias: 'A',
    count: 0,
  },
  {
    id: '3',
    name: 'Matthias Bleden',
    alias: 'M',
    count: 2,
  },
  {
    id: '4',
    name: 'John Sarah',
    alias: 'J',
    count: 0,
  },
  {
    id: '5',
    name: 'Jideon Gideon',
    alias: 'G',
    count: 3,
  },
  {
    id: '6',
    name: 'Peter David',
    alias: 'P',
    count: 0,
  },
];
const SideMenu = ({onSelectUser}: ChatListProps) => {
  const user = useAdmin().admin;
  const [activeId, setActiveId] = useState(-1);
  return (
    <div className='flex flex-col py-8 pl-6 pr-2 w-64 bg-white flex-shrink-0'>
      <div className='flex flex-row items-center justify-center h-12 w-full'>
        <div className='flex items-center justify-center rounded-2xl text-indigo-700 bg-indigo-100 h-10 w-10'>
          <svg
            className='w-6 h-6'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z'></path>
          </svg>
        </div>
        <div className='ml-2 font-bold text-2xl'>Quick Reply</div>
      </div>
      <div className='flex flex-col items-center bg-blue-200 border border-gray-200 mt-4 w-full py-6 px-4 rounded-lg'>
        <div className='h-20 w-20 rounded-full border overflow-hidden'>
          <img src={UserProfile} alt='Avatar' className='h-full w-full' />
        </div>
        <div className='text-sm font-semibold mt-2'>{`${user.firstName}  ${user.lastName}`}</div>
        <div className='text-xs text-gray-400'>Support Admin</div>
        <div className='flex flex-row items-center mt-3'>
          <IoIosCheckmark size={24} className='text-success' />
          <div className='leading-none ml-1 text-xs'>Active</div>
        </div>
      </div>
      <div className='flex flex-col mt-8'>
        <div className='flex flex-row items-center justify-between text-xs'>
          <span className='font-bold'>Messages</span>
          <span className='flex items-center justify-center bg-gray-300 h-4 w-4 rounded-full'>
            5
          </span>
        </div>
        <div className='flex flex-col space-y-1 mt-4 -mx-2 h-full overflow-y-auto'>
          {customer.map((item) => (
            <button
              onClick={() => {
                onSelectUser(item.name);
                setActiveId(item.id);
              }}
              key={item.id}
              className={
                activeId === item.id
                  ? 'bg-gray-100 flex flex-row items-center hover:bg-gray-100 hover:rounded-xl rounded-md p-2'
                  : 'flex flex-row items-center hover:bg-gray-100 hover:rounded-xl p-2 bg-transparent '
              }>
              <div className='flex items-center justify-center h-8 w-8 bg-indigo-200 rounded-full'>
                {item.alias}
              </div>
              <div className='ml-2 text-sm font-semibold'>{item.name}</div>
              {item.count > 0 && (
                <div className='flex items-center justify-center ml-auto text-xs text-white bg-red-500 h-4 w-4 rounded leading-none'>
                  {item.count}
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideMenu;
