import React, {useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Loader, axiosError, scrollToTop} from '../components/Helpers';
// import {Hooks} from '../app';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {setVendorCredentials} from '../redux/slices/vendorSlice';
import {useAppDispatch} from '../app/hooks';
import {regexValidation} from '../components/Helpers/regexValidation';
import {AuthService} from '../redux/services/vendor/auth';
import Button from '../components/ui/Button';
import InputFieldAuth from '../components/forms/inputs/inputField/InputFieldAuth';

const Signin = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [validEmailPhone, setValidEmailPhone] = useState(false);
  const [emailPhoneFocus, setEmailPhoneFocus] = useState(false);

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const emailPhoneRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    emailPhone: '',
    password: '',
  });

  // Check Validation
  useEffect(() => {
    emailPhoneRef.current?.focus();
  }, []);

  // REGEX Validations
  useEffect(() => {
    const isEmailPhoneValid = regexValidation.validEmailPhone(state.emailPhone);
    const isPasswordValid = regexValidation.adminValidatePassword(
      state.password
    );
    setValidEmailPhone(isEmailPhoneValid);
    setValidPwd(isPasswordValid);
  }, [state.emailPhone, state.password]);

  // HandleChange
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((p: any) => ({
      ...p,
      [name]: value,
    }));
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      emailPhone: state.emailPhone,
      password: state.password,
    };
    try {
      const response = await AuthService.login(data);
      // console.log(response);
      if (response.data.status === 'success') {
        // console.log(response.data.data);
        if (response.data.data.vendor.isEmailVerified === true) {
          const res = response.data;
          dispatch(setVendorCredentials(res.data));
          // console.log(res.data);
          navigate('../vendor/dashboard');
          toast.success(res.data.message || 'Login Successfully');
        } else {
          const res = response.data;
          toast.error('Account not yet verified');
          dispatch(setVendorCredentials(res.data));
          navigate('../verification');
        }
      }
      setIsLoading(false);
    } catch (error: any) {
      // console.log(error);
      axiosError(error?.response);
      setIsLoading(false);
    }
  };

  // console.log("admin: ", admin);
  return (
    <div className='h-full grid place-items-center md:my-10'>
      <div className='container mx-auto'>
        {/* Body */}
        <div className='lg:w-[75%] sm:w-[90%]  w-full sm:p-10 p-2 sm:rounded-xl sm:bg-[#FAFAFA] m-auto'>
          <div className='xl:w-[40%] md:w-[60%] w-full mx-auto'>
            <div>
              <div className=''>
                <div className='w-full sm:pt-4 md:px-2 px-4 font-poppinsRegular'>
                  <div className='w-full h-full flex flex-row items-center justify-center'>
                    {/*  LOGIN FORM HERE */}
                    <div className='grid place-items-center sm:py-10 py-4 w-full'>
                      <div className='w-full'>
                        <h3 className='text-3xl font-poppinsSemibold text-center pb-3'>
                          Log In
                        </h3>
                        <h4 className=' text-xs md:text-sm font-poppinsRegular text-center pb-3 text-[#777777]'>
                          Enter your login details to access your dashboard
                        </h4>
                        <form className='w-full'>
                          <InputFieldAuth
                            label='Email or Phone Number'
                            name='emailPhone'
                            type='text'
                            placeholder='Enter Email or Phone Number'
                            value={state.emailPhone}
                            required={true}
                            Inputref={emailPhoneRef}
                            ariaInvalid={validEmailPhone ? 'false' : 'true'}
                            ariaDescribedby='uiuser'
                            onFocus={() => setEmailPhoneFocus(true)}
                            onBlur={() => setEmailPhoneFocus(false)}
                            onChange={handleChange}
                            style={{height: '100%'}}
                            email
                          />
                          <p
                            id='uidnote'
                            className={
                              emailPhoneFocus && !validEmailPhone
                                ? 'instructions'
                                : 'offscreen'
                            }>
                            <span className='font-poppinsRegular'>
                              Oops! It looks like the email format is incorrect
                              or not found. Please check and try again.
                            </span>
                          </p>

                          <InputFieldAuth
                            name='password'
                            label='Password'
                            type='password'
                            placeholder='************'
                            value={state.password}
                            required={true}
                            ariaInvalid={validPwd ? 'false' : 'true'}
                            ariaDescribedby='uipwd'
                            onFocus={() => setPwdFocus(true)}
                            onBlur={() => setPwdFocus(false)}
                            password
                            onChange={handleChange}
                            style={{height: '100%'}}
                          />
                          <p
                            id='uipwd'
                            className={
                              pwdFocus && !validPwd
                                ? 'instructions text-xs'
                                : 'offscreen text-xs'
                            }>
                            <span className='font-poppinsRegular'>
                              Your password needs 8+ characters, including
                              uppercase, lowercase letters, numbers, and special
                              characters.
                            </span>
                          </p>

                          <div className='flex justify-end pt-4 items-center'>
                            <div className='cursor-pointer '>
                              <Link
                                onClick={() => scrollToTop()}
                                to={'../forgotPassword'}
                                className='text-secondary_500 font-poppinsMedium text-xs'>
                                Forgot Password?
                              </Link>
                            </div>
                          </div>
                          <div className='py-2'></div>
                          <Button
                            label={
                              isLoading ? (
                                <div>
                                  <Loader color={'#fff'} />
                                </div>
                              ) : (
                                'Login'
                              )
                            }
                            width='full'
                            onClick={handleSubmit}
                            // onClick={() => navigate('../admin/dashboard')}
                          />
                          {/* Don't have an account */}
                          <div className='flex  justify-center text-sm text-center px-12'>
                            <p className='cursor-pointer text-menuIcon font-poppinsRegular'>
                              Don't have an account? &nbsp;
                              <span
                                className='font-poppinsSemibold text-secondary_500'
                                onClick={() => {
                                  navigate('../signup');
                                  scrollToTop();
                                }}>
                                Sign up
                              </span>
                            </p>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signin;
