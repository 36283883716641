/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import {MdOutlineModeEditOutline, MdAdd} from 'react-icons/md';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {axiosError} from '../../../components/Helpers';
import {useAdmin} from '../../../app/hooks';
import {motion} from 'framer-motion';
import {ArrowLeft} from 'iconsax-react';
import {useNavigate} from 'react-router-dom';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {toast} from 'react-toastify';

interface ICategory {
  id: string;
  name: string;
}

const UpdateSuperCategory = () => {
  const [superCategories, setSuperCategories] = useState<ICategory[]>([]);
  const [editingSuperCategory, setEditingSuperCategory] = useState<
    string | null
  >(null);
  const [newSuperCategoryName, setNewSuperCategoryName] = useState<string>('');
  const [showAddInput, setShowAddInput] = useState<boolean>(false);
  const token: any = useAdmin().adminToken;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await VendorServices.getSuperCategory();
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setSuperCategories(res.rows);
      }
      setLoading(false);
    } catch (error: any) {
      axiosError(error.response);
    }
    setLoading(false);
  };

  const handleEditCategory = (id: string, name: string) => {
    setEditingSuperCategory(id);
    setNewSuperCategoryName(name);
  };
  const handleUpdateSuperCategory = async (id: string) => {
    try {
      // Call API to update category name
      const data = {
        name: newSuperCategoryName,
      };
      const response = await VendorServices.updateSuperCategory(
        token,
        data,
        id
      );
      if (response.data.status === 'success') {
        // Update categories list
        const updatedSuperCategories = superCategories.map((category) => {
          if (category.id === id) {
            return {...category, name: newSuperCategoryName};
          }
          return category;
        });
        setSuperCategories(updatedSuperCategories);
        setEditingSuperCategory(null);
        toast.success(response.data.data.message);
      }
      //   toast.success(response.data)
    } catch (error: any) {
      axiosError(error.response);
    }
  };

  const handleAddSuperCategory = async () => {
    try {
      // Call API to add new category
      const data = [
        {
          name: newSuperCategoryName,
        },
      ];
      const response = await VendorServices.addSuperCategory(token, data);
      if (response.data.status === 'success') {
        const newSuperCategory = response.data.data;
        setSuperCategories((prevCategories) => [
          ...prevCategories,
          newSuperCategory,
        ]); // Update categories state with the new category
        setNewSuperCategoryName('');
        setShowAddInput(false); // Hide the input field after adding the category
        toast.success(response.data.data.message);
        fetchData();
      }
    } catch (error: any) {
      axiosError(error.response);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string
  ) => {
    if (event.key === 'Enter') {
      handleUpdateSuperCategory(id);
      //   handleAddCategory()
    }
  };
  const handleKeyDownAdd = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      //   handleSaveCategory(id, code);
      handleAddSuperCategory();
    }
  };
  return (
    <div className='h-full w-full xl:w-[70] sm:w-[80%] p-10 bg-white rounded-lg'>
      {loading ? (
        <TableDataSkeleton />
      ) : (
        <>
          <motion.div
            whileHover={{scale: 1.1}}
            className='w-24 text-center cursor-pointer pb-4'
            onClick={() => navigate(-1)}>
            <ArrowLeft size={28} variant='Linear' />
          </motion.div>
          <div className='flex flex-wrap items-center justify-start gap-4'>
            {superCategories.map((category) => (
              <div
                key={category.id}
                className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                {/* {editingSuperCategory === category.id ? (
                  <>
                    <div className='w-[90%]'>
                      <input
                        type='text'
                        className='h-full outline-none bg-transparent focus:outline-none w-full'
                        value={newSuperCategoryName}
                        onChange={(e) =>
                          setNewSuperCategoryName(e.target.value)
                        }
                        onBlur={() => handleUpdateSuperCategory(category.id)}
                        onKeyDown={(e) => handleKeyDown(e, category.id)}
                        autoFocus
                      />
                    </div>
                    <MdOutlineModeEditOutline
                      size={22}
                      onClick={() => setEditingSuperCategory(null)}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className='w-[90%]'
                      onClick={() =>
                        handleEditCategory(category.id, category.name)
                      }>
                      {category.name}
                    </div>
                    <MdOutlineModeEditOutline
                      size={22}
                      onClick={() =>
                        handleEditCategory(category.id, category.name)
                      }
                    />
                  </>
                )} */}
                <>
                  <div
                    className='w-[90%]'
                    // onClick={() =>
                    //   handleEditCategory(category.id, category.name)
                    // }
                  >
                    {category.name}
                  </div>
                  <MdOutlineModeEditOutline
                    size={22}
                    // onClick={() =>
                    //   handleEditCategory(category.id, category.name)
                    // }
                  />
                </>
              </div>
            ))}
            {showAddInput ? (
              <div className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                <input
                  type='text'
                  className='w-[90%] h-full outline-none bg-transparent focus:outline-none'
                  value={newSuperCategoryName}
                  onChange={(e) => setNewSuperCategoryName(e.target.value)}
                  placeholder='Enter Super Category'
                  //   onBlur={() => handleAddCategory}
                  onKeyDown={(e) => handleKeyDownAdd(e)}
                  autoFocus
                />
                <MdAdd size={22} onClick={handleAddSuperCategory} />
              </div>
            ) : (
              <div className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md cursor-pointer'>
                <MdAdd size={22} onClick={() => setShowAddInput(true)} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UpdateSuperCategory;
