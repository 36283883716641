import React, { useContext, useState } from 'react'
import { NotificationIcon } from '../../assets/icons'
import { ChatContext } from '../../context/chat'
import { AuthContext } from '../../context/auth'
import { unreadNotifications } from '../../utils'
import moment from 'moment'

const Notifications = () => {
    const [isOpen, setIsOpen] = useState(false)
    const { notifications, allUsers, markAllNotificationsAsRead, markSingleNotificationAsRead, chatUsers } = useContext(ChatContext)
    const { user } = useContext(AuthContext)

    const unread = unreadNotifications(notifications)
    const modified = notifications.map(n => {
        const sender = allUsers.find(user => user?.id === n?.senderId)
        return {
            ...n,
            senderName: sender?.businessName
        }
    })

  return (
    <div className='notifications'>
        <div className='notifications-icon' onClick={() => setIsOpen(!isOpen)}>
            <NotificationIcon />
            {unread?.length === 0 ? null : (
                <span className='notification-count'>
                    <span>{unread?.length}</span>
                </span>
            )}
        </div>
        {
            isOpen && (
        <div className='notifications-box'>
            <div className="notifications-header">
                <h3>Notifications</h3>
                <div 
                className='mark-as-read'
                onClick={() => markAllNotificationsAsRead(notifications)}
                >Mark all as read</div>
            </div>
            {modified?.length === 0 && <span className='notification'>No notification yet...</span>}
            {modified && modified?.map((n, i) => (
                <div 
                key={i} 
                className={n?.isRead ? 'notification' : 'notification not-read'}
                onClick={() => {
                    markSingleNotificationAsRead(n, chatUsers, user, notifications)
                    setIsOpen(false)
                }}
                >
                    <span>{`${n?.senderName} sent you a new message`}</span>
                    <span className='notification-time'>{moment(n?.date).calendar()}</span>
                </div>
            ))}
        </div>
            )
        }
    </div>
  )
}

export default Notifications