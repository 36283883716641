/* eslint-disable react-hooks/exhaustive-deps */

import {useContext, useEffect, useRef, useState} from 'react';
import {useAppDispatch, useBuyerSupport} from '../../../app/hooks';
import {ChatContext} from '../../context/chat';
import {AuthContext} from '../../context/auth';
import {useNavigate} from 'react-router-dom';
import Actions from '../../actions';
import {setChatBuyers} from '../../../redux/slices/chat/chatBuyerSlice';
import {ChatBox, ChatUser, Notifications} from '../../components/buyerChat';
import {DefaultImage} from '../../../assets/images';
import {AddSquare, ArrowLeft, LogoutCurve, SearchNormal1} from 'iconsax-react';
import {motion} from 'framer-motion';

const BuyerChatScreen = () => {
  const supportUser = useBuyerSupport().supportBuyer;
  const dispatch = useAppDispatch();

  const {
    updateChatUsers,
    chatUsers,
    updatePotentialChats,
    updateCurrentChat,
    currentChat,
    updateChatMessages,
    chatMessages,
    updateAllUsers,
    notifications,
  } = useContext(ChatContext);
  const {user, updateUser} = useContext(AuthContext);
  const [loading, setLoading] = useState({
    chatUsersLoading: false,
    potentialUsersLoading: false,
    chatMessagesLoading: false,
  });
  const {chatMessagesLoading, potentialUsersLoading} = loading;
  const [myLoading, setMyLoading] = useState(false);
  const [activeChat, setActiveChat] = useState(1);
  const navigate = useNavigate();

  const scroll = useRef();

  // // Loading only at the first time
  // useEffect(() => {
  //   // Simulate loading data
  //   const timer = setTimeout(() => {
  //     setMyLoading(false); // Set loading state to false after initial loading
  //   }, 2000);

  //   // Clean up timer on component unmount
  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    updateUser(supportUser);
    const getChatUsers = async () => {
      try {
        // setMyLoading(true);
        setLoading({...loading, chatUsersLoading: true});
        const response = await Actions.fetchUserChats();
        // if (response?.badToken) localStorage.removeItem('supportInfo');
        if (response?.status === 'success') {
          updateChatUsers(response?.data?.details);
        }
        setMyLoading(false);
        setLoading({...loading, chatUsersLoading: false});
      } catch (error) {
        console.log('chat user error: ', error);
        setMyLoading(false);
      }
    };
    getChatUsers();
  }, [navigate, notifications]);

  useEffect(() => {
    const getPotentialChatUsers = async () => {
      try {
        setLoading({...loading, potentialUsersLoading: true});
        const response = await Actions.fetchAllBuyerUsers();
        // console.log('response', response);
        if (response?.badToken) localStorage.removeItem('supportBuyerInfo');
        if (response?.status === 'success') {
          const otherUsers = response?.data?.vendors?.rows?.filter(
            (singleUser) => singleUser?.id !== user?.id
          );
          updateAllUsers(response?.data?.vendors?.rows);
          dispatch(setChatBuyers(response?.data?.vendors?.rows));
          const arrOfIds = chatUsers?.reduce(
            (ids, item) => [...ids, ...item.members],
            []
          );
          const potentialUsers = otherUsers?.filter(
            (user) => !arrOfIds.includes(user?.id)
          );
          updatePotentialChats(potentialUsers);
          setLoading({...loading, potentialUsersLoading: false});
        } else if (
          (response?.status === 'failed' &&
            response?.message === 'Invalid token or token expired') ||
          response?.message === 'Authorization denied! Token expired'
        ) {
          localStorage.clear();
          window.location.reload();
        } else if (
          response?.status === 'failed' &&
          response?.message === 'ERR no such key'
        ) {
          console.log(response?.message);
        } else {
          console.log(response?.message || 'Connection Error');
        }
      } catch (error) {
        console.log('potential chat user error: ', error);
      }
    };
    getPotentialChatUsers();
  }, [chatUsers]);

  useEffect(() => {
    const getChatMessages = async () => {
      try {
        if (currentChat?._id) {
          setLoading({...loading, chatMessagesLoading: true});
          const response = await Actions.findChatMessages(currentChat?._id);
          if (response?.badToken) localStorage.removeItem('supportBuyerInfo');
          if (response?.status === 'success') {
            updateChatMessages(response?.data?.details);
          } else if (
            (response?.status === 'failed' &&
              response?.message === 'Invalid token or token expired') ||
            response?.message === 'Authorization denied! Token expired'
          ) {
            localStorage.clear();
            window.location.reload();
          } else if (
            response?.status === 'failed' &&
            response?.message === 'ERR no such key'
          ) {
            console.log(response?.message);
          } else {
            console.log(response?.message || 'Connection Error');
          }
        }
        // const response = await Actions.findChatMessages(currentChat?.id)
      } catch (error) {
        console.log('chat message error: ', error);
      }
    };
    getChatMessages();
  }, [currentChat]);

  useEffect(() => {
    scroll?.current?.scrollIntoView({behavior: 'smooth'});
  }, [chatMessages]);

  // const [currentChatUser, setCurrentChatUser] = useState(null)

  // const navigate = useNavigate()
  // useEffect(() => {
  //   const getResponse = async () => {
  //     const loggedInUser = await Actions.details(userDispatch)
  //     if(loggedInUser?.badToken) {
  //       Actions.logout(userDispatch)
  //       navigate('/');
  //       return;
  //     }

  //       await Actions.fetchChatUsers(dispatch),
  //       await Actions.users(potentialUsersDispatch)
  //   }
  //   getResponse()

  // }, [navigate, currentChatUser])

  // Show create new chat modal
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    // Lock scrolling when the drawer is opened
    document.body.style.overflow = isDrawerOpen ? 'auto' : 'hidden';
  };
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);

  const toggleProfileDrawer = () => {
    setIsProfileDrawerOpen(!isProfileDrawerOpen);
    // Lock scrolling when the drawer is opened
    document.body.style.overflow = isProfileDrawerOpen ? 'auto' : 'hidden';
  };

  const isMobile = window.matchMedia(
    'only screen and (max-width: 760px)'
  ).matches;
  if (isMobile) {
    return (
      <div className='text-lg text-center font-poppinsRegular bg-primary text-white flex justify-center items-center p-8 h-screen'>
        Sorry, this admin app is only accessible by tablet, TV or desktop users.
        Kindly use the supported devices.
      </div>
    );
  }

  return (
    <div className='chat-container w-full h-screen pt-10'>
      <div className='xl:container w-full mx-auto'>
        {/* <PotentialChats loading={potentialUsersLoading} /> */}
        <div className='flex flex-row justify-end'>
          <Notifications />
        </div>
        <div className='text-white align-items-start bg-gray-800 flex flex-row gap-4 h-full'>
          <div className='messages-box flex-grow-0  bg-[#0b0b0ba1] h-full gap-4'>
            <div className='bg-[#0b0b0bb6] w-[390px] py-2 flex flex-row justify-between items-center px-2 relative'>
              <div
                onClick={toggleProfileDrawer}
                className='h-10 w-10 cursor-pointer'>
                <img
                  src={DefaultImage}
                  alt='dft'
                  className='rounded-full w-full h-full object-cover'
                />
              </div>
              <div className='font-poppinsSemibold text-white'>
                Support Desk
              </div>
              <motion.button whileTap={{scale: 0.97}} onClick={toggleDrawer}>
                <AddSquare color='gray' size={24} />
              </motion.button>

              {/* Drawer */}
              {isDrawerOpen && (
                <div className='absolute top-0 left-0'>
                  <InitiateAnotherChat
                    isOpenDrawer={isDrawerOpen}
                    onClose={toggleDrawer}
                    loading={potentialUsersLoading}
                  />
                </div>
              )}
              {/* Profile Drawer */}
              {isProfileDrawerOpen && (
                <div className='absolute top-0 left-0'>
                  <Profile
                    isOpenDrawer={isProfileDrawerOpen}
                    onClose={toggleProfileDrawer}
                  />
                </div>
              )}
            </div>
            {myLoading && (
              <div className='flex flex-row h-full justify-center items-center'>
                <div className='loader'></div>
              </div>
            )}
            {chatUsers?.map((chat, i) => {
              return (
                <div
                  className={
                    activeChat === chat._id
                      ? 'pe-3 bg-blue-950 rounded-md'
                      : 'pe-3'
                  }
                  key={i}
                  onClick={() => {
                    updateCurrentChat(chat);
                    setActiveChat(chat._id);
                  }}>
                  <ChatUser chat={chat} activeChat={activeChat} />
                </div>
              );
            })}
          </div>
          <ChatBox loading={chatMessagesLoading} scroll={scroll} />
        </div>
      </div>
    </div>
  );
};

export default BuyerChatScreen;

const InitiateAnotherChat = ({onClose, isOpenDrawer, loading}) => {
  // Search
  const [searchQuery, setSearchQuery] = useState('');
  const keys = ['firstName'];
  const search = (data) => {
    return data.filter((item) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(searchQuery.toLocaleLowerCase())
      )
    );
  };

  const {updateChatUsers, onlineUsers, potentialChats} =
    useContext(ChatContext);
  // const { user } = useContext(AuthContext)

  // handleFireChatStraight();

  async function handleAddChat(id) {
    const response = await Actions.createChat({secondUser: id});
    if (response?.status === 'success') {
      // console.log('response', response.data);
      updateChatUsers((prev) => [...prev, response?.data?.details]);
    } else if (
      response?.status === 'failed' &&
      response?.message === 'Invalid token or token expired'
    ) {
      localStorage.clear();
      window.location.reload();
    } else if (
      response?.status === 'failed' &&
      response?.message === 'ERR no such key'
    ) {
      console.log(response?.message);
    } else {
      console.log(response?.message || 'Connection Error');
    }
    // handleFireChatStraight();
  }

  if (loading)
    <div style={{width: '100%', textAlign: 'center'}}>Loading users...</div>;

  const vendorSearch = search(potentialChats);
  return (
    <motion.div
      className={`fixed z-50 w-[390px] bg-[#141414] h-[85vh]`}
      initial={{x: '-100%'}}
      animate={{x: isOpenDrawer ? '0' : '-100%'}}
      transition={{type: 'tween'}}>
      <div className='h-full pb-2 overflow-hidden'>
        <div className='px-2 py-3 flex flex-row gap-4 items-center bg-gray-800'>
          {/* goback */}
          <div onClick={onClose} className='cursor-pointer'>
            <ArrowLeft size={24} />
          </div>
          <div>
            <h1 className={`text-white font-poppinsMedium text-base`}>
              New Cart
            </h1>
          </div>
        </div>
        {/* Search */}
        <div className='bg-gray-800 flex flex-row items-center w-[99%] mx-auto rounded-md py-1 px-4 mt-1'>
          <input
            type='search'
            placeholder='Who are you looking for?'
            className=' p-2 text-white h-full w-full focus:border-transparent focus:shadow-none focus:outline-none bg-transparent font-poppinsRegular'
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
          />
          <div className='icon cursor-pointer'>
            <SearchNormal1 size={20} />
          </div>
        </div>

        <div className='py-6 h-[90%] overflow-auto'>
          {vendorSearch.map((user) => (
            <div
              key={user?.email}
              onClick={() => {
                handleAddChat(user.id);
                onClose();
              }}
              direction='horizontal'
              gap={3}
              className='user-card align-items-center p-2 justify-content-between'
              role='button'>
              <div className='flex flex-row gap-4 items-center'>
                <div className='me-2'>
                  <div className='border-[0.3px] border-gray-600 rounded-full'>
                    <div className='h-[35px] w-[35px]'>
                      <img
                        src={DefaultImage}
                        alt='vendorIm'
                        className='rounded-full w-full h-full object-cover'
                      />
                    </div>
                  </div>
                </div>
                <div className='text-context'>
                  <div className='name'>{user?.firstName}</div>
                </div>
                <span
                  className={
                    onlineUsers?.some(
                      (onlineUser) => onlineUser?.userId === user?.id
                    )
                      ? 'user-online'
                      : ''
                  }></span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

const Profile = ({onClose, isOpenDrawer}) => {
  // Search
  const user = useBuyerSupport().supportBuyer;
  // console.log(user);
  return (
    <motion.div
      className={`fixed z-50 w-[390px] bg-[#141414] h-[85vh]`}
      initial={{x: '-100%'}}
      animate={{x: isOpenDrawer ? '0' : '-100%'}}
      transition={{type: 'tween'}}>
      <div className='h-full pb-2'>
        <div className='px-2 py-3 flex flex-row gap-4 items-center bg-gray-800'>
          {/* goback */}
          <div onClick={onClose} className='cursor-pointer'>
            <ArrowLeft size={24} />
          </div>
          <div>
            <h1 className={`text-white font-poppinsMedium text-base`}>
              Profile
            </h1>
          </div>
        </div>
        <div className='pt-4 h-full p-6'>
          {/* Image */}
          <div className='w-full flex flex-row justify-center'>
            <div className='w-[200px] h-[200px]'>
              <img
                src={DefaultImage}
                alt='profileImg'
                className='object-cover w-full h-full rounded-full'
              />
            </div>
          </div>
          {/* Profile Details */}
          <div className='py-10'>
            <p className='text-primary font-poppinsRegular text-xs'>
              Your Name
            </p>
            <p className='text-white font-poppinsRegular italic pt-1 text-base'>
              {`${user?.firstName}  ${user?.lastName}`}
            </p>
          </div>
          <div className='py-10'>
            <p className='text-primary font-poppinsRegular text-xs'>
              Your Role
            </p>
            <p className='text-white font-poppinsRegular italic pt-1 text-base'>
              {user?.role}
            </p>
          </div>
          {/* Logout */}
          <div
            onClick={() => {
              localStorage.removeItem('supportBuyerInfo');
              window.location.reload();
            }}
            className=' flex flex-row items-center gap-1 text-red-500 cursor-pointer hover:text-red-800'>
            <div>
              <LogoutCurve className='w-full h-full' size={20} />
            </div>
            <div className=''>Logout</div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
