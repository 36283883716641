import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {ICouponReducerReducer} from '../../../types';

const initialState: ICouponReducerReducer = {
  coupon: localStorage.getItem('#coupon')
    ? JSON.parse(localStorage.getItem('#coupon') || '{}')
    : null,
};

const couponReducer = createSlice({
  name: 'coupon',
  initialState,
  reducers: {
    setSingleACoupon: (state, action) => {
      state.coupon = action.payload;
      localStorage.setItem('#coupon', JSON.stringify(action.payload));
    },
  },
});

export const {setSingleACoupon} = couponReducer.actions;
export const selectCoupon = (state: RootState) => state.coupon.coupon;

export default couponReducer.reducer;
