import {NavLink} from 'react-router-dom';
import {useState} from 'react';
import {HiMenuAlt3} from 'react-icons/hi';
import logo from '../../assets/images/logo.png';
import {IMenu} from '../../pages/dashboard/sidebar.interface';
import {COLORS} from '../../assets/color';
import {motion} from 'framer-motion';
import styles from '../../assets/styles/modals/ConfirmModal.module.css';
import {LogoutCurve} from 'iconsax-react';
// import {useDispatch} from 'react-redux';

interface ISidebar {
  logoutHandler: () => void;
  appScreens: IMenu[];
  isOpenDrawer?: boolean;
  onClose: () => void;
}
const MobileSidebar = ({
  logoutHandler,
  appScreens,
  onClose,
  isOpenDrawer,
}: ISidebar) => {
  // const dispatch = useDispatch();
  const [isOpenLogout, setIsOpenLogout] = useState(false);
  return (
    <div className=''>
      {isOpenLogout && (
        <div className='h-screen'>
          <div className='z-[999] absolute top-[45%] left-[10%]'>
            <ConfirmLogout
              setIsOpen={setIsOpenLogout}
              logoutHandler={logoutHandler}
            />
          </div>
          <div
            className='bg-[#002668] w-screen h-full z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-30 border-2'
            onClick={() => setIsOpenLogout(true)}
          />
        </div>
      )}
      <motion.div
        className={`fixed inset-y-0 left-0 z-40 w-4/5 bg-white p-4`}
        initial={{x: '-100%'}}
        animate={{x: isOpenDrawer ? '0' : '-100%'}}
        transition={{type: 'tween'}}>
        <div>
          <div className='py-3 flex flex-row justify-between items-center'>
            {/* Logo */}
            <div className='w-10 h-10'>
              <img
                src={logo}
                alt='logo'
                className='w-full h-full object-contain'
              />
            </div>
            <div>
              <h1 className={`text-neutral-600 font-poppinsMedium text-xl`}>
                People's Store
              </h1>
            </div>
            <div>
              <HiMenuAlt3
                size={30}
                className='cursor-pointer'
                color={COLORS.primary}
                onClick={onClose}
              />
            </div>
          </div>

          <div className='relative h-full pt-4'>
            {appScreens.map((link, i) => (
              <SidebarMobileLink
                key={link.key}
                link={link}
                i={i}
                setShowMenu={onClose}
              />
            ))}
            <div className='absolute -bottom-20'>
              <div
                className='h-8 w-8 cursor-pointer'
                onClick={() => setIsOpenLogout(true)}>
                <LogoutCurve size={22} color={COLORS.primary} />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MobileSidebar;

type IsidebarLink = {
  link: any;
  i: any;
  setShowMenu?: any;
};

function SidebarMobileLink({i, link, setShowMenu}: IsidebarLink) {
  const [activeId, setActiveId] = useState(1);

  return (
    <div
      className='flex flex-col relative lg:group'
      onClick={() => setShowMenu(false)}>
      <NavLink
        to={link.path}
        className={({isActive}) => {
          return (
            'md:text-lg flex flex-row py-3 items-center gap-4 px-2 rounded-md cursor-pointer my-2' +
            (!isActive
              ? ' text-primary hover:bg-primary hover:text-white'
              : 'hh text-[#FFFFFF] bg-primary ')
          );
        }}>
        <span
          className='flex  flex-row items-center gap-3'
          onClick={() => setActiveId(link.id)}>
          {activeId === link.id ? (
            <span>{link.iconTwo}</span>
          ) : (
            <span>{link.icon}</span>
          )}
        </span>

        <span
          style={{
            transitionDelay: `${i + 3}00ms`,
          }}
          className={`duration-500 font-poppinsRegular ${' overflow-hidden font-poppinsRegular'}`}>
          {link.item}
        </span>
      </NavLink>
    </div>
  );
}

interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  logoutHandler?: () => void;
}
const ConfirmLogout = ({setIsOpen, logoutHandler}: IModal) => {
  return (
    <div>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          Are you sure you want to logout?
        </div>
        <div className={styles.modalActions}>
          <div className={styles.actionsContainer}>
            <button
              className={styles.cancelBtn}
              onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button
              className={styles.deleteBtn}
              onClick={() => {
                logoutHandler && logoutHandler();
                setIsOpen(false);
              }}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
