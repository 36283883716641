import {IBusinessInfo} from '../../../../../types';
import CustomSelect from '../../../../../components/forms/inputs/selectInput/CustomSelect';
import {useEffect, useState} from 'react';
import {VendorServices} from '../../../../../redux/services/vendor/vendor';
import {axiosError} from '../../../../../components/Helpers';
import InputFieldAuth from '../../../../../components/forms/inputs/inputField/InputFieldAuth';

const BusinessInfo = ({
  businessName,
  businessType,
  vendorType,
  r,
  setVendorInfo,
}: IBusinessInfo) => {
  const [businessTypeOption, setBusinessTypeOption] = useState<any[]>([]);
  const [vendorTypeOption, setVendorTypeOption] = useState<any[]>([]);

  // Handle Business Type
  const handleBusinnessType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const businessType = event.target.value;
    const selectedBusinessType = businessTypeOption.find(
      (c) => c.name === businessType
    );

    if (selectedBusinessType) {
      setVendorTypeOption(selectedBusinessType.sub_businesses);
      setVendorInfo((prevData: any) => ({
        ...prevData,
        businessType: businessType,
        vendorType: '',
      }));
    } else {
      setVendorTypeOption([]);
      setVendorInfo((prevData: any) => ({
        ...prevData,
        businessType: '',
        vendorType: '',
      }));
    }
  };

  // Handle Vendor Type
  const handleVendorType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setVendorInfo((prevFormData: any) => ({
      ...prevFormData,
      vendorType: value,
    }));
  };
  // Get Category
  useEffect(() => {
    const getBusinessTypw = async () => {
      try {
        const response = await VendorServices.getBusinessType();
        // console.log(response);
        if (response.data.status === 'success') {
          const res = response.data.data.details;
          setBusinessTypeOption(res.rows);
        }
      } catch (error: any) {
        // console.log(error);
        axiosError(error.response);
        // setIsLoading(false);
      }
    };
    getBusinessTypw();
  }, []);

  return (
    <div className='flex flex-col gap-2'>
      {/* Business Name */}
      <InputFieldAuth
        label='Business Name'
        name='businessName'
        type='text'
        placeholder='Business Name'
        value={businessName}
        required={true}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      {/* Business Type */}
      <div className='pb-1 flex gap-2 items-start flex-row'>
        <p className='font-poppinsRegular'>Business Type</p>
        <p className='text-red-600 text-base'>*</p>
      </div>
      <CustomSelect
        label='Business Type'
        handleChange={handleBusinnessType}
        name='businessType'
        valueField={businessType}
        options={businessTypeOption}
      />
      {/* Vendor Type */}
      <div className='pb-1 flex gap-2 items-start flex-row'>
        <p className='font-poppinsRegular'>Vendor Type</p>
        <p className='text-red-600 text-base'>*</p>
      </div>
      <CustomSelect
        label='Vendor Type'
        handleChange={handleVendorType}
        name='vendorType'
        valueField={vendorType}
        options={vendorTypeOption}
      />
      {/*Button */}
      <div className='flex flex-row items-center justify-between gap-4 py-4 w-full'>
        <button
          className='bg-primary text-white sm:px-20 w-full py-3 transition-hover rounded-md'
          onClick={() => r.prevStep()}>
          Prev
        </button>
        <button
          className='bg-primary text-white sm:px-20 w-full py-3 transition-hover rounded-md disabled:opacity-60'
          onClick={() => r.nextStep()}
          disabled={
            !businessName || !businessType || !vendorType ? true : false
          }>
          Next
        </button>
      </div>
    </div>
  );
};

export default BusinessInfo;
