/* eslint-disable react-hooks/exhaustive-deps */
import {createContext, useCallback, useState} from 'react';

export const AuthContext = createContext();

const AuthContextProvider = ({children}) => {
  const [user, setUser] = useState(null);

  const updateUser = useCallback(
    (info) => {
      setUser(info);
    },
    [user]
  );

  return (
    <AuthContext.Provider
      value={{
        user,
        updateUser,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
