import {useDispatch, useSelector, TypedUseSelectorHook} from 'react-redux';
import {RootState, AppDispatch} from './store';
import {selectVendor, selectVendorToken} from '../redux/slices/vendorSlice';
import {selectAdmin, selectAdminToken} from '../redux/slices/admin/userSlice';
import {useMemo} from 'react';
import {
  selectProduct,
  selectProducts,
} from '../redux/slices/product/productSlice';
import {selectAdminVendor} from '../redux/slices/vendor/adminVendorSlice';
import {selectAdminBuyer} from '../redux/slices/buyers/adminBuyerSlice';
import {selectAdminOrder} from '../redux/slices/admin/adminOrderSlice';
import {selectChatVendors} from '../redux/slices/chat/chatVendorSlice';
import {
  selectBuyerSupport,
  selectBuyerSupportToken,
  selectSupport,
  selectSupportToken,
} from '../redux/slices/support/userSlice';
import {selectAdminSettlement} from '../redux/slices/settlements/adminSettlementSlice';
import {selectCoupon} from '../redux/slices/coupon/couponSlice';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useVendor = () => {
  const vendor = useSelector(selectVendor);
  const token = useSelector(selectVendorToken);

  return useMemo(() => ({vendor, token}), [vendor, token]);
};

export const useAdmin = () => {
  const admin = useSelector(selectAdmin);
  const adminToken = useSelector(selectAdminToken);

  return useMemo(() => ({admin, adminToken}), [admin, adminToken]);
};
export const useSupport = () => {
  const support = useSelector(selectSupport);
  const supportToken = useSelector(selectSupportToken);

  return useMemo(() => ({support, supportToken}), [support, supportToken]);
};
export const useBuyerSupport = () => {
  const supportBuyer = useSelector(selectBuyerSupport);
  const supportBuyerToken = useSelector(selectBuyerSupportToken);

  return useMemo(
    () => ({supportBuyer, supportBuyerToken}),
    [supportBuyer, supportBuyerToken]
  );
};

export const useProduct = () => {
  const product = useSelector(selectProduct);
  const products = useSelector(selectProducts);

  return useMemo(() => ({product, products}), [product, products]);
};

export const useAdminVendor = () => {
  const adminVendor = useSelector(selectAdminVendor);

  return useMemo(() => ({adminVendor}), [adminVendor]);
};

export const useAdminBuyer = () => {
  const adminBuyer = useSelector(selectAdminBuyer);

  return useMemo(() => ({adminBuyer}), [adminBuyer]);
};
export const useAdminOrder = () => {
  const adminOrder = useSelector(selectAdminOrder);

  return useMemo(() => ({adminOrder}), [adminOrder]);
};
export const useChat = () => {
  const chatVendors = useSelector(selectChatVendors);

  return useMemo(() => ({chatVendors}), [chatVendors]);
};
export const useSettlement = () => {
  const settlement = useSelector(selectAdminSettlement);

  return useMemo(() => ({settlement}), [settlement]);
};
export const useCoupon = () => {
  const coupon = useSelector(selectCoupon);

  return useMemo(() => ({coupon}), [coupon]);
};
