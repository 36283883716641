import styles from '../../assets/styles/modals/ConfirmModal.module.css';
interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  logoutHandler?: () => void;
}
const ConfirmLogout = ({setIsOpen, logoutHandler}: IModal) => {
  return (
    <>
      <div
        className='bg-[#002668] w-screen h-screen z-50 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-30'
        onClick={() => setIsOpen(true)}
      />
      {/* <div className={styles.darkBGLogout} onClick={() => setIsOpen(true)} /> */}
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            Are you sure you want to logout?
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}>
                Cancel
              </button>
              <button
                className={styles.deleteBtn}
                onClick={() => {
                  logoutHandler && logoutHandler();
                  setIsOpen(false);
                }}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmLogout;
