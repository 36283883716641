import React from 'react';

interface ICouponFormCard {
  title: string;
  isRequired?: boolean;
  content: React.ReactNode;
}
const CouponFormCard = ({title, isRequired, content}: ICouponFormCard) => {
  return (
    <div className='bg-white rounded-lg shadow-sm p-10 lg:w-[48%] w-full'>
      <div>
        <p className='text-lg font-poppinsMedium'>
          {title}

          {isRequired === true && (
            <span className='inline-flex px-2 text-lg font-poppinsMedium -top-2 right-0'>
              *
            </span>
          )}
        </p>
      </div>
      {content}
    </div>
  );
};

export default CouponFormCard;
