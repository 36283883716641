/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {useCallback, useEffect, useState} from 'react';
import {useAdmin} from '../../../app/hooks';
import {axiosError, numberFormat} from '../../../components/Helpers';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import Pagination from '../../../components/ui/Pagination';
import {AdminServices} from '../../../redux/services/admin/admin';
import PaymentRequestTable from '../../../components/dashboard/PaymentRquestTable';
import {ISettlement} from '../../../components/interfaces/settlement';
import CustomDatePicker from '../../../components/forms/inputs/selectInput/CustomDatePicker';
import {addMonths, isWithinInterval, parseISO} from 'date-fns';
import {toast} from 'react-toastify';
import Dropdown from '../../../components/forms/Dropdown';
import Spinner from '../../../components/spinner/Spinner';
import DateRangePicker from '../../../components/forms/inputs/selectInput/DateRangePicker';

const Settlements = () => {
  localStorage.setItem('currentPath', '/vendor/orders');
  const [settlements, setSettlements] = useState<any>([]);
  const token = useAdmin().adminToken;
  const [isLoading, setIsLoading] = useState(false);

  // Query
  const [query, setQuery] = useState('');
  const keys = ['bankName', 'name', 'productName', 'status', 'amount'];
  const search = (data: any) => {
    return data?.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };

  // Sort --------------------
  const [openSort, setOpenSort] = useState(false);

  const [sortingOption, setSortingOption] = useState<keyof ISettlement>('name');
  const [isAscending, setIsAscending] = useState(false);

  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const sortedData = (data: any) => {
    return [...data].sort((a, b) => {
      // Use the selected sorting option
      const sortOrder = isAscending ? 1 : -1;
      const aValue = a[sortingOption];
      const bValue = b[sortingOption];

      // Compare values based on the sorting order
      if (aValue < bValue) return -1 * sortOrder;
      if (aValue > bValue) return 1 * sortOrder;
      return 0;
    });
  };

  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Get Settlements

  // Filter Start and End date
  const [startDate, setStartDate] = useState(new Date('2024-04-01'));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 6));

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  // Get Vendors
  useEffect(() => {
    getSettlements();
  }, [startDate, endDate]);

  const getSettlements = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getSettlementsWithDateRange(
        token,
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0]
      );
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response?.data.data.details;
        setSettlements(res);
        // console.log(res.rows);
        setIsLoading(false);
        // console.log('profile', profile);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  const totalFilteredData = search(settlements);
  const sortedFilteredData = sortedData(totalFilteredData);
  const currentTableData = sortedFilteredData.slice(
    firstPageIndex,
    lastPageIndex
  );
  const totalPages = Math.ceil(sortedFilteredData.length / PageSize);

  // Handle Selected and select Row
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [selectedAmounts, setSelectedAmounts] = useState<number[]>([]);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    amount: number
  ) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, id]);
      setSelectedAmounts((prevSelectedRows) => [...prevSelectedRows, amount]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowId) => rowId !== id)
      );
      setSelectedAmounts((prevSelectedRows) =>
        prevSelectedRows.filter((amt, index) => selectedRows[index] !== id)
      );
    }
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const allRowIds = totalFilteredData.map((row: any) => row.id.toString());
      const allRowAmounts = totalFilteredData.map((row: any) =>
        Number(row.amount)
      );
      setSelectedRows(allRowIds);
      setSelectedAmounts(allRowAmounts);
    } else {
      setSelectedRows([]);
      setSelectedAmounts([]);
    }
    setSelectAll(event.target.checked);
  };

  // Effect to update selectAll state based on selectedRows
  useEffect(() => {
    const total = selectedAmounts.reduce((acc, num) => acc + Number(num), 0);
    setTotalAmount(total);

    if (selectedRows.length === totalFilteredData.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [totalAmount, selectedRows, totalFilteredData.length]);

  //  Download selected rows to csv
  const downloadSelectedRows = () => {
    // Filter the data based on selectedRows
    // const selectedData = search(
    //   totalFilteredData.filter((row: any) =>
    //     selectedRows.includes(row.id.toString())
    //   )
    // );
    const selectedData = settlements;

    // Create CSV headers
    const csvHeaders = [
      '#ID',
      'Product Name',
      'Vendor Name',
      'Amount',
      'Account Number',
      'Account Name',
      'Bank Name',
      'Status',
    ];

    // Convert selectedData to CSV format
    const csvData: string[] = [];
    csvData.push(csvHeaders.join(',')); // Add headers to the CSV data
    selectedData.forEach((row: any) => {
      csvData.push(
        `${row.id},${row.productName},${row.name},${row.amount},${row.accountNo},${row.accountName},${row.bankName}, ${row.status}`
      );
    });

    // Create a CSV string
    const csvString = csvData.join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvString], {type: 'text/csv'});

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'settlements.csv');

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Handle Drop Down
  const options = [
    {value: 'paid', label: 'Paid'},
    {value: 'declined', label: 'Declined'},
  ];

  const [selectedStatus, setSelectedStatus] = useState('');
  const handleDropdownChange = (selectedValue: string) => {
    // console.log('Selected Value:', selectedValue);
    // Implement your logic here when the dropdown value changes
    setSelectedStatus(selectedValue);
  };

  // Update Settlement Handler
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  // const [isFeedback, setIsFeedBack] = useState(false);
  // const [reason, setReason] = useState('');
  const [enabledCheckboxRow, setEnabledCheckboxRow] = useState<
    number | null | string
  >(null);

  const updateSettlement = async () => {
    // Data
    const data = {
      status: selectedStatus,
      settlementIds: selectedRows,
      rejectionReason: '',
    };

    setIsUpdateLoading(true);
    try {
      const response = await AdminServices.updateSettlements(token, data);
      // console.log(response);
      if (response.data.status === 'success') {
        toast.success(response.data.data.message);
        setIsUpdateLoading(false);

        await getSettlements();
        setIsUpdateLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      console.log(error);
      setIsUpdateLoading(false);
    }
  };
  // console.log(selectedRows);

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        {isUpdateLoading && <Spinner />}
        <Helmet>
          <meta charSet='utf-8' />
          <title>Settlements</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>SETTLEMENTS</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='flex flex-row items-center justify-end gap-6 pt-6'>
          <button
            onClick={downloadSelectedRows}
            className='bg-primary text-white px-3 py-2 text-center transition-hover rounded-sm'>
            Download CSV
          </button>
          {/* Sort */}
          <div
            onClick={() => setOpenSort(!openSort)}
            className='flex flex-row items-center gap-4 cursor-pointer'>
            <div className='font-poppinsMedium'>Sort by:</div>
            <div className='relative'>
              <div className='flex flex-row items-center gap-10'>
                <p className='text-[#868FE1] text-xs font-poppinsMedium'>
                  {sortingOption === 'bankName'
                    ? 'Bank Name'
                    : sortingOption === 'productName'
                    ? 'Product Name'
                    : sortingOption === 'name'
                    ? 'Vendor Name'
                    : 'Status'}
                </p>
                <BiChevronDown size={20} color='#868FE1' />
              </div>
              {/* Sort DropDown */}
              {openSort && (
                <div
                  onClick={() => setOpenSort(false)}
                  className='bg-white w-full px-2 py-2 flex flex-col items-start absolute rounded-md shadow-md top-8 z-50 text-xs gap-2'>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('name')}>
                    Vendor Name
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('bankName')}>
                    Bank Name
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('productName')}>
                    Product Name
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('status')}>
                    Status
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Date Filter */}
          <div className=''>
            <DateRangePicker
              endDate={endDate}
              onEndDateChange={handleEndDateChange}
              onStartDateChange={handleStartDateChange}
              startDate={startDate}
            />
          </div>
        </div>
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <PaymentRequestTable
                payments={currentTableData}
                handleCheckboxChange={handleCheckboxChange}
                handleSelectAllChange={handleSelectAllChange}
                selectAll={selectAll}
                selectedRows={selectedRows}
                selectedStatus={selectedStatus}
                enabledCheckboxRow={enabledCheckboxRow}
              />
            )}
          </div>
        </div>

        {/* Button */}
        <div className='flex flex-row items-center gap-10 justify-end w-full'>
          <div
            className={
              selectedRows.length > 0
                ? 'flex flex-row justify-center gap-10 py-6'
                : 'flex flex-row justify-center gap-10 py-6 w-full'
            }>
            {/* Custom Dropdown */}
            <Dropdown options={options} onChange={handleDropdownChange} />

            {/* Button */}
            <button
              disabled={
                selectedStatus === 'paid' && selectedRows.length > 0
                  ? false
                  : true
              }
              onClick={updateSettlement}
              className='bg-primary px-7 py-3 text-white transition-hover rounded-sm font-poppinsMedium disabled:bg-gray-200'>
              Submit
            </button>
          </div>
          {selectedRows.length > 0 && (
            <div className='flex gap-4 items-center flex-row pt-2 border-t border-b border-t-primary border-b-primary'>
              <p className='text-lg'> Total Amount</p>
              <p className=' font-poppinsBold'> ₦{numberFormat(totalAmount)}</p>
            </div>
          )}
        </div>
        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;

export default Settlements;
