export const productSummary = [
  {
    name: 'Laptops',
    sales: 9800,
  },
  {
    name: 'Television',
    sales: 4567,
  },
  {
    name: 'Desktops',
    sales: 3908,
  },
  {
    name: 'Phones',
    sales: 2400,
  },
  {
    name: 'Air Conditioner',
    sales: 1908,
  },
  {
    name: 'Dish Washer',
    sales: 1398,
  },
];
export const dailySales = [
  {
    name: 'Monday',
    sales: 2000,
  },
  {
    name: 'Tuesday',
    sales: 6000,
  },
  {
    name: 'Wednesday',
    sales: 500,
  },
  {
    name: 'Thursday',
    sales: 2400,
  },
  {
    name: 'Friday',
    sales: 5000,
  },
  {
    name: 'Saturday',
    sales: 1398,
  },
];
export const userSummary = [
  {
    user: 'Vendors',
    total: 1000,
  },
  {
    user: 'Buyers',
    total: 2000,
  },
  {
    user: 'Admins',
    total: 200,
  },
  {
    user: 'Staffs',
    total: 250,
  },
];
export const visitorsSummary = [
  {
    name: 'Buyers',
    visitors: 2000,
  },
  {
    name: 'Vendors',
    visitors: 4567,
  },
  {
    name: 'NonUser',
    visitors: 3908,
  },
];

export const productSummaryBar = [
  {
    name: 'Jan',
    sales: 190000,
  },
  {
    name: 'Feb',
    sales: 100000,
  },
  {
    name: 'March',
    sales: 75000,
  },
  {
    name: 'April',
    sales: 190000,
  },
  {
    name: ' May',
    sales: 120000,
  },
  {
    name: 'June',
    sales: 110000,
  },
  {
    name: 'July',
    sales: 140000,
  },
  {
    name: ' Aug',
    sales: 160000,
  },
  {
    name: 'Sep',
    sales: 130000,
  },
  {
    name: 'Oct',
    sales: 169000,
  },
  {
    name: ' Nov',
    sales: 25500,
  },
  {
    name: 'Dec',
    sales: 180000,
  },
];

export const bankData = [
  {id: '1', name: 'Access Bank', code: '5d6fe57a4099cc4b210bbec0'},
  {id: '2', name: 'Citibank', code: '611ff0ee412b11f1b2143bc5'},
  {id: '3', name: 'Diamond Bank', code: '5f63f473d550180010a14bf1'},
  {id: '5', name: 'Ecobank Nigeria', code: '5d6fe57a4099cc4b210bbeae'},
  {id: '6', name: 'Fidelity Bank Nigeria', code: '070'},
  {id: '7', name: 'First Bank of Nigeria', code: '5d6fe57a4099cc4b210bbeb1'},
  {
    id: '8',
    name: 'First City Monument Bank',
    code: '5d6fe57a4099cc4b210bbeb2',
  },
  {id: '9', name: 'Guaranty Trust Bank', code: '5d6fe57a4099cc4b210bbeb3'},
  {id: '10', name: 'Heritage Bank Plc', code: '030'},
  {id: '11', name: 'Jaiz Bank', code: '5fb906f78c486a00b987ec06'},
  {id: '12', name: 'Keystone Bank Limited', code: '082'},
  {id: '13', name: 'Providus Bank Plc', code: '101'},
  {id: '14', name: 'Polaris Bank', code: '5d6fe57a4099cc4b210bbeb5'},
  {id: '15', name: 'Stanbic IBTC Bank Nigeria Limited', code: '221'},
  {
    id: '16',
    name: 'Standard Chartered Bank',
    code: '5d6fe57a4099cc4b210bbeb7',
  },
  {id: '17', name: 'Sterling Bank', code: '5d6fe57a4099cc4b210bbeb8'},
  {id: '18', name: 'Suntrust Bank Nigeria Limited', code: '100'},
  {id: '19', name: 'Union Bank of Nigeria', code: '5d6fe57a4099cc4b210bbeb9'},
  {
    id: '20',
    name: 'United Bank for Africa',
    code: '5d6fe57a4099cc4b210bbebb',
  },
  {id: '21', name: 'Unity Bank Plc', code: '215'},
  {id: '22', name: 'Wema Bank', code: '5d6fe57a4099cc4b210bbebc'},
  {id: '23', name: 'Zenith Bank', code: '5d6fe57a4099cc4b210bbec2'},
];

export const resolutionData = [
  {
    id: '12354788',
    name: 'Techno S3',
    amount: 523000,
    date: '13-04-2024',
    status: 'Successful',
  },
  {
    id: '12354728',
    name: 'Iphone 14Pro',
    amount: 993000,
    date: '18-03-2024',
    status: 'Successful',
  },
  {
    id: '12354929',
    name: 'Samsung',
    amount: 993000,
    date: '13-04-2024',
    status: 'Unsuccessful',
  },
  {
    id: '12354788',
    name: 'Techno S3',
    amount: 523000,
    date: '13-04-2024',
    status: 'Unsuccessful',
  },
  {
    id: '12354688',
    name: 'Coke Vanilla',
    amount: 523000,
    date: '13-04-2024',
    status: 'Pending',
  },
  {
    id: '12354788',
    name: 'Techno S3',
    amount: 523000,
    date: '13-04-2024',
    status: 'Successful',
  },
  {
    id: '12354728',
    name: 'Iphone 14Pro',
    amount: 993000,
    date: '18-03-2024',
    status: 'Successful',
  },
  {
    id: '12354929',
    name: 'Samsung',
    amount: 993000,
    date: '13-04-2024',
    status: 'Unsuccessful',
  },
  {
    id: '12354788',
    name: 'Techno S3',
    amount: 523000,
    date: '13-04-2024',
    status: 'Unsuccessful',
  },
  {
    id: '12354688',
    name: 'Coke Vanilla',
    amount: 523000,
    date: '13-04-2024',
    status: 'Pending',
  },
];

export interface SalesData {
  id: string;
  vendorId: string;
  name: string;
  businessName: string;
  productName: string;
  productId: string;
  email: string;
  productImg: string[];
  amount: string;
  quantity: number;
  price: string;
  accountNo: string;
  accountName: string;
  bankName: string;
  requestDate: string | null;
  isRequested: boolean;
  isSettled: boolean;
  settlementDate: string | null;
  rejectionReason: string | null;
  status: string;
  createdAt: string;
}
