/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {useState} from 'react';
import SideMenu from '../../../components/dashboard/supports/SideMenu';
import Chats from '../../../components/dashboard/supports/Chats';

const Supports = () => {
  const [query, setQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  // const numberF = (value: number) => {
  //   const numberFormat = new Intl.NumberFormat().format(value);
  //   return numberFormat;
  // };
  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Supports</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>SUPPORTS</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Chats*/}

        <div
          className='overflow-hidden flex items-center justify-center rounded-md'
          style={{background: '#edf2f7'}}>
          <div className='flex flex-row h-full w-full'>
            {/* Sidebar */}
            <SideMenu onSelectUser={(user) => setSelectedUser(user)} />
            <div className='w-full'>
              <Chats selectedUser={selectedUser} />
            </div>
          </div>
        </div>
        {/* Test */}
        {/* <div className='text-sm'>{numberF(100000)}</div>
        <div className='text-sm'>{numberF(2000)}</div>
        <div className='text-sm'>{numberF(3000)}</div> */}
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;

export default Supports;
