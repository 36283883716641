/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {axiosError, numberFormat} from '../../../components/Helpers';
import {useAdmin} from '../../../app/hooks';
import {AdminServices} from '../../../redux/services/admin/admin';
import {ISettlement} from '../../../components/interfaces/settlement';
import moment from 'moment';

interface IResolution {
  products: any[];
}
const Resolution = ({products}: IResolution) => {
  const [settlements, setSettlements] = useState<ISettlement[]>([]);
  const token = useAdmin().adminToken;
  const [isLoading, setIsLoading] = useState(false);

  // Get Settlements
  useEffect(() => {
    getSettlements();
  }, []);

  const getSettlements = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getSettlements(token);
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response?.data.data.details;
        setSettlements(res);
        // console.log(res.rows);
        setIsLoading(false);
        // console.log('profile', profile);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className=' sm:block hidden'>
        {isLoading ? (
          <div className='w-full'>
            <TableDataSkeleton />
          </div>
        ) : (
          <table className=' mt-4 w-full overflow-scroll text-xs'>
            <thead>
              <tr className='h-[2rem] text-[#718EBF]'>
                <th className='w-10 rounded-tl-md text-start'>Name</th>
                <th className='w-16'>Item ID #</th>
                <th className='w-10'>Amount</th>
                <th className='w-10'>Date Time</th>
                <th className='w-10 '>Status</th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {settlements?.length === 0 && (
                <tr>
                  <td className='text-center py-6' colSpan={5}>
                    <span className='text-base text-primary font-semibold'>
                      No Data available
                    </span>
                  </td>
                </tr>
              )}
              <>
                {settlements?.slice(0, 10).map((payment, index) => {
                  return (
                    <tr
                      className='overflow-x-auto h-[2rem] font-medium text-[#6C757D]'
                      key={index}>
                      <td className='w-10 text-start'>{payment.productName}</td>
                      <td className='w-10 text-center'>
                        #{payment.id?.slice(0, 5)}
                      </td>

                      <td className='w-10 text-center'>
                        ₦{numberFormat(payment.amount)}
                      </td>
                      <td className='w-10 text-center'>
                        {moment(payment.createdAt).format('ll')}
                      </td>
                      <td
                        className={
                          payment.status === 'paid'
                            ? 'w-10 text-center text-success'
                            : payment.status === 'pending'
                            ? ' w-10 text-center text-[#FFAD33]'
                            : 'w-10 text-center text-black'
                        }>
                        {payment.status === 'paid'
                          ? 'Paid'
                          : payment.status === 'pending'
                          ? 'Pending'
                          : payment.status === 'cancelled'
                          ? 'Cancelled'
                          : 'Eligible'}
                      </td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        )}
      </div>

      {/* Table Mobile */}
      {/* <div className=' block sm:hidden'>
        {loading ? (
          <div className='w-full'>
            <TableDataSkeleton />
          </div>
        ) : (
          <table className=' mt-4 w-full overflow-scroll text-xs'>
            <thead>
              <tr className='h-[2rem] text-[#718EBF]'>
                <th className='w-10 rounded-tl-md'>Name</th>
                <th className='w-16'>Item ID #</th>
                <th className='w-10'>Amount</th>
                <th className='w-10'>Status</th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {products?.length === 0 && (
                <tr>
                  <td className='text-center py-6' colSpan={5}>
                    <span className='text-base text-primary font-semibold'>
                      No Data available
                    </span>
                  </td>
                </tr>
              )}
              <>
                {products?.map((product: any, index) => {
                  return (
                    <tr
                      className='overflow-x-auto h-[2rem] font-medium text-[#6C757D]'
                      key={index}>
                      <td className='w-10'>{product.name}</td>
                      <td className='w-10 text-center'>
                        #{product.id.slice(0, 6)}
                      </td>
                      <td className='w-16 text-center'>
                        ₦{numberFormat(product.amount)}
                      </td>
                      <td className='w-10 text-center hover:underline cursor-pointer hover:text-primary'>
                        {product.date}
                      </td>
                      <td
                        className={
                          product.status === 'Successful'
                            ? 'w-10 text-center text-success'
                            : product.status === 'Unsuccessful'
                            ? 'w-10 text-center text-[#C81E19]'
                            : 'w-10 text-center text-[#FFAD33]'
                        }>
                        {product.status}
                      </td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        )}
      </div> */}
    </>
  );
};

export default Resolution;
