import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/dist/query';

import vendorReducer from '../redux/slices/vendorSlice';
import adminReducer from '../redux/slices/admin/userSlice';
import productReducer from '../redux/slices/product/productSlice';
import adminVendorReducer from '../redux/slices/vendor/adminVendorSlice';
import adminBuyerReducer from '../redux/slices/buyers/adminBuyerSlice';
import adminOrderReducer from '../redux/slices/admin/adminOrderSlice';
import chatVendorsReducer from '../redux/slices/chat/chatVendorSlice';
import chatBuyersReducer from '../redux/slices/chat/chatBuyerSlice';
import supportReducer from '../redux/slices/support/userSlice';
import adminSettlementReducer from '../redux/slices/settlements/adminSettlementSlice';
import couponReducer from '../redux/slices/coupon/couponSlice';

export const rootReducers = combineReducers({
  vendor: vendorReducer,
  admin: adminReducer,
  support: supportReducer,
  product: productReducer,
  adminVendor: adminVendorReducer,
  adminBuyer: adminBuyerReducer,
  adminOrder: adminOrderReducer,
  vendors: chatVendorsReducer,
  buyers: chatBuyersReducer,
  settlement: adminSettlementReducer,
  coupon: couponReducer,
});

export const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(),
  devTools: process.env.NODE_ENV === 'development',
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: { vendorReducer}
export type AppDispatch = typeof store.dispatch;
