import {ReactEventHandler, useState} from 'react';
import {VscEye, VscEyeClosed} from 'react-icons/vsc';
import {COLORS} from '../../../../assets/color';
import {CiMail} from 'react-icons/ci';

interface IFormInput {
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  label?: string;
  password?: boolean;
  email?: boolean;
  value?: string;
  error?: ReactEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  style?: React.CSSProperties | undefined;
  min?: number;
  ariaInvalid?: boolean | 'false' | 'true' | 'grammar' | 'spelling' | undefined;
  ariaDescribedby?: string | undefined;
  Inputref?: React.LegacyRef<HTMLInputElement> | undefined;
  toolTipId?: string;
  toolTipBackgroundColor?: string;
  toolTip?: boolean;
}
const InputFieldAuth = ({
  name,
  placeholder,
  type,
  error,
  onChange,
  password,
  required,
  style,
  value,
  onBlur,
  min,
  label,
  ariaInvalid,
  ariaDescribedby,
  Inputref,
  onFocus,
  readOnly,
  email,
}: IFormInput) => {
  const [hidePassword, setHidePassword] = useState(false);
  return (
    <div className='py-2'>
      <div className='pb-1 flex gap-2 items-start flex-row'>
        {required ? (
          <>
            <label className='font-poppinsRegular'>{label}</label>
            <p className='text-red-600 text-base'>*</p>
          </>
        ) : (
          <label className='font-poppinsRegular'>{label}</label>
        )}
      </div>
      <div className='input mb-2 overflow-hidden last:mb-0 font-poppinsRegular flex border-[1px] border-[#E3E3E8] sm:h-12 h-14 items-center rounded-lg bg-white'>
        <input
          type={password ? (hidePassword ? 'text' : 'password') : type}
          name={name}
          placeholder={placeholder}
          required={required}
          onError={error}
          style={style}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          min={min}
          aria-invalid={ariaInvalid}
          autoComplete='off'
          aria-describedby={ariaDescribedby}
          ref={Inputref}
          onFocus={onFocus}
          readOnly={readOnly}
          className='w-full px-5 focus:border-none focus:shadow-none focus:outline-none focus:text-[#667185] bg-transparent'
        />
        {password && (
          <div
            className='-ml-10 cursor-pointer'
            onClick={() => {
              setHidePassword(!hidePassword);
            }}>
            {hidePassword ? (
              <VscEye size={22} style={{color: COLORS.neutral}} />
            ) : (
              <VscEyeClosed size={22} style={{color: COLORS.neutral}} />
            )}
          </div>
        )}
        {email && (
          <div className='-ml-10 cursor-pointer'>
            <CiMail size={22} style={{color: COLORS.neutral}} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InputFieldAuth;
