export const COLORS = {
  transparent: 'transparent',
  white: '#ffffff',
  black: '#1D1D1F',
  // primary: ' #2e3ecee6',
  primary: ' #825A3F',
  secondary_500: '#F2830D',
  placeholder: '#94A3B8',
  neutral: '#9FA09F',
  error: '#E11D48',
  warning: '#D97706',
  success: '#10B981',
  confirmed: '#0096FF',
  buyerPrimary: '#2E3ECE8A',
};
