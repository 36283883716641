/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {useCallback, useEffect, useState} from 'react';
import {useVendor} from '../../../app/hooks';
import {useNavigate} from 'react-router-dom';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import Pagination from '../../../components/ui/Pagination';
import 'react-datepicker/dist/react-datepicker.css';
import {parseISO, isWithinInterval, format} from 'date-fns';
import CustomDatePicker from '../../../components/forms/inputs/selectInput/CustomDatePicker';
import TransactionTable from './TransactionTable';

const Transactions = () => {
  localStorage.setItem('currentPath', '/vendor/orders');
  const [orders, setOrders] = useState<any>([]);
  const token = useVendor().token;
  const profile = useVendor().vendor;
  const vendorId = useVendor().vendor.id;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // Query
  const [query, setQuery] = useState('');
  // Define keys to include both direct and nested keys
  const keys = ['orderId', 'createdAt', 'product.name'];

  // Function to access nested keys in objects
  const getValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const search = (data: any) => {
    return data?.filter((item: any) =>
      keys.some((key) => {
        const value = getValue(item, key);
        return value
          ? value.toString().toLowerCase().includes(query.toLowerCase())
          : false;
      })
    );
  };

  // Sort --------------------
  const [openSort, setOpenSort] = useState(false);

  const [sortingOption, setSortingOption] = useState<
    | 'productId'
    | 'createdAt'
    | 'subTotal'
    | 'isReturned'
    | 'isMovedToSettlement'
  >('productId');

  const [isAscending, setIsAscending] = useState(false);

  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const sortedData = (data: any) => {
    return [...data].sort((a, b) => {
      // Use the selected sorting option
      const sortOrder = isAscending ? 1 : -1;
      const aValue = getValue(a, sortingOption);
      const bValue = getValue(b, sortingOption);

      // Compare values based on the sorting order
      if (aValue < bValue) return -1 * sortOrder;
      if (aValue > bValue) return 1 * sortOrder;
      return 0;
    });
  };

  // Get Orders
  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    setIsLoading(true);
    try {
      const response = await VendorServices.getVendorOrders(token, vendorId);
      if (response.data.status === 'success') {
        const res = response.data.data;
        setOrders(res?.details.rows);
        setIsLoading(false);
      }
    } catch (error: any) {
      console.log(error.response);
      setIsLoading(false);
    }
  };

  // Pagination
  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;

  // Filter Start and End date
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const filteredOrdersByDate = useCallback(
    (data: any) => {
      if (!startDate || !endDate) {
        return data;
      }

      return data.filter((order: any) => {
        const orderDate = parseISO(order.createdAt);
        return isWithinInterval(orderDate, {start: startDate, end: endDate});
      });
    },
    [startDate, endDate]
  );

  const totalFilteredData = search(filteredOrdersByDate(orders));
  const sortedFilteredData = sortedData(totalFilteredData);
  const currentTableData = sortedFilteredData.slice(
    firstPageIndex,
    lastPageIndex
  );
  const totalPages = Math.ceil(sortedFilteredData.length / PageSize);

  return (
    <AppContainer>
      <div className='xl:w-[90%] md:w-[95%] w-full pt-4'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Transactions</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>TRANSACTIONS</h2>
          </Title>
          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center sm:w-56 w-36 rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div className='sm:block hidden'>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div className='sm:block hidden'>
              <ProfileImage
                onClick={() => navigate('../vendor/profile')}
                profileImage={profile?.vendorImg}
              />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='sm:flex hidden flex-row items-center justify-end gap-6 pt-6'>
          {/* Sort */}
          <div
            onClick={() => setOpenSort(!openSort)}
            className='flex flex-row items-center gap-4 cursor-pointer'>
            <div className='font-poppinsMedium'>Sort by:</div>
            <div className='relative'>
              <div className='flex flex-row items-center gap-10'>
                <p className='text-[#868FE1] text-xs font-poppinsMedium'>
                  {sortingOption === 'createdAt'
                    ? 'Date & Time'
                    : sortingOption === 'productId'
                    ? 'Product ID'
                    : sortingOption === 'subTotal'
                    ? 'Amount'
                    : sortingOption === 'isReturned'
                    ? 'Returned'
                    : 'Settled'}
                </p>
                <BiChevronDown size={20} color='#868FE1' />
              </div>
              {/* Sort DropDown */}
              {openSort && (
                <div
                  onClick={() => setOpenSort(false)}
                  className='bg-white w-full px-2 py-2 flex flex-col items-start absolute rounded-md shadow-md top-8 z-50 text-xs gap-2'>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('productId')}>
                    Product ID
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('subTotal')}>
                    Amount
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('createdAt')}>
                    Date & Time
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('isReturned')}>
                    Returned
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() =>
                      handleSortingOptionChange('isMovedToSettlement')
                    }>
                    Settled
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Date Filter */}

          <div className=''>
            <CustomDatePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
            />
          </div>
        </div>
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <TransactionTable orders={currentTableData} />
            )}
            {/* Pagination */}
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;

export default Transactions;
