import {IInbox} from './inbox';

interface IInboxCard {
  data: IInbox[];
  onClick?: () => void;
}
const InboxCard = ({data, onClick}: IInboxCard) => {
  return (
    <>
      {data.map((item, index) => (
        <div
          onClick={onClick}
          key={index}
          className='flex fex-row items-start justify-between py-2 cursor-pointer hover:bg-gray-100 p-2 rounded-md sm:gap-0 gap-4'>
          {/* image */}
          <div className='sm:w-10 sm:h-10 w-14 h-14'>
            <img
              src={item.img}
              alt=''
              className='w-full h-full rounded-full sm:object-cover object-contain'
            />
          </div>
          {/* Text */}
          <div>
            <p className='text-[#1D1D1F] font-poppinsMedium'> {item.name}</p>
            <p className='text-[#1D1D1F] pt-2 text-xs'> {item.description}</p>
          </div>
          {/* Time */}
          <div>
            <p className='text-gray-500 text-xs'> {item.dateTime}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default InboxCard;
