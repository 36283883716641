/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {MdOutlineModeEditOutline, MdAdd} from 'react-icons/md';
import {useNavigate, useParams} from 'react-router-dom'; // Import useParams from React Router
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {axiosError} from '../../../components/Helpers';
import {useAdmin} from '../../../app/hooks';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {motion} from 'framer-motion';
import {ArrowLeft} from 'iconsax-react';
import {toast} from 'react-toastify';

interface ISubCategory {
  id: string;
  name: string;
  code: number;
}

const UpdateSubCategory = () => {
  const {categoryId} = useParams(); // Retrieve categoryId from route params
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [editingSubCategory, setEditingSubCategory] = useState<string | null>(
    null
  );
  const [newSubCategoryName, setNewSubCategoryName] = useState<string>('');
  const [showAddInput, setShowAddInput] = useState<boolean>(false);
  const [nextCode, setNextCode] = useState<number>(2000);
  const token: any = useAdmin().adminToken;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await VendorServices.getCategory();
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        const sub = res.rows;
        const findSub = sub.filter((item: any) => item.id === categoryId);
        setSubCategories(findSub[0].subCategories);
        // console.log(findSub[0].subCategories);
        const maxCode = Math.max(
          ...findSub.map((category: any) => category.code || 0)
        ); // Find the maximum code
        setNextCode(maxCode >= 30 ? maxCode + 1 : 30); // Set next code to the maximum code + 1 or 30
      }
      setLoading(false);
    } catch (error: any) {
      axiosError(error.response);
    }
    setLoading(false);
  };

  const handleSaveSubCategory = async (id: string, code: number) => {
    try {
      // Call API to update subcategory name
      const data = {
        name: newSubCategoryName,
        code,
      };
      const response = await VendorServices.updateSubCategory(token, data, id);
      if (response.data.status === 'success') {
        // Update subcategories list
        const updatedSubCategories = subCategories.map((subCategory) => {
          if (subCategory.id === id) {
            return {...subCategory, name: newSubCategoryName};
          }
          return subCategory;
        });
        setSubCategories(updatedSubCategories);
        setEditingSubCategory(null);
        setNewSubCategoryName('');
        toast.success(response.data.data.message);
      }
    } catch (error: any) {
      axiosError(error.response);
    }
  };

  const handleEditSubCategory = (id: string, name: string) => {
    setEditingSubCategory(id);
    setNewSubCategoryName(name);
  };

  const handleAddSubCategory = async () => {
    try {
      // Call API to add new subcategory
      const data = {
        categoryId: categoryId,
        arrOfSubCategories: [{name: newSubCategoryName}],
      };
      const response = await VendorServices.addSubCategory(token, data);
      if (response.data.status === 'success') {
        const newSubCategory = response.data.data;
        setSubCategories([...subCategories, newSubCategory]);
        setNewSubCategoryName('');
        setShowAddInput(false); // Hide the input field after adding the subcategory
        setNextCode(nextCode + 1); // Increment next code for the next subcategory
        toast.success(response.data.data.message);
        fetchData();
      }
    } catch (error: any) {
      axiosError(error.response);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string,
    code: number
  ) => {
    if (event.key === 'Enter') {
      handleSaveSubCategory(id, code);
    }
  };

  const handleKeyDownAdd = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      //   handleSaveCategory(id, code);
      handleAddSubCategory();
    }
  };
  return (
    <div className='h-full w-full xl:w-[70] sm:w-[80%] p-10 bg-white rounded-lg'>
      {loading ? (
        <TableDataSkeleton />
      ) : (
        <>
          <motion.div
            whileHover={{scale: 1.1}}
            className='w-24 text-center cursor-pointer pb-4'
            onClick={() => navigate(-1)}>
            <ArrowLeft size={28} variant='Linear' />
          </motion.div>
          <div className='flex flex-wrap items-center justify-start gap-4'>
            {subCategories.map((subCategory) => (
              <div
                key={subCategory.id}
                className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                {editingSubCategory === subCategory.id ? (
                  <>
                    <input
                      type='text'
                      className='w-[90%] h-full outline-none bg-transparent focus:outline-none'
                      value={newSubCategoryName}
                      onChange={(e) => setNewSubCategoryName(e.target.value)}
                      onBlur={() =>
                        handleSaveSubCategory(subCategory.id, subCategory.code)
                      }
                      onKeyDown={(e) =>
                        handleKeyDown(e, subCategory.id, subCategory.code)
                      }
                      autoFocus
                    />
                    <MdOutlineModeEditOutline
                      size={22}
                      onClick={() => setEditingSubCategory(null)}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className='w-[90%]'
                      onClick={() =>
                        handleEditSubCategory(subCategory.id, subCategory.name)
                      }>
                      {subCategory.name}
                    </div>
                    <MdOutlineModeEditOutline
                      size={22}
                      onClick={() =>
                        handleEditSubCategory(subCategory.id, subCategory.name)
                      }
                    />
                  </>
                )}
              </div>
            ))}
            {showAddInput ? (
              <div className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                <input
                  type='text'
                  className='w-[90%] h-full outline-none bg-transparent focus:outline-none'
                  value={newSubCategoryName}
                  onChange={(e) => setNewSubCategoryName(e.target.value)}
                  placeholder='Enter new subcategory'
                  onBlur={() => handleAddSubCategory}
                  onKeyDown={(e) => handleKeyDownAdd(e)}
                  autoFocus
                />
                <MdAdd size={22} onClick={handleAddSubCategory} />
              </div>
            ) : (
              <div className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                <MdAdd size={22} onClick={() => setShowAddInput(true)} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default UpdateSubCategory;
