import axios, {Method} from 'axios';

const baseURL = process.env.REACT_APP_API_URL || '';

// Fetch APi

type IFetchApi = {
  body?: any;
  url: string;
  method: Method;
  baseUrl?: string;
  header?: any;
};

export const fetchApi = async ({
  body,
  header,
  method,
  url,
  baseUrl = baseURL,
}: IFetchApi) => {
  const data = JSON.stringify(body);
  const headers = header;
  const pathUrl = `${baseUrl}${url}`;

  try {
    const response = await fetch(pathUrl, {
      body: data,
      method,
      headers,
    });

    const result = await response.json();

    return {
      data: result,
      hasError: !response.ok,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error: any) {
    return {
      data: error,
      hasError: true,
      status: error.status || 500,
      statusText: error.message || 'Internal Server Error',
    };
  }
};

// Axios Custom

type IAxiosAPi = {
  body?: any;
  url: string;
  method: Method;
  baseUrl?: string;
  header?: any;
};

export const axiosApi = async ({
  body,
  method,
  url,
  baseUrl = baseURL,
  header,
}: IAxiosAPi) => {
  const pathUrl = `${baseUrl}/${url}`;
  const headers = header;
  const data = body;

  try {
    const response = await axios({
      url: pathUrl,
      method,
      headers,
      data,
    });

    return {
      data: response,
      hasError: false,
      status: response.status,
      statusText: response.statusText,
    };
  } catch (error: any) {
    return {
      data: error,
      hasError: true,
      status: error.response?.status || 500,
      statusText: error.message || 'Internal Server Error',
    };
  }
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  // withCredentials: true,
});

// Function to handle token expiration (
// const handleTokenExpiration = () => {
//   Navigate({to: '../signin', replace: true});
// };

// // Add a response interceptor to handle token expiration errors
// api.interceptors.response.use(
//   (response: AxiosResponse) => {
//     return response;
//   },
//   (error) => {
//     console.log(error.response.status);
//     // Check if the error is due to an expired token
//     if ((error.response && error.response.status === 403) || 401) {
//       // Handle token expiration (e.g., log out the user)
//       handleTokenExpiration();
//     }

//     return Promise.reject(error);
//   }
// );
// export default api;
