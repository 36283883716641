import React, {ChangeEvent, useState} from 'react';
import styles from '../../../../assets/styles/modals/ConfirmModal.module.css';
import {DefaultImage, PencilSm} from '../../../../assets/images';
import Input from '../../../../components/dashboard/profile/input';
import {CloseCircle} from 'iconsax-react';
import {useAdminBuyer} from '../../../../app/hooks';
import {IBuyer} from '../../../../components/interfaces/vendors';

interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onApprove?: any;
  isLoading?: any;
}

const ViewBuyer = ({setIsOpen}: IModal) => {
  const buyer = useAdminBuyer().adminBuyer;
  //   console.log(vendor);
  const [formData, setFormData] = useState<IBuyer>({
    firstName: buyer?.firstName,
    lastName: buyer?.lastName,
    buyerImg: buyer?.buyerImg,
    email: buyer?.email,
    phone: buyer?.phone,
    isActive: buyer?.isActive,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // const isActive = String(vendor.isActive);
  //   console.log(isUpdated);
  return (
    <div>
      <div className={styles.productViewBG} onClick={() => setIsOpen(true)} />
      <div className={styles.productCentered}>
        <div className={styles.productViewModal}>
          <div className={styles.modalContent}>
            {/* Content */}
            <div className='bg-white p-6 relative'>
              <div
                className='absolute top-0 right-0 cursor-pointer'
                onClick={() => setIsOpen(false)}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='flex flex-row items-center justify-between px-16 pb-4'>
                {/* Image */}
                <div>
                  <div className='w-[200px] h-[200px]'>
                    {/* {buyer?.buyerImg !== null ? (
                      <img
                        src={buyer.buyerImg}
                        alt='vendor-img'
                        className='rounded-md w-full h-full object-cover'
                      />
                    ) : (
                      <img
                        src={DefaultImage}
                        alt='profile-def'
                        className='rounded-md w-full h-full object-cover'
                      />
                    )} */}
                    <img
                      src={DefaultImage}
                      alt='profile-def'
                      className='rounded-md w-full h-full object-cover'
                    />
                  </div>
                </div>
              </div>
              <div className=''>
                <span className='font-bold  text-base text-[#2E3ECE] w-full '>
                  CUSTOMER DETAILS
                </span>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 justify-items-center xl:justify-items-start gap-y-16 w-full h-auto p-5'>
                <Input
                  label='LAST NAME'
                  icon={PencilSm}
                  pattern=''
                  // name='lastName'
                  handleChange={handleInputChange}
                  valueField={formData.lastName}
                />

                <Input
                  label='PHONE NUMBER'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.phone}
                />
                <Input
                  label='FIRST NAME'
                  icon={PencilSm}
                  // name='firstName'
                  handleChange={handleInputChange}
                  valueField={formData.firstName}
                />

                <Input
                  label='EMAIL'
                  icon={PencilSm}
                  // name='email'
                  handleChange={handleInputChange}
                  valueField={formData.email}
                />
                <div className='flex flex-col'>
                  <span className='text-gray-400 font-poppinsMedium font-medium pb-2'>
                    STATUS
                  </span>
                  <div className='w-[14.5rem] rounded-md h-[2.5rem] border-[2px] border-gray-400 px-3flex flex-row justify-start items-center'>
                    {buyer.isActive === true ? (
                      <p className=''>Active</p>
                    ) : (
                      <p className=' '>InActive</p>
                    )}
                  </div>
                </div>
                <Input
                  label='LAST NAME'
                  icon={PencilSm}
                  pattern=''
                  // name='lastName'
                  handleChange={handleInputChange}
                  valueField={formData.lastName}
                />

                <Input
                  label='PHONE NUMBER'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.phone}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewBuyer;
