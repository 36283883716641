import { createElement } from 'react';

const combineProviders = ({ contexts, children }) => {
  return contexts.reduceRight((acc, Provider) => {
    return createElement(Provider, {
      children: acc
    });
  }, children);
};

export default combineProviders;