import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {IAdminOrderReducer} from '../../../types';

const initialState: IAdminOrderReducer = {
  order: localStorage.getItem('#ordadm')
    ? JSON.parse(localStorage.getItem('#ordadm') || '{}')
    : null,
};

const adminOrderReducer = createSlice({
  name: 'adminOrder',
  initialState,
  reducers: {
    setSingleAdminOrder: (state, action) => {
      state.order = action.payload;
      localStorage.setItem('#ordadm', JSON.stringify(action.payload));
    },
  },
});

export const {setSingleAdminOrder} = adminOrderReducer.actions;
export const selectAdminOrder = (state: RootState) => state.adminOrder.order;

export default adminOrderReducer.reducer;
