/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {IAdmin, IVendor} from '../interfaces/vendors';
import {IoMdMore} from 'react-icons/io';
import Modal from './modal/Modal';
import EditAdmin from '../../pages/dashboard/admin/modal/EditAdmin';
import Spinner from '../spinner/Spinner';
import ConfirmDeactivate from '../modal/ConfirmDeactivate';

interface IAssignRoleTable {
  admins: IAdmin[];
  handleEditAdmin: (
    id: string,
    adminEmail: string,
    adminPhone: string,
    adminFirstName: string,
    adminLastName: string,
    adminRole: string
  ) => void;
  handleDeactivate: (id: string) => void;
  isEditLoading: boolean;
  showEdit: boolean;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
}
const AdminRoleTable = ({
  admins,
  isEditLoading,
  handleEditAdmin,
  setShowEdit,
  showEdit,
  handleDeactivate,
}: IAssignRoleTable) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showActivate, setShowActivate] = useState(false);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };
  const adminId = JSON.parse(localStorage.getItem('#iAdmin') || '{}');

  return (
    <>
      {/* Modal View */}
      {showEdit && (
        <div className='z-50'>
          <EditAdmin
            setShowView={setShowEdit}
            handleEditAdmin={handleEditAdmin}
            isActionLoading={isEditLoading}
          />
        </div>
      )}
      <div>
        {isEditLoading && <Spinner />}
        <table className=' mt-6 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10 rounded-tl-md'>First Name</th>
              <th className='w-10'>Last Name</th>
              <th className='w-10'>Email</th>
              <th className='w-10'>Phone</th>
              <th className='w-10'>Role</th>
              <th className='w-10'>Status</th>
              <th className='w-10 rounded-tr-md '>Action</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100'>
            {admins.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={7}>
                  <span className='text-xl text-primary font-semibold'>
                    No Admin available
                  </span>
                </td>
              </tr>
            )}
            <>
              {admins.slice(0, 10).map((admin: IAdmin) => {
                return (
                  <tr className='overflow-x-auto h-[3.125rem]' key={admin.id}>
                    <td className='w-10 text-center'>{admin.firstName}</td>
                    <td className='w-10 text-center'>{admin.lastName}</td>
                    <td className='w-10 text-center'>{admin.email}</td>
                    <td className='w-10 text-center'>{admin.phone}</td>
                    <td className='w-10 text-center'>{admin.role}</td>
                    {/* Status */}
                    <td className='w-10 text-center'>
                      {' '}
                      <div
                        className={
                          admin.isActive === true
                            ? 'bg-[#E6FCE5] px-3 py-2 text-[#35C655] rounded-md shadow-sm'
                            : 'bg-[#FEEFEE] px-3 py-2 text-[#C81E19] rounded-md shadow-sm'
                        }>
                        {' '}
                        {admin.isActive === true ? 'Active' : 'InActive'}
                      </div>
                    </td>

                    {/* Action */}
                    <td className='w-10 text-center'>
                      <div className='w-full relative '>
                        <button
                          onClick={() => {
                            setSelectedRow(admin.id);
                            setIsModalOpen(!isModalOpen);
                          }}
                          className='text-primary hover:text-primary px-2 py-1 rounded flex flex-row items-center justify-center gap-2 m-auto transition-hover'>
                          <IoMdMore size={24} />
                        </button>
                        {isModalOpen && selectedRow === admin.id && (
                          <Modal isOpen={isModalOpen}>
                            <div className=''>
                              <div
                                onClick={() => {
                                  setShowEdit(true);
                                  handleCloseModal();
                                  localStorage.setItem(
                                    '#idAdmin',
                                    JSON.stringify(admin)
                                  );
                                }}
                                className='p-2 cursor-pointer  hover:bg-gray-100 hover:text-primary hover:rounded  flex flex-row items-center gap-2 px-2 text-primary'>
                                <p> Edit Admin</p>
                              </div>
                              {admin.isActive === true ? (
                                <div
                                  onClick={() => {
                                    setShowDeactivate(true);
                                    setIsModalOpen(false);
                                    localStorage.setItem(
                                      '#iAdmin',
                                      JSON.stringify(admin.id)
                                    );
                                  }}
                                  className='p-2  hover:bg-red-100 hover:text-red-600 hover:rounded-md flex flex-row items-center gap-2 px-2 text-gray-400 cursor-pointer'>
                                  <p> Deactivate</p>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    setShowActivate(true);
                                    setIsModalOpen(false);
                                    localStorage.setItem(
                                      '#iAdmin',
                                      JSON.stringify(admin.id)
                                    );
                                  }}
                                  className='p-2  hover:bg-green-100 hover:text-green-600 hover:rounded-md flex flex-row items-center gap-2 px-4 text-gray-400 cursor-pointer'>
                                  <p> Activate</p>
                                </div>
                              )}
                            </div>
                          </Modal>
                        )}
                      </div>
                      {showDeactivate && (
                        <ConfirmDeactivate
                          setIsOpen={setShowDeactivate}
                          onAction={() => {
                            handleDeactivate(adminId);
                          }}
                          label={'Deactivate'}
                          activeLabel='Deactivate'
                          bgColor={'red'}
                        />
                      )}
                      {showActivate && (
                        <ConfirmDeactivate
                          setIsOpen={setShowActivate}
                          onAction={() => {
                            handleDeactivate(adminId);
                          }}
                          label={'Activate'}
                          activeLabel='Activate'
                          bgColor={'green'}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminRoleTable;
