import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {IProduct} from '../../../components/interfaces/products';

type IProps = {
  prod: {
    product: IProduct;
    products: IProduct[];
  };
};
const initialState: IProps = {
  prod: {
    product:
      localStorage.getItem('product') !== undefined
        ? JSON.parse(localStorage.getItem('product') || '{}')
        : null,
    products:
      localStorage.getItem('#products') !== undefined
        ? JSON.parse(localStorage.getItem('#products') || '[]')
        : [],
  },
};

const productReducer = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setSingleProduct: (state, action) => {
      state.prod.product = action.payload;
      localStorage.setItem('product', JSON.stringify(action.payload));
    },
    setAllProducts: (state, action) => {
      state.prod.products = action.payload;
      localStorage.setItem('#products', JSON.stringify(action.payload));
    },
  },
});

export const {setSingleProduct, setAllProducts} = productReducer.actions;
export const selectProduct = (state: RootState) => state.product?.prod.product;
export const selectProducts = (state: RootState) =>
  state.product?.prod.products;

export default productReducer.reducer;
