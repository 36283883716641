import {Outlet, useNavigate} from 'react-router-dom';
import Sidebar from './Sidebar';
import {appScreens} from '../../pages/dashboard/sidebar.interface';
import {toast} from 'react-toastify';

const HomeLayout = () => {
  // Logout Handler
  const navigate = useNavigate();

  const logoutHandler = () => {
    //  dispatch(vendorLogout());
    localStorage.removeItem('adminInfo');
    toast.success('Logout successfully');
    navigate('/admin');
  };
  const isMobile = window.matchMedia(
    'only screen and (max-width: 760px)'
  ).matches;
  if (isMobile) {
    return (
      <div className='text-lg text-center font-poppinsRegular bg-primary text-white flex justify-center items-center p-8 h-screen'>
        Sorry, this admin app is only accessible by tablet, TV or desktop users.
        Kindly use the supported devices.
      </div>
    );
  }
  return (
    <div className='w-full bg-blue-50 overflow-hidden h-screen'>
      <div className='container-fluid flex h-full'>
        {/* Section Left */}
        <div className='overflow-auto h-full'>
          <Sidebar appScreens={appScreens} logoutHandler={logoutHandler} />
        </div>
        {/* Right section for Content Holder */}
        <div className='w-full overflow-auto pt-3 h-full flex-1 ml-10'>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default HomeLayout;
