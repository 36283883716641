import React from 'react';
import {IVendor} from '../interfaces/vendors';
import {DefaultImage} from '../../assets/images';

interface IVendorDashboard {
  data: IVendor[];
}
const VendorsList = ({data}: IVendorDashboard) => {
  return (
    <>
      {data.slice(0, 4).map((item) => (
        <div
          key={item.id}
          className='flex flex-row items-center justify-between py-2'>
          <div className='flex flex-row items-center gap-4'>
            {/* Image */}
            <div className='w-[40px] h-[40px]'>
              {item.vendorImg === null ? (
                <img
                  src={DefaultImage}
                  alt='imgvendor'
                  className='w-full h-full object-cover rounded-md'
                />
              ) : (
                <img
                  src={item.vendorImg}
                  alt='imgvendor'
                  className='w-full h-full object-cover rounded-md'
                />
              )}
            </div>
            <div className='flex flex-col gap-1 items-start'>
              <p className='text-black font-poppinsRegular'>{`${item.firstName} ${item.lastName}`}</p>
              <p className='text-[#808191] font-poppinsRegular'>{item.email}</p>
            </div>
          </div>
          <div>
            {item.isActive === true ? (
              <p className='text-success'>Active</p>
            ) : (
              <p className='text-red-500'>InActive</p>
            )}
          </div>
        </div>
      ))}
    </>
  );
};

export default VendorsList;
