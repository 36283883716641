/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {AuthContext} from './auth';
import {io} from 'socket.io-client';
import Actions from '../actions';
import messageSound from '../../assets/sounds/message.mp3';

export const ChatContext = createContext();

const ChatContextProvider = ({children}) => {
  const [chatUsers, setChatUsers] = useState([]);
  const [potentialChats, setPotentialChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [socket, setSocket] = useState(null);
  const {user} = useContext(AuthContext);

  // initial socket
  const socketUrl = process.env.REACT_APP_SOCKET_URL;
  useEffect(() => {
    const newSocket = io(socketUrl);
    // const newSocket = io()
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  // Add new user and then get online users
  useEffect(() => {
    if (socket === null) return;
    socket.emit('addNewUser', user?.id);
    socket.on('getOnlineUsers', (res) => {
      updateOnlineUsers(res);
    });

    return () => {
      socket.off('getOnlineUsers');
    };
  }, [socket]);

  // Send message
  useEffect(() => {
    if (socket === null) return;

    const recipientId = currentChat?.members?.find((id) => id !== user?.id);
    socket.emit('sendMessage', {...newMessage, recipientId});
  }, [newMessage]);

  // Receive message and notification
  useEffect(() => {
    if (socket === null) return;

    socket.on('getMessage', (res) => {
      if (currentChat?._id !== res?.chatId) return;
      setChatMessages((prev) => [...prev, res]);
    });

    socket.on('getNotifications', async (res) => {
      const isChatOpen = currentChat?.members?.some(
        (id) => id === res?.senderId
      );
      if (isChatOpen && document.hasFocus()) {
        setNotifications((prev) => [...prev, {...res, isRead: true}]);
      } else {
        await Actions.addNotification(res);
        setNotifications((prev) => [...prev, res]);
        const sound = new Audio(messageSound);
        sound.play();
      }
    });

    return () => {
      socket.off('getMessage');
      socket.off('getNotifications');
    };
  }, [socket, currentChat]);

  useEffect(() => {
    const getNotifications = async () => {
      const response = await Actions.findNotifications();
      if (response?.status === 'success') {
        setNotifications(response?.data?.details);
      }
    };
    getNotifications();
  }, [newMessage]);

  const updateChatUsers = useCallback(
    (info) => {
      setChatUsers(info);
    },
    [chatUsers]
  );

  const updatePotentialChats = useCallback(
    (info) => {
      setPotentialChats(info);
    },
    [potentialChats]
  );

  const updateCurrentChat = useCallback(
    (info) => {
      setCurrentChat(info);
    },
    [currentChat]
  );

  const updateChatMessages = useCallback(
    (info) => {
      setChatMessages(info);
    },
    [chatMessages]
  );

  const updateNewMessage = useCallback(
    (info) => {
      setNewMessage(info);
    },
    [newMessage]
  );

  const updateOnlineUsers = useCallback(
    (info) => {
      setOnlineUsers(info);
    },
    [onlineUsers]
  );

  const updateAllUsers = useCallback(
    (info) => {
      setAllUsers(info);
    },
    [allUsers]
  );

  const markAllNotificationsAsRead = useCallback((notifications) => {
    const markedNotifications = notifications.map((n) => {
      return {...n, isRead: true};
    });
    setNotifications(markedNotifications);
    markedNotifications.forEach(async (item) => {
      await Actions.updateNotification({
        isRead: true,
        senderId: item?.senderId,
      });
    });
  }, []);

  const markSingleNotificationAsRead = useCallback(
    async (clickedNotification, userChats, user, allNotifications) => {
      // find chat to open
      const desiredChat = userChats?.find((chat) => {
        const chatMembers = [user?.id, clickedNotification?.senderId];
        const isDesiredChat = chat?.members?.every((member) =>
          chatMembers.includes(member)
        );
        return isDesiredChat;
      });

      // mark notification as read
      const markedNotifications = allNotifications.map((notification) => {
        if (clickedNotification?.senderId === notification?.senderId) {
          return {...clickedNotification, isRead: true};
        } else {
          return notification;
        }
      });
      updateCurrentChat(desiredChat);
      setNotifications(markedNotifications);
      await Actions.updateNotification({
        isRead: true,
        senderId: clickedNotification?.senderId,
      });
    },
    []
  );

  const markSpecificUserNotificationAsRead = useCallback(
    (specificUserNotifications, notifications) => {
      // mark notification as read
      const markedNotifications = notifications.map((single) => {
        let notification;

        specificUserNotifications.forEach((n) => {
          if (n?.senderId === single?.senderId) {
            notification = {...n, isRead: true};
          } else {
            notification = single;
          }
        });

        return notification;
      });
      setNotifications(markedNotifications);
      markedNotifications.forEach(async (item) => {
        await Actions.updateNotification({
          isRead: true,
          senderId: item?.senderId,
        });
      });
    },
    []
  );

  return (
    <ChatContext.Provider
      value={{
        chatUsers,
        potentialChats,
        chatMessages,
        currentChat,
        newMessage,
        onlineUsers,
        notifications,
        allUsers,
        updateAllUsers,
        updateChatUsers,
        updatePotentialChats,
        updateCurrentChat,
        updateChatMessages,
        updateNewMessage,
        updateOnlineUsers,
        markAllNotificationsAsRead,
        markSingleNotificationAsRead,
        markSpecificUserNotificationAsRead,
      }}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContextProvider;
