import styled from 'styled-components';
import {COLORS} from '../../../assets/color';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {Helmet} from 'react-helmet';
import {useNavigate} from 'react-router-dom';
import {useVendor} from '../../../app/hooks';
import {Loader} from '../../../assets/svg';
import InputField from '../../../components/forms/inputs/inputField/InputField';
import {toast} from 'react-toastify';
import {useEffect, useState} from 'react';
import {regexValidation} from '../../../components/Helpers/regexValidation';
import {AuthService} from '../../../redux/services/vendor/auth';
import {axiosError} from '../../../components/Helpers';
import ButtonAuth from '../../../components/ui/ButtonAuth';

const ChangePassword = () => {
  const navigate = useNavigate();
  const profile = useVendor().vendor;

  const [isLoading, setIsLoading] = useState(false);

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [validConfirm, setValidConfirm] = useState(false);
  const [confirmFocus, setConfirmFocus] = useState(false);

  const [state, setState] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });

  // REGEX Validations
  useEffect(() => {
    const isPasswordValid = regexValidation.adminValidatePassword(
      state.password
    );
    setValidPwd(isPasswordValid);
    setValidConfirm(state.password === state.confirmPassword);
  }, [state.password, state.confirmPassword]);

  // HandleChange
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((p: any) => ({
      ...p,
      [name]: value,
    }));
  };
  const token = useVendor().token;

  const submitHandler = async () => {
    setIsLoading(true);
    const data = {
      password: state.oldPassword,
      newPassword: state.password,
      confirmPassword: state.confirmPassword,
    };
    try {
      const response = await AuthService.changePassword(token, data);
      if (response.data.status === 'success') {
        const res = response.data;
        toast.success(res.data.message || 'Successfully change password');
        navigate('../vendor/dashboard');
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  return (
    <AppContainer>
      <div className='xl:w-[90%] md:w-[95%] w-full pt-4'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Change Password</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>Change Password</h2>
          </Title>
          <div className='sm:flex flex-row items-center gap-4 justify-end hidden'>
            {/* Search */}

            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage
                onClick={() => navigate('../vendor/profile')}
                profileImage={profile?.vendorImg}
              />
            </div>
          </div>
        </ProductBar>
        {/* Body Start here */}
        <div className='py-4 w-[45%] mx-auto'>
          <div className='w-full pt-6 md:px-2 px-4'>
            <div className='w-full h-full flex flex-row items-center justify-center'>
              {/*  LOGIN FORM HERE */}
              <div className='grid place-items-center pt-4 w-full'>
                <div className='w-full'>
                  <h3 className='text-lg md:text-3xl font-poppinsMedium text-center pb-3'>
                    Let's update your account password
                  </h3>
                  <form className='w-full'>
                    {/* Email */}
                    <InputField
                      name='oldPassword'
                      label='Old Password'
                      type='password'
                      placeholder='************'
                      value={state.oldPassword}
                      required={true}
                      ariaDescribedby='uipwd'
                      password
                      onChange={handleChange}
                      style={{height: '100%', fontFamily: 'poppinsRegular'}}
                    />

                    {/* Password */}
                    <InputField
                      name='password'
                      label=' New Password'
                      type='password'
                      placeholder='************'
                      value={state.password}
                      required={true}
                      ariaInvalid={validPwd ? 'false' : 'true'}
                      ariaDescribedby='uipwd'
                      onFocus={() => setPwdFocus(true)}
                      onBlur={() => setPwdFocus(false)}
                      password
                      onChange={handleChange}
                      style={{height: '100%', fontFamily: 'poppinsRegular'}}
                    />
                    <p
                      id='uipwd'
                      className={
                        pwdFocus && !validPwd
                          ? 'instructions text-xs'
                          : 'offscreen text-xs'
                      }>
                      <span className='font-poppinsRegular'>
                        Your password needs 8+ characters, including uppercase,
                        lowercase letters, numbers, and special characters.
                      </span>
                    </p>

                    {/* Confirm Password */}
                    <InputField
                      name='confirmPassword'
                      label='confirm New Password'
                      type='password'
                      placeholder='************'
                      value={state.confirmPassword}
                      required={true}
                      ariaInvalid={validConfirm ? 'false' : 'true'}
                      ariaDescribedby='uipwd'
                      onFocus={() => setConfirmFocus(true)}
                      onBlur={() => setConfirmFocus(false)}
                      password
                      onChange={handleChange}
                      style={{height: '100%', fontFamily: 'poppinsRegular'}}
                    />
                    <p
                      id='uipwdConfirm'
                      className={
                        confirmFocus && !validConfirm
                          ? 'instructions'
                          : 'offscreen'
                      }>
                      <span className='font-poppinsRegular'>
                        Password not matched
                      </span>
                    </p>
                    <ButtonAuth
                      label={
                        isLoading ? (
                          <div>
                            <Loader color={'#fff'} />
                          </div>
                        ) : (
                          'Update Password'
                        )
                      }
                      width='full'
                      onClick={submitHandler}
                      disabled={!validConfirm}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default ChangePassword;

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
