import AdsScreen from './withdrawal/AdsScreen';

export interface IWithdrawal {
  label: string;
  route?: React.ReactNode;
}

export const withdrawSlices: IWithdrawal[] = [
  {label: 'Pay For Ads', route: <AdsScreen />},
];

const [Ads] = withdrawSlices;
export const initialTabs = [Ads];

export function getNextWithdrawal(
  slides: IWithdrawal[]
): IWithdrawal | undefined {
  const existing = new Set(slides);
  return withdrawSlices.find((slide) => !existing.has(slide));
}
