import {CgClose} from 'react-icons/cg';
import {NavLink} from 'react-router-dom';
import {customerUrl} from '../../constant';

interface IAdvert {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const AdvertBar = ({setIsOpen}: IAdvert) => {
  return (
    <div className='bg-primary h-[3rem] flex flex-row justify-center items-center text-white px-4 gap-4'>
      {/* Text and Shop now Link */}
      <p className='font-poppinsRegular sm:text-sm text-xs'>
        Sign up and get 20% off to your first order.
        <NavLink
          to={`${customerUrl}/register`}
          className='font-poppinsSemibold pl-1 sm:text-base text-sm'>
          Sign Up Now
        </NavLink>
      </p>

      {/* Language */}
      <div onClick={() => setIsOpen(false)} className='cursor-pointer'>
        <CgClose size={24} />
      </div>
    </div>
  );
};

export default AdvertBar;
