import { useEffect, useState } from "react";
import Actions from "../actions";


const useFetchRecipient = (recipientId) => {
    const [recipient, setRecipient] = useState(null) 

    useEffect(() => {
        const getUser = async () => {
            if(!recipientId) return null;
            const recipientData = await Actions.fetchRecipientUser(recipientId)
            setRecipient(recipientData?.data?.details)
        }
        getUser()
    }, [recipientId])

    return recipient;
}


export default useFetchRecipient;