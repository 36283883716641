/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {useEffect, useState} from 'react';
import {AdminServices} from '../../../redux/services/admin/admin';
import {IAdmin} from '../../../components/interfaces/vendors';
import {axiosError} from '../../../components/Helpers';
import {Zoom, toast} from 'react-toastify';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {useAdmin} from '../../../app/hooks';
import {Add} from 'iconsax-react';
import AdminRoleTable from '../../../components/table/AdminRoleTable';
import AddNewAdmin from './modal/AddNewAdmin';
import {AuthServices} from '../../../redux/services/admin/auths';

const UserManagementAdmin = ({show}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [admins, setAdmins] = useState<IAdmin[]>([]);
  const token = useAdmin().adminToken;
  // Query
  const [query, setQuery] = useState('');
  const keys = ['firstName', 'email', 'phone'];
  const search = (data: any) => {
    return data.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };

  // Get Vendors
  useEffect(() => {
    getAdmins();
  }, []);

  const getAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.allAdmins(token);
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response.data?.data;
        setAdmins(res?.admins);
        // console.log(res.rows);
        setIsLoading(false);
        // console.log('profile', profile);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  // Reset Vendor
  const [showView, setShowView] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  // Sort --------------------
  const [openSort, setOpenSort] = useState(false);

  const [sortingOption, setSortingOption] = useState<keyof IAdmin>('firstName');
  const [isAscending, setIsAscending] = useState(false);

  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const sortedData = [...admins].sort((a, b) => {
    // Use the selected sorting option
    const sortOrder = isAscending ? 1 : -1;
    const aValue = a[sortingOption];
    const bValue = b[sortingOption];

    // Compare values based on the sorting order
    if (aValue < bValue) return -1 * sortOrder;
    if (aValue > bValue) return 1 * sortOrder;
    return 0;
  });

  const handleEditAdmin = async (
    id: string,
    adminFirstName: string,
    adminLastName: string,
    adminEmail: string,
    adminPhone: string,
    adminRole: string
  ) => {
    const adminId = id;
    setIsActionLoading(true);
    const data = {
      firstName: adminFirstName,
      lastName: adminLastName,
      email: adminEmail,
      phone: adminPhone,
      role: adminRole,
    };
    try {
      const response = await AdminServices.resetVendor(token, adminId, data);
      setIsActionLoading(false);
      if (response.data.status === 'success') {
        const res = response.data.data;
        toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          transition: Zoom,
          closeOnClick: false,
          hideProgressBar: true,
          draggable: false,
          style: {width: '400px'},
        });
        setShowEdit(false);
        setIsActionLoading(false);
        await getAdmins();
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsActionLoading(false);
    }
  };
  const handleNewAdmin = async (
    adminFirstName: string,
    adminLastName: string,
    adminEmail: string,
    adminPhone: string,
    adminRole: string,
    adminPassword: string
  ) => {
    setIsActionLoading(true);
    const data = {
      firstName: adminFirstName,
      lastName: adminLastName,
      email: adminEmail,
      phone: adminPhone,
      role: adminRole,
      password: adminPassword,
    };
    try {
      const response = await AuthServices.register(data);
      setIsActionLoading(false);
      if (response.data.status === 'success') {
        const res = response.data.data;
        toast.success(res.message, {
          position: toast.POSITION.TOP_CENTER,
          transition: Zoom,
          closeOnClick: false,
          hideProgressBar: true,
          draggable: false,
          style: {width: '400px'},
        });
        setShowView(false);
        setIsActionLoading(false);
        await getAdmins();
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsActionLoading(false);
    }
  };

  // Deactivate Vendor
  const onDeactivate = async (id: any) => {
    const data = {};
    setIsActionLoading(true);
    try {
      const response = await AdminServices.deactivateAdmin(token, id, data);
      // console.log('Deactivate', response.data);
      if (response.data.status === 'success') {
        toast.success(response.data.data.message);
        setIsActionLoading(false);
        // closing the modal and trigger data reload
        await getAdmins();
      } else if (
        (response?.data.status === 'failed' &&
          response?.data.message === 'Invalid token or token expired') ||
        response?.data.message === 'Authorization denied! Token expired'
      ) {
        localStorage.clear();
        window.location.reload();
        setIsActionLoading(false);
      } else if (
        response?.data.status === 'failed' &&
        response?.data.message === 'ERR no such key'
      ) {
        console.log(response?.data.message);
        setIsActionLoading(false);
      } else {
        toast.error(response?.data.message || 'Connection Error');
        setIsActionLoading(false);
      }
      // setIsLoading(false);
    } catch (error: any) {
      // axiosError(error.response);
      console.log(error);
      setIsActionLoading(false);
    }
  };
  return (
    <AppContainer>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <AddNewAdmin
            setShowView={setShowView}
            handleAddNewAdmin={handleNewAdmin}
            isActionLoading={isActionLoading}
          />
        </div>
      )}
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Admin Management</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>ADMIN MANAGEMENT</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        <div className='flex flex-row justify-between items-center  pt-4'>
          <div className='flex gap-2 items-center bg-[#D8CCC3] border border-primary rounded-md justify-center px-4 py-2'>
            <div className='text-primary'>Admins</div>
            <div className='flex flex-row justify-center items-center bg-primary w-6 h-6 rounded-full'>
              <p className=' text-white text-xs'>{20}</p>
            </div>
          </div>
          {/* Sort and Date Filter */}
          <div className='flex flex-row items-center justify-end gap-6'>
            {/* Sort */}
            <div
              onClick={() => setOpenSort(!openSort)}
              className='flex flex-row items-center gap-4 cursor-pointer'>
              <div className='font-poppinsMedium'>Sort by:</div>
              <div className='relative'>
                <div className='flex flex-row items-center gap-10'>
                  <p className='text-[#868FE1] text-xs font-poppinsMedium'>
                    {sortingOption.charAt(0).toUpperCase() +
                      sortingOption.slice(1)}
                  </p>
                  <BiChevronDown size={20} color='#868FE1' />
                </div>
                {/* Sort DropDown */}
                {openSort && (
                  <div
                    onClick={() => setOpenSort(false)}
                    className='bg-white w-full px-2 py-2 flex flex-col items-start absolute rounded-md shadow-md top-8 z-50 text-xs gap-2'>
                    <p
                      className='hover:underline hover:text-[#868FE1]'
                      onClick={() => handleSortingOptionChange('firstName')}>
                      First Name
                    </p>
                    <p
                      className='hover:underline hover:text-[#868FE1]'
                      onClick={() => handleSortingOptionChange('lastName')}>
                      Last Name
                    </p>
                    <p
                      className='hover:underline hover:text-[#868FE1]'
                      onClick={() => handleSortingOptionChange('role')}>
                      Role
                    </p>
                  </div>
                )}
              </div>
            </div>
            {/* Add New Admin */}
            <div
              onClick={() => setShowView(true)}
              className='flex flex-row cursor-pointer justify-between items-center px-2 py-2 gap-2 bg-primary rounded-md text-white transition-hover'>
              <Add size={20} />
              <p>Add New Admin</p>
            </div>
          </div>
        </div>
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <AdminRoleTable
                admins={search(sortedData)}
                showEdit={showEdit}
                setShowEdit={setShowEdit}
                handleEditAdmin={handleEditAdmin}
                isEditLoading={isActionLoading}
                handleDeactivate={onDeactivate}
              />
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default UserManagementAdmin;
