/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {IOrder} from '../../../components/interfaces/products';
import moment from 'moment';
import {numberFormat} from '../../../components/Helpers';
import {IoMdMore} from 'react-icons/io';
import Modal from '../../../components/table/modal/Modal';
import {CgClose} from 'react-icons/cg';
import Dropdown from '../../../components/table/modal/Dropdown';
import Spinner from '../../../components/spinner/Spinner';
import {Loader} from '../../../assets/svg';

interface IVendorOrderTable {
  orders: IOrder[];
  updateOrder?: any;
  isUpdateLoading?: boolean;
  isCancelLoading?: boolean;
  cancelVendorOrder?: any;
}

const VendorOrderTable = ({
  orders,
  updateOrder,
  isUpdateLoading,
  cancelVendorOrder,
  isCancelLoading,
}: IVendorOrderTable) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedRow(null);
  };

  // Modal
  const [openRejectionModal, setOpenRejectionModal] = useState(false);
  const [description, setDescription] = useState('');
  const handleFeedbackChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const [selectedReason, setSelectedReason] = useState('');

  const handleReasonChange = (selectedValue: string) => {
    console.log('Selected Value:', selectedValue);
    // Implement your logic here when the dropdown value changes
    setSelectedReason(selectedValue);
  };
  const reasonOptions = [
    {value: 'Order Duplication', label: 'Order Duplication'},
    {value: 'Not Available', label: 'Not Available'},
    {value: 'Wrong Item ', label: 'Wrong Item '},
    {value: 'Not Described ', label: 'Not Described '},
  ];

  const singleOrderId: string = JSON.parse(
    localStorage.getItem('#idOd') || '{}'
  ).id;

  return (
    <>
      <div className=' sm:block hidden'>
        <table className=' mt-4 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <div className='h-full'>{isUpdateLoading && <Spinner />}</div>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10 rounded-tl-md'>Date</th>
              <th className='w-16'>Order ID</th>
              <th className='w-10'>Item Name</th>
              <th className='w-10'>Product Description</th>
              <th className='w-10'>Price NGN</th>
              <th className='w-10'>Status</th>
              <th className='w-10'>Action</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#FEF8F0]'>
            {orders?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={7}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {orders?.map((order: any) => {
                return (
                  <tr
                    className='overflow-x-auto h-[3.125rem] font-medium'
                    key={order?.id}>
                    <td className='w-10 text-center'>
                      {moment(order?.createdAt).format('l')}
                    </td>
                    <td className='w-16 text-center'>
                      #{order?.productId.slice(0, 6).toUpperCase()}
                    </td>
                    <td className='w-10 text-center'>{order?.product?.name}</td>
                    <td className='w-10 text-center'>
                      <div className=' flex flex-row justify-center'>
                        <div className='flex flex-col gap-2 items-start md:w-[50%] w-full '>
                          <div className='flex flex-row items-center gap-2'>
                            <p className=''>Quantity: </p>
                            <p className=''>{order?.quantity} </p>
                          </div>
                          {order?.product?.color && (
                            <div className='flex flex-row items-center gap-2'>
                              <p className=''>Color: </p>
                              <p className=''>{order?.product?.color} </p>
                            </div>
                          )}
                          {order?.product?.size && (
                            <div className='flex flex-row items-center gap-2'>
                              <p className=''>Size: </p>
                              <p className=''>{order?.product?.size} </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='w-10 text-center'>
                      ₦{numberFormat(order?.subTotal)}
                    </td>
                    <td className='w-10 text-center'>
                      {order?.status && (
                        <div
                          className={
                            order?.status === 'orderPlaced' ||
                            order?.status === 'pending'
                              ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm w-[70%] m-auto'
                              : order?.status === 'takeToWarehouse'
                              ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm  w-[70%] m-auto'
                              : order?.status === 'ready for pickup' ||
                                order?.status === 'delivered' ||
                                order?.status === 'completed'
                              ? 'bg-[#E6FCE5] px-3 py-2 text-[#35C655] rounded-md shadow-sm  w-[70%] m-auto'
                              : 'bg-[#FEEFEE] px-3 py-2 text-[#C81E19] rounded-md shadow-sm  w-[70%] m-auto'
                          }>
                          {order?.status}
                        </div>
                      )}
                    </td>
                    <td className='w-16 text-center'>
                      <div className='w-full relative '>
                        <button
                          onClick={() => {
                            setSelectedRow(order?.id);
                            setIsModalOpen(!isModalOpen);
                          }}
                          className='text-primary hover:text-primary px-2 py-1 rounded flex flex-row items-center justify-center gap-2 m-auto transition-hover'>
                          <IoMdMore size={24} />
                        </button>
                        {isModalOpen && selectedRow === order?.id && (
                          <Modal isOpen={isModalOpen}>
                            <div>
                              <div>
                                {order?.status === 'delivered' ||
                                order?.status === 'completed' ? (
                                  <div className='p-2 flex flex-row items-center gap-2 px-2 text-gray-400'>
                                    <p> Ready for Pick Up</p>
                                  </div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      handleCloseModal();
                                      updateOrder(order?.id);
                                    }}
                                    className='p-2 cursor-pointer hover:bg-gray-100 hover:text-primary hover:rounded flex flex-row items-center gap-2 px-2 text-gray-400'>
                                    <p> Ready for Pick Up</p>
                                  </div>
                                )}

                                <div
                                  onClick={() => {
                                    setOpenRejectionModal(true);
                                    handleCloseModal();
                                    localStorage.setItem(
                                      '#idOd',
                                      JSON.stringify(order)
                                    );
                                  }}
                                  className='p-2 cursor-pointer hover:bg-red-100 hover:text-red-600 hover:rounded-md flex flex-row items-center gap-2 px-2 text-red-500'>
                                  <p> Cancel Order</p>
                                </div>
                              </div>
                            </div>
                          </Modal>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
              {openRejectionModal && (
                <div className='bg-white w-[400px] py-4 rounded-md p-6 z-50 absolute 2xl:left-[55%] left-[48%] top-[30%] shadow-lg drop-shadow-lg'>
                  {/* Close Button */}
                  <div className='flex flex-row justify-end cursor-pointer'>
                    <CgClose
                      size={20}
                      color='black'
                      onClick={() => setOpenRejectionModal(false)}
                    />
                  </div>
                  {/* Text */}
                  <div className='pb-6'>
                    <p className='text-black font-poppinsMedium text-base'>
                      Give Reason For Cancel
                    </p>
                    <div className='py-2'>
                      <p className='text-black text-xs font-poppinsRegular'>
                        Select a reason
                      </p>
                      <Dropdown
                        options={reasonOptions}
                        onChange={handleReasonChange}
                        placeholder='Return Reason'
                      />
                    </div>
                    <p className='text-black text-xs pt-4 font-poppinsRegular'>
                      Add Description
                    </p>
                  </div>
                  {/* Text Area */}
                  <textarea
                    className='w-full pl-5 pt-3 focus:border-[#DADDE1] focus:outline-none border rounded-md'
                    name='description'
                    value={description}
                    placeholder='Please write a description for this cancellation'
                    cols={10}
                    rows={8}
                    onChange={handleFeedbackChange}
                  />
                  {/* Buttons */}
                  <div className='py-4 flex flex-row gap-6 justify-end'>
                    {/* Cancel */}
                    <button
                      className='px-6 py-2 rounded-md bg-[#EEEEEE] text-primary shadow-sm bg-transparent font-poppinsMedium'
                      onClick={() => setOpenRejectionModal(false)}>
                      Cancel
                    </button>
                    {/* Save */}
                    <button
                      className='px-6 py-2 rounded-md text-white shadow-sm bg-primary font-poppinsMedium disabled:bg-gray-200'
                      onClick={() => {
                        cancelVendorOrder(
                          singleOrderId,
                          selectedReason,
                          description
                        );
                      }}
                      disabled={!description ? true : false}>
                      {isCancelLoading ? <Loader color={'white'} /> : 'Submit'}
                    </button>
                  </div>
                </div>
              )}
            </>
          </tbody>
        </table>
      </div>

      {/* Table Mobile */}
      <div className='sm:hidden flex items-center justify-center w-full'>
        <table className='border-separate border-spacing-y-2 text-sm overflow-x-auto w-full table-fixed'>
          <thead className=''>
            <tr className='bg-orange-100 h-[3.125rem] text-primary'>
              <th className='rounded-tl-md'>Date</th>
              <th className=''>Name</th>
              <th className=' '>Desc</th>
              <th className=''>Price</th>
              <th className='rounded-tr-md'>Status</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-50'>
            {orders?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={5}>
                  <span className='text-base text-primary font-semibold'>
                    No Product available
                  </span>
                </td>
              </tr>
            )}
            <>
              {orders?.map((order: any) => {
                return (
                  <tr key={order?.id}>
                    <td className=' text-center td-transClass'>
                      {moment(order?.createdAt).format('l')}
                    </td>
                    <td className=' text-center td-transClass'>
                      {order?.product?.name}
                    </td>
                    <td className=' text-center td-transClass'>
                      <div className=' flex flex-row justify-center'>
                        <div className='flex flex-col gap-2 items-start w-full '>
                          <div className='flex flex-row items-center gap-2'>
                            <p className=''>Quantity: </p>
                            <p className=''>{order?.quantity} </p>
                          </div>
                          {order?.product?.color && (
                            <div className='flex flex-row items-center gap-2'>
                              <p className=''>Color: </p>
                              <p className=''>{order?.product?.color} </p>
                            </div>
                          )}
                          {order?.product?.size && (
                            <div className='flex flex-row items-center gap-2'>
                              <p className=''>Size: </p>
                              <p className=''>{order?.product?.size} </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                    <td className=' text-center td-transClass'>
                      ₦{numberFormat(order?.subTotal)}
                    </td>
                    <td className=' text-center td-transClass'>
                      {order?.status && (
                        <div
                          className={
                            order?.status === 'order?Placed' ||
                            order?.status === 'pending'
                              ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm'
                              : order?.status === 'takeToWarehouse'
                              ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm'
                              : order?.status === 'ready for pickup'
                              ? 'bg-[#E6FCE5] px-3 py-2 text-[#35C655] rounded-md shadow-sm'
                              : 'bg-[#FEEFEE] px-3 py-2 text-[#C81E19] rounded-md shadow-sm'
                          }>
                          {order?.status}
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VendorOrderTable;
