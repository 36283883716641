import {useState} from 'react';
import {useAppDispatch} from '../../app/hooks';
import {setSingleAdminSettlement} from '../../redux/slices/settlements/adminSettlementSlice';
import {numberFormat} from '../Helpers';
import ViewSettlement from '../../pages/dashboard/admin/modal/ViewSettlement';
interface IPaymentTable {
  payments: any[];
  handleCheckboxChange: any;
  handleSelectAllChange: any;
  selectAll: any;
  selectedRows: any;
  onSelectRows?: any;
  selectedStatus?: any;
  enabledCheckboxRow?: any;
}
const PaymentRequestTable = ({
  payments,
  handleCheckboxChange,
  handleSelectAllChange,
  selectAll,
  selectedRows,
}: IPaymentTable) => {
  const [showView, setShowView] = useState(false);

  const dispatch = useAppDispatch();

  // onViewProduct
  const viewHandler = async (id: any) => {
    payments.filter((t: any) => t.id === id);
  };
  return (
    <>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <ViewSettlement setIsOpen={setShowView} />
        </div>
      )}
      <div className=' sm:block hidden'>
        <table className=' mt-4 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-4 rounded-tl-md'>
                {/* <p className='pr-2 inline-flex'>Select All</p> */}
                <input
                  type='checkbox'
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                />
              </th>
              <th className='w-10'>Product Name</th>
              <th className='w-10'>Vendor Name</th>
              <th className='w-10'>Amount</th>
              <th className='w-10'>Account Number</th>
              <th className='w-10'>Account Name</th>
              <th className='w-10'>Bank Name</th>
              <th className='w-16'>Status</th>
              <th className='w-10'>Action</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8FAFC]'>
            {payments?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={9}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {payments?.map((payment: any) => {
                return (
                  <tr
                    className='overflow-x-auto h-[3rem] font-medium'
                    key={payment.id}>
                    <td className='w-4 text-center'>
                      <input
                        type='checkbox'
                        checked={selectedRows.includes(payment.id.toString())}
                        onChange={(event) =>
                          handleCheckboxChange(
                            event,
                            payment.id.toString(),
                            payment.amount
                          )
                        }
                      />
                    </td>
                    <td className='w-10 text-center'>{payment.productName}</td>
                    <td className='w-10 text-center'>{payment.name}</td>

                    <td className='w-10 text-center'>
                      ₦{numberFormat(payment.amount)}
                    </td>
                    <td className='w-10 text-center'>{payment.accountNo}</td>
                    <td className='w-10 text-center'>{payment.accountName}</td>
                    <td className='w-10 text-center'>{payment.bankName}</td>
                    <td
                      className={
                        payment.status === 'paid'
                          ? 'w-10 text-center text-success'
                          : payment.status === 'pending'
                          ? ' w-10 text-center text-[#FFAD33]'
                          : 'w-10 text-center text-black'
                      }>
                      {payment.status === 'paid'
                        ? 'Paid'
                        : payment.status === 'pending'
                        ? 'Pending'
                        : payment.status === 'cancelled'
                        ? 'Cancelled'
                        : 'Eligible'}
                    </td>
                    <td className='w-16'>
                      <div className='flex flex-row justify-evenly items-center h-10'>
                        {/* View  */}
                        <span
                          onClick={() => {
                            viewHandler(payment.id);
                            dispatch(setSingleAdminSettlement(payment));
                            setShowView(true);
                          }}
                          className='border-b-[1.7px] border-b-primary text-primary font-poppinsMedium pb-1 cursor-pointer'>
                          View
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PaymentRequestTable;
