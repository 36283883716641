import './customSelect.css';
import {inputProps} from '../../../dashboard/profile/select';

const CustomSelect = (p: inputProps) => {
  return (
    <select
      value={p.valueField}
      name={p.name}
      className='w-full sm:h-12 h-14 rounded-lg px-5 border-[1px] border-gray-300 outline-none bg-transparent font-poppinsRegular text-gray-400 custom-select mb-2 bg-white'
      onChange={(e) => p.handleChange(e)}>
      <option className='text-gray-500'>Select Option</option>
      {p.options?.map((opt: any, index: any) => (
        <option key={index} value={opt.name} className=''>
          {opt.name}
        </option>
      ))}
    </select>
  );
};

export default CustomSelect;
