const ProfileSkeleton = () => {
  return (
    <>
      <div className='animate-pulse px-4 h-full w-full'>
        <div className='w-full flex flex-row py-4 items-center justify-between'>
          <div className=' mx-2 rounded-full w-32 h-32 bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6 sm:w-[40%] w-[20%] bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6 sm:w-[40%] w-[20%] bg-gray-200'></div>
        </div>
        <div className='w-full grid grid-cols-2 py-3'>
          <div className=' mx-2 rounded-md sm:h-8 h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-3 py-6'>
          <div className=' mx-2 rounded-md sm:h-8 h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-3 py-3'>
          <div className=' mx-2 rounded-md sm:h-8 h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-2 py-6'>
          <div className=' mx-2 rounded-md sm:h-8 h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-3 py-3'>
          <div className=' mx-2 rounded-md sm:h-8 h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
        </div>
        <div className='w-full grid grid-cols-2 py-6'>
          <div className=' mx-2 rounded-md sm:h-8 h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md sm:h-8 h-6  bg-gray-200'></div>
        </div>
      </div>
    </>
  );
};

export default ProfileSkeleton;
