/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {IoIosCheckmark} from 'react-icons/io';
import {motion} from 'framer-motion';

interface ITest {
  setActiveScreen: React.Dispatch<React.SetStateAction<number>>;
  activeScreen: number;
}
const MultiFormController = ({setActiveScreen, activeScreen}: ITest) => {
  const items = ['Personal', 'Business', 'Others', 'Confirmation'];
  const active = activeScreen + 1;

  // const handleButtonClick = (num: any) => {
  //   setActiveScreen(num);
  // };

  const controller = (num: number) => {
    const item = (
      <div
        key={num}
        className={
          active <= num
            ? 'text-[#FBD9B4] flex flex-col items-center justify-center gap-1 pb-7'
            : 'text-primary flex flex-col items-center justify-center gap-1 pb-7'
        }>
        <div
          className={
            active <= num ? 'text-[#D0D5DD] text-base' : 'text-black text-base'
          }>
          {items[num]}
        </div>
        <div
          className={
            active <= num
              ? 'h-[1.3rem] w-[1.3rem] rounded-full bg-[#FBD9B4] flex flex-row justify-center items-center'
              : 'h-[1.3rem] w-[1.3rem] rounded-full bg-secondary_500 flex flex-row justify-center items-center'
          }>
          <IoIosCheckmark className='w-full h-full text-white' size={24} />
        </div>
      </div>
    );

    return item;
  };

  console.log(activeScreen);
  return (
    <motion.div
      initial={{opacity: 0, y: 20}}
      animate={{opacity: 1, y: 0}}
      transition={{duration: 2}}
      className='relative w-full h-14 flex items-center'>
      <div className='absolute w-full top-1/2 transform -translate-y-1/2'>
        <div className='rounded-md lg:w-[85%]  sm:w-[85%] w-[79%] mx-auto border-[#FBD9B4] bg-[#FBD9B4] border-2 h-1'></div>
      </div>
      <div className=' flex flex-row items-center w-full justify-between z-10'>
        {items.map((_item, index) => controller(index))}
      </div>
    </motion.div>
  );
};

export default MultiFormController;
