import {useAdmin} from '../../../app/hooks';

interface ChatWindowProps {
  selectedUser: string | null;
}
const Chats = ({selectedUser}: ChatWindowProps) => {
  const user = useAdmin().admin;
  return (
    <>
      {selectedUser ? (
        <div className='flex-auto h-full px-6 pt-2 pb-2'>
          <div className='flex flex-col rounded-2xl bg-gray-100 p-4 relative h-full pb-32'>
            <div className='flex flex-col h-full '>
              <p className='inline-flex bg-white p-3 w-60'>
                Chat with{' '}
                <span className='font-poppinsMedium pl-2'> {selectedUser}</span>
              </p>
              {/* Chats */}
              <div className='flex flex-col h-full overflow-auto'>
                <div className='grid grid-cols-12 '>
                  <div className='col-start-6 col-end-13 p-3 rounded-lg'>
                    <div className='flex items-center justify-start flex-row-reverse'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white font-poppinsMedium'>
                        A
                      </div>
                      <ConversationCard
                        text={`Hi, Good noon! I'd like to close my account please.`}
                        type='customer'
                      />
                    </div>
                  </div>
                  <div className='col-start-1 col-end-8 p-3 rounded-lg'>
                    <div className='flex flex-row items-center'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-primary flex-shrink-0 text-white font-poppinsMedium'>
                        {user.firstName?.slice(0, 1)}
                      </div>
                      <ConversationCard
                        text='Hey! How may I help you today?'
                        type='agent'
                      />
                    </div>
                  </div>
                  <div className='col-start-1 col-end-8 p-3 rounded-lg'>
                    <div className='flex flex-row items-center'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-primary flex-shrink-0 text-white font-poppinsMedium'>
                        {user.firstName?.slice(0, 1)}
                      </div>
                      <ConversationCard
                        text=' Please provide your registered email address and full name for security reasons'
                        type='agent'
                      />
                    </div>
                  </div>
                  <div className='col-start-6 col-end-13 p-3 rounded-lg'>
                    <div className='flex items-center justify-start flex-row-reverse'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white font-poppinsMedium'>
                        A
                      </div>
                      <ConversationCard
                        text={`matthiasbleden@gmail.com`}
                        type='customer'
                      />
                    </div>
                  </div>
                  <div className='col-start-6 col-end-13 p-3 rounded-lg'>
                    <div className='flex items-center justify-start flex-row-reverse'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white font-poppinsMedium'>
                        A
                      </div>
                      <ConversationCard
                        text={`Matthias Blenden`}
                        type='customer'
                      />
                    </div>
                  </div>
                  <div className='col-start-1 col-end-8 p-3 rounded-lg'>
                    <div className='flex flex-row items-center'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-primary flex-shrink-0 text-white font-poppinsMedium'>
                        {user.firstName?.slice(0, 1)}
                      </div>
                      <ConversationCard
                        text={`Thank you, please hold while I query your profile`}
                        type='agent'
                      />
                    </div>
                  </div>
                  <div className='col-start-6 col-end-13 p-3 rounded-lg'>
                    <div className='flex items-center justify-start flex-row-reverse'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0 text-white font-poppinsMedium'>
                        A
                      </div>
                      <ConversationCard text={`Thank you!`} type='customer' />
                    </div>
                  </div>
                  <div className='col-start-1 col-end-8 p-3 rounded-lg'>
                    <div className='flex flex-row items-center'>
                      <div className='flex items-center justify-center h-10 w-10 rounded-full bg-primary flex-shrink-0 text-white font-poppinsMedium'>
                        {user.firstName?.slice(0, 1)}
                      </div>
                      <ConversationCard
                        text={`Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, in.`}
                        type='agent'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Input */}
            <div className='absolute bottom-0 w-full left-0'>
              <div className='flex flex-row items-center h-16 rounded-bl-xl rounded-br-xl bg-white w-full px-4'>
                <div>
                  <button className='flex items-center justify-center text-gray-400 hover:text-gray-600'>
                    <svg
                      className='w-5 h-5'
                      fill='none'
                      stroke='currentColor'
                      viewBox='0 0 24 24'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        strokeWidth='2'
                        d='M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13'></path>
                    </svg>
                  </button>
                </div>
                <div className='flex-grow ml-4'>
                  <div className='relative w-full'>
                    <input
                      type='text'
                      className='flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10'
                    />
                    <button className='absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600'>
                      <svg
                        className='w-6 h-6'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className='ml-4'>
                  <button className='flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-2 flex-shrink-0'>
                    <span>Send</span>
                    <span className='ml-2'>
                      <svg
                        className='w-4 h-4 transform rotate-45 -mt-px'
                        fill='none'
                        stroke='currentColor'
                        viewBox='0 0 24 24'
                        xmlns='http://www.w3.org/2000/svg'>
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          strokeWidth='2'
                          d='M12 19l9 2-9-18-9 18 9-2zm0 0v-8'></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex-auto h-full px-6 pt-2 pb-2'>
          <div className='grid grid-cols-1 place-items-center  h-full'>
            Please find the list of messages at the left side
          </div>
        </div>
      )}
    </>
  );
};

export default Chats;

interface IConversation {
  type: 'agent' | 'customer';
  text: string;
}
const ConversationCard = ({type, text}: IConversation) => {
  return (
    <>
      {type === 'agent' ? (
        <div className='relative ml-3 text-sm bg-white py-2 px-4 shadow rounded-xl'>
          <p className='font-poppinsRegular'>{text}</p>
        </div>
      ) : (
        <div className='relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl'>
          <p className='font-poppinsRegular'>{text}</p>
        </div>
      )}
    </>
  );
};
