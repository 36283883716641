import {api} from '../api';

// Register
const register = (data: any) => {
  return api.post('admin/register', data);
};

// Login
const login = (data: any) => {
  return api.post('admin/login', data);
};
const supportLogin = (data: any) => {
  return api.post('support/login', data);
};

export const AuthServices = {
  register,
  login,
  supportLogin,
};
