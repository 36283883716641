import React, {useEffect, useState} from 'react';
import ViewProduct from './modal/ViewProduct';
import {setSingleProduct} from '../../../redux/slices/product/productSlice';
import {IProduct} from '../../../components/interfaces/products';
import {useAppDispatch} from '../../../app/hooks';
import {numberFormat} from '../../../components/Helpers';
import {CgClose} from 'react-icons/cg';
import {Zoom, toast} from 'react-toastify';

// Table Row
interface TableRowProps {
  data: IProduct;
  handleOpenModal: boolean;
  selectedRows: {id: any; reason: string}[];
  onRowSelect: (rowId: string, isSelected: boolean) => void;
  onSaveFeedback: (rowId: string, feedback: string) => void;
  onClick: () => void;
  selectedStatus: string;
  indexId: any;
  enabledCheckboxRow: number | null | string;
  setIsFeedBack: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableRow = ({
  data,
  onRowSelect,
  onClick,
  onSaveFeedback,
  selectedStatus,
  handleOpenModal,
  selectedRows,
  enabledCheckboxRow,
  indexId,
  setIsFeedBack,
}: TableRowProps) => {
  // Modal
  const [openRejectionModal, setOpenRejectionModal] = useState(false);
  const [feedback, setFeedback] = useState('');

  const handleCheckboxChange = (isChecked: boolean, rowId: any) => {
    onRowSelect(rowId, isChecked);
  };

  const handleFeedbackChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setFeedback(event.target.value);
  };

  const handleSaveFeedback = () => {
    onSaveFeedback(data.id, feedback);
    setOpenRejectionModal(false);
    toast.success(`Saved!`, {
      position: toast.POSITION.TOP_RIGHT,
      transition: Zoom,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      style: {width: '120px', height: '10px'},
      delay: 100,
    });
    setIsFeedBack(true);
    // setFeedback('');
  };

  return (
    <>
      <tr className='overflow-x-auto' key={data.id}>
        <td className='w-6 text-center'>
          <input
            type='checkbox'
            checked={selectedRows.some(
              (selectedRow) => selectedRow.id === data.id
            )}
            disabled={enabledCheckboxRow !== indexId}
            onChange={(e) => handleCheckboxChange(e.target.checked, data.id)}

            // onChange={(e) => onRowSelect(data.id, e.target.checked)}
          />
        </td>
        <td className=''>
          <div className='flex justify-center'>
            <div className='w-20 h-20 self-center'>
              <img
                src={data.productImg || data.additionalImg[0]}
                alt=''
                className='object-contain w-full rounded-md h-full'
              />
            </div>
          </div>
        </td>

        <td className='w-10 text-center'>{data.id}</td>
        <td className='w-10 text-center'>{data.vendorMail}</td>
        <td className='w-10 text-center'>{data.name}</td>
        <td className='w-10 text-center'>{data.quantity}</td>
        <td className='w-10 text-center'>₦{numberFormat(data.price)}</td>
        <td className='w-10 text-center capitalize'>
          <div
            className={
              data.status === 'awaiting-approval'
                ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm'
                : data.status === 'approved'
                ? 'bg-[#E6FCE5] px-3 py-2 text-[#35C655] rounded-md shadow-sm'
                : 'bg-[#FEEFEE] px-3 py-2 text-[#C81E19] rounded-md shadow-sm'
            }>
            {' '}
            {data.status}
          </div>
        </td>
        <td className='w-10 text-center'>{data.approvedBy}</td>
        <td className='w-10'>
          <div className='flex flex-row justify-evenly items-center'>
            <span
              onClick={onClick}
              className='border-b-[1.7px] border-b-primary text-primary font-poppinsMedium pb-1 cursor-pointer'>
              View
            </span>
          </div>
        </td>
        <td className='w-10'>
          {selectedStatus === 'rejected' && enabledCheckboxRow === indexId && (
            <div>
              {/* bullets */}
              <div
                onClick={() => setOpenRejectionModal(true)}
                className='flex flex-row justify-center items-center'>
                <div className='cursor-pointer border border-primary p-2 rounded-md text-primary font-poppinsMedium'>
                  Add Feedback
                </div>
              </div>
            </div>
          )}
        </td>
      </tr>
      {openRejectionModal && (
        <div className='bg-white w-[600px] h-[350px] rounded-md p-6 z-50 absolute 2xl:left-[55%] left-[48%] top-[30%] shadow-lg drop-shadow-lg'>
          {/* Close Button */}
          <div className='flex flex-row justify-end cursor-pointer'>
            <CgClose
              size={22}
              color='black'
              onClick={() => setOpenRejectionModal(false)}
            />
          </div>
          {/* Text */}
          <div className='py-6'>
            <p className='text-black font-poppinsMedium text-base'>
              Write Reason For Rejection
            </p>
            <p className='text-black font-light text-xs'>
              Giving your reason for rejection would further help vendor on the
              next upload.
            </p>
          </div>
          {/* Text Area */}
          <textarea
            className='w-full pl-5 pt-3 focus:border-[#DADDE1] focus:outline-none border rounded-md'
            name='description'
            value={feedback}
            placeholder='Please write your reason for rejecting this product'
            cols={10}
            rows={8}
            onChange={handleFeedbackChange}
          />
          {/* Buttons */}
          <div className='py-4 flex flex-row gap-6 justify-end'>
            {/* Cancel */}
            <button
              className='px-3 py-2 rounded-md text-gray-300 shadow-sm bg-transparent font-poppinsMedium'
              onClick={() => setOpenRejectionModal(false)}>
              Cancel
            </button>
            {/* Save */}
            <button
              className='px-3 py-2 rounded-md text-white shadow-sm bg-primary font-poppinsMedium disabled:bg-gray-200'
              onClick={handleSaveFeedback}
              disabled={!feedback ? true : false}>
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
};

interface IVendorProductTable {
  products: IProduct[];
  handleCloseModal: () => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectRows: any;
  selectedStatus: string;
  enabledCheckboxRow: number | null | string;
  openModal: any;
  setIsFeedBack: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminProductTable = ({
  products,
  handleCloseModal,
  isModalOpen,
  setIsModalOpen,
  onSelectRows,
  selectedStatus,
  enabledCheckboxRow,
  openModal,
  setIsFeedBack,
}: IVendorProductTable) => {
  // onViewProduct
  const viewHandler = async (id: any) => {
    products.filter((t: any) => t.id === id);
  };

  const dispatch = useAppDispatch();

  // checkBox
  const [selectedRows, setSelectedRows] = useState<{id: any; reason: string}[]>(
    []
  );

  useEffect(() => {
    // Notify the parent component when selected rows change
    onSelectRows(selectedRows);
  }, [selectedRows, onSelectRows]);

  // Handle selectAll
  // const handleSelectAll = (isChecked: boolean) => {
  //   const allRowIds = products.map((data) => data.id);
  //   setSelectedRows(isChecked ? allRowIds.map((id) => ({id, reason: ''})) : []);
  // };

  // Handle selectRow
  const handleRowSelect = (rowId: string, isSelected: boolean) => {
    setSelectedRows((prevSelectedRows) =>
      isSelected
        ? [...prevSelectedRows, {id: rowId, reason: ''}]
        : prevSelectedRows.filter((row) => row.id !== rowId)
    );
  };

  //  Handle SaveFeedback
  const handleSaveFeedback = (rowId: string, feedback: string) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.map((row) =>
        row.id === rowId ? {...row, reason: feedback} : row
      )
    );
  };

  return (
    <>
      {/* Modal View */}
      {isModalOpen && (
        <div className='z-50'>
          <ViewProduct handleCloseModal={handleCloseModal} />
        </div>
      )}

      <div className=''>
        <table className=' mt-10 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-6 rounded-tl-md'>Select</th>
              <th className='w-10'>Image</th>
              <th className='w-10'>Product ID</th>
              <th className='w-10'>Vendor Email</th>
              <th className='w-10'>Name</th>
              <th className='w-10'>stock</th>
              <th className='w-10'>Price</th>
              <th className='w-10'>Status</th>
              <th className='w-10'>Approved By</th>
              <th className='w-10'>View More</th>
              <th className='w-10 rounded-tr-md'>Feedback</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8FAFC]'>
            {products.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={11}>
                  <span className='text-base text-primary font-semibold w-full'>
                    No Product available
                  </span>
                </td>
              </tr>
            )}
            <>
              {products.map((product: any, index) => (
                <TableRow
                  key={product.id}
                  data={product}
                  selectedRows={selectedRows}
                  onRowSelect={handleRowSelect}
                  handleOpenModal={isModalOpen}
                  // checkboxEnabled={checkboxEnabled}
                  selectedStatus={selectedStatus}
                  onSaveFeedback={handleSaveFeedback}
                  onClick={() => {
                    viewHandler(product.id);
                    dispatch(setSingleProduct(product));
                    setIsModalOpen(true);
                    // setCheckboxEnabled(true);
                    openModal(product.id);
                    selectedRows.some(
                      (selectedRow) => selectedRow.id === product.id
                    );
                  }}
                  indexId={product.id}
                  enabledCheckboxRow={enabledCheckboxRow}
                  setIsFeedBack={setIsFeedBack}
                />
              ))}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminProductTable;
