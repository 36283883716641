import {Navigate, Outlet} from 'react-router-dom';
import {useAdmin} from '../app/hooks';

const AdminProtectedRoutes = () => {
  const token = useAdmin().adminToken;
  return token ? <Outlet /> : <Navigate to='/admin' replace />;
};
// const AdminProtectedRoutes = () => {
//   return <Outlet />;
// };

export default AdminProtectedRoutes;
