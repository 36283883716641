import React, {useState} from 'react';
import styles from '../../assets/styles/modals/ConfirmModal.module.css';
import {cardImg} from '../../assets/images';
import {initialTabs as tabs} from './slice.interface';
import {motion, AnimatePresence} from 'framer-motion';
import '../../assets/styles/withdraw/styles.css';
import {Icon} from '@iconify/react';

interface IWithdraw {
  setOpenWithdrawal: React.Dispatch<React.SetStateAction<boolean>>;
}
const Withdraw = ({setOpenWithdrawal}: IWithdraw) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  return (
    <>
      <div className={styles.confirmDeleteBG} />
      <div className={styles.productCentered}>
        <div className={styles.withdrawModal}>
          {/* Body */}
          <div className='sm:p-10 p-6 bg-primary rounded-tl-lg rounded-tr-lg h-[190px] w-full flex flex-row justify-between items-start'>
            <div className='flex flex-col gap-10'>
              <div className='flex-row flex gap-2'>
                <p
                  onClick={() => setOpenWithdrawal(false)}
                  className='text-blue-300 cursor-pointer'>
                  Dashboard
                </p>
                <p className='text-white'>/ </p>
                <p className='text-white'>Run Ads</p>
              </div>
              <p className='text-white font-poppinsMedium'>
                Run your business Ads
              </p>
            </div>
            {/* Image */}
            <div className='flex flex-col justify-end items-end'>
              <Icon
                icon='material-symbols:cancel-rounded'
                className='text-white text-3xl cursor-pointer'
                onClick={() => setOpenWithdrawal(false)}
              />

              <div className='sm:pr-10'>
                <div className='sm:w-[120px] sm:h-[120px] h-24 w-24'>
                  <img
                    src={cardImg}
                    alt='imgg'
                    className='w-full h-full object-contain'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* -----------Body------ ------*/}
          <div className='lg:w-[70%] w-full mx-auto p-2 mt-8'>
            <nav>
              <ul className='gap-10'>
                {tabs.map((item) => (
                  <li
                    key={item.label}
                    className={
                      item === selectedTab
                        ? 'text-primary text-lg font-poppinsRegular border-b-2 border-b-primary'
                        : 'text-gray-400 font-poppinsRegular text-lg'
                    }
                    onClick={() => setSelectedTab(item)}>
                    {`${item.label}`}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className='w-[80%] mx-auto'>
            <AnimatePresence mode='wait'>
              <motion.div
                key={selectedTab ? selectedTab.label : 'empty'}
                initial={{y: 10, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                exit={{y: -10, opacity: 0}}
                transition={{duration: 0.2}}>
                <div className='w-full py-10 text-black'>
                  {selectedTab ? selectedTab.route : ''}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
