import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {supportInfo} from '../../../types';

type ISupportInfo = {
  roles: {
    vendorManagement: supportInfo;
    buyerManagement: supportInfo;
  };
};
const initialState: ISupportInfo = {
  roles: {
    vendorManagement: localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo') || '{}')
      : null,
    buyerManagement: localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo') || '{}')
      : null,
  },
};

const supportReducer = createSlice({
  name: 'support',
  initialState,
  reducers: {
    setSupportCredentials: (state, action) => {
      state.roles.vendorManagement = action.payload;
      localStorage.setItem('supportInfo', JSON.stringify(action.payload));
    },
    setBuyerSupportCredentials: (state, action) => {
      state.roles.buyerManagement = action.payload;
      localStorage.setItem('supportBuyerInfo', JSON.stringify(action.payload));
    },
  },
});

export const {setSupportCredentials, setBuyerSupportCredentials} =
  supportReducer.actions;
export const selectSupport = (state: RootState) =>
  state.support.roles.vendorManagement?.details;
export const selectSupportToken = (state: RootState) =>
  state.support.roles.vendorManagement?.token;
export const selectBuyerSupport = (state: RootState) =>
  state.support.roles.buyerManagement?.details;
export const selectBuyerSupportToken = (state: RootState) =>
  state.support.roles.buyerManagement?.token;

export default supportReducer.reducer;
