import {api} from '../api';

const header = (token: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

// Get Profile
const getProfile = (token: string) => {
  return api.get('vendor/details', header(token));
};

// Update Profile
const updateProfile = (token: string, data: any) => {
  return api.patch('vendor/profile', data, header(token));
};
// Update Profile
const updateProduct = (token: string, data: any, productId: any) => {
  return api.patch(`/product/${productId}`, data, header(token));
};
// Upload Product
const uploadProduct = (token: string, data: any) => {
  return api.post('product/create', data, header(token));
};
// Profile Image
const imageUpload = (token: string, data: any) => {
  return api.post('upload/image/single?type=profile', data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};
// Product Image
const productImageUpload = (token: string, data: any, businessName: string) => {
  return api.post(`upload/image/single?businessName=${businessName}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};
// Multi Product Image
const multiProductImageUpload = (
  token: string,
  data: any,
  businessName: string
) => {
  return api.post(`upload/image/multiple?businessName=${businessName}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteProduct = (token: string, productId: any) => {
  return api.delete(`/product/${productId}`, header(token));
};
const getCategory = () => {
  return api.get(`/category/all`);
};
const getSuperCategory = () => {
  return api.get(`/category/super/all`);
};
const getBusinessType = () => {
  return api.get(`/business/all`);
};
const getLocation = () => {
  return api.get(`/location`);
};
const getSubCategory = () => {
  return api.get(`/category/sub`);
};
const getVendorOrders = (token: string, parameter: string) => {
  return api.get(`/order/products?parameter=${parameter}`, header(token));
};
const getVendorOrdersWithDateRange = (
  token: string,
  parameter: string,
  startDate: string,
  endDate: string
) => {
  return api.get(
    `/order/products?startDate=${startDate}&endDate=${endDate}&parameter=${parameter}`,
    header(token)
  );
};
const addSuperCategory = (token: string, data: any) => {
  return api.post(`/category/super/add`, data, header(token));
};
const updateSuperCategory = (
  token: string,
  data: any,
  superCategoryId: any
) => {
  return api.patch(`/category/super/${superCategoryId}`, data, header(token));
};
const addCategory = (token: string, data: any) => {
  return api.post(`/category/add`, data, header(token));
};
const updateCategory = (token: string, data: any, categoryId: any) => {
  return api.patch(`/category/${categoryId}`, data, header(token));
};
const addSubCategory = (token: string, data: any) => {
  return api.post(`/category/sub/add`, data, header(token));
};
const updateSubCategory = (token: string, data: any, subCategoryId: any) => {
  return api.patch(`/category/sub/${subCategoryId}`, data, header(token));
};
const getAllMeasurement = (token: string, typeOfMeasurement: any) => {
  return api.get(`/measurement?type=${typeOfMeasurement}`, header(token));
};

export const VendorServices = {
  updateProfile,
  getProfile,
  imageUpload,
  productImageUpload,
  uploadProduct,
  deleteProduct,
  updateProduct,
  multiProductImageUpload,
  getCategory,
  getSubCategory,
  getLocation,
  getVendorOrders,
  addCategory,
  updateCategory,
  addSubCategory,
  updateSubCategory,
  getBusinessType,
  getSuperCategory,
  addSuperCategory,
  updateSuperCategory,
  getAllMeasurement,
  getVendorOrdersWithDateRange,
};
