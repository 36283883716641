import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {IoMdArrowDropdown} from 'react-icons/io';
import {format} from 'date-fns';

interface CustomDatePickerProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
}

const CustomDatePicker = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}: CustomDatePickerProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    onStartDateChange(start);
    onEndDateChange(end);
    setOpen(false);
  };

  return (
    <div className='relative'>
      <div
        className='flex flex-row items-center gap-4 bg-white px-3 py-2 rounded-md cursor-pointer relative'
        onClick={handleClick}>
        <div className='flex flex-row items-center gap-8'>
          <p className='text-black font-poppinsMedium'>
            {startDate && endDate
              ? `${format(startDate, 'MMM.')} - ${format(endDate, 'MMM. yyyy')}`
              : 'Select Date Range'}
          </p>
          <IoMdArrowDropdown size={20} color='black' />
        </div>
      </div>
      {open && (
        <div className='absolute z-50 -left-10'>
          <DatePicker
            selected={startDate}
            onChange={handleDateChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
          />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
