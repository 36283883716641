import React, {useContext, useEffect, useRef, useState} from 'react';
import {ChatContext} from '../../context/chat';
import moment from 'moment';
import InputEmoji from 'react-input-emoji';
import Actions from '../../actions';
import {useVendor} from '../../../app/hooks';
import {toast} from 'react-toastify';
import {Send2} from 'iconsax-react';

const ChatBox = ({
  loading,
  scroll,
  potentialChats,
  handleAddChat,
  chatUsers,
}) => {
  const {currentChat, chatMessages, updateNewMessage, updateChatMessages} =
    useContext(ChatContext);
  const user = useVendor().vendor;
  const [chatText, setChatText] = useState('');

  async function sendMessage() {
    try {
      const response = await Actions.sendChatMessage({
        chatId: currentChat?._id,
        message: chatText,
      });
      if (response?.badToken) localStorage.removeItem('vendorInfo');
      if (response?.status === 'success') {
        updateNewMessage(response?.data?.details);
        updateChatMessages((prev) => [...prev, response?.data?.details]);
        setChatText('');
      } else if (
        (response?.status === 'failed' &&
          response?.message === 'Invalid token or token expired') ||
        response?.message === 'Authorization denied! Token expired'
      ) {
        localStorage.clear();
        window.location.reload();
      } else if (
        response?.status === 'failed' &&
        response?.message === 'ERR no such key'
      ) {
        console.log(response?.message);
      } else {
        toast.error(response?.message || 'Connection Error');
      }
    } catch (error) {}
  }
  // Context
  const {updateCurrentChat} = useContext(ChatContext);

  // Get current user automatically
  const divRef = useRef(null);

  useEffect(() => {
    // Check if the ref has been assigned a DOM node
    if (divRef.current) {
      // Simulate a click event on the div
      divRef.current.click();
    }
  }, []);

  if (loading)
    <div style={{width: '100%', textAlign: 'center'}}>
      Loading chat messages...
    </div>;

  const supportId = JSON.parse(localStorage.getItem('supportId') || '{}');
  const loggedInUserId = user?.id;
  // console.log('loggedUser', loggedInUserId);

  // Check if a chat with the support manager exists
  const chatWithSupportManager = chatUsers.some(
    (chat) =>
      chat.members.includes(loggedInUserId) && chat.members.includes(supportId)
  );
  console.log('Support Id', supportId);

  return (
    <>
      {!chatWithSupportManager ? (
        <div className='h-full w-full'>
          <div className='h-full flex flex-col justify-center items-center'>
            <p> Let's get started...</p>
            {potentialChats.map((user) => (
              <div key={user?.email} className='pt-6'>
                <button
                  onClick={() => {
                    handleAddChat(user.id);
                  }}
                  className='bg-primary text-white px-3 py-2 rounded-md shadow-md'>
                  Let's begin
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className='gap-4 w-full h-full rounded-lg p-2 relative'>
          {chatUsers?.map((chat, i) => {
            return (
              <div
                ref={divRef}
                className='pe-3 opacity-0'
                key={i}
                onClick={() => {
                  updateCurrentChat(chat);
                }}></div>
            );
          })}
          <div className='overflow-y-scroll border-gray-300 rounded h-[90%]'>
            {chatMessages?.map((item, i) => {
              return (
                <div
                  ref={scroll}
                  key={i}
                  className={` p-2
                ${item?.senderId === user?.id ? ' text-right ' : 'text-left '}
              `}>
                  <div
                    className={`text-white inline-block p-2 rounded-lg  ${
                      item?.senderId === user?.id
                        ? 'bg-primary p-2 rounded-br-none rounded-l-md rounded-tr-md'
                        : 'bg-gray-900 p-2 rounded-bl-none rounded-r-md rounded-tl-md'
                    }`}>
                    <p>{item?.message}</p>
                    <p className='text-gray-400 text-[9px] font-poppinsRegular'>
                      {moment(item?.createdAt).fromNow('')}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='chat-input2 flex-grow-0 flex flex-row items-center gap-3 absolute left-0 right-0 bottom-0 h-[10%]  rounded-sm '>
            <InputEmoji
              value={chatText}
              onChange={setChatText}
              fontFamily='nunito'
              borderColor='rgba(72, 112, 223, 0.2)'
              onEnter={sendMessage}
              cleanOnEnter
              // shouldReturn={true}
              theme='white'
              placeholder='Type a message'
              placeholderColor='#C4C4C4'
            />
            <button
              className='p-2 flex flex-row justify-center items-center text-white bg-blue-400 rounded-full'
              onClick={sendMessage}>
              <Send2 size={16} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBox;
