/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {axiosError, numberFormat} from '../Helpers';
import {useAdmin, useAppDispatch} from '../../app/hooks';
import {AdminServices} from '../../redux/services/admin/admin';
import TableDataSkeleton from '../ui/skeletonUI/TableDataSkeleton';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import {setSingleAdminOrder} from '../../redux/slices/admin/adminOrderSlice';

const PendingOrders = () => {
  const [orders, setOrders] = useState<any[]>([]);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const token = useAdmin().adminToken;

  useEffect(() => {
    getOrders();
  }, []);

  const getOrders = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getOrders(token);
      // console.log('profile', response.data.data);
      if (response.data.status === 'success') {
        // toast.success(response.data.data.message);
        const res = response.data.data.details;
        // console.log(response.data.data.details);
        const pendingProducts = res?.rows.filter(
          (items: any) => items.status === 'pending'
        );
        setOrders(pendingProducts.slice(0, 12));
        // localStorage.setItem('#prds', JSON.stringify(res?.products));
        setIsLoading(false);
      }
    } catch (error: any) {
      // console.log(error.response);
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className=' sm:block hidden'>
        {loading ? (
          <div className='w-full'>
            <TableDataSkeleton />
          </div>
        ) : (
          <table className=' mt-4 w-full overflow-scroll text-xs'>
            <thead>
              <tr className='h-[2rem] text-[#6C757D]'>
                <th className='w-10 rounded-tl-md'>Order Code</th>
                <th className='w-16'>Order Date</th>
                <th className='w-10'>Total Amount</th>
                <th className='w-10'>Details</th>
                <th className='w-10'>Status</th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {orders?.length === 0 && (
                <tr>
                  <td className='text-center py-6' colSpan={5}>
                    <span className='text-base text-primary font-semibold'>
                      No Data available
                    </span>
                  </td>
                </tr>
              )}
              <>
                {orders?.map((order) => {
                  return (
                    <tr
                      className='overflow-x-auto h-[2rem] font-medium text-[#6C757D]'
                      key={order.id}>
                      <td className='w-10 text-center'>#{order.code}</td>
                      <td className='w-10 text-center'>
                        {moment(order.createdAt).format('ll')}
                      </td>
                      <td className='w-16 text-center'>
                        ₦{numberFormat(order.totalAmount)}
                      </td>
                      <td
                        className='w-10 text-center hover:underline cursor-pointer hover:text-primary'
                        onClick={() => {
                          navigate(`../admin/orders/${order.id}`);
                          dispatch(setSingleAdminOrder(order));
                        }}>
                        View Details
                      </td>

                      {order.status === 'pending' && (
                        <td className={'  text-[#FFAD33] text-center w-10'}>
                          {order.status}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        )}
      </div>

      {/* Table Mobile */}
      <div className=' block sm:hidden'>
        {loading ? (
          <div className='w-full'>
            <TableDataSkeleton />
          </div>
        ) : (
          <table className=' mt-4 w-full overflow-scroll text-xs'>
            <thead>
              <tr className='h-[2rem] text-[#6C757D]'>
                <th className='w-10 rounded-tl-md'>Order Code</th>
                <th className='w-16'>Order Date</th>
                <th className='w-10'>Total Amount</th>
                <th className='w-10'>Details</th>
                <th className='w-10'>Status</th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {orders?.length === 0 && (
                <tr>
                  <td className='text-center py-6' colSpan={5}>
                    <span className='text-base text-primary font-semibold'>
                      No Data available
                    </span>
                  </td>
                </tr>
              )}
              <>
                {orders?.map((order: any) => {
                  return (
                    <tr
                      className='overflow-x-auto h-[2rem] font-medium text-[#6C757D]'
                      key={order.id}>
                      <td className='w-10 text-center'>#{order.code}</td>
                      <td className='w-10 text-center'>
                        {moment(order.createdAt).format('ll')}
                      </td>
                      <td className='w-16 text-center'>
                        ₦{numberFormat(order.totalAmount)}
                      </td>
                      <td
                        className='w-10 text-center hover:underline cursor-pointer hover:text-primary'
                        onClick={() => navigate(`../admin/orders/${order.id}`)}>
                        View Details
                      </td>

                      {order.status === 'pending' && (
                        <td className={'  text-[#FFAD33] text-center w-10'}>
                          {order.status}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default PendingOrders;
