/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';
import GoBack from '../Helpers/GoBack';
import {useVendor} from '../../app/hooks';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react';
import {BiSearch} from 'react-icons/bi';
import {COLORS} from '../../assets/color';
import NotificationIcon from '../layouts/header/notification/NotificationIcon';
import ProfileImage from '../layouts/header/profile/ProfileImage';
import {Helmet} from 'react-helmet';

const InboxChat = () => {
  const profile = useVendor().vendor;

  const navigate = useNavigate();
  // Query
  const [query, setQuery] = useState('');
  return (
    <AppContainer>
      <div className='xl:w-[90%] md:w-[95%] w-full pt-4'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Message</title>
        </Helmet>
        <div className='sm:block hidden'>
          <ProductBar>
            <Title>
              <h2>Message Details</h2>
            </Title>
            <div className='sm:flex flex-row items-center gap-4 justify-end'>
              {/* Search */}
              <div className=' flex flex-row items-center w-full justify-between'>
                {/* Search Input  */}
                <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                  <div className='input w-full py-[4px] '>
                    <input
                      type='search'
                      placeholder='Search'
                      className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                      onChange={(e: any) => setQuery(e.target.value)}
                    />
                  </div>
                  <div className='icon text-gray-200'>
                    <BiSearch size={22} color={COLORS.primary} />
                  </div>
                </div>
              </div>
              {/* Notification */}
              <div>
                <NotificationIcon onClick={() => ''} />
              </div>
              {/* Profile Image */}
              <div>
                <ProfileImage
                  onClick={() => navigate('../vendor/profile')}
                  profileImage={profile?.vendorImg}
                />
              </div>
            </div>
          </ProductBar>
        </div>
        <div className='px-2 sm:py-10'>
          {/* Title */}
          <div className='flex flex-row justify-between items-center pb-2'>
            {/* Name */}
            <div className='flex flex-row items-center gap-2'>
              {/* Back Arrow */}
              <GoBack />
              <p className='text-black  text-lg font-poppinsSemibold'>
                {'Inbox Details'}
              </p>
            </div>
          </div>
          <hr className='text-black border border-gray-300' />
        </div>
        {/* Body Start here */}
        <div className='py-2 px-2'>
          <p className='py-2 text-lg font-poppinsMedium'>Important Update!!!</p>
          <p className='py-1 text-xs font-poppinsRegular'>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className='py-2 text-xs font-poppinsRegular'>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p className='py-1 text-xs font-poppinsRegular'>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
      </div>
    </AppContainer>
  );
};

export default InboxChat;

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
