/* eslint-disable @typescript-eslint/no-unused-vars */
import {useState} from 'react';
import ChatUsCard from './ChatUsCard';
import {BsEmojiLaughing} from 'react-icons/bs';
import {RiAttachment2} from 'react-icons/ri';
import {Zoom, toast} from 'react-toastify';
import styled from 'styled-components';
import {BiSearch} from 'react-icons/bi';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {useNavigate} from 'react-router-dom';
import {COLORS} from '../../../assets/color';
import {useVendor} from '../../../app/hooks';
import {Helmet} from 'react-helmet';
import InboxCard from '../../../components/dashboard/InboxCard';
import {inboxData} from '../../../components/dashboard/inbox';

const Chats = () => {
  const [state, setState] = useState({
    chat: '',
    email: '',
  });

  const profile = useVendor().vendor;

  const navigate = useNavigate();
  // Query
  const [query, setQuery] = useState('');
  // const keys = ['orderId', 'createdAt'];
  // const search = (data: any) => {
  //   return data?.filter((item: any) =>
  //     keys.some((key) =>
  //       item[key].toLowerCase().includes(query.toLocaleLowerCase())
  //     )
  //   );
  // };
  return (
    <AppContainer>
      <div className='xl:w-[90%] md:w-[95%] w-full pt-4'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Message</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>Messages</h2>
          </Title>
          <div className='sm:flex flex-row items-center gap-4 justify-end hidden'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage
                onClick={() => navigate('../vendor/profile')}
                profileImage={profile?.vendorImg}
              />
            </div>
          </div>
        </ProductBar>
        {/* Body Start here */}
        {/* Body Start here */}
        <div className='py-4 sm:pr-20'>
          <InboxCard
            data={inboxData}
            onClick={() => navigate('../vendor/chats/chatDetails')}
          />
        </div>
      </div>
    </AppContainer>
  );
};

export default Chats;

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;
const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 6px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
