import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Zoom, toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {ConfirmProps} from '../../../../../types';
import {AuthService} from '../../../../../redux/services/vendor/auth';
import {setVendorCredentials} from '../../../../../redux/slices/vendorSlice';
import {axiosError, scrollToTop} from '../../../../../components/Helpers';
import {Loader} from '../../../../../assets/svg';
import InputFieldAuth from '../../../../../components/forms/inputs/inputField/InputFieldAuth';

const FormConfirmation = ({
  firstName,
  lastName,
  email,
  // username,
  businessName,
  businessType,
  vendorType,
  phone,
  regNumber,
  r,
  password,
}: ConfirmProps) => {
  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      vendorType,
      phone,
      businessName,
      businessType,
      firstName,
      lastName,
      email,
      password,
    };

    try {
      const response = await AuthService.register(data);
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data;
        dispatch(setVendorCredentials(res.data));
        console.log(res.data);
        navigate('../verification');
        // toast.success(res.data.message || 'Registration Successfully');
        toast.success(
          `Registration Successfully, Please Check your email for OTP`,
          {
            position: toast.POSITION.TOP_CENTER,
            transition: Zoom,
            closeOnClick: false,
            hideProgressBar: true,
            draggable: false,
            style: {width: '400px'},
          }
        );
        scrollToTop();
        setIsLoading(false);
      }
    } catch (error: any) {
      console.log(error.response);
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  return (
    <div className='flex flex-col gap-2 h-full'>
      <InputFieldAuth
        label='First Name'
        name='fistName'
        type='text'
        value={firstName}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      <InputFieldAuth
        label='Last Name'
        name='lastName'
        type='text'
        value={lastName}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      <InputFieldAuth
        label='Email'
        name='email'
        type='email'
        value={email}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        email
      />
      <InputFieldAuth
        label='Business Name'
        name='businessName'
        type='text'
        value={businessName}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      <InputFieldAuth
        label='Business Type'
        name='businessType'
        type='text'
        value={businessType}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      <InputFieldAuth
        label='Vendor Type'
        name='vendorType'
        type='text'
        value={vendorType}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      <InputFieldAuth
        label='Phone Number'
        name='phone'
        type='text'
        value={phone}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      <InputFieldAuth
        label='Password '
        name='password'
        type='password'
        value={password}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        password
      />
      <InputFieldAuth
        label='Confirm Password'
        name='password'
        type='password'
        value={password}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        password
      />
      {/*Button */}
      <div className='flex flex-row items-center justify-between gap-4 py-4'>
        <button
          className='bg-primary text-white sm:px-20 w-full py-3 transition-hover rounded-md'
          onClick={() => r.prevStep()}>
          Prev
        </button>
        {isLoading ? (
          <button className='bg-primary text-white sm:px-20 w-full py-3 transition-hover rounded-md'>
            <Loader color={'#fff'} />
          </button>
        ) : (
          <button
            className='bg-primary text-white sm:px-20 w-full py-3 transition-hover rounded-md disabled:opacity-60'
            onClick={handleSubmit}
            disabled={
              !firstName ||
              !lastName ||
              !email ||
              !businessName ||
              !businessType ||
              !vendorType ||
              !phone
                ? true
                : false
            }>
            Sign Up
          </button>
        )}
      </div>
    </div>
  );
};

export default FormConfirmation;
