import React, {ChangeEvent, useState} from 'react';
import styled from 'styled-components';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {COLORS} from '../../../assets/color';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import CouponFormCard from '../../../components/layouts/CouponFormCard';
import {useNavigate} from 'react-router-dom';
import {AdminServices} from '../../../redux/services/admin/admin';
import {useAdmin} from '../../../app/hooks';
import {toast} from 'react-toastify';
import {axiosError} from '../../../components/Helpers';
import {Loader} from '../../../assets/svg';

const CreateCoupon = () => {
  const [isLoading, setIsLoading] = useState(false);
  const token = useAdmin().adminToken;

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    couponCode: '',
    couponName: '',
    discountPercentage: '',
    discountAmount: '',
    startDate: '',
    endDate: '',
    usage: '',
    minimumPurchased: '',
    productId: '',
  });
  const [discountType, setDiscountType] = useState<string>('percentage');

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDiscountType(event.target.value);
    setFormData((p) => ({
      ...p,
      value: '',
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setFormData((p) => ({
      ...p,
      [name]: value,
    }));
  };

  const postCoupon = async () => {
    setIsLoading(true);
    if (discountType === 'percentage') {
      const data = {
        productId: formData.productId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        minimumAmount: Number(formData.minimumPurchased),
        discountPercentage: Number(formData.discountPercentage),
        usageLimit: Number(formData.usage),
        couponCode: formData.couponCode,
        // couponName: formData.couponName,
      };
      try {
        const response = await AdminServices.createCoupon(token, data);
        setIsLoading(false);
        // console.log('Reseponse', response);
        if (response.data.status === 'success') {
          const res = response.data?.data;
          toast.success(res.message);
          // console.log(res.rows);
          setIsLoading(false);
          navigate(-1);
          // console.log('profile', profile);
        }
      } catch (error: any) {
        axiosError(error.response);
        setIsLoading(false);
      }
    } else {
      const data = {
        productId: formData.productId,
        startDate: formData.startDate,
        endDate: formData.endDate,
        minimumAmount: Number(formData.minimumPurchased),
        discountAmount: formData.discountAmount,
        usageLimit: Number(formData.usage),
        couponCode: formData.couponCode,
        // couponName: formData.couponName,
      };
      try {
        const response = await AdminServices.createCoupon(token, data);
        setIsLoading(false);
        // console.log('Reseponse', response);
        if (response.data.status === 'success') {
          const res = response.data?.data;
          toast.success(res.message);
          // console.log(res.rows);
          setIsLoading(false);
          navigate(-1);
          // console.log('profile', profile);
        }
      } catch (error: any) {
        axiosError(error.response);
        setIsLoading(false);
      }
    }
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>coupon</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>COUPON GENERATION</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/*Breadcrumb */}
        <div className='flex gap-1 text-gray-400 items-center text-lg pt-6 pb-4'>
          <p
            onClick={() => navigate(-1)}
            className='hover:underline font-poppinsMedium cursor-pointer'>
            Coupon
          </p>
          <p className='font-poppinsMedium'>/</p>
          <p className='text-black font-poppinsMedium'>New Coupon Creation</p>
        </div>
        <div className='w-full flex lg:flex-row flex-col gap-4 lg:flex-wrap'>
          {/* Coupon Code */}
          <CouponFormCard
            title='Coupon Code'
            content={
              <div>
                <div className='flex flex-row items-end justify-between pb-2'>
                  <p className='text-black font-poppinsRegular'>
                    Customers will enter this discount code at checkout
                  </p>
                </div>
                <div className='w-full rounded-md h-[2.5rem] border border-gray-200 px-3 '>
                  <input
                    type={'text'}
                    placeholder={'Enter discount code'}
                    className='outline-none w-full h-full focus:outline-none text-black placeholder:text-gray-400 placeholder:font-light placeholder:text-xs'
                    onChange={handleChange}
                    value={formData.couponCode}
                    name='couponCode'
                  />
                </div>
              </div>
            }
          />
          {/* Discount Type */}
          <CouponFormCard
            title='Value'
            isRequired={true}
            content={
              <div>
                <div className='flex flex-row items-end justify-between pb-2'>
                  <p className='text-black font-poppinsRegular'>
                    Discount Value
                  </p>
                </div>
                <div className='flex'>
                  <select
                    value={discountType}
                    onChange={handleSelectChange}
                    className='form-select block w-1/3 py-2 px-3 border border-gray-200 bg-white rounded-l-md shadow-sm focus:outline-none sm:text-sm'>
                    <option value='percentage'>Percentage (%)</option>
                    <option value='amount'>Amount (₦)</option>
                  </select>
                  {discountType === 'percentage' ? (
                    <input
                      type='text'
                      name='discountPercentage'
                      value={formData.discountPercentage}
                      onChange={handleChange}
                      placeholder={`Enter value Percentage`}
                      className='form-input block w-2/3 py-2 px-3 border border-gray-200 bg-white rounded-r-md shadow-sm focus:outline-none sm:text-sm'
                    />
                  ) : (
                    <input
                      type='text'
                      name='discountAmount'
                      value={formData.discountAmount}
                      onChange={handleChange}
                      placeholder={`Enter value amount`}
                      className='form-input block w-2/3 py-2 px-3 border border-gray-200 bg-white rounded-r-md shadow-sm focus:outline-none sm:text-sm'
                    />
                  )}
                </div>
              </div>
            }
          />
          {/* Coupon Code */}
          <CouponFormCard
            title='Duration'
            isRequired={true}
            content={
              <div className='flex gap-4'>
                {/* Start Date */}
                <div className='w-full'>
                  <div className='flex flex-row items-end justify-between pb-2'>
                    <p className='text-black font-poppinsRegular'>Start Date</p>
                  </div>
                  <div className='w-full rounded-md h-[2.5rem] border border-gray-200 px-3 '>
                    <input
                      type={'date'}
                      placeholder={'05/10/2025'}
                      className='outline-none w-full h-full focus:outline-none text-black placeholder:text-gray-400 placeholder:font-light placeholder:text-xs'
                      onChange={handleChange}
                      value={formData.startDate}
                      name='startDate'
                    />
                  </div>
                  {/* End Date  */}
                </div>
                <div className='w-full'>
                  <div className='flex flex-row items-end justify-between pb-2'>
                    <p className='text-black font-poppinsRegular'>End Date</p>
                  </div>
                  <div className='w-full rounded-md h-[2.5rem] border border-gray-200 px-3 '>
                    <input
                      type={'date'}
                      placeholder={'05/10/2025'}
                      className='outline-none w-full h-full focus:outline-none text-black placeholder:text-gray-400 placeholder:font-light placeholder:text-xs'
                      onChange={handleChange}
                      value={formData.endDate}
                      name='endDate'
                    />
                  </div>
                </div>
              </div>
            }
          />
          {/* Coupon Code */}
          <CouponFormCard
            title='Usage Limit'
            isRequired={true}
            content={
              <div>
                <div className='flex flex-row items-end justify-between pb-2'>
                  <p className='text-black font-poppinsRegular'>
                    Number of times the coupon code can be used
                  </p>
                </div>
                <div className='w-full rounded-md h-[2.5rem] border border-gray-200 px-3 '>
                  <input
                    type={'text'}
                    placeholder={'Enter usage limit'}
                    className='outline-none w-full h-full focus:outline-none text-black placeholder:text-gray-400 placeholder:font-light placeholder:text-xs'
                    onChange={handleChange}
                    value={formData.usage}
                    name='usage'
                  />
                </div>
              </div>
            }
          />
          {/* Coupon Code */}
          <CouponFormCard
            title='Minimum  Purchase  Amount'
            isRequired={true}
            content={
              <div>
                <div className='flex flex-row items-end justify-between pb-2'>
                  <p className='text-black font-poppinsRegular'>
                    Enter the minimum purchase amount required from customer
                  </p>
                </div>
                <div className='w-full rounded-md h-[2.5rem] border border-gray-200 px-3 '>
                  <input
                    type={'text'}
                    placeholder={'Enter minimum purchase'}
                    className='outline-none w-full h-full focus:outline-none text-black placeholder:text-gray-400 placeholder:font-light placeholder:text-xs'
                    onChange={handleChange}
                    value={formData.minimumPurchased}
                    name='minimumPurchased'
                  />
                </div>
              </div>
            }
          />
          {/* Coupon Code */}
          <CouponFormCard
            title='Eligible Product ID'
            isRequired={true}
            content={
              <div>
                <div className='flex flex-row items-end justify-between pb-2'>
                  <p className='text-black font-poppinsRegular'>
                    Enter product ID
                  </p>
                </div>
                <div className='w-full rounded-md h-[2.5rem] border border-gray-200 px-3 '>
                  <input
                    type={'text'}
                    placeholder={'Enter product ID'}
                    className='outline-none w-full h-full focus:outline-none text-black placeholder:text-gray-400 placeholder:font-light placeholder:text-xs'
                    onChange={handleChange}
                    value={formData.productId}
                    name='productId'
                  />
                </div>
              </div>
            }
          />
        </div>
        {/* Buttons */}
        <div className='flex flex-row gap-6 items-center py-6'>
          <button
            onClick={postCoupon}
            className='bg-primary text-white px-3 py-2 rounded-md font-poppinsMedium'>
            {isLoading ? <Loader color='white' /> : 'Submit'}
          </button>
        </div>
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default CreateCoupon;
