/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {useAppDispatch, useCoupon} from '../../app/hooks';
import Spinner from '../spinner/Spinner';
import {BiChevronDown} from 'react-icons/bi';
import {COLORS} from '../../assets/color';
import ConfirmDeactivateUser from '../modal/ConfirmDeactivateUser';
import ConfirmDeactivate from '../modal/ConfirmDeactivate';
import {setSingleACoupon} from '../../redux/slices/coupon/couponSlice';
import {ICoupon} from '../interfaces/coupons';
import moment from 'moment';

interface IVendorTable {
  coupons: ICoupon[];
  onDeactivate: (id: any) => void;
  onActivate: (str: any) => void;
  isActionLoading?: boolean;
}
const CouponTable = ({
  coupons,
  onActivate,
  onDeactivate,
  isActionLoading,
}: IVendorTable) => {
  // const [showView, setShowView] = useState(false);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [showActivate, setShowActivate] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  const dispatch = useAppDispatch();
  const singleCoupon = useCoupon().coupon;

  // onViewCoupon
  // const viewHandler = async (id: any) => {
  //   coupons.filter((t: any) => t.id === id);
  // };

  type IDropDownData = {
    id: number;
    name: string;
    onShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  };

  const dropDownData: IDropDownData[] = [
    {
      id: 1,
      name: 'Activate',
      onShowModal: setShowActivate,
    },
    {
      id: 2,
      name: 'Deactivate',
      onShowModal: setShowDeactivate,
    },
  ];

  // Handle Drop Down
  const options = [
    {value: 'Bad Products', label: 'Bad Products'},
    {value: 'Background Check Failed', label: 'Background Check Failed'},
    {value: 'Not Active for 6 Months', label: 'Not Active for 6 Months'},
  ];
  const [selectedReason, setSelectedReason] = useState('');
  const handleDropdownChange = (selectedValue: string) => {
    // console.log('Selected Value:', selectedValue);
    // Implement your logic here when the dropdown value changes
    setSelectedReason(selectedValue);
  };
  return (
    <>
      <div>
        {isActionLoading && <Spinner />}
        <table className=' mt-6 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10'>Coupon Code</th>
              <th className='w-10'>Discount</th>
              <th className='w-10'>Usage Limit</th>
              <th className='w-10'>Date Range</th>
              <th className='w-10'>Minimum Amount</th>
              <th className='w-10'>Product</th>
              <th className='w-10'>Status</th>
              <th className='w-10'>Generated By</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100'>
            {coupons.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={8}>
                  <span className='text-xl text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {coupons.map((coupon) => {
                return (
                  <tr className='overflow-x-auto h-[3.125rem]' key={coupon.id}>
                    <td className='w-10 text-center'>{coupon.code}</td>
                    <td className='w-10 text-center'>
                      {coupon.discountAmount || coupon.discountPercentage}
                    </td>
                    <td className='w-10 text-center'>{coupon.usageLimit}</td>
                    <td className='w-10 text-center'>
                      {moment(coupon.startDate).format('ll')} -{' '}
                      {moment(coupon.endDate).format('ll')}
                    </td>
                    <td className='w-10 text-center'>{coupon.minimumAmount}</td>
                    <td className='w-10 text-center'>{coupon.productName}</td>
                    {/* Status */}
                    <td className='w-16'>
                      {/* Status */}
                      <div className='flex flex-row justify-evenly items-center h-10'>
                        {coupon.isActive === true ? (
                          // Deactivate
                          <div className='relative'>
                            <div
                              onClick={() => {
                                setShowActivateModal(!showActivateModal);
                                setActiveId(coupon.id);
                              }}
                              className='px-2 py-1 bg-[#E6FCE5] text-success flex flex-row gap-1 items-center rounded-md justify-center cursor-pointer shadow-sm'>
                              <span>Activated</span>
                              <BiChevronDown
                                color={COLORS.success}
                                size={22}
                                style={{cursor: 'pointer'}}
                              />
                            </div>
                            {showActivateModal && activeId === coupon.id && (
                              <div
                                onClick={() => setShowActivateModal(false)}
                                className='bg-white w-full px-2 py-2 flex-col items-center absolute rounded-md shadow-md top-8 z-50'>
                                {dropDownData.map((item, index) => (
                                  <p
                                    key={index}
                                    onClick={() => {
                                      if (item.name === 'Deactivate') {
                                        setShowActivateModal(false);
                                        item.onShowModal(true);
                                        dispatch(setSingleACoupon(coupon));
                                      }
                                    }}
                                    className={
                                      item.name === 'Activate'
                                        ? 'text-success opacity-40 pb-2'
                                        : 'text-red-500 font-poppinsMedium cursor-pointer'
                                    }>
                                    {item.name}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        ) : (
                          // Activate
                          <div className='relative'>
                            <div
                              onClick={() => {
                                setShowDeactivateModal(!showDeactivateModal);
                                setActiveId(coupon.id);
                              }}
                              className='px-2 py-1 bg-[#FEEFEE] text-red-500 flex flex-row gap-1 items-center rounded-md justify-center cursor-pointer shadow-sm'>
                              <span>Deactivate</span>
                              <BiChevronDown
                                color={COLORS.error}
                                size={22}
                                style={{cursor: 'pointer'}}
                              />
                            </div>
                            {showDeactivateModal && activeId === coupon.id && (
                              <div
                                onClick={() => setShowDeactivateModal(false)}
                                className='bg-white w-full px-2 py-2 flex-col items-center absolute rounded-md shadow-md top-8 z-50'>
                                {dropDownData.map((item, index) => (
                                  <p
                                    key={index}
                                    onClick={() => {
                                      if (item.name === 'Activate') {
                                        setShowDeactivateModal(false);
                                        dispatch(setSingleACoupon(coupon));
                                        item.onShowModal(true);
                                      }
                                    }}
                                    className={
                                      item.name === 'Deactivate'
                                        ? 'text-red-500 opacity-40 pt-2'
                                        : 'text-success font-poppinsMedium cursor-pointer'
                                    }>
                                    {item.name}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {showDeactivate && (
                        <ConfirmDeactivate
                          setIsOpen={setShowDeactivate}
                          onAction={() => onDeactivate(singleCoupon.id)}
                          label={'Deactivate'}
                          bgColor={'red'}
                          activeLabel='deactivate'
                        />
                      )}
                      {showActivate && (
                        <ConfirmDeactivate
                          setIsOpen={setShowActivate}
                          onAction={() => onActivate(singleCoupon.id)}
                          bgColor={'green'}
                          label={'Activate'}
                          activeLabel='activate'
                        />
                      )}
                    </td>
                    <td className='w-10 text-center'>{coupon.generatedBy}</td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CouponTable;
