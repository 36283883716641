import React, {useEffect, useRef, useState} from 'react';
import {PersonalProps} from '../../../../../types';
import {regexValidation} from '../../../../../components/Helpers/regexValidation';
import {BsInfo} from 'react-icons/bs';
import InputFieldAuth from '../../../../../components/forms/inputs/inputField/InputFieldAuth';
const PersonalInfo = ({
  firstName,
  lastName,
  // username,
  email,
  r,
}: PersonalProps) => {
  const [isValid, setIsValid] = useState(false);
  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);

  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  // Validation
  useEffect(() => {
    const firstValid = regexValidation.validateName(firstName);
    const lastValid = regexValidation.validateName(lastName);
    setIsFirstNameValid(firstValid);
    setIsLastNameValid(lastValid);
    setIsValid(regexValidation.validateEmail(email));
  }, [email, firstName, lastName]);

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  // Check Validation
  useEffect(() => {
    lastNameRef.current?.focus();
    emailRef.current?.focus();
    firstNameRef.current?.focus();
  }, []);
  return (
    <div className='flex flex-col gap-2'>
      <InputFieldAuth
        label='First Name'
        name='firstName'
        type='text'
        placeholder='Enter First Name'
        onFocus={() => setFirstNameFocus(true)}
        onBlur={() => setFirstNameFocus(false)}
        Inputref={firstNameRef}
        value={firstName}
        required={true}
        ariaInvalid={isFirstNameValid ? 'false' : 'true'}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
      />
      <p
        id='uidnote'
        className={
          firstNameFocus && !isFirstNameValid
            ? 'instructions inline-flex'
            : 'offscreen inline-flex'
        }>
        <BsInfo size={24} />
        Please enter valid Name
      </p>

      {/* Last Name */}
      <InputFieldAuth
        label='Last Name'
        name='lastName'
        type='text'
        placeholder='Enter Last Name'
        value={lastName}
        required={true}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        onFocus={() => setLastNameFocus(true)}
        onBlur={() => setLastNameFocus(false)}
        ariaInvalid={isLastNameValid ? 'false' : 'true'}
        Inputref={lastNameRef}
      />
      <p
        id='uidnote'
        className={
          lastNameFocus && !isLastNameValid
            ? 'instructions inline-flex'
            : 'offscreen inline-flex'
        }>
        <BsInfo size={24} />
        Please enter valid Name
      </p>
      {/* Email */}
      <InputFieldAuth
        label='Email Address'
        name='email'
        type='email'
        placeholder='Enter Email Address'
        value={email}
        required={true}
        aria-invalid={isValid ? 'false' : 'true'}
        onChange={(e) => r.onChange(e)}
        style={{height: '100%'}}
        onFocus={() => setEmailFocus(true)}
        onBlur={() => setEmailFocus(false)}
        ariaInvalid={isValid ? 'false' : 'true'}
        Inputref={emailRef}
        email
      />
      <p
        id='uidnote'
        className={
          emailFocus && !isValid
            ? 'instructions inline-flex'
            : 'offscreen inline-flex'
        }>
        <BsInfo size={24} />
        Please enter valid email
      </p>
      {/*Button */}
      <div className='flex flex-row items-center justify-end py-4'>
        <button
          className='bg-primary text-white px-20 py-3 transition-hover rounded-md disabled:opacity-60'
          onClick={() => r.nextStep()}
          disabled={
            !isFirstNameValid || !isLastNameValid || !isValid ? true : false
          }>
          Next
        </button>
      </div>
    </div>
  );
};

export default PersonalInfo;
