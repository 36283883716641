import React, {ChangeEvent, useState} from 'react';
import styles from '../../../../assets/styles/modals/ConfirmModal.module.css';
import {DefaultImage, PencilSm} from '../../../../assets/images';
import Input from '../../../../components/dashboard/profile/input';
import {CloseCircle} from 'iconsax-react';
import {useAdminVendor} from '../../../../app/hooks';
import {IVendor} from '../../../../components/interfaces/vendors';

interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onApprove?: any;
  isLoading?: any;
}

const ViewVendor = ({setIsOpen}: IModal) => {
  const vendor = useAdminVendor().adminVendor;
  //   console.log(vendor);
  const [formData, setFormData] = useState<IVendor>({
    firstName: vendor?.firstName,
    lastName: vendor?.lastName,
    country: vendor?.country,
    vendorImg: vendor?.vendorImg,
    businessName: vendor?.businessName,
    businessType: vendor?.businessType,
    regNumber: vendor?.regNumber,
    email: vendor?.email,
    accountName: vendor?.accountName,
    accountNumber: vendor?.accountNumber,
    bankName: vendor?.bankName,
    state: vendor?.state,
    city: vendor?.city,
    address: vendor?.address,
    phone: vendor?.phone,
    isActive: vendor?.isActive,
    productCount: vendor?.productCount,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // const isActive = String(vendor.isActive);
  //   console.log(isUpdated);
  return (
    <div>
      <div className={styles.productViewBG} onClick={() => setIsOpen(true)} />
      <div className={styles.productCentered}>
        <div className={styles.productViewModal}>
          <div className={styles.modalContent}>
            {/* Content */}
            <div className='bg-white p-6 relative'>
              <div
                className='absolute top-0 right-0 cursor-pointer'
                onClick={() => setIsOpen(false)}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='flex flex-row items-center justify-center px-16 pb-4'>
                {/* Image */}
                <div>
                  <div className='w-[200px] h-[200px]'>
                    {vendor.vendorImg !== null ? (
                      <img
                        src={vendor.vendorImg}
                        alt='vendor-img'
                        className='rounded-md w-full h-full object-cover'
                      />
                    ) : (
                      <img
                        src={DefaultImage}
                        alt='profile-def'
                        className='rounded-md w-full h-full object-cover'
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className=''>
                <span className='font-bold  text-base text-[#2E3ECE] w-full '>
                  VENDOR DETAILS
                </span>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-start justify-items-center gap-x-[2rem] gap-y-3 w-full h-auto p-5'>
                {/* First */}
                <div className='flex flex-col items-center justify-center gap-y-9'>
                  <div className='space-y-16'>
                    <Input
                      label='BUSINESS NAME'
                      icon={PencilSm}
                      pattern=''
                      handleChange={(e: any) => handleInputChange(e)}
                      // name='businessName'
                      valueField={formData.businessName}
                    />
                    <Input
                      label='REGISTRATION NUMBER'
                      icon={PencilSm}
                      pattern=''
                      handleChange={(e: any) => handleInputChange(e)}
                      // name='regNumber'
                      valueField={formData.regNumber}
                    />
                    <Input
                      label='BUSINESS TYPE'
                      icon={PencilSm}
                      pattern=''
                      // name='businessType'
                      handleChange={handleInputChange}
                      valueField={formData.businessType}
                    />
                    <Input
                      label='ADDRESS'
                      icon={PencilSm}
                      pattern=''
                      // name='address'
                      handleChange={handleInputChange}
                      valueField={formData.address}
                    />

                    {/* Hidden on Laptop, show on Tablet  */}
                    <div className='xl:hidden block space-y-16'>
                      <Input
                        label='LAST NAME'
                        icon={PencilSm}
                        pattern=''
                        // name='lastName'
                        handleChange={handleInputChange}
                        valueField={formData.lastName}
                      />

                      <Input
                        label='PHONE NUMBER'
                        icon={PencilSm}
                        pattern=''
                        // name='phone'
                        handleChange={handleInputChange}
                        valueField={formData.phone}
                      />
                      <Input
                        label='CITY'
                        icon={PencilSm}
                        pattern=''
                        // name='city'
                        handleChange={handleInputChange}
                        valueField={formData.city}
                      />
                    </div>
                    {/* --------------- End Hidden --------------*/}
                    <div className='flex flex-col'>
                      <span className='text-gray-400 font-poppinsMedium font-medium pb-2'>
                        STATUS
                      </span>
                      <div className='w-[16.5rem] rounded-md h-[2.5rem] border-[2px] border-gray-400 px-3 flex flex-row justify-center items-center'>
                        {vendor.isActive === true ? (
                          <p className=''>Active</p>
                        ) : (
                          <p className=' '>InActive</p>
                        )}
                      </div>
                    </div>
                    <Input
                      label='Total Products'
                      icon={PencilSm}
                      pattern=''
                      handleChange={handleInputChange}
                      valueField={formData.productCount}
                    />
                  </div>
                </div>
                {/* Second */}
                <div className='space-y-16'>
                  <Input
                    label='FIRST NAME'
                    icon={PencilSm}
                    // name='firstName'
                    handleChange={handleInputChange}
                    valueField={formData.firstName}
                  />

                  <Input
                    label='EMAIL'
                    icon={PencilSm}
                    // name='email'
                    handleChange={handleInputChange}
                    valueField={formData.email}
                  />
                  <Input
                    label='COUNTRY'
                    icon={PencilSm}
                    // name='country'
                    handleChange={handleInputChange}
                    valueField={formData.country}
                  />
                  <Input
                    label='STATE'
                    icon={PencilSm}
                    // name='state'
                    handleChange={handleInputChange}
                    valueField={formData.state}
                  />
                  <Input
                    label='BANK NAME'
                    icon={PencilSm}
                    // name='bankName'
                    handleChange={handleInputChange}
                    valueField={formData.bankName}
                  />
                  {/* Hidden on Laptop, show on Tablet  */}
                  <div className='xl:hidden block space-y-16'>
                    <Input
                      label='ACCOUNT NO'
                      icon={PencilSm}
                      pattern=''
                      // name='accountNumber'
                      handleChange={handleInputChange}
                      valueField={formData.accountNumber}
                    />
                    <Input
                      label='ACCOUNT NAME'
                      icon={PencilSm}
                      pattern=''
                      // name='accountName'
                      handleChange={handleInputChange}
                      valueField={formData.accountName}
                    />
                  </div>
                  {/* --------------- End Hidden --------------*/}
                  {/* <div className='w-[18rem] rounded-lg h-[3.5rem] border border-[#2E3ECE] relative flex items-start justify-between p-2'>
                    <div className='top-2 left-2'>
                      <span className='text-[#2E3ECE]  font-normal'>
                        PROFILE IS-UPDATED
                      </span>
                      <p>{isUpdated}</p>
                    </div>
                    <div>
                      <img src={PencilSm} alt='' />
                    </div>
                  </div> */}
                  {/* <Button
                    handleSubmit={() => onApprove(product.id)}
                    type='button'
                    className='text-white font-bold bg-primary h-[50px]'>
                    {isLoading ? 'Loading...' : 'APPROVED'}
                  </Button> */}
                </div>

                {/* Third  hidden on Tablet*/}
                <div className='space-y-16 xl:block hidden'>
                  <Input
                    label='LAST NAME'
                    icon={PencilSm}
                    pattern=''
                    // name='lastName'
                    handleChange={handleInputChange}
                    valueField={formData.lastName}
                  />

                  <Input
                    label='PHONE NUMBER'
                    icon={PencilSm}
                    pattern=''
                    // name='phone'
                    handleChange={handleInputChange}
                    valueField={formData.phone}
                  />
                  <Input
                    label='CITY'
                    icon={PencilSm}
                    pattern=''
                    // name='city'
                    handleChange={handleInputChange}
                    valueField={formData.city}
                  />
                  <Input
                    label='ACCOUNT NO'
                    icon={PencilSm}
                    pattern=''
                    // name='accountNumber'
                    handleChange={handleInputChange}
                    valueField={formData.accountNumber}
                  />
                  <Input
                    label='ACCOUNT NAME'
                    icon={PencilSm}
                    pattern=''
                    // name='accountName'
                    handleChange={handleInputChange}
                    valueField={formData.accountName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewVendor;
