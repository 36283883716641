import React, {useContext} from 'react';
import useFetchRecipient from '../../hooks/useFetchRecipient';
import {ChatContext} from '../../context/chat';
import {unreadNotifications} from '../../utils';
import {useFetchLastMessage} from '../../hooks';
import moment from 'moment';
import {useSupport} from '../../../app/hooks';
import {UserIcon} from '../../assets/icons';

const ChatUser = ({chat, activeChat}) => {
  const support = useSupport().support;
  // console.log('user', support);
  const {onlineUsers, notifications, markSpecificUserNotificationAsRead} =
    useContext(ChatContext);
  // const recipientId = chat?.members?.find(id => id !== user?.id)
  const recipientId = chat?.members?.find((id) => id !== support?.id);
  // console.log('recipientId', recipientId);
  const recipient = useFetchRecipient(recipientId);
  const unreadNotification = unreadNotifications(notifications);
  const specificUserNotifications = unreadNotification?.filter(
    (notification) => notification?.senderId === recipientId
  );
  const latestMessage = useFetchLastMessage(chat);
  // console.log('chat', chat?._id);
  // console.log('lastMa', latestMessage);

  function adjustMessage(text) {
    let shortText = text?.substring(0, 20);

    if (text?.length > 20) {
      shortText = shortText + '...';
    }

    return shortText;
  }
  // console.log(chat, 'Chat');
  return (
    <div
      className={
        activeChat === chat._id
          ? 'relative bg-gray-600 justify-between p-2 flex flex-row items-center group'
          : 'user-card justify-between p-2 flex flex-row items-center group'
      }
      role='button'
      onClick={() => {
        if (specificUserNotifications?.length !== 0) {
          markSpecificUserNotificationAsRead(
            specificUserNotifications,
            notifications
          );
        }
      }}>
      <div className='flex flex-row gap-1 items-center w-full h-full'>
        <div className='me-2'>
          <div className='border-[0.3px] border-gray-600 rounded-full'>
            <div className='h-[36px] w-[36px]'>
              {recipient?.vendorImg === null ? (
                <UserIcon />
              ) : (
                <img
                  src={recipient?.vendorImg}
                  alt=''
                  className='rounded-full w-full h-full object-cover'
                />
              )}
            </div>
          </div>
        </div>
        {/* Message */}
        <div
          className={
            activeChat === chat._id
              ? 'flex flex-row justify-between items-start w-full pb-2 group-hover:border-none'
              : 'flex flex-row justify-between items-start w-full border-b pb-2 border-b-gray-800 group-hover:border-none'
          }>
          <div className='text-context'>
            <div className='name'>{recipient?.businessName}</div>
            <div className='text-gray-300 text-[12px] font-light'>
              {latestMessage?.message && adjustMessage(latestMessage?.message)}
            </div>
          </div>
          <div className='d-flex flex-column align-items-end'>
            <div className='text-gray-300 text-xs'>
              {moment(latestMessage?.createdAt).calendar()}
            </div>
            <div
              className={
                specificUserNotifications?.length > 0 &&
                'this-user-notifications'
              }>
              {specificUserNotifications?.length > 0 &&
                specificUserNotifications?.length}
            </div>
            <div
              className={
                onlineUsers?.some(
                  (onlineUser) => onlineUser?.userId === recipient?.id
                )
                  ? 'user-online'
                  : ''
              }></div>
            {/* <div className={'user-online'}></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatUser;
