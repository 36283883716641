import {ArrowLeft} from 'iconsax-react';
import {useNavigate} from 'react-router-dom';

const GoBack = () => {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(-1)} className='cursor-pointer'>
      <ArrowLeft size={24} />
    </div>
  );
};

export default GoBack;
