import React, {useContext, useState} from 'react';
import useFetchRecipient from '../../hooks/useFetchRecipient';
import {ChatContext} from '../../context/chat';
import moment from 'moment';
import {SendIcon} from '../../assets/icons';
import InputEmoji from 'react-input-emoji';
import Actions from '../../actions';
import {useSupport} from '../../../app/hooks';
import {toast} from 'react-toastify';
import {emptyChat} from '../../../assets/images';

const ChatBox = ({loading, scroll}) => {
  const {currentChat, chatMessages, updateNewMessage, updateChatMessages} =
    useContext(ChatContext);
  const user = useSupport().support;
  const [chatText, setChatText] = useState('');

  const recipientId = currentChat?.members?.find((id) => id !== user?.id);
  const recipient = useFetchRecipient(recipientId);

  async function sendMessage() {
    try {
      const response = await Actions.sendChatMessage({
        chatId: currentChat?._id,
        message: chatText,
      });
      if (response?.badToken) localStorage.removeItem('supportInfo');
      if (response?.status === 'success') {
        updateNewMessage(response?.data?.details);
        updateChatMessages((prev) => [...prev, response?.data?.details]);
        setChatText('');
      } else if (
        (response?.status === 'failed' &&
          response?.message === 'Invalid token or token expired') ||
        response?.message === 'Authorization denied! Token expired'
      ) {
        localStorage.clear();
        window.location.reload();
      } else if (
        response?.status === 'failed' &&
        response?.message === 'ERR no such key'
      ) {
        console.log(response?.message);
      } else {
        toast.error(response?.message || 'Connection Error');
      }
    } catch (error) {}
  }

  if (!recipient)
    return (
      <div className='max-h-[calc(100vh-10rem)] w-full'>
        <div className='h-full flex flex-row justify-center items-center'>
          <div className='w-40 h-40'>
            <img
              src={emptyChat}
              alt='empty'
              className='w-full h-full object-cover'
            />
          </div>
        </div>
      </div>
    );

  if (loading)
    <div style={{width: '100%', textAlign: 'center'}}>
      Loading chat messages...
    </div>;

  return (
    <div className='bg-[#0b0b0ba1] gap-4 w-full max-h-[calc(100vh-10rem)] rounded-lg p-2 relative'>
      <div className='chat-header'>
        <strong className=''>{recipient?.businessName}</strong>
      </div>
      <div className='overflow-y-scroll border-gray-300 rounded h-[80%]'>
        {chatMessages?.map((item, i) => {
          return (
            <div
              ref={scroll}
              key={i}
              className={` p-2
                ${item?.senderId === user?.id ? ' text-right ' : 'text-left '}
              `}>
              <div
                className={`text-white inline-block p-2 rounded-lg  ${
                  item?.senderId === user?.id
                    ? 'bg-blue-950 p-2 rounded-br-none rounded-l-md rounded-tr-md'
                    : 'bg-gray-600 p-2 rounded-bl-none rounded-r-md rounded-tl-md'
                }`}>
                <p>{item?.message}</p>
                <p className='text-gray-400 text-[9px] font-poppinsRegular'>
                  {moment(item?.createdAt).fromNow('')}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className='chat-input flex-grow-0 flex flex-row items-center gap-3 absolute left-0 right-0 bottom-0'>
        <InputEmoji
          value={chatText}
          onChange={setChatText}
          fontFamily='nunito'
          borderColor='rgba(72, 112, 223, 0.2)'
          onEnter={sendMessage}
          cleanOnEnter
          // shouldReturn={true}
          theme='dark'
          placeholder='Type a message'
          placeholderColor='#C4C4C4'
        />
        <button
          className='send-btn flex flex-row justify-center items-center'
          onClick={sendMessage}>
          <SendIcon />
        </button>
      </div>
    </div>
  );
};

export default ChatBox;
