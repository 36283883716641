/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState} from 'react';
import {useAppDispatch} from '../../app/hooks';
import {IBuyer} from '../interfaces/vendors';
import {DefaultImage} from '../../assets/images';
import {setSingleAdminBuyer} from '../../redux/slices/buyers/adminBuyerSlice';
import ViewBuyer from '../../pages/dashboard/admin/modal/ViewBuyer';

interface IVendorTable {
  buyers: IBuyer[];
}
const BuyerTable = ({buyers}: IVendorTable) => {
  const [showView, setShowView] = useState(false);
  const dispatch = useAppDispatch();

  // onViewProduct
  const viewHandler = async (id: any) => {
    buyers.filter((t: any) => t.id === id);
  };

  return (
    <>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <ViewBuyer setIsOpen={setShowView} />
        </div>
      )}
      <div>
        <table className=' mt-6 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-16 rounded-tl-md'>Image</th>
              <th className='w-16'>First Name</th>
              <th className='w-16'>Last Name</th>
              <th className='w-16'>Email</th>
              <th className='w-16'>Phone</th>
              <th className='w-16 rounded-tr-md '>View More</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100'>
            {buyers.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={7}>
                  <span className='text-xl text-primary font-semibold'>
                    No Vendor available
                  </span>
                </td>
              </tr>
            )}
            <>
              {buyers.map((buyer: IBuyer) => {
                return (
                  <tr className='overflow-x-auto' key={buyer.id}>
                    <td className='h-20'>
                      <div className='flex justify-center'>
                        <div className='lg:w-16 w-8 lg:h-16 h-8 rounded-full self-center'>
                          {/* {buyer?.buyerImg !== null ? (
                              <img
                                src={buyer.buyerImg}
                                alt=''
                                className='object-cover w-full rounded-full h-full'
                              />
                            ) : (
                              <img
                                src={DefaultImage}
                                alt='profile-def'
                                className='rounded-full w-full h-full object-cover'
                              />
                            )} */}
                          <img
                            src={DefaultImage}
                            alt='profile-def'
                            className='rounded-full w-full h-full object-cover'
                          />
                        </div>
                      </div>
                    </td>
                    <td className='w-10 text-center'>{buyer.firstName}</td>
                    <td className='w-10 text-center'>{buyer.lastName}</td>
                    <td className='w-10 text-center'>{buyer.email}</td>
                    <td className='w-10 text-center'>{buyer.phone}</td>
                    {/* Status */}

                    {/* Action */}
                    <td className='w-16'>
                      <div className='flex flex-row justify-evenly items-center h-10'>
                        {/* View  */}
                        <span
                          onClick={() => {
                            viewHandler(buyer.id);
                            dispatch(setSingleAdminBuyer(buyer));
                            setShowView(true);
                          }}
                          className='border-b-[1.7px] border-b-primary text-primary font-poppinsMedium pb-1 cursor-pointer'>
                          View
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BuyerTable;
