/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useState, useEffect} from 'react';
import {MdOutlineModeEditOutline, MdAdd} from 'react-icons/md';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {axiosError} from '../../../components/Helpers';
import {useAdmin} from '../../../app/hooks';
import {motion} from 'framer-motion';
import {ArrowLeft, SaveAdd} from 'iconsax-react';
import {useNavigate, useParams} from 'react-router-dom';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import {toast} from 'react-toastify';
import {RiSave3Fill} from 'react-icons/ri';

interface ICategory {
  id: string;
  name: string;
  code: number;
}

const UpdateCategory = () => {
  const {superCategoryId} = useParams(); // Retrieve categoryId from route params
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [editingCategory, setEditingCategory] = useState<string | null>(null);
  const [newCategoryName, setNewCategoryName] = useState<string>('');
  const [commission, setCommission] = useState<string>('');
  const [showAddInput, setShowAddInput] = useState<boolean>(false);
  const token: any = useAdmin().adminToken;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await VendorServices.getSuperCategory();
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        const sub = res.rows;
        const findCat = sub.filter((item: any) => item.id === superCategoryId);
        setCategories(findCat[0].categories);
      }
      setLoading(false);
    } catch (error: any) {
      axiosError(error.response);
    }
    setLoading(false);
  };

  const handleEditCategory = (id: string, name: string) => {
    setEditingCategory(id);
    setNewCategoryName(name);
  };
  const handleUpdateCategory = async (id: string, code: number) => {
    try {
      // Call API to update category name
      const data = {
        name: newCategoryName,
        code,
      };
      const response = await VendorServices.updateCategory(token, data, id);
      if (response.data.status === 'success') {
        // Update categories list
        const updatedCategories = categories.map((category) => {
          if (category.id === id) {
            return {...category, name: newCategoryName};
          }
          return category;
        });
        setCategories(updatedCategories);
        setEditingCategory(null);
        toast.success(response.data.data.message);
      }
      //   toast.success(response.data)
    } catch (error: any) {
      axiosError(error.response);
    }
  };

  const handleAddCategory = async () => {
    try {
      // Call API to add new category
      const data = {
        superCategoryId: superCategoryId,
        arrOfCategories: [{name: newCategoryName, commission: commission}],
      };
      const response = await VendorServices.addCategory(token, data);
      if (response.data.status === 'success') {
        const newCategory = response.data.data;
        setCategories((prevCategories) => [...prevCategories, newCategory]); // Update categories state with the new category
        setNewCategoryName('');
        setNewCategoryName('');
        setShowAddInput(false); // Hide the input field after adding the category
        toast.success(response.data.data.message);
        fetchData();
      }
    } catch (error: any) {
      axiosError(error.response);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    id: string,
    code: number
  ) => {
    if (event.key === 'Enter') {
      handleUpdateCategory(id, code);
      //   handleAddCategory()
    }
  };
  const handleKeyDownAdd = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      //   handleSaveCategory(id, code);
      handleAddCategory();
    }
  };

  const handleCommissionChange = (e: any) => {
    const {value} = e.target;
    const numericValueNUmber = value.replace(/\D/g, '');
    setCommission(numericValueNUmber);
  };
  return (
    <div className='h-full w-full xl:w-[70] sm:w-[80%] p-10 bg-white rounded-lg'>
      {loading ? (
        <TableDataSkeleton />
      ) : (
        <>
          <motion.div
            whileHover={{scale: 1.1}}
            className='w-24 text-center cursor-pointer pb-4'
            onClick={() => navigate(-1)}>
            <ArrowLeft size={28} variant='Linear' />
          </motion.div>
          <div className='flex flex-wrap items-center justify-start gap-4'>
            {categories.map((category) => (
              <div
                key={category.id}
                className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                {/* {editingCategory === category.id ? (
                  <>
                    <div className='w-[90%]'>
                      <input
                        type='text'
                        className='h-full outline-none bg-transparent focus:outline-none w-full'
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                        onBlur={() =>
                          handleUpdateCategory(category.id, category.code)
                        }
                        onKeyDown={(e) =>
                          handleKeyDown(e, category.id, category.code)
                        }
                        autoFocus
                      />
                    </div>
                    <MdOutlineModeEditOutline
                      size={22}
                      onClick={() => setEditingCategory(null)}
                    />
                  </>
                ) : (
                  <>
                    <div
                      className='w-[90%]'
                      onClick={() =>
                        handleEditCategory(category.id, category.name)
                      }>
                      {category.name}
                    </div>
                    <MdOutlineModeEditOutline
                      size={22}
                      onClick={() =>
                        handleEditCategory(category.id, category.name)
                      }
                    />
                  </>
                )} */}
                <>
                  <div
                    className='w-[90%]'
                    // onClick={() =>
                    //   handleEditCategory(category.id, category.name)
                    // }
                  >
                    {category.name}
                  </div>
                  <MdOutlineModeEditOutline
                    size={22}
                    // onClick={() =>
                    //   handleEditCategory(category.id, category.name)
                    // }
                  />
                </>
              </div>
            ))}
            {showAddInput ? (
              <div className='flex flex-row gap-2 items-center'>
                <div className=' bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                  <input
                    type='text'
                    className='w-[90%] h-full outline-none bg-transparent focus:outline-none'
                    value={newCategoryName}
                    onChange={(e) => setNewCategoryName(e.target.value)}
                    placeholder='Enter new category name'
                    // onBlur={() => handleAddCategory}
                    // onKeyDown={(e) => handleKeyDownAdd(e)}
                    autoFocus
                  />
                </div>
                <div className='bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                  <input
                    type='text'
                    className='w-[90%] h-full outline-none bg-transparent focus:outline-none'
                    value={commission}
                    onChange={handleCommissionChange}
                    placeholder='Enter new commission'
                    // onBlur={() => handleAddCategory}
                    // onKeyDown={(e) => handleKeyDownAdd(e)}
                    // autoFocus
                  />
                </div>
                <div className='cursor-pointer text-primary'>
                  {!newCategoryName || !commission ? (
                    <div></div>
                  ) : (
                    <RiSave3Fill size={28} onClick={handleAddCategory} />
                  )}
                </div>
              </div>
            ) : (
              <div className='flex flex-row items-start gap-2 bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md cursor-pointer'>
                <MdAdd size={22} onClick={() => setShowAddInput(true)} />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UpdateCategory;
