import ApprovedReviewsScreen from './ApprovedReviewsScreen';
import PendingReviewsScreen from './PendingReviewsScreen';

export interface IReviewSlice {
  label: string;
  route?: React.ReactNode;
}

export const ReviewSlice: IReviewSlice[] = [
  {
    label: 'Pending Reviews',
    route: <PendingReviewsScreen />,
  },
  {label: 'Approved Reviews', route: <ApprovedReviewsScreen />},
];

const [PendingReviews, ApprovedReviews] = ReviewSlice;
export const initialTabs = [PendingReviews, ApprovedReviews];

export function getNextWithdrawal(
  slides: IReviewSlice[]
): IReviewSlice | undefined {
  const existing = new Set(slides);
  return ReviewSlice.find((slide) => !existing.has(slide));
}
