type buttonProps = {
  type: 'submit' | 'button';
  className?: string;
  children: any;
  handleSubmit?: any;
  disabled?: boolean;
};

const Button = ({
  type,
  children = <div></div>,
  handleSubmit,
  className,
  disabled,
}: buttonProps) => {
  return (
    <div>
      <button
        onClick={handleSubmit}
        type={type}
        className={
          disabled
            ? `xl:w-[20rem]  w-[15rem] h-[3em] bg-gray-500 rounded-lg  ${className}`
            : `xl:w-[20rem] w-[15rem] h-[3em] bg-primary rounded-lg  ${className}`
        }
        disabled={disabled}>
        {children}
      </button>
    </div>
  );
};

export default Button;
