import {NavLink, useLocation} from 'react-router-dom';
import {useState} from 'react';
import {HiMenuAlt3} from 'react-icons/hi';
import logo from '../../assets/images/logo.png';
import {IMenu} from '../../pages/dashboard/sidebar.interface';
import {COLORS} from '../../assets/color';
import ConfirmLogout from '../modal/ConfirmLogout';
import {LogoutCurve} from 'iconsax-react';
import {IoMdArrowDropdown} from 'react-icons/io';
import {motion} from 'framer-motion';

interface ISidebar {
  logoutHandler: () => void;
  appScreens: IMenu[];
}
const Sidebar = ({logoutHandler, appScreens}: ISidebar) => {
  const [open, setOpen] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  // const dispatch = useDispatch();
  return (
    <>
      <section className='flex gap-4 min-h-[100vh]  m-0'>
        <div
          className={` bg-white border-gray-300 rounded-2xl lg:block hidden relative ${
            open ? 'min-w-[12rem]' : 'w-16'
          } duration-500 text-gray-50 px-4 pt-4`}>
          {open ? (
            <div className='py-3 flex flex-row justify-between gap-1 items-center'>
              {/* Logo */}
              <div className='w-8 h-8'>
                <img
                  src={logo}
                  alt='logo'
                  className='w-full h-full object-contain'
                />
              </div>
              <div>
                <h1
                  className={`text-neutral-600 font-poppinsMedium origin-left text-base duration-200 ${
                    !open && 'scale-0'
                  }`}>
                  People's Store
                </h1>
              </div>
              <div>
                <HiMenuAlt3
                  size={28}
                  className='cursor-pointer'
                  onClick={() => setOpen(false)}
                  color={COLORS.primary}
                />
              </div>
            </div>
          ) : (
            <div className='full justify-center flex'>
              <HiMenuAlt3
                size={28}
                className='cursor-pointer'
                onClick={() => setOpen(true)}
                color={COLORS.primary}
              />
            </div>
          )}
          <div className='flex-1 pt-6 pb-8 overflow-auto'>
            {appScreens.map((link, i) => (
              <SidebarLink key={link.key} link={link} i={i} open={open} />
            ))}
          </div>
          <div className='absolute bottom-1'>
            <div
              className='h-8 w-8 cursor-pointer'
              onClick={() => setIsOpen(true)}>
              <LogoutCurve size={22} color={COLORS.primary} />
            </div>
          </div>
        </div>
        {/* tablet */}
        <div
          className={` bg-white border-[1.5px] border-gray-300 rounded-2xl relative w-16 duration-500 text-gray-50 px-4 pt-4 lg:hidden sm:block hidden`}>
          <div className='full justify-center flex'>
            <HiMenuAlt3
              size={28}
              className='cursor-pointer'
              onClick={() => setOpen(true)}
              color={COLORS.primary}
            />
          </div>
          <div className='flex-1 overflow-auto pt-6 pb-8 '>
            {appScreens.map((link, i) => (
              <SidebarLink key={link.key} link={link} i={i} open={open} />
            ))}
          </div>
          <div className='absolute bottom-1'>
            <div
              className='h-8 w-8 cursor-pointer'
              onClick={() => setIsOpen(true)}>
              <LogoutCurve size={22} color={COLORS.primary} />
            </div>
          </div>
        </div>
        {/* tablet end */}
      </section>
      {isOpen && (
        <div className='z-50'>
          <ConfirmLogout setIsOpen={setIsOpen} logoutHandler={logoutHandler} />
        </div>
      )}
    </>
  );
};

export default Sidebar;

type IsidebarLink = {
  link: any;
  i: any;
  open: boolean;
};

function SidebarLink({i, link, open}: IsidebarLink) {
  const [activeId, setActiveId] = useState(1);
  const [openMenu, setOpenMenu] = useState(null);

  const handleMenuClick = (index: any) => {
    setOpenMenu(openMenu === index ? null : index);
  };
  const submenuVariants = {
    hidden: {height: 0, opacity: 0},
    visible: {height: 'auto', opacity: 1},
  };

  const location = useLocation();
  return (
    <div className='flex flex-col xl:gap-3 gap-2 relative group'>
      <NavLink
        to={link.path}
        className={({isActive}) => {
          return (
            'flex py-3 items-center px-1 rounded-md justify-between cursor-pointer my-2 group' +
            (isActive && !link.subMenu
              ? ' text-[#FFFFFF] bg-primary '
              : location.pathname?.includes('Orders') &&
                link.path?.includes('Orders')
              ? ' text-[#FFFFFF] bg-primary '
              : location.pathname?.includes('userManagement') &&
                link.path?.includes('userManagement')
              ? ' text-[#FFFFFF] bg-primary '
              : ' text-primary hover:bg-primary hover:text-white')
          );
        }}
        onClick={() => handleMenuClick(i)}>
        <span
          className='flex flex-row items-center gap-3'
          onClick={() => setActiveId(link.id)}>
          {activeId === link.id ? (
            <span>{link.iconTwo}</span>
          ) : (
            <span>{link.icon}</span>
          )}

          <span
            style={{
              transitionDelay: `${i + 3}00ms`,
            }}
            className={`whitespace-pre duration-500 font-poppinsRegular lg:block hidden text-xs ${
              !open &&
              'opacity-0 translate-x-28 overflow-hidden font-poppinsRegular'
            }`}>
            {link.item}
          </span>
          {link.subMenu && (
            <IoMdArrowDropdown
              className={`ml-auto transform transition-transform  ${
                openMenu === i ? 'rotate-180' : ''
              }`}
              size={18}
            />
          )}
          {!open && (
            <span
              className={` absolute bg-primary z-50 invisible font-semibold whitespace-pre text-white px-2 text-xs rounded drop-shadow-lg overflow-hidden group-hover:px-2 group-hover:py-1 group-hover:left-[55px] group-hover:duration-300 group-hover:w-fit group-hover:visible`}>
              {link.item}
            </span>
          )}
        </span>
      </NavLink>
      {link.subMenu && openMenu === i && (
        <motion.div
          className='overflow-hidden'
          initial='hidden'
          animate={openMenu === i ? 'visible' : 'hidden'}
          variants={submenuVariants}
          transition={{duration: 0.3}}>
          {link.subMenu.map((subItem: any, subIndex: any) => (
            <NavLink
              key={subIndex}
              to={subItem.path}
              className={({isActive}) =>
                ` flex items-center p-2 my-2 rounded-md cursor-pointer ${
                  isActive
                    ? ' text-white hover:bg-primary hover:text-white bg-primary'
                    : ' text-primary hover:bg-primary hover:text-white'
                }`
              }>
              {subItem.icon}
              <span className='ml-4'>{subItem.item}</span>
            </NavLink>
          ))}
        </motion.div>
      )}
    </div>
  );
}
