/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from 'react';
import {VendorServices} from '../../redux/services/vendor/vendor';
import {BsFillBoxFill} from 'react-icons/bs';
import {IoMdWallet} from 'react-icons/io';
import OverviewCard, {IOverviewCardProps} from './OverviewCard';
import {axiosError} from '../Helpers';
import {useVendor} from '../../app/hooks';
import Picker from '../forms/inputs/selectInput/Picker';
import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  isWithinInterval,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import {Icon} from '@iconify/react';

interface IProductOverview {
  settlements: any[];
}
const ProductOverview = ({settlements}: IProductOverview) => {
  const token = useVendor().token;
  const vendorId = useVendor().vendor.id;

  const options = [
    {value: 'Monthly', label: 'Monthly'},
    {value: 'Weekly', label: 'Weekly'},
    {value: 'Daily', label: 'Daily'},
  ];

  const [completedOrders, setCompletedOrders] = useState<any>([]);

  const getOrders = async () => {
    try {
      const response = await VendorServices.getVendorOrders(token, vendorId);
      if (response.data.status === 'success') {
        // toast.success(response.data.data.message);
        const res = response.data.data;
        // console.log('profile', res);
        const orders = res?.details.rows;
        const filteredOrders = orders.filter(
          (order: any) => order.isMovedToSettlement
        );
        setCompletedOrders(filteredOrders);
      }
    } catch (error: any) {
      console.log(error.response);
      axiosError(error.response);
    }
  };
  // console.log(completedOrders);

  // Filter Cards by Dates

  const [totalIncome, setTotalIncome] = useState<any[]>([]);
  const [totalEarned, setTotalEarned] = useState<any[]>([]);
  const [totalProducts, setTotalProducts] = useState<any[]>([]);
  const [filter, setFilter] = useState('Monthly');

  useEffect(() => {
    getIncome();
    getEarned();
    getProducts();
    getOrders();
  }, []);

  const getIncome = async () => {
    // Fetch total income
    // Replace with your actual endpoint
    const response = [{}];
    setTotalIncome(response);
  };

  const getEarned = async () => {
    // Fetch total earned
    // Replace with your actual endpoint
    const response = settlements;
    setTotalEarned(response);
  };

  const getProducts = async () => {
    try {
      const response = await VendorServices.getProfile(token);
      if (response.data.status === 'success') {
        const products = response.data.data.vendor?.products;
        // console.log(response.data.data.vendor?.products, 'Reso');
        setTotalProducts(products);
      }
    } catch (error: any) {
      console.log(error.response);
      axiosError(error.response);
    }
  };

  const filterData = useCallback(() => {
    const now = new Date();
    const filtered: any = {
      income: [],
      earned: [],
      products: [],
      orders: [],
    };

    if (filter === 'Daily') {
      const startOfDayDate = startOfDay(now);
      const endOfDayDate = endOfDay(now);

      filtered.income = totalIncome.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfDayDate,
          end: endOfDayDate,
        });
      });

      filtered.earned = totalEarned.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfDayDate,
          end: endOfDayDate,
        });
      });

      filtered.products = totalProducts.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfDayDate,
          end: endOfDayDate,
        });
      });

      filtered.orders = completedOrders.filter((order: any) => {
        const orderDate = new Date(order.createdAt);
        return isWithinInterval(orderDate, {
          start: startOfDayDate,
          end: endOfDayDate,
        });
      });
    } else if (filter === 'Weekly') {
      const startOfWeekDate = startOfWeek(now, {weekStartsOn: 1}); // Assuming week starts on Monday
      const endOfWeekDate = endOfWeek(now, {weekStartsOn: 1});

      filtered.income = totalIncome.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfWeekDate,
          end: endOfWeekDate,
        });
      });

      filtered.earned = totalEarned.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfWeekDate,
          end: endOfWeekDate,
        });
      });

      filtered.products = totalProducts.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfWeekDate,
          end: endOfWeekDate,
        });
      });

      filtered.orders = completedOrders.filter((order: any) => {
        const orderDate = new Date(order.createdAt);
        return isWithinInterval(orderDate, {
          start: startOfWeekDate,
          end: endOfWeekDate,
        });
      });
    } else if (filter === 'Monthly') {
      const startOfMonthDate = startOfMonth(now);
      const endOfMonthDate = endOfMonth(now);

      filtered.income = totalIncome.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfMonthDate,
          end: endOfMonthDate,
        });
      });

      filtered.earned = totalEarned.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfMonthDate,
          end: endOfMonthDate,
        });
      });

      filtered.products = totalProducts.filter((item) => {
        const itemDate = new Date(item.createdAt);
        return isWithinInterval(itemDate, {
          start: startOfMonthDate,
          end: endOfMonthDate,
        });
      });

      filtered.orders = completedOrders.filter((order: any) => {
        const orderDate = new Date(order.createdAt);
        return isWithinInterval(orderDate, {
          start: startOfMonthDate,
          end: endOfMonthDate,
        });
      });
    }

    return filtered;
  }, [filter, totalIncome, totalEarned, totalProducts, completedOrders]);

  const filteredData = filterData();
  //  console.log(filterData(), 'filterData');

  const getTotalEarned = () =>
    filteredData.earned.reduce(
      (acc: number, item: any) => acc + Number(item.amount),
      0
    );
  // console.log(settlements, 'kkkk');
  const getTotalProducts = () => filteredData?.products?.length;
  const getTotalOrders = () => filteredData?.orders?.length;

  // ------------------------ End Filters
  const OverviewData: IOverviewCardProps[] = [
    {
      icon: <IoMdWallet size={30} />,
      title: 'Ads Earning',
      amount: 0,
    },
    {
      icon: <Icon icon='healthicons:money-bag' fontSize={32} />,
      title: 'Total Earned',
      amount: getTotalEarned(),
    },
    {
      icon: <BsFillBoxFill size={30} />,
      title: 'Total Products',
      value: getTotalProducts(),
    },
    {
      icon: <Icon icon='solar:bill-check-bold' fontSize={32} />,
      title: 'Total Completed Orders',
      value: getTotalOrders(),
    },
  ];

  return (
    <div className='col-span-1'>
      <div className='flex flex-row justify-between items-center pb-2 2xl:px-8'>
        <div className='flex items-center flex-row gap-2'>
          <p className='font-poppinsMedium'>Product Overview</p>
        </div>
        {/* Sort */}
        <div className=' bg-[#DEE1FF] rounded-md text-black'>
          <Picker
            onChange={setFilter}
            options={options}
            placeholder={'Monthly'}
          />
        </div>
      </div>
      {/* Card */}
      <div className='flex flex-wrap gap-4 justify-center'>
        <OverviewCard datasets={OverviewData} type='Vendor' />
      </div>
    </div>
  );
};

export default ProductOverview;
