import {store} from '../../app';
import {api} from '../utils';

export const fetchRecipientUser = async (userId) => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;
    try {
      const data = await api.req(
        `chat/recipient/${userId}`,
        undefined,
        null,
        token
      );
      return data;
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};

export const fetchAllUsers = async () => {
  try {
    const token = store.getState().support.roles.vendorManagement.token;
    const data = await api.req('vendor/all', undefined, null, token);

    return data;
  } catch (error) {
    console.log(error?.message);
  }
};
export const fetchAllBuyerUsers = async () => {
  try {
    const token = store.getState().support.roles.buyerManagement.token;
    const data = await api.req('buyer/all', undefined, null, token);

    return data;
  } catch (error) {
    console.log(error?.message);
  }
};
export const fetchAllSupportUsers = async () => {
  try {
    const token = store.getState().vendor.vendor?.token;
    const data = await api.req('support', undefined, null, token);

    return data;
  } catch (error) {
    console.log(error?.message);
  }
};
