import {customerUrl} from '../constant';

export interface INavBarMenu {
  id?: number;
  key: string;
  item: string;
  icon?: React.ReactNode;
  iconTwo?: React.ReactNode;
  path: string;
}

export const navBarMenu: INavBarMenu[] = [
  {
    item: 'Contact',
    path: `${customerUrl}/contact-us`,
    key: 'contact',
  },
  {
    item: 'Become a Vendor ',
    path: '/',
    key: 'makeMoney ',
  },
];
