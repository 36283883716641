import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ProtectedRoutes from './routes/protectedRoutes';
import NotFound from './pages/NotFound';
import Login from './pages/dashboard/admin/Login';
import AdminProtectedRoutes from './routes/AdminProtectedRoutes';
import AuthLayout from './components/layouts/AuthLayout';
import HomeLayout from './components/layouts/HomeLayout';
import Home from './pages/dashboard/admin/home';
import Products from './pages/dashboard/admin/products';
import Vendors from './pages/dashboard/admin/Vendors';
import Orders from './pages/dashboard/admin/Orders';
import Accounting from './pages/dashboard/admin/Accounting';
import Buyers from './pages/dashboard/admin/Buyers';
import VendorHomeLayout from './components/layouts/VendorHomeLayout';
import VendorHome from './pages/dashboard/vendor/home';
import VendorProducts from './pages/dashboard/vendor/products';
import Transactions from './pages/dashboard/vendor/Transactions';
import Chat from './pages/dashboard/vendor/chat';
import Profile from './pages/dashboard/vendor/profile';
import EditProduct from './pages/dashboard/vendor/EditProduct';
import Verification from './pages/dashboard/vendor/auths/Verification';
import ForgotPassword from './pages/dashboard/vendor/auths/ForgotPassword';
import ResetPassword from './pages/dashboard/vendor/auths/ResetPassword';
import Signin from './pages/signin';
import MultiForm from './pages/dashboard/vendor/auths/MultiForm/MultiForm';
import Supports from './pages/dashboard/admin/Supports';
import InboxChat from './components/dashboard/InboxChat';
import ProductDetails from './components/dashboard/ProductDetails';
import CategoriesScreen from './pages/dashboard/admin/CategoriesScreen';
import UpdateCategory from './pages/dashboard/admin/UpdateCategory';
import UpdateSubCategory from './pages/dashboard/admin/UpdateSubCategory';
import VendorPaymentHistory from './pages/dashboard/vendor/VendorPaymentHistory';
import OrderDetails from './components/dashboard/OrderDetails';
import Reviews from './pages/dashboard/admin/Reviews';
import {CombineProviders, providers} from './chat/context';
import LoginScreen from './pages/supportDesk/LoginScreen';
import ChatScreen from './chat/pages/chat';
import AdminChatProtectedRoutes from './routes/AdminChatProtectedRoutes';
import TransactionsAdmin from './pages/dashboard/admin/Transactions';
import AdminBuyerChatProtectedRoutes from './routes/AdminBuyerChatProtectedRoutes';
import BuyerChatScreen from './chat/pages/buyerChat/buyerChat';
import Settlements from './pages/dashboard/admin/Settlements';
import Returns from './pages/dashboard/admin/Returns';
import UpdateSuperCategory from './pages/dashboard/admin/UpdateSuperCategory';
import Coupon from './pages/dashboard/admin/Coupon';
import CreateCoupon from './pages/dashboard/admin/CreateCoupon';
import UserManagementVendor from './pages/dashboard/admin/UserManagementVendor';
import VendorAuthLayout from './components/layouts/VendorAuthLayout';
import CancelledOrders from './pages/dashboard/admin/CancelledOrders';
import UserManagementAdmin from './pages/dashboard/admin/UserManagementAdmin';
import Commissions from './pages/dashboard/admin/Commissions';
import ChangePassword from './pages/dashboard/vendor/ChangePassword';
import VendorOrders from "./pages/dashboard/vendor/VendorOrders";

function App() {
  return (
    <CombineProviders contexts={providers}>
      <Router>
        <Routes>
          <Route path='*' element={<NotFound />} />
          <Route path='' element={<VendorAuthLayout />}>
            <Route path='' element={<Signin />} />
            <Route path='/signup' element={<MultiForm />} />
            <Route path='verification' element={<Verification />} />
            <Route path='forgotPassword' element={<ForgotPassword />} />
            <Route
              path='reset-password/:token'
              element={<ResetPassword />}></Route>
          </Route>

          <Route path='' element={<ProtectedRoutes />}>
            <Route path='' element={<VendorHomeLayout />}>
              <Route path='vendor/dashboard' element={<VendorHome />} />
              <Route path='vendor/products' element={<VendorProducts />} />
              <Route path='vendor/orders' element={<VendorOrders />} />
              <Route
                path='vendor/change-password'
                element={<ChangePassword />}
              />
              <Route
                path='vendor/products/:productId'
                element={<EditProduct />}
              />
              <Route path='vendor/profile/' element={<Profile />} />
              <Route path='vendor/transactions' element={<Transactions />} />
              <Route
                path='vendor/payments'
                element={<VendorPaymentHistory />}
              />
              <Route path='vendor/chats' element={<Chat />} />
              <Route path='vendor/chats/chatDetails' element={<InboxChat />} />
            </Route>
          </Route>
          {/* Admin Auth */}
          <Route path='/admin' element={<AuthLayout />}>
            <Route path='' element={<Login />} />
            {/* <Route path='reg' element={<Register />} /> */}
          </Route>
          {/* Support Login */}
          <Route path='/support-desk' element={<LoginScreen />} />

          {/* Admin Protected Route */}
          <Route path='' element={<AdminProtectedRoutes />}>
            <Route path='' element={<HomeLayout />}>
              <Route path='admin/dashboard' element={<Home />} />
              <Route path='admin/products' element={<Products />} />
              <Route path='admin/commissions' element={<Commissions />} />
              <Route
                path='admin/products/:productId'
                element={<ProductDetails />}
              />
              <Route
                path='admin/products/categories'
                element={<CategoriesScreen />}
              />
              <Route
                path='admin/products/updateSuperCategory'
                element={<UpdateSuperCategory />}
              />
              <Route
                path='admin/products/updateCategory/:superCategoryId'
                element={<UpdateCategory />}
              />
              <Route
                path='admin/products/updateSubCategory/:categoryId'
                element={<UpdateSubCategory />}
              />
              <Route path='admin/vendors' element={<Vendors />} />
              <Route path='admin/customers' element={<Buyers />} />
              {/* <Route path='admin/orders' element={<Orders />} /> */}
              <Route path='admin/allOrders' element={<Orders />} />
              <Route
                path='admin/cancelledOrders'
                element={<CancelledOrders />}
              />
              <Route path='admin/returns' element={<Returns />} />
              <Route
                path='admin/transactions'
                element={<TransactionsAdmin />}
              />
              <Route path='admin/orders/:orderId' element={<OrderDetails />} />
              <Route path='admin/settlements' element={<Settlements />} />
              <Route path='admin/accounting' element={<Accounting />} />
              <Route path='admin/supports' element={<Supports />} />
              <Route path='admin/reviews' element={<Reviews />} />
              <Route path='admin/coupon' element={<Coupon />} />
              <Route path='admin/create-coupon' element={<CreateCoupon />} />
              <Route
                path='admin/userManagement/admin'
                element={<UserManagementAdmin />}
              />
              <Route
                path='admin/userManagement/vendor'
                element={<UserManagementVendor />}
              />
              {/* <Route path='admin/profile' element={<Payments />} /> */}
            </Route>
          </Route>
          <Route path='' element={<AdminChatProtectedRoutes />}>
            <Route path='support-desk/chat' element={<ChatScreen />} />
          </Route>
          <Route path='' element={<AdminBuyerChatProtectedRoutes />}>
            <Route
              path='support-desk/chat-customer'
              element={<BuyerChatScreen />}
            />
          </Route>
        </Routes>
      </Router>
    </CombineProviders>
  );
}

export default App;
