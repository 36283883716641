import {createSlice} from '@reduxjs/toolkit';
import {IVendor} from '../../../components/interfaces/vendors';
import {RootState} from '../../../app/store';

type IVendorReducer = {
  vendors: IVendor[];
};
const initialState: IVendorReducer = {
  vendors: localStorage.getItem('chatVendors')
    ? JSON.parse(localStorage.getItem('chatVendors') || '[]')
    : [],
};

const chatVendorReducer = createSlice({
  name: 'vendors',
  initialState,
  reducers: {
    setChatVendors: (state, action) => {
      state.vendors = action.payload;
      localStorage.setItem('chatVendors', JSON.stringify(action.payload));
    },
  },
});

export const {setChatVendors} = chatVendorReducer.actions;
export const selectChatVendors = (state: RootState) => state.vendors.vendors;

export default chatVendorReducer.reducer;
