type inputProps = {
  label: string;
  icon?: string;
  pattern?: string;
  handleChange?: any;
  valueField?: any;
  name?: string;
  required?: boolean;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
};

const Input = ({
  label,
  handleChange,
  valueField,
  name,
  required,
  placeholder,
  type,
}: inputProps) => {
  return (
    <div className='flex flex-col items-start'>
      {required ? (
        <span className='text-gray-400 font-poppinsMedium font-medium pb-2'>
          {label} <span className='text-red-500 text-base'>*</span>
        </span>
      ) : (
        <span className='text-gray-400 font-poppinsMedium font-medium pb-2'>
          {label}
        </span>
      )}
      <div className='w-full rounded-md h-[2.5rem] border-[2px] border-gray-400 px-3'>
        <input
          type={type ? type : 'text'}
          placeholder={placeholder ? placeholder : 'Placeholder'}
          className='outline-none w-full h-full focus:outline-none text-black placeholder:text-gray-400 placeholder:font-light placeholder:text-xs'
          onChange={handleChange}
          value={valueField}
          name={name}
          required={required}
        />
      </div>
    </div>
  );
};

export default Input;
