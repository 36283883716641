import {Outlet} from 'react-router-dom';
import '../../assets/styles/auth/Auth.css';
import {ThePeopleStore} from '../../assets/images';

function AuthLayout() {
  const isMobile = window.matchMedia(
    'only screen and (max-width: 760px)'
  ).matches;
  if (isMobile) {
    return (
      <div className='text-lg text-center font-poppinsRegular bg-primary text-white flex justify-center items-center p-8 h-screen'>
        Sorry, this admin app is only accessible by tablet, TV or desktop users.
        Kindly use the supported devices.
      </div>
    );
  }
  return (
    <div className='w-full h-screen grid place-items-center bg-blue-50'>
      {/* Outlet */}
      <div className='main-container border-strokeColor xl:w-[30vw]  md:w-[40vw] w-[60vw] overflow-auto md:h-[100vh] h-full'>
        <div className='flex justify-center w-full items-center h-full'>
          <div className='self-auto w-full md:pt-14 pt-2 px-2 md:px-0 '>
            {/* Logo */}
            <div className='flex flex-row gap-6 items-center justify-center pb-4'>
              <div className='w-32'>
                <img
                  src={ThePeopleStore}
                  alt='logo'
                  className='w-full h-full object-cover'
                />
              </div>
            </div>
            <div className='p-4 bg-white rounded-xl shadow-md md:drop-shadow-sm'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
