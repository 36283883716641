import React, {useEffect, useRef, useState} from 'react';
import {regexValidation} from '../../../../components/Helpers/regexValidation';
import {useNavigate} from 'react-router-dom';
import {AuthService} from '../../../../redux/services/vendor/auth';
import {Zoom, toast} from 'react-toastify';
import {axiosError} from '../../../../components/Helpers';
import {Loader} from '../../../../assets/svg';
import InputFieldAuth from '../../../../components/forms/inputs/inputField/InputFieldAuth';
import Button from '../../../../components/ui/Button';
import {COLORS} from '../../../../assets/color';

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [state, setState] = useState({
    email: '',
  });

  const EmailRef = useRef<HTMLInputElement>(null);
  // Check Validation
  useEffect(() => {
    EmailRef.current?.focus();
  }, []);

  // REGEX Validations
  useEffect(() => {
    const isEmailValid = regexValidation.validateEmail(state.email);
    setValidEmail(isEmailValid);
  }, [state.email]);

  // HandleChange
  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setState({
        ...state,
        [e.target.name]: e.target.value.replace(' ', ''),
      });
    },

    handleSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
    },
  };
  const navigate = useNavigate();

  //   Forgot Password
  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      email: state.email,
    };
    try {
      const response = await AuthService.forgotPassword(data);
      if (response.data.status === 'success') {
        const res = response.data;
        // console.log(response);
        navigate('/');
        toast.success(
          res.data.message ||
            `Reset link sent Successfully, Please Check your email for OTP`,
          {
            position: toast.POSITION.TOP_CENTER,
            transition: Zoom,
            closeOnClick: false,
            hideProgressBar: true,
            draggable: false,
            style: {width: '400px'},
          }
        );
        // console.log(`Response`);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  return (
    <div className='h-full grid place-items-center md:my-10'>
      <div className='container mx-auto'>
        {/* Body */}
        <div className='lg:w-[75%] sm:w-[90%]  w-full sm:p-10 p-2 sm:rounded-xl sm:bg-[#FAFAFA] m-auto'>
          <div className='xl:w-[40%] md:w-[60%] w-full mx-auto'>
            <div>
              <div className=''>
                <div className='w-full pt-4 md:px-2 px-4 font-poppinsRegular'>
                  <div className='w-full h-full flex flex-row items-center justify-center'>
                    <div className='grid place-items-center sm:py-10 py-4  w-full'>
                      <div className='w-full'>
                        <h3 className='text-3xl font-poppinsSemibold text-center pb-3'>
                          Password Recovery
                        </h3>
                        <h4 className=' text-xs md:text-sm font-poppinsRegular text-center pb-3 text-[#777777]'>
                          Enter the email address associated with your account
                          to reset password
                        </h4>
                        <form className='w-full'>
                          <InputFieldAuth
                            label='Email Address'
                            name='email'
                            type='email'
                            placeholder='Enter your email'
                            value={state.email}
                            required={true}
                            Inputref={EmailRef}
                            ariaInvalid={validEmail ? 'false' : 'true'}
                            onChange={r.onChange}
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                            style={{height: '100%'}}
                            email
                          />
                          <p
                            id='uidnote'
                            className={
                              emailFocus && !validEmail
                                ? 'instructions'
                                : 'offscreen'
                            }>
                            <span className='font-poppinsRegular'>
                              Oops! It looks like the email format is incorrect
                              or not found. Please check and try again.
                            </span>
                          </p>

                          <Button
                            label={
                              isLoading ? (
                                <div>
                                  <Loader color={'#fff'} />
                                </div>
                              ) : (
                                'Request reset link'
                              )
                            }
                            width='full'
                            onClick={handleSubmit}
                            // onClick={() => navigate('../admin/dashboard')}
                          />
                          <div className='py-4'>
                            <Button
                              bgColor='#F3EFEC'
                              textCOlor={COLORS.primary}
                              borderCOlor='#F3EFEC'
                              label={'Back to Login'}
                              width='full'
                              onClick={() => navigate(-1)}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
