import AuthContextProvider from "./auth";
import ChatContextProvider from "./chat";
import CombineProviders from "./combine";

const providers = [
    AuthContextProvider, 
    ChatContextProvider
]

export {
    CombineProviders,
    providers
}