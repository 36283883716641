import {DefaultImage} from '../../assets/images';

export interface IInbox {
  name: string;
  description: string;
  dateTime: string;
  img: any;
}
export const inboxData: IInbox[] = [
  {
    name: `People's Store`,
    description:
      'Important updates voluptatem quia voluptas sit aspernatur aut odit....',
    dateTime: 'Yesterday',
    img: DefaultImage,
  },
  {
    name: 'Package Delivered',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit.....',
    dateTime: 'Yesterday',
    img: DefaultImage,
  },
  {
    name: 'Order Completed',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit....',
    dateTime: 'Yesterday',
    img: DefaultImage,
  },
  {
    name: 'Package Delivered',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit....',
    dateTime: 'Yesterday',
    img: DefaultImage,
  },
  {
    name: 'Order Completed',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit....',
    dateTime: 'Yesterday',
    img: DefaultImage,
  },
  {
    name: 'Package Delivered',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit....',
    dateTime: '05/04/24',
    img: DefaultImage,
  },
  {
    name: 'Order Cancelled',
    description:
      'Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit....',
    dateTime: '04/04/24',
    img: DefaultImage,
  },
];
