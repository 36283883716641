import {useNavigate} from 'react-router-dom';
import styles from '../../assets/styles/modals/ConfirmModal.module.css';
interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleHandler?: () => void;
}
const ConfirmDelete = ({setIsOpen, deleHandler}: IModal) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={styles.confirmDeleteBG}
        onClick={() => {
          navigate(-1);
          // setIsOpen(true);
        }}
      />
      <div className={styles.centeredMobile}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            Are you sure you want to delete?
          </div>
          <div className={styles.modalActions}>
            <div className={styles.actionsContainer}>
              <button
                className={styles.cancelBtn}
                onClick={() => setIsOpen(false)}>
                Cancel
              </button>
              <button
                className={styles.deleteBtn}
                onClick={() => {
                  deleHandler && deleHandler();
                  setIsOpen(false);
                }}>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmDelete;
