/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import {BiChevronDown, BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {useEffect, useState} from 'react';
import {useAdmin} from '../../../app/hooks';
import {useNavigate} from 'react-router-dom';
import {axiosError} from '../../../components/Helpers';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';
import Pagination from '../../../components/ui/Pagination';
import {IAdminOrder} from '../../../components/interfaces/products';
import {IoMdArrowDropdown} from 'react-icons/io';
import {AdminServices} from '../../../redux/services/admin/admin';
import AdminOrderTable from './AdminOrderTable';
import moment from 'moment';
import {toast} from 'react-toastify';
import {addMonths} from 'date-fns';
import DateRangePicker from '../../../components/forms/inputs/selectInput/DateRangePicker';

const Orders = () => {
  localStorage.setItem('currentPath', '/vendor/orders');
  const [orders, setOrders] = useState<any>([]);
  const token = useAdmin().adminToken;
  const [isLoading, setIsLoading] = useState(false);

  // Query
  const [query, setQuery] = useState('');
  // Define keys to include both direct and nested keys
  const keys = [
    'orderProducts.orderId',
    'createdAt',
    'orderProducts.product.name',
    'orderProducts.status',
    'orderProducts.product.businessName',
  ];

  // Function to access nested keys in objects
  const getValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const search = (data: any, query: string) => {
    return data?.filter((item: any) =>
      keys.some((key) => {
        const value =
          getValue(item, key) ||
          getValue(item.orderProducts[0], key.split('.').slice(1).join('.'));
        return value
          ? value.toString().toLowerCase().includes(query.toLowerCase())
          : false;
      })
    );
  };

  // Sort --------------------
  const [openSort, setOpenSort] = useState(false);

  const [sortingOption, setSortingOption] = useState<keyof IAdminOrder>('code');
  const [isAscending, setIsAscending] = useState(false);

  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const searchData = search(orders, query);

  const sortedData = [...searchData].sort((a, b) => {
    // Use the selected sorting option
    const sortOrder = isAscending ? 1 : -1;
    const aValue = a[sortingOption];
    const bValue = b[sortingOption];

    // Compare values based on the sorting order
    if (aValue < bValue) return -1 * sortOrder;
    if (aValue > bValue) return 1 * sortOrder;
    return 0;
  });

  // Pagination
  const PageSize = 15;
  const [currentPage, setCurrentPage] = useState(1);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = sortedData.slice(firstPageIndex, lastPageIndex);

  const totalPages = Math.ceil(orders.length / PageSize);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Filter Start and End date
  const [startDate, setStartDate] = useState(new Date('2024-04-01'));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 6));

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  useEffect(() => {
    getOrders();
  }, [startDate, endDate]);

  const getOrders = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getOrdersWithDateRange(
        token,
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0]
      );
      if (response.data.status === 'success') {
        // toast.success(response.data.data.message);
        const res = response.data.data.details.rows;
        const allOrders = res?.filter((item: any) =>
          item.orderProducts.some(
            (product: any) => product.status !== 'cancelled'
          )
        );
        setOrders(allOrders);
        setIsLoading(false);
      }
    } catch (error: any) {
      // console.log(error.response);
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  //  Download selected rows to csv

  // Flatten the orderProducts from all orders
  const orderAllData = orders?.flatMap(
    (order: any) => order?.orderProducts || []
  );

  const downloadSelectedRows = () => {
    const selectedData = orderAllData;

    // Create CSV headers
    const csvHeaders = [
      'Date',
      'Vendor',
      '#Order ID',
      'Item Name',
      'Product Description',
      'Price NGN',
      'Status',
    ];

    // Convert selectedData to CSV format
    const csvData: string[] = [];
    csvData.push(csvHeaders.join(',')); // Add headers to the CSV data
    selectedData?.forEach((row: any) => {
      csvData.push(
        `${moment(row?.createdAt).format('l')},${row?.product.businessName},${
          row?.orderId
        },${row?.product.name},${
          row?.quantity + ' ' + row?.product.color + ' ' + row?.product.size
        },${row?.subTotal}, ${row?.status}`
      );
    });

    // Create a CSV string
    const csvString = csvData.join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvString], {type: 'text/csv'});

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'orders.csv');

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  // Update Order
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const updateOrder = async (orderId: string) => {
    const data = {
      status: 'delivered',
    };
    setIsUpdateLoading(true);
    try {
      const response = await AdminServices.updateOrder(token, orderId, data);
      if (response.data.status === 'success') {
        const res = response?.data;
        toast.success(res.message || 'Order Update Successfully');
        setIsUpdateLoading(false);
        await getOrders();
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsUpdateLoading(false);
    }
  };
  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Orders</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>ORDERS</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='flex flex-row items-center justify-end gap-6 pt-6'>
          <button
            onClick={downloadSelectedRows}
            className='bg-primary text-white px-3 py-2 text-center transition-hover rounded-sm'>
            Download CSV
          </button>
          {/* Sort */}
          <div
            onClick={() => setOpenSort(!openSort)}
            className='flex flex-row items-center gap-4 cursor-pointer'>
            <div className='font-poppinsMedium'>Sort by:</div>
            <div className='relative'>
              <div className='flex flex-row items-center gap-10'>
                <p className='text-[#868FE1] text-xs font-poppinsMedium'>
                  {sortingOption === 'createdAt'
                    ? 'Date & Time'
                    : sortingOption === 'code'
                    ? 'Order Code'
                    : 'Status'}
                </p>
                <BiChevronDown size={20} color='#868FE1' />
              </div>
              {/* Sort DropDown */}
              {openSort && (
                <div
                  onClick={() => setOpenSort(false)}
                  className='bg-white w-full px-2 py-2 flex flex-col items-start absolute rounded-md shadow-md top-8 z-50 text-xs gap-2'>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('code')}>
                    Order Code
                  </p>
                  <p
                    className='hover:underline hover:text-[#868FE1]'
                    onClick={() => handleSortingOptionChange('createdAt')}>
                    Date & Time
                  </p>
                </div>
              )}
            </div>
          </div>
          {/* Date Filter */}
          <DateRangePicker
            endDate={endDate}
            onEndDateChange={handleEndDateChange}
            onStartDateChange={handleStartDateChange}
            startDate={startDate}
          />
        </div>
        <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
          <div className=''>
            {/* New Table */}
            {isLoading ? (
              <TableDataSkeleton />
            ) : (
              <AdminOrderTable
                orders={currentTableData}
                isUpdateLoading={isUpdateLoading}
                updateOrder={updateOrder}
              />
            )}
          </div>
        </div>
        {/* Pagination */}
        <Pagination
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalPages={totalPages}
        />
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;

export default Orders;
