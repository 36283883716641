// Dropdown.tsx

import React, {useState} from 'react';
import {IoMdArrowDropdown} from 'react-icons/io';

interface DropdownProps {
  options: {value: string; label: string}[];
  onChange: (selectedValue: string) => void;
  placeholder?: string;
  type?: 'forLogin' | 'normal';
}

const CustomDropDown = ({
  options,
  onChange,
  placeholder,
  type,
}: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [openStatus, setOpenStatus] = useState(false);

  const handleSelectChange = (e: React.MouseEvent<HTMLParagraphElement>) => {
    const value = e.currentTarget.dataset.value;
    setSelectedValue(value || '');
    onChange(value || '');
  };

  return (
    <>
      {type === 'forLogin' ? (
        <div className='relative inline-block text-left w-full'>
          <div
            onClick={() => setOpenStatus(!openStatus)}
            className='rounded-sm shadow-sm flex flex-row items-center  justify-between gap-2 bg-[#EAECFD] text-primary cursor-pointer px-10 py-3 font-poppinsMedium w-full'>
            <p className=''>{selectedValue ? selectedValue : placeholder}</p>
            <IoMdArrowDropdown size={24} />
          </div>

          {/* Dropdown content */}
          {openStatus && (
            <div className='origin-top-right absolute right-0 mt-1 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
              <div onClick={() => setOpenStatus(false)} className='py-1'>
                {options.map((option) => (
                  <p
                    key={option.value}
                    data-value={option.value}
                    onClick={handleSelectChange}
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-primary cursor-pointer font-poppinsRegular`}>
                    {option.label}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='relative inline-block text-left'>
          <div
            onClick={() => setOpenStatus(!openStatus)}
            className='rounded-sm shadow-sm flex flex-row items-center gap-6 bg-[#EAECFD] text-primary cursor-pointer px-10 py-3 font-poppinsMedium'>
            <p className=''>{selectedValue ? selectedValue : placeholder}</p>
            <IoMdArrowDropdown size={24} />
          </div>

          {/* Dropdown content */}
          {openStatus && (
            <div className='origin-top-right absolute right-0 mt-1 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
              <div onClick={() => setOpenStatus(false)} className='py-1'>
                {options.map((option) => (
                  <p
                    key={option.value}
                    data-value={option.value}
                    onClick={handleSelectChange}
                    className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-primary cursor-pointer font-poppinsRegular`}>
                    {option.label}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomDropDown;
