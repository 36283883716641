/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useContext, useState} from 'react';
import {ChatContext} from '../context/chat';
import Actions from '../actions';
import {toast} from 'react-toastify';

const useFetchLastMessage = (chat) => {
  const [latestMessage, setLatestMessage] = useState(null);
  const {newMessage, notifications} = useContext(ChatContext);

  useEffect(() => {
    const getMessages = async () => {
      try {
        const response = await Actions.findChatMessages(chat?._id);
        // console.log(chat._id, 'chat_id');
        // console.log(chat, 'chat');
        if (response?.status === 'success') {
          // console.log('ChatMessage', response);
          const lastMessage =
            response?.data?.details[response?.data?.details?.length - 1];
          setLatestMessage(lastMessage);
        } else if (
          (response?.status === 'failed' &&
            response?.message === 'Invalid token or token expired') ||
          response?.message === 'Authorization denied! Token expired'
        ) {
          localStorage.clear();
          window.location.reload();
        } else if (
          response?.status === 'failed' &&
          response?.message === 'ERR no such key'
        ) {
          console.log(response?.message);
        } else {
          toast.error(response?.message || 'Connection Error');
        }
      } catch (error) {}
    };
    getMessages();
  }, [newMessage, notifications]);

  return latestMessage;
};

export default useFetchLastMessage;
