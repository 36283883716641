import React, {useEffect, useRef, useState} from 'react';
import {regexValidation} from '../../../../components/Helpers/regexValidation';
import {useNavigate} from 'react-router-dom';
import {useVendor} from '../../../../app/hooks';
import {AuthService} from '../../../../redux/services/vendor/auth';
import {toast} from 'react-toastify';
import {axiosError, scrollToTop} from '../../../../components/Helpers';
import {Loader} from '../../../../assets/svg';
import InputFieldAuth from '../../../../components/forms/inputs/inputField/InputFieldAuth';
import Button from '../../../../components/ui/Button';
import {COLORS} from '../../../../assets/color';

const Verification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [validOtp, setValidOtp] = useState(false);
  const [state, setState] = useState({
    otp: '',
  });

  const otpRef = useRef<HTMLInputElement>(null);
  // Check Validation
  useEffect(() => {
    otpRef.current?.focus();
  }, []);

  const {otp} = state;

  // REGEX Validations
  useEffect(() => {
    const isOtpValid = regexValidation.otpValidation(state.otp);
    setValidOtp(isOtpValid);
  }, [state.otp]);

  // HandleChange
  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setState({
        ...state,
        [e.target.name]: e.target.value.replace(' ', ''),
      });
    },

    handleSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
    },
  };

  const email = useVendor().vendor.email;
  const navigate = useNavigate();

  //   Confirm Verification
  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      email,
      otp: state.otp,
    };
    try {
      const response = await AuthService.verifyEmail(data);
      if (response.data.status === 'success') {
        const res = response.data;
        // console.log(response);
        navigate('/');
        toast.success(res.data.message || 'Account Verified');
        // console.log(`Response`);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  //   Resend Otp

  const handleResendOtp = async () => {
    setIsOtpLoading(true);
    const data = {
      email,
    };
    try {
      const response = await AuthService.resendOTP(data);
      if (response.data.status === 'success') {
        const res = response.data;
        // console.log(response);
        // navigate('../signin');
        toast.success(
          res.data.message || 'Verification Code sent successfully'
        );
        scrollToTop();
        setIsOtpLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsOtpLoading(false);
    }
  };
  return (
    <div className=' h-full grid place-items-center md:my-10'>
      <div className='container mx-auto'>
        {/* Body */}
        <div className='lg:w-[75%] sm:w-[90%]  w-full sm:p-10 p-2 sm:rounded-xl sm:bg-[#FAFAFA] m-auto'>
          <div className='xl:w-[40%] md:w-[60%] w-full mx-auto'>
            <div>
              <div className=''>
                <div className='w-full pt-4 md:px-2 px-4 font-poppinsRegular'>
                  <div className='w-full h-full flex flex-row items-center justify-center'>
                    <div className='grid place-items-center sm:py-10 py-4w-full'>
                      <div className='w-full'>
                        <h3 className='text-3xl font-poppinsSemibold text-center pb-3'>
                          Account Verification
                        </h3>
                        <h4 className=' text-xs md:text-sm font-poppinsRegular text-center pb-3 text-[#777777]'>
                          Enter the otp to validate verification
                        </h4>
                        <form className='w-full'>
                          <InputFieldAuth
                            type='text'
                            label='Email Address'
                            placeholder='Enter OTP'
                            name='otp'
                            onChange={(e) => r.onChange(e)}
                            value={otp}
                          />

                          <Button
                            label={
                              isLoading ? (
                                <div>
                                  <Loader color={'#fff'} />
                                </div>
                              ) : (
                                'Verify OTP'
                              )
                            }
                            width='full'
                            onClick={handleSubmit}
                            disabled={!validOtp ? true : false}
                          />
                          <div className='flex flex-row justify-between items-center'>
                            <p>Don't received otp yet? </p>
                            <Button
                              bgColor='#F3EFEC'
                              textCOlor={COLORS.primary}
                              borderCOlor='#F3EFEC'
                              label={
                                isOtpLoading ? (
                                  <div>
                                    <Loader color={'#fff'} />
                                  </div>
                                ) : (
                                  'Resend OTP'
                                )
                              }
                              width='small'
                              onClick={handleResendOtp}
                            />
                          </div>
                          <div className='py-4'></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
