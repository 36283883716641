import {
  fetchUserChats,
  createChat,
  sendChatMessage,
  findChatMessages,
  addNotification,
  findNotifications,
  updateNotification,
  // addMessage
} from './chat';
import {
  fetchRecipientUser,
  fetchAllUsers,
  fetchAllBuyerUsers,
  fetchAllSupportUsers,
} from './user';

const actions = {
  // details,
  fetchUserChats,
  fetchRecipientUser,
  fetchAllUsers,
  fetchAllBuyerUsers,
  fetchAllSupportUsers,
  // recipient,
  // users,
  createChat,
  sendChatMessage,
  findChatMessages,
  findNotifications,
  addNotification,
  updateNotification,
  // addMessage
};

export default actions;
