import {numberFormat} from '../Helpers';

export interface IOverviewCardProps {
  title: string;
  value?: any;
  amount?: number;
  icon: React.ReactNode;
}
interface IOverviewCard {
  datasets: IOverviewCardProps[];
  type?: 'Admin' | 'Vendor';
}

const OverviewCard = ({datasets, type}: IOverviewCard) => {
  return (
    <>
      {type === 'Vendor' ? (
        <>
          {datasets.map((item, index) => (
            <div
              key={index}
              className='bg-white rounded-lg 2xl:w-[200px] sm:w-[190px] w-40 h-40 sm:h-[197px] p-4 flex flex-col items-start gap-10 shadow-md'>
              {/* Image */}
              <div className='p-1 bg-[#EEEFFF] rounded-xl flex flex-row justify-center items-center text-primary'>
                {item.icon}
              </div>
              <div className='flex flex-col gap-4'>
                <p className='text-gray-400 text-xs font-poppinsMedium'>
                  {item.title}
                </p>
                {item.amount && (
                  <p className='text-black font-poppinsSemibold'>
                    ₦{numberFormat(item.amount)}
                  </p>
                )}
                {item.value && (
                  <p className='text-black font-poppinsSemibold'>
                    {numberFormat(item.value)}
                  </p>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {datasets.map((item, index) => (
            <div
              key={index}
              className='bg-white rounded-lg 2xl:w-[300px] lg:w-[200px] sm:w-[300px]  w-[250px] py-4 p-6 flex flex-col items-start gap-6 shadow-md'>
              {/* Image */}
              <div className='p-2 bg-primary rounded-xl flex flex-row justify-center items-center text-white'>
                {item.icon}
              </div>
              <div className='flex flex-col gap-2'>
                <p className='text-gray-400 text-xs font-poppinsMedium'>
                  {item.title}
                </p>
                {item.amount && (
                  <p className='text-black font-poppinsSemibold'>
                    ₦{numberFormat(item.amount)}
                  </p>
                )}
                {item.value && (
                  <p className='text-black font-poppinsSemibold'>
                    {numberFormat(item.value)}
                  </p>
                )}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default OverviewCard;
