import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {IAdminVendorReducer} from '../../../types';

const initialState: IAdminVendorReducer = {
  vendor: localStorage.getItem('adminVendor')
    ? JSON.parse(localStorage.getItem('adminVendor') || '{}')
    : null,
};

const adminVendorReducer = createSlice({
  name: 'adminVendor',
  initialState,
  reducers: {
    setSingleAdminVendor: (state, action) => {
      state.vendor = action.payload;
      localStorage.setItem('adminVendor', JSON.stringify(action.payload));
    },
  },
});

export const {setSingleAdminVendor} = adminVendorReducer.actions;
export const selectAdminVendor = (state: RootState) => state.adminVendor.vendor;

export default adminVendorReducer.reducer;
