import {Outlet, useNavigate} from 'react-router-dom';
import Sidebar from './Sidebar';
import {toast} from 'react-toastify';
import {vendorScreens} from '../../pages/dashboard/sidebar.interface';
import MobileMenu from './MobileMenu';
import ChatWidget from '../dashboard/chatwidget/ChatWidget';
import {useEffect, useState} from 'react';

const VendorHomeLayout = () => {
  // Logout Handler
  const navigate = useNavigate();

  const logoutHandler = () => {
    //  dispatch(vendorLogout());
    localStorage.removeItem('vendorInfo');
    toast.success('Logout successfully');
    navigate('/');
  };

  // HandleScrolling
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isTop = currentScrollPos < 10; // Change 100 to your desired scroll position
      setIsScrolled(!isTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // const isMobile = window.matchMedia(
  //   'only screen and (max-width: 760px)'
  // ).matches;
  // if (isMobile) {
  //   return (
  //     <div className='text-lg text-center font-poppinsRegular bg-primary text-white flex justify-center items-center p-8 h-screen'>
  //       Sorry, this app is only accessible by tablet, TV or desktop users.
  //       Kindly use the supported devices.
  //     </div>
  //   );
  // }
  return (
    <div className='w-full bg-blue-50 sm:h-screen h-full border-2'>
      {/* Mobile Menu */}
      <div
        className={`sm:hidden block border-t top-0 z-50 transition-all duration-300 sticky ${
          isScrolled ? 'shadow-md ' : ''
        }`}>
        <MobileMenu logoutHandler={logoutHandler} />
      </div>
      <div className='container-fluid flex h-full'>
        {/* Section Left */}
        <div className=''>
          <Sidebar appScreens={vendorScreens} logoutHandler={logoutHandler} />
        </div>

        {/* Right section for Content Holder */}
        <div className='w-full overflow-auto pt-3 h-full flex-1 lg:pl-10 relative'>
          <Outlet />
          {/* Widget */}
          <div className='absolute bottom-5 md:right-5  2xl:z-0 z-10 sm:block hidden'>
            <ChatWidget />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorHomeLayout;
