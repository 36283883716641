import React, {useState} from 'react';

interface PaginationProps {
  currentPage: number;
  handlePageChange: (page: number) => void;
  totalPages: number;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  handlePageChange,
  totalPages,
}) => {
  const [inputPage, setInputPage] = useState('');

  const goToPage = () => {
    const pageNumber = parseInt(inputPage, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      handlePageChange(pageNumber);
    }
    setInputPage('');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      goToPage();
    }
  };

  const pagesArray = Array.from(
    {length: Math.min(totalPages, 3)},
    (_, index) => index + 1
  );

  return (
    <div className='w-full h-3 flex items-center flex-row justify-end py-10 px-4 '>
      <div>
        <div className='h-full flex flex-row items-center gap-2'>
          <div
            className={`flex items-center  hover:text-${
              currentPage > 1 ? 'amber' : 'primary'
            }-700 cursor-pointer ${
              currentPage === 1
                ? 'pointer-events-none opacity-50 text-gray-600'
                : 'text-primary'
            }`}
            onClick={() => handlePageChange(currentPage - 1)}>
            <svg
              width='14'
              height='8'
              viewBox='0 0 14 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.1665 4H12.8332'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M1.1665 4L4.49984 7.33333'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M1.1665 4.00002L4.49984 0.666687'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            <p className='text-sm ml-3 font-medium leading-none'>Previous</p>
          </div>
          <div className='flex items-center flex-row gap-2 '>
            {pagesArray.map((page) => (
              <p
                key={page}
                className={`text-sm font-medium leading-none cursor-pointer ${
                  page === currentPage
                    ? 'text-primary border border-primary py-1 px-2'
                    : 'text-gray-600 hover:text-primary border-t border-transparent hover:border-primary py-1 px-2'
                }`}
                onClick={() => handlePageChange(page)}>
                {page}
              </p>
            ))}
            {totalPages > 3 && (
              <p className='text-sm font-medium leading-none cursor-pointer'>
                ...
              </p>
            )}
          </div>
          <div className='flex items-center ml-3 '>
            <input
              type='text'
              className='bg-gray-50 text-gray-800 p-1 w-10 rounded-md border border-gray-300 focus:outline-none text-xs text-center'
              placeholder={`${currentPage}`}
              value={inputPage}
              onChange={(e) => setInputPage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div
            className={`flex items-center  text-${
              currentPage < totalPages ? 'text-gray-600' : 'primary'
            }-700 hover:text-${
              currentPage < totalPages ? 'amber' : 'primary'
            }-700 cursor-pointer ${
              currentPage === totalPages
                ? 'pointer-events-none  text-gray-600 opacity-50'
                : 'text-primary'
            }`}
            onClick={() => handlePageChange(currentPage + 1)}>
            <p className='text-sm font-medium leading-none mr-3'>Next</p>
            <svg
              width='14'
              height='8'
              viewBox='0 0 14 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M1.1665 4H12.8332'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M9.5 7.33333L12.8333 4'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M9.5 0.666687L12.8333 4.00002'
                stroke='currentColor'
                strokeWidth='1.25'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
