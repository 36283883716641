import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../app/store';
import {vendorInfo} from '../../types';

const initialState: vendorInfo = {
  vendor: localStorage.getItem('vendorInfo')
    ? JSON.parse(localStorage.getItem('vendorInfo') || '{}')
    : null,
};

const vendorReducer = createSlice({
  name: 'vendor',
  initialState,
  reducers: {
    setVendorCredentials: (state, action) => {
      state.vendor = action.payload;
      localStorage.setItem('vendorInfo', JSON.stringify(action.payload));
    },
  },
});

export const {setVendorCredentials} = vendorReducer.actions;
export const selectVendor = (state: RootState) => state.vendor.vendor?.vendor;
export const selectVendorToken = (state: RootState) =>
  state.vendor.vendor?.token;

export default vendorReducer.reducer;
