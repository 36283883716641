/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs';
import {RxDotFilled} from 'react-icons/rx';

export interface ISlider {
  slideImage?: any;
}
const Slider = ({slideImage}: ISlider) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slideImage.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slideImage.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex: any) => {
    setCurrentIndex(slideIndex);
  };

  // Auto slide change every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex]); // Run when currentIndex changes

  return (
    <div className='w-full h-full relative group cursor-pointer'>
      {slideImage?.map((slide: any, index: number) => (
        <div
          key={index}
          style={{
            backgroundImage: `url(${slide})`,
            opacity: index === currentIndex ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out', // CSS transition
          }}
          className='w-full h-full rounded-md bg-contain duration-500 bg-no-repeat absolute top-0 left-0 object-center '></div>
      ))}
      {/* Left Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className='hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className='flex w-full justify-center '>
        <div className='flex bottom-0 justify-center py-2 absolute'>
          {slideImage.map((_slide: any, slideIndex: any) => (
            <div
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
              className='text-2xl cursor-pointer'>
              <RxDotFilled
                className={`${
                  slideIndex === currentIndex ? 'text-primary' : 'text-gray-300'
                }`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Slider;
