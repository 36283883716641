import {extractParentheses} from '../../Helpers';

export type inputProps = {
  label: string;
  options?: any;
  icon?: string;
  handleChange?: any;
  valueField?: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
};

const SelectForMeasurement = ({
  label,
  icon,
  options,
  handleChange,
  valueField,
  name,
  placeholder,
  required,
}: inputProps) => {
  return (
    <div className='flex flex-col items-start'>
      {required ? (
        <span className='text-gray-400 font-poppinsMedium font-medium pb-2'>
          {label} <span className='text-red-500 text-base'>*</span>
        </span>
      ) : (
        <span className='text-gray-400 font-poppinsMedium font-medium pb-2'>
          {label}
        </span>
      )}
      <div className='w-full rounded-md h-[2.5rem] border-[2px] border-gray-400'>
        <select
          value={valueField}
          name={name}
          required={required}
          className='outline-none w-full h-full bg-transparent cursor-pointer px-3 text-gray-400 font-poppinsRegular font-medium'
          onChange={(e) => handleChange(e)}>
          <option className='text-gray-400 font-light text-xs'>
            {placeholder || 'Select'}
          </option>
          {options?.map((opt: any, index: any) => (
            <option
              className='text-gray-400 font-poppinsRegular font-medium'
              key={index}
              value={extractParentheses(opt.name)}>
              {opt.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default SelectForMeasurement;
