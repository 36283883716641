import Loader from './loader';
import {toast} from 'react-toastify';
// import NavigateAfterLogout from './NavigateAfterLogout';

const axiosError = (error: any) => {
  // console.log(`Error1`, error);
  if (error?.status === 500 && error?.data.message !== 'ERR no such key') {
    toast.error(
      error?.statusText ||
        'Internal Server Error... Check your internet connection'
    );
  } else if (
    (error?.data.status === 'failed' &&
      error?.data.message === 'Invalid token or token expired') ||
    error?.data.message === 'Authorization denied! Token expired'
  ) {
    localStorage.clear();
    window.location.reload();
  } else if (
    error?.data.status === 'failed' &&
    error?.data.message === 'ERR no such key'
  ) {
    console.log(error?.data.message);
  } else {
    toast.error(error?.data.message || 'Connection Error');
  }
  return error;
};

const calculateTotalForCurrentMonth = (settlements: any) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  return settlements
    .filter((settlement: any) => {
      const date = new Date(settlement.createdAt);
      return (
        date.getMonth() === currentMonth && date.getFullYear() === currentYear
      );
    })
    .reduce((sum: any, settlement: any) => sum + Number(settlement.amount), 0);
};

const extractParentheses = (str: any): string => {
  const regex = /\(([^)]+)\)/;
  const match = str.match(regex);
  return match ? `(${match[1]})` : '';
};
const removeCurlyBrackets = (str: string): string => {
  return str.replace(/[{}]/g, '');
};
const removeBracketsAndContent = (str: string): string => {
  return str.replace(/\(.*?\)/g, '');
};
// Currency Converter
const numberFormat = (value: any) => new Intl.NumberFormat().format(value);

// Scroll To Top
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export {
  Loader,
  axiosError,
  numberFormat,
  calculateTotalForCurrentMonth,
  extractParentheses,
  removeCurlyBrackets,
  removeBracketsAndContent,
  scrollToTop,
};

//  else if (error?.status === 401) {
//     toast.error(
//       error?.statusText ||
//         'Internal Server Error... Check your internet connection'
//     );
//   }
