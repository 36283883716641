import {createSlice} from '@reduxjs/toolkit';
import {IBuyer} from '../../../components/interfaces/vendors';
import {RootState} from '../../../app/store';

type IBuyerReducer = {
  buyers: IBuyer[];
};
const initialState: IBuyerReducer = {
  buyers: localStorage.getItem('chatBuyers')
    ? JSON.parse(localStorage.getItem('chatBuyers') || '[]')
    : [],
};

const chatBuyerReducer = createSlice({
  name: 'buyers',
  initialState,
  reducers: {
    setChatBuyers: (state, action) => {
      state.buyers = action.payload;
      localStorage.setItem('chatBuyers', JSON.stringify(action.payload));
    },
  },
});

export const {setChatBuyers} = chatBuyerReducer.actions;
export const selectChatBuyer = (state: RootState) => state.buyers.buyers;

export default chatBuyerReducer.reducer;
