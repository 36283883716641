/* eslint-disable react-hooks/exhaustive-deps */
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useVendor} from '../../../app/hooks';
import {ChevronDown, PencilSm} from '../../../assets/images';
import Input from '../../../components/dashboard/profile/input';
import Button from '../../../components/dashboard/profile/button';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {axiosError, extractParentheses} from '../../../components/Helpers';
import {toast} from 'react-toastify';
import {IProduct} from '../../../components/interfaces/products';
import {useNavigate, useParams} from 'react-router-dom';
import {ArrowLeft} from 'iconsax-react';
import {motion} from 'framer-motion';
import {Loader} from '../../../assets/svg';
import {COLORS} from '../../../assets/color';
import ProfileSkeleton from '../../../components/ui/skeletonUI/ProfileSkeleton';
import Slider from '../../../components/ui/Slider';
import Select from '../../../components/dashboard/profile/select';
import SelectForMeasurement from '../../../components/dashboard/profile/SelectForMeasurement';

interface IFormData {
  name?: string;
  description?: string;
  price?: string;
  quantity?: string;
  discount?: string;
  location?: string;
  warranty?: string;
  superCategory?: string;
  category?: string;
  subCategory?: string;
  productImg?: File | null;
  brand?: string;
  minimumStock?: number;
  additionalImg: FileList | null;
  dimension?: string;
  volume?: string;
  weight?: string;
  count?: string;
  commission?: string;
  color?: string;
  size?: string;
  shipFromAbroad: boolean;
}

const EditProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState<string[]>([]);
  const token = useVendor().token;
  const [products, setProducts] = useState<IProduct[]>([]);
  const navigate = useNavigate();

  // console.log(product);

  const [formData, setFormData] = useState<IFormData>({
    name: '',
    description: '',
    subCategory: '',
    productImg: null,
    quantity: '',
    warranty: '',
    discount: '',
    superCategory: '',
    category: '',
    price: '',
    location: '',
    brand: '',
    minimumStock: 0,
    additionalImg: null,
    dimension: '',
    volume: '',
    weight: '',
    count: '',
    commission: '',
    color: '',
    size: '',
    shipFromAbroad: false,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value, type, checked} = event.target;
    if (name === 'price' || name === 'quantity' || name === 'minimumStock') {
      const numericValueNUmber = value.replace(/\D/g, ''); // Remove non-numeric characters
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValueNUmber,
      }));
      return;
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const {productId} = useParams();
  const product = products.find((prod) => String(prod.id) === productId);
  // console.log('image', image);
  useEffect(() => {
    setFormData((p: any) => ({
      ...p,
      additionalImg: product?.additionalImg,
      brand: product?.brand,
      superCategory: product?.superCategory,
      category: product?.category,
      description: product?.description,
      discount: product?.discount,
      id: product?.id,
      location: product?.location,
      name: product?.name,
      price: product?.price,
      productImg: product?.productImg,
      quantity: product?.quantity,
      subCategory: product?.subCategory,
      minimumStock: product?.minimumStock,
      warranty: product?.warranty,
      dimension: product?.dimension,
      volume: product?.volume,
      weight: product?.weight,
      count: product?.count,
      commission: product?.commission,
      color: product?.color,
      size: product?.size,
      shipFromAbroad: product?.shipFromAbroad,
    }));
  }, [
    product?.additionalImg,
    product?.brand,
    product?.superCategory,
    product?.category,
    product?.description,
    product?.discount,
    product?.location,
    product?.name,
    product?.price,
    product?.productImg,
    product?.quantity,
    product?.subCategory,
    product?.id,
    product?.minimumStock,
    product?.warranty,
    product?.dimension,
    product?.volume,
    product?.weight,
    product?.count,
    product?.commission,
    product?.color,
    product?.size,
    product?.shipFromAbroad,
  ]);

  // Get Product
  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setIsLoading(true);
    const getProducts = localStorage.getItem('#prds');
    if (getProducts) {
      const products = JSON.parse(getProducts);
      setProducts(products);
      setIsLoading(false);
    }
  };

  //  Handle Multi Image Upload
  const handleMultiImageUpload = (event: any) => {
    const files = event.target.files;
    setFormData((prevFormData) => ({
      ...prevFormData,
      additionalImg: files,
    }));
    // console.log(formData.images);

    if (files) {
      // Convert FileList to an array
      const selectedFiles = Array.from(files).slice(0, 3);

      // Validate that all files are images and less than 1MB
      const validFiles = selectedFiles.filter((file: any) => {
        if (!file.type.startsWith('image/')) {
          toast.error('Only image files are allowed.');
          return false;
        }
        if (file.size > 1 * 1024 * 1024) {
          toast.error('File size should be less than 1MB.');
          return false;
        }
        return true;
      });

      if (validFiles.length > 0) {
        // Generate preview URLs for valid files
        const urls = validFiles.map((file: any) => URL.createObjectURL(file));
        setPreviewImages(urls);
        toast.error(null);
      } else {
        setPreviewImages([]);
      }
    }
  };

  const product_id = product?.id;
  const businessName = useVendor().vendor.businessName;
  // Submit Additional Images to Cloud

  const onEditProduct = async () => {
    setIsUpdateLoading(true);
    if (previewImages.length > 0) {
      const images = formData.additionalImg;
      if (!images) {
        console.error('No images selected for upload.');
        return;
      }

      const data = new FormData();
      for (let i = 0; i < images.length; i++) {
        data.append(`images`, images[i]);
      }

      try {
        // Run if preview image available

        const imageRes = await VendorServices.multiProductImageUpload(
          token,
          data,
          businessName
        );
        if (imageRes.data.status === 'success') {
          // console.log('Images', imageRes);
          console.log('Image URLs:', imageRes.data.data.urls);
          // toast.success(imageRes.data.data.message);
          setIsUpdateLoading(false);
        } else {
          toast.error(imageRes.data.data.message);
          setIsUpdateLoading(false);
        }

        // Edit Products
        const productResData = {
          brand: formData?.brand,
          superCategory: formData?.superCategory,
          category: formData?.category,
          description: formData?.description,
          discount: formData?.discount,
          location: formData?.location,
          name: formData?.name,
          price: formData?.price,
          productImg: formData?.productImg,
          quantity: formData?.quantity,
          subCategory: formData?.subCategory,
          minimumStock: formData?.minimumStock,
          warranty: formData?.warranty,
          additionalImg: imageRes.data.data.urls,
          dimension: realDimension,
          volume: realVolume,
          weight: realWeight,
          count: realCount,
          commission: Number(formData?.commission),
          color: formData?.color,
          size: formData?.size,
          shipFromAbroad: formData?.shipFromAbroad,
        };
        try {
          const response = await VendorServices.updateProduct(
            token,
            productResData,
            product_id
          );
          // console.log(response);
          if (response.data.status === 'success') {
            toast.success(response.data.data.message);
            navigate(-1);
            setIsUpdateLoading(false);
            // console.log('profile', response.data.data);
          }
        } catch (error: any) {
          axiosError(error.response);
          // console.log(error);
          setIsUpdateLoading(false);
        }
      } catch (error: any) {
        console.log(error);
        axiosError(error.response);
        setIsUpdateLoading(false);
      }
    } else {
      // Edit Products
      const productResData = {
        brand: formData?.brand,
        superCategory: formData?.superCategory,
        category: formData?.category,
        description: formData?.description,
        discount: formData?.discount,
        location: formData?.location,
        name: formData?.name,
        price: formData?.price,
        productImg: formData?.productImg,
        quantity: formData?.quantity,
        subCategory: formData?.subCategory,
        minimumStock: formData?.minimumStock,
        warranty: formData?.warranty,
        dimension: realDimension,
        volume: realVolume,
        weight: realWeight,
        count: realCount,
        commission: Number(formData?.commission),
        color: formData?.color,
        size: formData?.size,
        shipFromAbroad: formData?.shipFromAbroad,
      };
      try {
        const response = await VendorServices.updateProduct(
          token,
          productResData,
          product_id
        );
        // console.log(response);
        if (response.data.status === 'success') {
          toast.success(response.data.data.message);
          navigate(-1);
          setIsUpdateLoading(false);
          // console.log('profile', response.data.data);
        }
      } catch (error: any) {
        axiosError(error.response);
        // console.log(error);
        setIsUpdateLoading(false);
      }
    }
  };
  const images = product?.additionalImg;

  const [superCategoryOption, setSuperCategoryOption] = useState<any[]>([]);
  const [categoryOption, setCategoryOption] = useState<any[]>([]);
  const [subCategoryOption, setSubCategoryOption] = useState<any[]>([]);
  const [locationOption, setLocationOption] = useState<any[]>([]);

  useEffect(() => {
    const selectedSuperCategory = superCategoryOption.find(
      (c) => c.name === formData.superCategory
    );

    if (selectedSuperCategory) {
      setCategoryOption(selectedSuperCategory.categories);
    }
  }, [formData.superCategory, superCategoryOption]);

  useEffect(() => {
    const selectedCategory = categoryOption.find(
      (c) => c.name === formData.category
    );

    if (selectedCategory) {
      setSubCategoryOption(selectedCategory.subCategories);
    }
  }, [formData.category, categoryOption]);

  // Handle Category
  const handleSuperCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const superCategory = event.target.value;
    const selectedSuperCategory = superCategoryOption.find(
      (c) => c.name === superCategory
    );

    if (selectedSuperCategory) {
      setCategoryOption(selectedSuperCategory.categories);
      setFormData((prevData) => ({
        ...prevData,
        superCategory,
        category: '',
        subCategory: '',
      }));
    } else {
      setCategoryOption([]);
      setSubCategoryOption([]);
      setFormData((prevData) => ({
        ...prevData,
        supperCategory: '',
        category: '',
        subCategory: '',
      }));
    }
  };
  // Handle Category
  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const category = event.target.value;
    const selectedCategory = categoryOption.find((c) => c.name === category);
    if (selectedCategory) {
      setFormData((prevData) => ({
        ...prevData,
        commission: selectedCategory.commission,
      }));
    }
    if (selectedCategory) {
      setSubCategoryOption(selectedCategory.subCategories);
      setFormData((prevData) => ({...prevData, category, subCategory: ''}));
    } else {
      setSubCategoryOption([]);
      setFormData((prevData) => ({
        ...prevData,
        category: '',
        subCategory: '',
      }));
    }
  };

  // Handle Sub Category
  const handleSubCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({...prevFormData, subCategory: value}));
  };
  const handleLocationChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    setFormData((prevFormData) => ({...prevFormData, location: value}));
  };

  // Get Category
  useEffect(() => {
    const getSuperCategory = async () => {
      try {
        const response = await VendorServices.getSuperCategory();
        // console.log(response);
        if (response.data.status === 'success') {
          const res = response.data.data.details;
          setSuperCategoryOption(res.rows);
        }
      } catch (error: any) {
        // console.log(error);
        axiosError(error.response);
        // setIsLoading(false);
      }
    };
    getSuperCategory();
  }, []);

  // Get Location
  useEffect(() => {
    const getLocation = async () => {
      try {
        const response = await VendorServices.getLocation();
        // console.log('Location', response);
        if (response.data.status === 'success') {
          const res = response.data.data.details;
          setLocationOption(res.rows);
        }
      } catch (error: any) {
        // console.log(error);
        axiosError(error.response);
        // setIsLoading(false);
      }
    };
    getLocation();
  }, []);

  const [dimensionOption, setDimensionOption] = useState<any[]>([]);
  const [volumeOption, setVolumeOption] = useState<any[]>([]);
  const [weightOption, setWeightOption] = useState<any[]>([]);
  const [countOption, setCountOption] = useState<any[]>([]);

  // Get Measurement
  useEffect(() => {
    getDimension();
    getWeight();
    getVolume();
    getCount();
  }, []);

  const getVolume = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(token, 'volume');
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setVolumeOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const getWeight = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(token, 'weight');
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setWeightOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const getCount = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(token, 'count');
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setCountOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const getDimension = async () => {
    try {
      const response = await VendorServices.getAllMeasurement(
        token,
        'dimension'
      );
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.details;
        setDimensionOption(res);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      // setIsLoading(false);
    }
  };
  const [selectedCount, setSelectedCount] = useState('');
  const [selectedVolume, setSelectedVolume] = useState('');
  const [selectedWeight, setSelectedWeight] = useState('');
  const [selectedDimension, setSelectedDimension] = useState('');

  const handleWeightChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedWeight(event.target.value);
  };
  const handleVolumeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedVolume(event.target.value);
  };
  const handleDimensionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedDimension(event.target.value);
  };
  const handleCountChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCount(event.target.value);
  };

  useEffect(() => {
    if (product?.dimension) {
      // Extract the unit from product.count
      const unit = extractParentheses(product?.dimension);
      setSelectedDimension(unit);
    } else {
      // If product.count is null, you can set a default value or leave it empty
      setSelectedDimension('');
    }
  }, [product]);
  useEffect(() => {
    if (product?.volume) {
      // Extract the unit from product.count
      const unit = extractParentheses(product?.volume);
      setSelectedVolume(unit);
    } else {
      // If product.count is null, you can set a default value or leave it empty
      setSelectedVolume('');
    }
  }, [product]);
  useEffect(() => {
    if (product?.weight) {
      // Extract the unit from product.count
      const unit = extractParentheses(product?.weight);
      setSelectedWeight(unit);
    } else {
      // If product.count is null, you can set a default value or leave it empty
      setSelectedWeight('');
    }
  }, [product]);
  useEffect(() => {
    if (product?.count) {
      // Extract the unit from product.count
      const unit = extractParentheses(product?.count);
      setSelectedCount(unit);
    } else {
      // If product.count is null, you can set a default value or leave it empty
      setSelectedCount('');
    }
  }, [product]);

  // const allDimension = removeCurlyBrackets(JSON.stringify(dimensionFormData));

  const [dimensionUnit, setDimensionUnit] = useState('');
  const [volumeUnit, setVolumeUnit] = useState('');
  const [weightUnit, setWeightUnit] = useState('');
  const [countUnit, setCountUnit] = useState('');

  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(selectedDimension);
    setDimensionUnit(extractedUnit);
  }, [selectedDimension]);

  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(selectedVolume);
    setVolumeUnit(extractedUnit);
  }, [selectedVolume]);
  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(selectedWeight);
    setWeightUnit(extractedUnit);
  }, [selectedWeight]);
  useEffect(() => {
    // Use the helper function to extract the substring
    const extractedUnit = extractParentheses(selectedCount);
    setCountUnit(extractedUnit);
  }, [selectedCount]);

  const realDimension = `${formData.dimension} ${dimensionUnit}`;
  const realVolume = `${formData.volume} ${volumeUnit}`;
  const realWeight = `${formData.weight} ${weightUnit}`;
  const realCount = `${formData.count} ${countUnit}`;
  // alert(formData.category);
  return (
    <div className='pt-6'>
      {/* Content */}
      <div className='bg-white xl:w-[90%] md:w-[95%]  w-full m-auto py-4 px-2'>
        <motion.div
          whileHover={{scale: 1.1}}
          className='w-24 text-center cursor-pointer'
          onClick={() => navigate(-1)}>
          <ArrowLeft size={28} variant='Linear' />
        </motion.div>
        <div className='flex flex-row items-center  px-16 pb-4 justify-center'>
          {/* Image */}

          {/* Additional Images */}
          <div className='relative'>
            {previewImages.length === 0 && (
              <div className='flex flex-row items-center gap-4'>
                {images && (
                  <div className='w-[400px] h-[400px] text-center object-cover'>
                    <Slider slideImage={images} />
                  </div>
                )}
              </div>
            )}

            {previewImages.length > 0 && (
              <div className='flex flex-row items-center gap-4'>
                <div className='w-[400px] h-[400px] text-center object-cover'>
                  <Slider slideImage={previewImages} />
                </div>
              </div>
            )}

            <div
              className='bg-primary py-2 text-white rounded cursor-pointer text-center px-8 relative mt-4'
              onClick={handleMultiImageUpload}>
              <p>Pick Images</p>
              <input
                type='file'
                accept='image/*'
                multiple
                style={{opacity: 0}}
                onChange={handleMultiImageUpload}
                className='absolute z-10 right-[0%] bottom-0 cursor-pointer w-full h-full border-2'
                title='Upload'
              />
            </div>
          </div>
        </div>
        <div className='w-full flex flow-row justify-center'>
          <span className='font-bold  text-base text-primary'>
            PRODUCT DETAILS
          </span>
        </div>
        {isLoading ? (
          <ProfileSkeleton />
        ) : (
          <>
            <div className='mt-[2rem] flex justify-center'>
              <label>
                <input
                  type='checkbox'
                  checked={formData.shipFromAbroad}
                  onChange={handleInputChange}
                  name='shipFromAbroad'
                />
                <span className='pl-2'>
                  Tick only if you want to ship the product from abroad
                </span>
              </label>
            </div>
            <div className='grid lg:grid-cols-2 grid-cols-1 place-items-baseline justify-items-center w-[80%] m-auto space-y-[2rem] gap-6'>
              <div className='w-full'>
                <Select
                  label='SUPER CATEGORY'
                  icon={ChevronDown}
                  name='superCategory'
                  handleChange={handleSuperCategoryChange}
                  options={superCategoryOption}
                  valueField={formData.superCategory || ''}
                />
              </div>
              <div className='w-full'>
                <Select
                  label='CATEGORY'
                  icon={ChevronDown}
                  name='category'
                  handleChange={handleCategoryChange}
                  options={categoryOption}
                  valueField={formData.category || ''}
                />
              </div>
              <div className='w-full'>
                <Select
                  label='SUB CATEGORY'
                  icon={ChevronDown}
                  handleChange={handleSubCategoryChange}
                  options={subCategoryOption}
                  name='subCategory'
                  valueField={formData.subCategory || ''}
                />
              </div>
              <div className='w-full'>
                <Input
                  label='NUMBER IN STOCK'
                  icon={PencilSm}
                  pattern=''
                  handleChange={(e: any) => handleInputChange(e)}
                  name='quantity'
                  valueField={formData.quantity || ''}
                />
              </div>

              <div className='w-full'>
                <Input
                  label='PRICE'
                  icon={PencilSm}
                  name='price'
                  handleChange={handleInputChange}
                  valueField={formData.price || ''}
                />
              </div>
              <div className='w-full'>
                <Input
                  label='PRODUCT NAME'
                  icon={PencilSm}
                  pattern=''
                  handleChange={handleInputChange}
                  name='name'
                  valueField={formData.name || ''}
                />
              </div>
              <div className='w-full'>
                <Input
                  label='BRAND NAME'
                  icon={PencilSm}
                  pattern=''
                  handleChange={handleInputChange}
                  name='brand'
                  valueField={formData.brand || ''}
                />
              </div>
              <div className='w-full'>
                <Input
                  label='MINIMUM STOCK'
                  icon={PencilSm}
                  pattern=''
                  name='minimumStock'
                  handleChange={handleInputChange}
                  valueField={formData.minimumStock || ''}
                />
              </div>
              <div className='w-full'>
                <Input
                  label={`DISCOUNT %`}
                  icon={PencilSm}
                  pattern=''
                  handleChange={handleInputChange}
                  name='discount'
                  valueField={formData.discount || ''}
                />
              </div>
              <div className='w-full'>
                <Select
                  label='LOCATION'
                  icon={ChevronDown}
                  handleChange={handleLocationChange}
                  options={locationOption}
                  name='Location'
                  valueField={formData.location}
                />
              </div>
              {/* Warranty */}
              <div className='w-full'>
                <Input
                  label='WARRANTY '
                  icon={PencilSm}
                  pattern=''
                  name='warranty'
                  handleChange={handleInputChange}
                  valueField={formData.warranty || ''}
                />
              </div>
              <div className='w-full flex flex-col items-start'>
                <span className='text-[#9A9AAA] font-medium pb-2'>
                  PRODUCT DESCRIPTION
                  <span className='text-red-500 text-base pl-1'>*</span>
                </span>
                <div className='w-full rounded-md border-[2px] border-gray-400 px-3'>
                  <textarea
                    className='outline-none w-full h-full p-3 font-light text-xs'
                    name='description'
                    value={formData.description}
                    placeholder=''
                    cols={10}
                    rows={4}
                    style={{
                      height: '100%',
                      fontFamily: 'montserrat',
                    }}
                    onChange={(e: any) => handleInputChange(e)}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <div className='flex flex-col w-[80%] m-auto gap-6'>
          <div className='flex lg:flex-row flex-col gap-1 items-center py-6'>
            <h2 className='font-poppinsSemibold text-black '>Color and Size</h2>
          </div>
          <div className='flex flex-col w-full gap-6 pb-4'>
            <div className='w-full flex lg:flex-row flex-col justify-between items-center gap-10'>
              <div className='w-full'>
                <Input
                  label={`Color`}
                  icon={PencilSm}
                  pattern=''
                  handleChange={handleInputChange}
                  name='color'
                  valueField={formData.color || ''}
                  placeholder=''
                />
              </div>
              <div className='w-full'>
                <Input
                  label={`Size`}
                  icon={PencilSm}
                  pattern=''
                  handleChange={handleInputChange}
                  name='size'
                  valueField={formData.size || ''}
                  placeholder=''
                />
              </div>
            </div>
          </div>
          <div className='flex lg:flex-row flex-col gap-1 items-center pb-6'>
            <h2 className='font-poppinsSemibold text-black '>
              Unit of Measurement
            </h2>
          </div>
          <div className='w-full flex lg:flex-row flex-col justify-between lg:items-end items-center gap-10'>
            {/* large Width */}
            <div className='w-full'>
              <SelectForMeasurement
                label='DIMENSION'
                icon={ChevronDown}
                handleChange={handleDimensionChange}
                options={dimensionOption}
                name='dimension'
                valueField={selectedDimension}
                // required
              />
            </div>
            {/* small Width */}
            <div className='w-full'>
              {/* Length && Width &  ... */}
              <Input
                label={``}
                icon={PencilSm}
                pattern=''
                handleChange={handleInputChange}
                name='dimension'
                valueField={formData.dimension?.replace(/"|\(.*?\)/g, '') || ''}
                // required
                placeholder='40'
              />
            </div>
          </div>
          <div className='w-full flex lg:flex-row flex-col justify-between lg:items-end items-center gap-10'>
            {/* large Width */}
            <div className='w-full'>
              <SelectForMeasurement
                label='VOLUME'
                icon={ChevronDown}
                handleChange={handleVolumeChange}
                options={volumeOption}
                name='volume'
                valueField={selectedVolume}
                // required
              />
            </div>
            {/* small Width */}
            <div className=' w-full'>
              {/* Length && Width &  ... */}

              <Input
                label=''
                icon={PencilSm}
                pattern=''
                handleChange={handleInputChange}
                name='volume'
                valueField={formData.volume?.replace(/\(.*?\)/g, '') || ''}
                // required
                placeholder='5'
              />
            </div>
          </div>
          <div className='w-full flex lg:flex-row flex-col justify-between lg:items-end items-center gap-10'>
            {/* large Width */}
            <div className='w-full'>
              <SelectForMeasurement
                label='WEIGHT'
                icon={ChevronDown}
                handleChange={handleWeightChange}
                options={weightOption}
                name='weight'
                valueField={selectedWeight}
                // required
              />
            </div>
            {/* small Width */}
            <div className=' w-full'>
              {/* Length && Width &  ... */}

              <Input
                label=''
                icon={PencilSm}
                pattern=''
                handleChange={handleInputChange}
                name='weight'
                valueField={formData.weight?.replace(/\(.*?\)/g, '') || ''}
                // required
                placeholder='5'
              />
            </div>
          </div>
          <div className='w-full flex lg:flex-row flex-col justify-between lg:items-end items-center gap-10'>
            {/* large Width */}
            <div className='w-full'>
              <SelectForMeasurement
                label='COUNT'
                icon={ChevronDown}
                handleChange={handleCountChange}
                options={countOption}
                name='count'
                valueField={selectedCount}
                // required
              />
            </div>
            {/* small Width */}
            <div className='w-full'>
              {/* Length && Width &  ... */}

              <Input
                label=''
                icon={PencilSm}
                pattern=''
                handleChange={handleInputChange}
                name='count'
                valueField={formData.count?.replace(/\(.*?\)/g, '') || ''}
                // required
                placeholder='5'
              />
            </div>
          </div>
        </div>
        {/* Button */}
        <div className='py-6'>
          {isLoading ? (
            <div className='flex items-center gap-5 justify-center'>
              <p className='text-xl'>Fetching Profile</p>
              <Loader color={COLORS.primary} />
            </div>
          ) : (
            <div className='w-48 m-auto'>
              <Button
                type='submit'
                className='text-white font-bold h-[3.38rem] w-full'
                handleSubmit={onEditProduct}>
                {isUpdateLoading ? 'Loading...' : 'UPDATE'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
