const TableDataSkeleton = () => {
  return (
    <>
      <div className='animate-pulse pt-8'>
        <div className='w-full grid grid-cols-5 py-4'>
          <div className=' mx-2 rounded-md h-10 bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-10  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-10  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-10  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-10  bg-gray-200'></div>
        </div>
        <div className='w-full grid grid-cols-5 py-3'>
          <div className=' mx-2 rounded-md h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-5 py-3'>
          <div className=' mx-2 rounded-md h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-5 py-3'>
          <div className=' mx-2 rounded-md h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-5 py-3'>
          <div className=' mx-2 rounded-md h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
        </div>

        <div className='w-full grid grid-cols-5 py-3'>
          <div className=' mx-2 rounded-md h-6 bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
          <div className=' mx-2 rounded-md h-6  bg-gray-200'></div>
        </div>
      </div>
    </>
  );
};

export default TableDataSkeleton;
