import React, {ChangeEvent, useState} from 'react';
import styles from '../../../../assets/styles/modals/ConfirmModal.module.css';
import {DefaultImage, PencilSm} from '../../../../assets/images';
import Input from '../../../../components/dashboard/profile/input';
import {CloseCircle} from 'iconsax-react';
import {useSettlement} from '../../../../app/hooks';
import {ISettlement} from '../../../../components/interfaces/settlement';
import Slider from '../../../../components/ui/Slider';

interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading?: any;
}

const ViewSettlement = ({setIsOpen}: IModal) => {
  const settlement = useSettlement().settlement;
  //   console.log(vendor);
  const [formData, setFormData] = useState<ISettlement>({
    accountName: settlement.accountName,
    accountNo: settlement.accountNo,
    amount: settlement.amount,
    bankName: settlement.bankName,
    businessName: settlement.businessName,
    createdAt: settlement.createdAt,
    email: settlement.email,
    name: settlement.name,
    productImg: settlement.productImg,
    productName: settlement.productName,
    quantity: settlement.quantity,
    status: settlement.status,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // const isActive = String(vendor.isActive);
  //   console.log(isUpdated);
  const images: any = settlement?.productImg;
  return (
    <div>
      <div className={styles.productViewBG} onClick={() => setIsOpen(true)} />
      <div className={styles.productCentered}>
        <div className={styles.productViewModal}>
          <div className={styles.modalContent}>
            {/* Content */}
            <div className='bg-white p-6 relative'>
              <div
                className='absolute top-0 right-0 cursor-pointer'
                onClick={() => setIsOpen(false)}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='flex flex-row items-center justify-center px-16 pb-4'>
                {settlement?.productImg !== null ? (
                  <>
                    {images && (
                      <div className='w-[400px] h-[400px] text-center object-cover'>
                        <Slider slideImage={images} />
                      </div>
                    )}
                  </>
                ) : (
                  <img
                    src={DefaultImage}
                    alt='profile-def'
                    className='rounded-md w-full h-full object-cover'
                  />
                )}
                {/* Additional Images */}
              </div>
              <div className=''>
                <span className='font-bold  text-base text-[#2E3ECE] w-full '>
                  SETTLEMENT DETAILS
                </span>
              </div>
              <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-center  gap-y-16 w-full h-auto p-5 '>
                <Input
                  label='VENDOR NAME'
                  icon={PencilSm}
                  pattern=''
                  // name='lastName'
                  handleChange={handleInputChange}
                  valueField={formData.name}
                />

                <Input
                  label='BUSINESS NAME'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.businessName}
                />
                <Input
                  label='PRODUCT NAME'
                  icon={PencilSm}
                  // name='firstName'
                  handleChange={handleInputChange}
                  valueField={formData.productName}
                />

                <Input
                  label='EMAIL'
                  icon={PencilSm}
                  // name='email'
                  handleChange={handleInputChange}
                  valueField={formData.email}
                />
                <Input
                  label='EMAIL'
                  icon={PencilSm}
                  // name='email'
                  handleChange={handleInputChange}
                  valueField={formData.email}
                />
                <Input
                  label='AMOUNT'
                  icon={PencilSm}
                  pattern=''
                  // name='lastName'
                  handleChange={handleInputChange}
                  valueField={formData.amount}
                />

                <Input
                  label='QUANTITY'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.quantity}
                />
                <Input
                  label='ACCOUNT NUMBER'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.accountNo}
                />
                <Input
                  label='ACCOUNT NAME'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.accountName}
                />
                <Input
                  label='BANK NAME'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.bankName}
                />
                <Input
                  label='STATUS'
                  icon={PencilSm}
                  pattern=''
                  // name='phone'
                  handleChange={handleInputChange}
                  valueField={formData.status}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSettlement;
