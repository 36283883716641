import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {adminInfo} from '../../../types';

const initialState: adminInfo = {
  admin: localStorage.getItem('adminInfo')
    ? JSON.parse(localStorage.getItem('adminInfo') || '{}')
    : null,
};

const adminReducer = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setAdminCredentials: (state, action) => {
      state.admin = action.payload;
      localStorage.setItem('adminInfo', JSON.stringify(action.payload));
    },
  },
});

export const {setAdminCredentials} = adminReducer.actions;
export const selectAdmin = (state: RootState) => state.admin.admin?.admin;
export const selectAdminToken = (state: RootState) => state.admin.admin?.token;

export default adminReducer.reducer;
