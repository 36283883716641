const baseUrl = process.env.REACT_APP_API_URL;

const api = {
  req: async (url, method = 'GET', body = null, token = null) => {
    try {
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      if (body) options.body = JSON.stringify(body);
      const response = await fetch(`${baseUrl}${url}`, options);
      const data = await response.json();
      return data;
    } catch (e) {
      return e.response
        ? e.response.data
        : {
            status: 'failure',
            responseCode: '01',
            responseMessage: 'Network Error',
          };
    }
  },
};

export default api;
