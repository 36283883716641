/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect } from "react";
import {BarChart, Title} from '@tremor/react';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import {SalesData} from '../../../utils/data';
import VendorHeader from '../../../components/layouts/header/profile/VendorHeader';
import {useVendor} from '../../../app/hooks';
import {useEffect, useState} from 'react';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {axiosError} from '../../../components/Helpers';
import ProfileSkeleton from '../../../components/ui/skeletonUI/ProfileSkeleton';
import VendorCard from '../../../components/dashboard/VendorCard';
import Picker from '../../../components/forms/inputs/selectInput/Picker';
import Withdraw from '../../../components/dashboard/Withdraw';
import ProductOverview from '../../../components/dashboard/ProductOverview';
import {AdminServices} from '../../../redux/services/admin/admin';
import {format, getWeek, parseISO, startOfWeek} from 'date-fns';
import Spinner from '../../../components/spinner/Spinner';

// const { useAppSelector, useAppDispatch } = Hooks;
interface FilteredData {
  name: string;
  sales: number;
}
const Home = () => {
  // const vendor = useVendor().vendor
  localStorage.setItem('currentPath', '/vendor/dashboard');

  const valueFormatter = (number: number) =>
    `${Intl.NumberFormat('en-NG').format(number).toString()}`;

  const token: any = useVendor().token;
  const user = useVendor().vendor;
  const [profile, setProfile] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSettleLoading, setIsSettleLoading] = useState(false);
  const [openWithdrawal, setOpenWithdrawal] = useState(false);
  const [settlements, setSettlements] = useState<any>([]);
  const [salesData, setSalesData] = useState<SalesData[]>([]);
  const [filteredData, setFilteredData] = useState<FilteredData[]>([]);
  const [filter, setFilter] = useState<'Monthly' | 'Weekly' | 'Daily'>(
    'Monthly'
  );

  // Get Profile
  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true);
      try {
        const response = await VendorServices.getProfile(token);
        if (response.data.status === 'success') {
          const res = response.data.data.vendor;
          setProfile(res);
          setIsLoading(false);
        }
      } catch (error: any) {
        console.log(error.response);
        axiosError(error.response);
        setIsLoading(false);
      }
    };
    getProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = [
    {value: 'Monthly', label: 'Monthly'},
    {value: 'Weekly', label: 'Weekly'},
    {value: 'Daily', label: 'Daily'},
  ];

  // Get Settlements
  useEffect(() => {
    getSettlements();
  }, [filter]);

  const getSettlements = async () => {
    setIsSettleLoading(true);
    try {
      const response = await AdminServices.getSettlements(token);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response?.data.data.details;
        const data = res.filter((item: any) => item.vendorId === user.id);
        setSettlements(data);
        setSalesData(data);
        filterData(data, filter);
        // console.log(res.rows);
        setIsSettleLoading(false);
        // console.log('profile', profile);
      }
    } catch (error: any) {
      // axiosError(error.response);
      setIsSettleLoading(false);
    }
  };

  const filterData = (
    data: SalesData[],
    filterType: 'Monthly' | 'Weekly' | 'Daily'
  ) => {
    const formattedData = data.map((item) => {
      const date = parseISO(item.createdAt);
      let formattedDate = '';
      if (filterType === 'Monthly') {
        formattedDate = format(date, 'MMMM'); // Format as month name
      } else if (filterType === 'Weekly') {
        formattedDate = `Week ${getWeek(startOfWeek(date))}`; // Format as week number
      } else if (filterType === 'Daily') {
        formattedDate = format(date, 'EEEE'); // Format as day of the week name
      }
      return {
        ...item,
        createdAt: formattedDate,
      };
    });

    const aggregatedData = formattedData.reduce((acc, item) => {
      const found = acc.find((i) => i.name === item.createdAt);
      if (found) {
        found.sales += Number(item.amount);
      } else {
        acc.push({name: item.createdAt, sales: Number(item.amount)});
      }
      return acc;
    }, [] as FilteredData[]);

    setFilteredData(aggregatedData);
  };

  const handleFilterChange = (value: string) => {
    const filterType = value as 'Monthly' | 'Weekly' | 'Daily';
    setFilter(filterType);
    filterData(salesData, filterType);
  };
  return (
    <>
      <AppContainer>
        {isLoading ? (
          <div className='xl:w-[92%] md:w-[95%] w-full sm:pt-4 pt-0'>
            <ProfileSkeleton />
          </div>
        ) : (
          <div className='xl:w-[92%] md:w-[95%] w-full sm:pt-4 pt-0 px-3 sm:px-0'>
            {isSettleLoading && <Spinner />}
            <Helmet>
              <meta charSet='utf-8' />
              <title>Dashboard</title>
            </Helmet>
            <div className='pb-4'>
              <VendorHeader
                profileImg={profile?.vendorImg}
                firstName={profile?.firstName}
              />
            </div>

            <div>
              <p className='text-lg text-[#878987] font-poppinsRegular inline-flex'>
                Welcome,
                <span className='text-black font-poppinsMedium pl-2'>
                  {profile?.firstName} 👋
                </span>
              </p>
            </div>
            <div className='py-4'>
              <VendorCard
                onOpenWithdraw={() => setOpenWithdrawal(true)}
                profile={profile}
              />
            </div>

            {/* Chart nad Card */}
            <div className='grid 2xl:grid-cols-3 gap-4 grid-cols-1'>
              {/* Chart */}
              <div className='col-span-2'>
                <div className='w-full bg-white rounded-lg h-auto sm:p-6 p-4 text-xs'>
                  <div>
                    <Title className='text-black font-poppinsMedium'>
                      Sales Performance
                    </Title>
                    <div className='flex flex-row justify-between items-center relative pt-2'>
                      <div className='flex items-center flex-row gap-2'>
                        <p className='font-poppinsMedium'>Overview</p>
                        <p className='text-success'>(+5) more </p>
                        <p className='text-gray-400'>in 2024 </p>
                      </div>
                      {/* Sort */}
                      <div className='text-sm absolute bg-[#F2F2F2] text-black right-3 rounded-md z-10'>
                        <Picker
                          onChange={handleFilterChange}
                          options={options}
                          placeholder={'Monthly'}
                        />
                      </div>
                    </div>

                    <div className=''>
                      <BarChart
                        className='mt-6 text-black'
                        data={filteredData}
                        index='name'
                        categories={['sales']}
                        colors={['amber']}
                        valueFormatter={valueFormatter}
                        yAxisWidth={60}
                        showLegend={false}
                        showAnimation={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* OverView  */}
              <ProductOverview settlements={settlements} />
            </div>
          </div>
        )}
      </AppContainer>
      {openWithdrawal && (
        <Withdraw setOpenWithdrawal={setOpenWithdrawal} />
        // <ConfirmDelete setIsOpen={setOpenWithdrawal} />
      )}
    </>
  );
};

const AppContainer = styled.div<any>`
  margin-bottom: 3rem;
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

export default Home;
