import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {IAdminBuyerReducer} from '../../../types';

const initialState: IAdminBuyerReducer = {
  buyer: localStorage.getItem('adminBuyer')
    ? JSON.parse(localStorage.getItem('adminBuyer') || '{}')
    : null,
};

const adminBuyerReducer = createSlice({
  name: 'adminBuyer',
  initialState,
  reducers: {
    setSingleAdminBuyer: (state, action) => {
      state.buyer = action.payload;
      localStorage.setItem('adminBuyer', JSON.stringify(action.payload));
    },
  },
});

export const {setSingleAdminBuyer} = adminBuyerReducer.actions;
export const selectAdminBuyer = (state: RootState) => state.adminBuyer.buyer;

export default adminBuyerReducer.reducer;
