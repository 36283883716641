/* eslint-disable @typescript-eslint/no-unused-vars */

import {IBuyer} from '../interfaces/vendors';
import {DefaultImage} from '../../assets/images';
import Spinner from '../spinner/Spinner';

interface IVendorTable {
  disputes: any[];
  isActionLoading: boolean;
}
const DisputeTable = ({disputes, isActionLoading}: IVendorTable) => {
  return (
    <>
      <div>
        {isActionLoading && <Spinner />}
        <table className='mt-6 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary table-fixed'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10 rounded-tl-md'>Image</th>
              <th className='w-10'>Order ID</th>
              <th className='w-10'>Product Name</th>
              <th className='w-10'>Return Tpe</th>
              <th className='w-10'>Return Method</th>
              <th className='w-10'>Quantity</th>
              <th className='w-14'>Reason</th>
              <th className='w-14'>Description</th>
              <th className='w-10'>Status</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-orange-100'>
            {disputes.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={9}>
                  <span className='text-xl text-primary font-semibold'>
                    No Vendor available
                  </span>
                </td>
              </tr>
            )}
            <>
              {disputes.map((dispute: any) => {
                return (
                  <tr className='overflow-x-auto' key={dispute.id}>
                    <td className='h-20'>
                      <div className='flex justify-center'>
                        <div className='lg:w-16 w-8 lg:h-16 h-8 rounded-full self-center'>
                          {dispute?.orderProduct.product.additionalImg[0] !==
                          null ? (
                            <img
                              src={
                                dispute.orderProduct.product.additionalImg[0]
                              }
                              alt=''
                              className='object-contain w-full rounded-full h-full'
                            />
                          ) : (
                            <img
                              src={DefaultImage}
                              alt='profile-def'
                              className='rounded-full w-full h-full object-cover'
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className='w-10 text-center'>
                      #{dispute.orderId.slice(0, 12) + '...'}
                    </td>
                    <td className='w-10 text-center'>
                      {dispute.orderProduct.product.name}
                    </td>
                    <td className='w-10 text-center'>{dispute.returnType}</td>
                    <td className='w-10 text-center'>{dispute.returnMethod}</td>
                    <td className='w-10 text-center'>{dispute.quantity}</td>
                    <td className='w-14 text-center'>{dispute.reason}</td>
                    <td className='w-14 text-center'>{dispute.description}</td>
                    <td
                      className={
                        dispute.status === 'approved'
                          ? 'w-10 text-center text-green-500'
                          : 'w-10 text-center text-orange-500'
                      }>
                      {dispute.status === 'approved'
                        ? 'Approved'
                        : dispute.status === 'pending'
                        ? 'Pending'
                        : dispute.status}
                    </td>
                    {/* Status */}
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DisputeTable;
