import {useLocation, Navigate, Outlet} from 'react-router-dom';
import {useVendor} from '../app/hooks';
// import {TokenService} from '../redux/services';

const ProtectedRoutes = () => {
  const location = useLocation();
  const token = useVendor().token;
  return token ? (
    <Outlet />
  ) : (
    <Navigate to='/' state={{from: location}} replace />
  );
};

export default ProtectedRoutes;
