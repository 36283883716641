/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {MdOutlineModeEditOutline} from 'react-icons/md';
import {VendorServices} from '../../../redux/services/vendor/vendor';
import {axiosError} from '../../../components/Helpers';
import {useNavigate} from 'react-router-dom';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';

interface ISuperCategory {
  id: string;
  name?: string;
  categories: ICategory[];
}
interface ICategory {
  id: string;
  name?: string;
  code?: number;
  subCategories: ISubCategory[];
}

interface ISubCategory {
  id: string;
  name?: string;
  code?: number;
}

const CategoriesScreen = () => {
  const [superCategory, setSuperCategory] = useState<ISuperCategory[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [activeId, setActiveId] = useState<string | undefined>(undefined);
  const [activeCategoryId, setActiveCategoryId] = useState<string | undefined>(
    undefined
  );
  const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await VendorServices.getSuperCategory();
        if (response.data.status === 'success') {
          const res = response.data.data.details;
          setSuperCategory(res.rows);
          if (res.rows.length > 0) {
            const firstSuperCategory = res.rows[0];
            setActiveCategoryId(firstSuperCategory.id);

            if (firstSuperCategory.categories.length > 0) {
              const firstCategory = firstSuperCategory.categories[0];
              setCategories(firstSuperCategory.categories);
              setActiveId(firstCategory.id);

              if (firstCategory.subCategories.length > 0) {
                setSubCategories(firstCategory.subCategories);
              } else {
                setSubCategories([]);
              }
            } else {
              setCategories([]);
              setSubCategories([]);
            }
          }
        }
        setLoading(false);
      } catch (error: any) {
        axiosError(error.response);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSetCategoryActiveId = (id: string) => {
    setActiveCategoryId(id);
    const activeSuperCategory = superCategory.find((cat) => cat.id === id);
    if (activeSuperCategory && activeSuperCategory.categories.length > 0) {
      setCategories(activeSuperCategory.categories);
      const firstCategory = activeSuperCategory.categories[0];
      setActiveId(firstCategory.id);
      if (firstCategory.subCategories.length > 0) {
        setSubCategories(firstCategory.subCategories);
      } else {
        setSubCategories([]);
      }
    } else {
      setCategories([]);
      setSubCategories([]);
    }
  };

  const handleSetActiveId = (id: string) => {
    setActiveId(id);
    const activeCategory = categories.find((cat) => cat.id === id);
    if (activeCategory && activeCategory.subCategories) {
      setSubCategories(activeCategory.subCategories);
    } else {
      setSubCategories([]);
    }
  };

  return (
    <div className='h-full w-full xl:w-[70] sm:w-[80%] p-10 bg-white rounded-lg'>
      {loading ? (
        <TableDataSkeleton />
      ) : (
        <>
          {/* Super Category */}
          <div className='flex flex-row justify-between items-center w-full py-6'>
            <p className='text-base font-poppinsMedium'>
              List of Super Category
            </p>
            <div className='flex flex-row bg-white text-primary rounded-full h-7 w-7 justify-center items-center p-1 border border-primary cursor-pointer'>
              <MdOutlineModeEditOutline
                size={22}
                onClick={() =>
                  navigate('../admin/products/updateSuperCategory')
                }
              />
            </div>
          </div>

          {/* Super Category */}
          <div className='flex flex-wrap items-center justify-start gap-4'>
            {superCategory.map((superCategoryItem) => (
              <button
                key={superCategoryItem.id}
                onClick={() => handleSetCategoryActiveId(superCategoryItem.id)}
                className={
                  activeCategoryId === superCategoryItem.id
                    ? 'bg-primary text-white py-2 px-4 rounded-md'
                    : 'bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'
                }>
                {superCategoryItem.name}
              </button>
            ))}
          </div>
          {/*  Category */}
          <div className='flex flex-row justify-between items-center w-full py-6'>
            <p className='text-base font-poppinsMedium'>List of Category</p>
            <div className='flex flex-row bg-white text-primary rounded-full h-7 w-7 justify-center items-center p-1 border border-primary cursor-pointer'>
              <MdOutlineModeEditOutline
                size={22}
                onClick={() =>
                  navigate(
                    `../admin/products/updateCategory/${activeCategoryId}`
                  )
                }
              />
            </div>
          </div>

          {/* Category */}
          {categories.length > 0 ? (
            <div className='flex flex-wrap items-center justify-start gap-4'>
              {categories.map((categoryItem) => (
                <button
                  key={categoryItem.id}
                  onClick={() => handleSetActiveId(categoryItem.id)}
                  className={
                    activeId === categoryItem.id
                      ? 'bg-primary text-white py-2 px-4 rounded-md'
                      : 'bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'
                  }>
                  {categoryItem.name}
                </button>
              ))}
            </div>
          ) : (
            <div className='text-base flex flex-row justify-center'>
              No Categories
            </div>
          )}

          {/* Subcategories */}
          <div className='mt-4'>
            <div className='flex flex-row justify-between items-center w-full py-6 '>
              <p className='text-base font-poppinsMedium'>
                List of SubCategory
              </p>
              <div className='flex flex-row bg-white text-primary rounded-full h-7 w-7 justify-center items-center p-1 border border-primary cursor-pointer'>
                <MdOutlineModeEditOutline
                  size={22}
                  onClick={() =>
                    navigate(`../admin/products/updateSubCategory/${activeId}`)
                  }
                />
              </div>
            </div>
            {subCategories.length > 0 ? (
              <div className='flex flex-wrap items-center justify-start gap-4'>
                {subCategories.map((subCategory) => (
                  <button
                    key={subCategory.id}
                    className='bg-[#E5E8FE] text-[#444D5E] py-2 px-4 rounded-md'>
                    {subCategory.name}
                  </button>
                ))}
              </div>
            ) : (
              <div className='text-base flex flex-row justify-center'>
                No SubCategories
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CategoriesScreen;
