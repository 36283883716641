import {DefaultImage} from '../../../../assets/images';

interface IProfileImage {
  onClick: () => void;
  profileImage?: any;
}
const ProfileImage = ({onClick, profileImage}: IProfileImage) => {
  return (
    <div className='flex flex-row items-center'>
      <div className='w-[20%] h-[20%] cursor-pointer' onClick={onClick}>
        {profileImage ? (
          <div className='w-[36px] h-[36px] '>
            <img
              src={profileImage}
              alt='profile-img'
              className='rounded-full w-full h-full object-cover'
            />
          </div>
        ) : (
          <div className='w-[36px] h-[36px] '>
            <img
              src={DefaultImage}
              alt='default-img'
              className='rounded-full w-full h-full object-cover'
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileImage;
