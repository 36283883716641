// Dropdown.tsx

import React, {useState} from 'react';
import {IoMdArrowDropdown} from 'react-icons/io';

interface DropdownProps {
  options: {value: string; label: string}[];
  onChange: (selectedValue: string) => void;
  placeholder: string;
}

const Picker = ({onChange, options, placeholder}: DropdownProps) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [openStatus, setOpenStatus] = useState(false);

  const handleSelectChange = (e: React.MouseEvent<HTMLParagraphElement>) => {
    const value = e.currentTarget.dataset.value;
    setSelectedValue(value || '');
    onChange(value || '');
    setOpenStatus(false);
  };

  return (
    <div className='relative inline-block text-left'>
      <div
        onClick={() => setOpenStatus(!openStatus)}
        className=' inline-flex gap-2 cursor-pointer p-2'>
        <p className='font-poppinsRegular'>
          {selectedValue ? selectedValue : placeholder}
        </p>
        <IoMdArrowDropdown size={24} />
      </div>

      {/* Dropdown content */}
      {openStatus && (
        <div className='origin-top-right absolute right-0 mt-1 w-full rounded-md shadow-lg text-center bg-white ring-1 ring-black ring-opacity-5'>
          <div onClick={() => setOpenStatus(false)} className='py-1'>
            {options.map((option) => (
              <p
                key={option.value}
                data-value={option.value}
                onClick={handleSelectChange}
                className={`block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-primary cursor-pointer font-poppinsRegular`}>
                {option.label}
              </p>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Picker;
