import React from 'react';
import {motion} from 'framer-motion';

interface IModal {
  isOpen: boolean;
  children?: React.ReactNode;
}
const Modal = ({isOpen, children}: IModal) => {
  const modalVariants = {
    hidden: {opacity: 0, scale: 0.9},
    visible: {opacity: 1, scale: 1, transition: {duration: 0}},
    exit: {opacity: 0, scale: 0.9, transition: {duration: 0.3}},
  };

  return (
    <motion.div
      className='absolute bg-white py-4 px-4 rounded-md shadow-md left-0 top-4 z-50'
      initial='hidden'
      animate={isOpen ? 'visible' : 'hidden'}
      exit='exit'
      variants={modalVariants}>
      {children}
    </motion.div>
  );
};

export default Modal;
