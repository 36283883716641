import React, {forwardRef} from 'react';
import DatePicker from 'react-datepicker';
import {IoMdArrowDropdown} from 'react-icons/io';

interface IDateRangePicker {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
}

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
}

const CustomInput = forwardRef<HTMLButtonElement, CustomInputProps>(
  ({value, onClick}, ref) => (
    <button
      type='button'
      className='bg-white text-black font-poppinsMedium py-2 px-4 rounded-lg shadow-md hover:bg-orange-100 focus:outline-none focus:ring-1 focus:ring-primary focus:ring-opacity-50'
      onClick={onClick}
      ref={ref}>
      <div className='flex flex-row items-center gap-8'>
        <p className='text-black font-poppinsMedium'>{value}</p>
        <IoMdArrowDropdown size={20} color='black' />
      </div>
    </button>
  )
);

const DateRangePicker = ({
  endDate,
  onEndDateChange,
  onStartDateChange,
  startDate,
}: IDateRangePicker) => {
  return (
    <div className='flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 p-4'>
      <div className='flex flex-col items-center'>
        {/* <label htmlFor='start-date' className='mb-2 text-gray-700'>
          Start Date
        </label> */}
        <DatePicker
          selected={startDate}
          onChange={onStartDateChange}
          // dateFormat='yyyy-MM-dd'
          dateFormat='dd-MMM-yyyy'
          customInput={<CustomInput />}
          id='start-date'
        />
      </div>
      <div className='flex flex-col items-center'>
        {/* <label htmlFor='end-date' className='mb-2 text-gray-700'>
          End Date
        </label> */}
        <DatePicker
          selected={endDate}
          onChange={onEndDateChange}
          dateFormat='dd-MMM-yyyy'
          customInput={<CustomInput />}
          id='end-date'
          minDate={startDate}
        />
      </div>
    </div>
  );
};

export default DateRangePicker;
