import {Facebook, Instagram, Send2} from 'iconsax-react';
import {BsLinkedin} from 'react-icons/bs';
import {Link} from 'react-router-dom';
import {
  downloadAppStore,
  googleplay,
  qrcode,
  whiteLogo,
} from '../assets/images';
import {CiMail} from 'react-icons/ci';
import {FaXTwitter} from 'react-icons/fa6';
import {customerUrl} from '../constant';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className='w-full py-10 flex-col justify-between h-full sm:flex hidden'>
        <div className='justify-between items-start flex container mx-auto px-10 gap-4 w-full'>
          <div className='lg:flex flex-col items-start gap-4 hidden'>
            {/* Logo */}
            <div className='logo-img w-36 h-full cursor-pointer'>
              <img
                src={whiteLogo}
                alt='logo'
                className='object-cover w-full h-full'
              />
            </div>
            {/* Subscribe */}
            <p className='text-xl text-white font-poppinsSemibold pt-4'>
              Subscribe
            </p>
            <div className='h-12 w-full px-3 rounded-md border border-white bg-white flex flex-row gap-4 items-center text-black'>
              <CiMail size={24} color='black' />
              <input
                type='text'
                placeholder='Enter your email to subscribe'
                className='w-full h-full bg-transparent text-black outline-none focus:text-[#667185] rounded-md'
              />
            </div>
            <button className='flex flex-row justify-between items-end px-4 py-2 rounded-xl bg-[#F59C3D] gap-4'>
              <p className='text-white font-poppinsMedium text-lg'>Subscribe</p>

              <Send2 size={22} color='white' variant='Linear' />
            </button>
          </div>
          <div className='flex-col justify-start items-start gap-6 inline-flex'>
            <div className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
              Support
            </div>
            <div className='flex-col justify-start items-start gap-4 flex'>
              <div className='w-64 text-neutral-50   leading-normal'>
                4, Aderoju Adewuyi Street, Awuse Estate, Opebi, Ikeja, Lagos.
              </div>
              <div className='text-neutral-50   leading-normal'>
                contact@sterlingtech.com.ng
              </div>
              <div className='text-neutral-50   leading-normal'>
                +234 467 382 3843
              </div>
            </div>
          </div>
          <div className='flex-col justify-start items-start gap-6 inline-flex'>
            <div className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
              Account
            </div>
            <div className='flex-col justify-start items-start gap-4 flex'>
              <Link
                to={`${customerUrl}/account/profile`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                My Account
              </Link>
              <Link
                to={`${customerUrl}/login`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Login / Register
              </Link>
              <Link
                to={`${customerUrl}/cart`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Cart
              </Link>
              <Link
                to={`${customerUrl}/products`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Shop
              </Link>
            </div>
          </div>
          <div className='flex-col justify-start items-start gap-6 inline-flex'>
            <p className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
              Quick Link
            </p>
            <div className='flex-col justify-start items-start gap-4 flex'>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/privacy`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Privacy Policy
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/termsOfUse`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Terms Of Use
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/faqs`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                FAQ
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/about-us`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                About
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/charges`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Charges
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/contact-us`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Contact
              </Link>
            </div>
          </div>
          <div className='flex-col justify-start items-start gap-6 lg:inline-flex hidden'>
            <div className='flex-col justify-start items-start gap-6 flex'>
              <div className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
                Download App
              </div>
              <div className='flex-col justify-start items-start gap-2 flex'>
                <div className='justify-start items-center gap-2 inline-flex'>
                  <div className='w-20 h-20 p-0.5 justify-center items-center flex'>
                    <img className='w-20 h-20' src={qrcode} alt='qr' />
                  </div>
                  <div className='flex-col justify-start items-start gap-1 inline-flex'>
                    <div className='w-28 h-10 relative'>
                      <img
                        className='w-24 h-8 left-[3px] top-[5px] absolute rounded'
                        src={googleplay}
                        alt='googleplay'
                      />
                    </div>
                    <div className='w-28 h-10 relative'>
                      <img
                        className='w-24 h-8 left-[3px] top-[5px] absolute rounded'
                        src={downloadAppStore}
                        alt='appStore'
                      />
                    </div>
                    {/* <div className='w-28 h-10 p-0.5 justify-center items-center inline-flex'>
                    <img className='w-24 h-8 rounded' src={downloadAppStore} />
                  </div> */}
                  </div>
                </div>
                <div className='flex flex-row items-center justify-between w-full pr-6 pt-4'>
                  <Facebook
                    size={20}
                    color='white'
                    variant='Bold'
                    className='cursor-pointer'
                  />
                  <FaXTwitter
                    size={20}
                    color='white'
                    className='cursor-pointer'
                  />
                  <Instagram
                    size={20}
                    color='white'
                    className='cursor-pointer'
                  />
                  <BsLinkedin
                    size={20}
                    color='white'
                    className='cursor-pointer'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  tablet*/}
        <div className='justify-between items-start flex container mx-auto px-10 gap-4 w-full lg:hidden pt-8'>
          <div className='flex-row justify-between items-start gap-6 flex w-full'>
            {/* Logo */}
            <div className='logo-img w-36 h-full cursor-pointer'>
              <img
                src={whiteLogo}
                alt='logo'
                className='object-cover w-full h-full'
              />
            </div>
            {/* SubScribe */}
            <div className='flex flex-col items-start gap-4'>
              <p className='text-xl text-white font-poppinsSemibold'>
                Subscribe
              </p>
              <div className=' h-12 w-full px-3 rounded-lg border border-white bg-white flex flex-row gap-4 items-center text-black'>
                <CiMail size={24} />
                <input
                  type='text'
                  placeholder='Enter your email to subscribe'
                  className='w-full h-full bg-transparent text-black outline-none focus:text-[#667185]'
                />
              </div>
              <button className='flex flex-row justify-between items-end px-4 py-2 rounded-xl bg-[#F59C3D] gap-4'>
                <p className='text-white font-poppinsMedium text-lg'>
                  Subscribe
                </p>

                <Send2 size={22} color='white' variant='Linear' />
              </button>
            </div>
            {/* Download apps */}
            <div className='flex-col justify-start items-start gap-2 flex'>
              <p className='text-xl text-white font-poppinsSemibold'>
                Download App
              </p>
              <div className='justify-start items-center gap-2 inline-flex'>
                <div className='w-20 h-20 p-0.5 justify-center items-center flex'>
                  <img className='w-20 h-20' src={qrcode} alt='qr' />
                </div>
                <div className='flex-col justify-start items-start gap-1 inline-flex'>
                  <div className='w-28 h-10 relative'>
                    <img
                      className='w-24 h-8 left-[3px] top-[5px] absolute rounded'
                      src={googleplay}
                      alt='google'
                    />
                  </div>
                  <div className='w-28 h-10 relative'>
                    <img
                      className='w-24 h-8 left-[3px] top-[5px] absolute rounded'
                      src={downloadAppStore}
                      alt='apps'
                    />
                  </div>
                  {/* <div className='w-28 h-10 p-0.5 justify-center items-center inline-flex'>
                    <img className='w-24 h-8 rounded' src={downloadAppStore} />
                  </div> */}
                </div>
              </div>
              {/* Social Icon */}
              <div className='flex flex-row gap-4 items-center justify-between w-full pr-6 pt-4'>
                <Facebook
                  size={20}
                  color='white'
                  variant='Bold'
                  className='cursor-pointer'
                />
                <FaXTwitter
                  size={20}
                  color='white'
                  className='cursor-pointer'
                />
                <Instagram size={20} color='white' className='cursor-pointer' />
                <BsLinkedin
                  size={20}
                  color='white'
                  className='cursor-pointer'
                />
              </div>
            </div>
          </div>
        </div>

        <div className='lg:flex w-full flex-col opacity-60 hidden'>
          <div className='pb-2'>
            <hr className='text-[#C6B3A7] w-full' />
          </div>
          <div className='flex-row justify-center items-center flex w-full container mx-auto px-20'>
            <div className='text-white leading-normal'>
              &copy; Copyright 2023. All right reserved
            </div>
          </div>
        </div>
        {/* Tablet */}
        <div className='flex w-full flex-col opacity-60 border-t-2 border-t-[#C6B3A7] pt-4 lg:hidden'>
          <div className='flex-row justify-center items-center flex w-full container mx-auto px-20'>
            <div className='text-white leading-normal'>
              &copy; Copyright 2023. All right reserved
            </div>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className='w-full py-10 flex-col justify-between h-full sm:hidden flex gap-6'>
        <div className='justify-between items-start flex container mx-auto px-4 gap-4 w-full'>
          <div className='flex-col justify-start items-start gap-6 inline-flex w-[60%]'>
            <div className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
              Support
            </div>
            <div className='flex-col justify-start items-start gap-4 flex'>
              <div className='w-full text-neutral-50 leading-normal'>
                4, Aderoju Adewuyi Street, Awuse Estate, Opebi, Ikeja, Lagos.
              </div>
              <div className='text-neutral-50'>contact@sterlingtech.com.ng</div>
              <div className='text-neutral-50'>+234 467 382 3843</div>
            </div>
          </div>
          <div className='flex-col items-start gap-6 inline-flex'>
            <div className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
              Account
            </div>
            <div className='flex-col justify-start items-start gap-4 flex'>
              <Link
                to={`${customerUrl}/account/profile`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                My Account
              </Link>
              <Link
                to={`${customerUrl}/login`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Login / Register
              </Link>
              <Link
                to={`${customerUrl}/cart`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Cart
              </Link>
              <Link
                to={`${customerUrl}/products`}
                onClick={() => scrollToTop()}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Shop
              </Link>
            </div>
          </div>
        </div>
        {/* Quick Link And Download */}
        <div className='justify-between items-start flex container mx-auto px-4 gap-4 w-full'>
          <div className='flex-col justify-start items-start gap-6 inline-flex'>
            <p className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
              Quick Link
            </p>
            <div className='flex-col justify-start items-start gap-4 flex'>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/privacy`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Privacy Policy
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/termsOfUse`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Terms Of Use
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/faqs`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                FAQ
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/about-us`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                About
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/charges`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Charges
              </Link>
              <Link
                onClick={() => scrollToTop()}
                to={`${customerUrl}/contact-us`}
                className='text-neutral-50   leading-normal hover:underline cursor-pointer'>
                Contact
              </Link>
            </div>
          </div>

          {/* Downloads */}
          <div className='flex-col items-start gap-6 flex'>
            <div className='flex-col justify-start items-start gap-6 flex'>
              <div className='text-neutral-50 text-lg font-poppinsMedium leading-7'>
                Download App
              </div>
              <div className='flex-col justify-start items-start gap-2 flex'>
                <div className='justify-start items-center gap-2 inline-flex'>
                  <div className='w-20 h-20 p-0.5 justify-center items-center flex'>
                    <img className='w-20 h-20' src={qrcode} alt='qr' />
                  </div>
                  <div className='flex-col justify-start items-start gap-1 inline-flex'>
                    <div className='w-28 h-10 relative'>
                      <img
                        className='w-24 h-8 left-[3px] top-[5px] absolute rounded'
                        src={googleplay}
                        alt='googleplay'
                      />
                    </div>
                    <div className='w-28 h-10 relative'>
                      <img
                        className='w-24 h-8 left-[3px] top-[5px] absolute rounded'
                        src={downloadAppStore}
                        alt='appStore'
                      />
                    </div>
                    {/* <div className='w-28 h-10 p-0.5 justify-center items-center inline-flex'>
                    <img className='w-24 h-8 rounded' src={downloadAppStore} />
                  </div> */}
                  </div>
                </div>
                <div className='flex flex-row items-center justify-between w-full pr-6 pt-4'>
                  <Facebook
                    size={20}
                    color='white'
                    variant='Bold'
                    className='cursor-pointer'
                  />
                  <FaXTwitter
                    size={20}
                    color='white'
                    className='cursor-pointer'
                  />
                  <Instagram
                    size={20}
                    color='white'
                    className='cursor-pointer'
                  />
                  <BsLinkedin
                    size={20}
                    color='white'
                    className='cursor-pointer'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Subscribe */}
        <div className='lg:flex flex-col items-start gap-4 hidden'>
          {/* Logo */}
          <div className='logo-img w-36 h-full cursor-pointer'>
            <img
              src={whiteLogo}
              alt='logo'
              className='object-cover w-full h-full'
            />
          </div>
          {/* Subscribe */}
          <p className='text-xl text-white font-poppinsSemibold pt-4'>
            Subscribe
          </p>
          <div className='h-12 w-full px-3 rounded-md border border-white bg-white flex flex-row gap-4 items-center text-black'>
            <CiMail size={24} color='black' />
            <input
              type='text'
              placeholder='Enter your email to subscribe'
              className='w-full h-full bg-transparent text-black outline-none focus:text-[#667185] rounded-md'
            />
          </div>
          <button className='flex flex-row justify-between items-end px-4 py-2 rounded-xl bg-[#F59C3D] gap-4'>
            <p className='text-white font-poppinsMedium text-lg'>Subscribe</p>

            <Send2 size={22} color='white' variant='Linear' />
          </button>
        </div>

        <div className='lg:hidden w-full flex-col opacity-60 flex'>
          <div className='pb-2'>
            <hr className='text-[#C6B3A7] w-full' />
          </div>
          <div className='flex-row justify-center items-center flex w-full container mx-auto'>
            <div className='text-white leading-normal'>
              &copy; Copyright 2023. All right reserved
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
