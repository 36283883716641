import React, {useState} from 'react';
import {IOrder} from '../../../components/interfaces/products';
import moment from 'moment';
import {numberFormat} from '../../../components/Helpers';
import ViewOrder from './modal/ViewOrder';
import Spinner from '../../../components/spinner/Spinner';

interface IVendorOrderTable {
  orders: IOrder[];
  updateOrder?: any;
  isUpdateLoading?: boolean;
}
const AdminOrderTable = ({
  orders,
  isUpdateLoading,
  updateOrder,
}: IVendorOrderTable) => {
  const [showView, setShowView] = useState(false);

  return (
    <>
      {/* Modal View */}
      {showView && (
        <div className='z-50'>
          <ViewOrder setIsOpen={setShowView} />
        </div>
      )}
      <div className=' sm:block hidden'>
        <table className=' mt-4 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <div className='h-full'>{isUpdateLoading && <Spinner />}</div>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10 rounded-tl-md'>Date</th>
              <th className='w-10'>Vendor</th>
              <th className='w-10'>Order ID</th>
              <th className='w-10'>Item Name</th>
              <th className='w-10'>Product Description</th>
              <th className='w-10'>Price NGN</th>
              <th className='w-10'>Status</th>
              <th className='w-10'>Action</th>
              {/* <th className='w-16 rounded-tr-md'>Actions</th> */}
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8FAFC]'>
            {orders?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={9}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {orders?.map((order: any) => {
                return (
                  <>
                    {order?.orderProducts?.map((orderProduct?: any) => (
                      <tr
                        className='overflow-x-auto h-[3rem] font-medium'
                        key={orderProduct?.id}>
                        <td className='w-16 text-center'>
                          {moment(orderProduct?.createdAt).format('l')}
                        </td>
                        <td className='w-10 text-center'>
                          {orderProduct?.product?.businessName}
                        </td>
                        <td className='w-10 text-center'>
                          #{orderProduct?.orderId.slice(0, 6).toUpperCase()}
                        </td>
                        <td className='w-10 text-center'>
                          {orderProduct?.product?.name}
                        </td>
                        <td className='w-10 text-center'>
                          <div className=' flex flex-row justify-center'>
                            <div className='flex flex-col gap-2 items-start md:w-[50%] w-full '>
                              <div className='flex flex-row items-center gap-2'>
                                <p className=''>Quantity: </p>
                                <p className=''>{orderProduct?.quantity} </p>
                              </div>
                              {orderProduct?.product?.color && (
                                <div className='flex flex-row items-center gap-2'>
                                  <p className=''>Color: </p>
                                  <p className=''>
                                    {orderProduct?.product?.color}
                                  </p>
                                </div>
                              )}
                              {orderProduct?.product?.size && (
                                <div className='flex flex-row items-center gap-2'>
                                  <p className=''>Size: </p>
                                  <p className=''>
                                    {orderProduct?.product?.size}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </td>
                        <td className='w-10 text-center'>
                          {numberFormat(orderProduct?.subTotal)}
                        </td>
                        <td className='w-10 text-center'>
                          {orderProduct?.status && (
                            <div
                              className={
                                orderProduct?.status === 'orderPlaced' ||
                                orderProduct?.status === 'pending'
                                  ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm'
                                  : orderProduct?.status === 'takeToWarehouse'
                                  ? ' bg-[#FDF3E4] px-3 py-2 text-[#FFAD33] rounded-md shadow-sm'
                                  : orderProduct?.status ===
                                      'ready for pickup' ||
                                    orderProduct?.status === 'delivered' ||
                                    orderProduct?.status === 'completed'
                                  ? 'bg-[#E6FCE5] px-3 py-2 text-[#35C655] rounded-md shadow-sm'
                                  : 'bg-[#FEEFEE] px-3 py-2 text-[#C81E19] rounded-md shadow-sm'
                              }>
                              {orderProduct?.status}
                            </div>
                          )}
                        </td>

                        {/* Action */}
                        <td className='w-10 text-center'>
                          {orderProduct?.status === 'delivered' ||
                          orderProduct?.status === 'completed' ? (
                            <div className='bg-gray-300 text-white px-3 py-2 shadow-md rounded-md xl:w-[70%] m-auto'>
                              Success
                            </div>
                          ) : (
                            <>
                              {orderProduct?.status === 'ready for pickup' ? (
                                <div
                                  className='cursor-pointer hover:bg-green-700 bg-success text-white px-3 py-2 shadow-md rounded-md xl:w-[70%] m-auto'
                                  onClick={() => {
                                    updateOrder(orderProduct?.id);
                                  }}>
                                  Mark as Delivered
                                </div>
                              ) : (
                                <div className=' bg-gray-200 text-white px-3 py-2 shadow-md rounded-md xl:w-[70%] m-auto'>
                                  Mark as Delivered
                                </div>
                              )}
                            </>
                          )}
                        </td>
                        {/* <td className='w-16'></td> */}
                      </tr>
                    ))}
                  </>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminOrderTable;
