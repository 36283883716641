import React from 'react';
import {useState} from 'react';
import logo from '../../assets/images/logo.png';
import {useNavigate} from 'react-router-dom';
import {motion} from 'framer-motion';
import {COLORS} from '../../assets/color';
import {useVendor} from '../../app/hooks';
import {vendorScreens} from '../../pages/dashboard/sidebar.interface';
import {HiMenuAlt3} from 'react-icons/hi';
import MobileSidebar from './MobileSidebar';

interface IMenuSideBar {
  logoutHandler: () => void;
}
const MobileMenu = ({logoutHandler}: IMenuSideBar) => {
  const user = useVendor().vendor;
  const navigate = useNavigate();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    // Lock scrolling when the drawer is opened
    document.body.style.overflow = isDrawerOpen ? 'auto' : 'hidden';
  };

  return (
    <>
      {/* Mobile View */}
      <div className='flex flex-row pb-4 pt-4 items-center justify-between px-4 bg-white w-full'>
        <motion.button whileTap={{scale: 0.97}} onClick={toggleDrawer}>
          <HiMenuAlt3
            size={30}
            className='cursor-pointer'
            color={COLORS.primary}
          />
        </motion.button>
        <div className='flex flex-row gap-4 items-center'>
          <div className='w-[28px] h-[28px]'>
            <img
              src={logo}
              alt='logo'
              className='object-contain h-full w-full'
            />
          </div>
          <div>
            <p className='text-lg text-primary font-semibold'>
              {' '}
              People's Store Vendor
            </p>
          </div>
        </div>
        {/* Profile */}
        <div
          className='w-10 h-10 cursor-pointer'
          onClick={() => navigate('../vendor/profile')}>
          <img
            src={user?.vendorImg}
            alt=''
            className='rounded-full object-cover w-full h-full'
          />
        </div>
      </div>
      {/* Menus */}
      {isDrawerOpen && (
        <div>
          <MobileSidebar
            isOpenDrawer={isDrawerOpen}
            onClose={toggleDrawer}
            appScreens={vendorScreens}
            logoutHandler={logoutHandler}
          />
          <div
            onClick={toggleDrawer}
            className={`fixed inset-0 bg-[#2e3ecee6] opacity-25 z-30 ${
              isDrawerOpen ? 'block' : 'hidden'
            }`}
          />
        </div>
      )}
    </>
  );
};

export default MobileMenu;
