import React from 'react';

const NotFound = () => {
  return (
    <div className='grid place-items-center h-screen'>
      <div className='text-xl text-primary font-bold'>Page Not found</div>
    </div>
  );
};

export default NotFound;
