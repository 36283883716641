import {ReactEventHandler, useState} from 'react';
import {AiOutlineEyeInvisible, AiOutlineEye} from 'react-icons/ai';
import {COLORS} from '../../../../assets/color';

interface IFormInput {
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  label?: string;
  password?: boolean;
  value?: string;
  error?: ReactEventHandler<HTMLInputElement> | undefined;
  onBlur?: React.FocusEventHandler<HTMLInputElement> | undefined;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute | undefined;
  style?: React.CSSProperties | undefined;
  min?: number;
  ariaInvalid?: boolean | 'false' | 'true' | 'grammar' | 'spelling' | undefined;
  ariaDescribedby?: string | undefined;
  Inputref?: React.LegacyRef<HTMLInputElement> | undefined;
  toolTipId?: string;
  toolTipBackgroundColor?: string;
  toolTip?: boolean;
}
const InputField = ({
  name,
  placeholder,
  type,
  error,
  onChange,
  password,
  required,
  style,
  value,
  onBlur,
  min,
  label,
  ariaInvalid,
  ariaDescribedby,
  Inputref,
  onFocus,
  readOnly,
  toolTipId,
  toolTip,
}: IFormInput) => {
  const [hidePassword, setHidePassword] = useState(false);
  return (
    <div className='py-2'>
      <div className='pb-1 flex gap-2 items-start flex-row'>
        {required ? (
          <>
            <label className='font-poppinsRegular'>{label}</label>
            <p className='text-red-600 text-base'>*</p>
          </>
        ) : (
          <label className='font-poppinsRegular'>{label}</label>
        )}
      </div>
      <div className='input mb-2 overflow-hidden last:mb-0 font-poppinsRegular flex border-[2px] border-[#DADDE1] h-12 items-center rounded-lg'>
        <input
          type={password ? (hidePassword ? 'text' : 'password') : type}
          name={name}
          placeholder={placeholder}
          required={required}
          onError={error}
          style={style}
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          min={min}
          aria-invalid={ariaInvalid}
          autoComplete='off'
          aria-describedby={ariaDescribedby}
          ref={Inputref}
          onFocus={onFocus}
          readOnly={readOnly}
          className='w-full px-5 border-[#999] focus:border-[#999] focus:shadow-none focus:outline-none'
        />
        {password && (
          <div
            className='-ml-10 cursor-pointer'
            onClick={() => {
              setHidePassword(!hidePassword);
            }}>
            {hidePassword ? (
              <AiOutlineEye size={24} style={{color: COLORS.neutral}} />
            ) : (
              <AiOutlineEyeInvisible
                size={24}
                style={{color: COLORS.neutral}}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputField;
