import React, {ChangeEvent, useState} from 'react';
import moment from 'moment';
import {useProduct} from '../../app/hooks';
import Slider from '../ui/Slider';
import Input from './profile/input';
import {PencilSm} from '../../assets/images';
import {useNavigate, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import {ArrowLeft} from 'iconsax-react';

const ProductDetails = () => {
  const prod = useProduct().products;
  const {productId} = useParams();
  const product = prod.find((prod) => String(prod.id) === productId);
  // console.log(product);
  const [formData, setFormData] = useState<any>({
    additionalImg: product?.additionalImg,
    approvedBy: product?.approvedBy,
    approvedDate: product?.approvedDate,
    brand: product?.brand,
    category: product?.category,
    description: product?.description,
    discount: product?.discount,
    id: product?.id,
    location: product?.location,
    name: product?.name,
    price: product?.price,
    productImg: product?.productImg,
    quantity: product?.quantity,
    productStatus: product?.status,
    subCategory: product?.subCategory,
    vendor: product?.vendor,
    minimumStock: product?.minimumStock,
    warranty: product?.warranty,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const images = product?.additionalImg;
  const navigate = useNavigate();
  return (
    <div className='w-[90%]'>
      {/* Content */}
      <div className='bg-white p-6 relative'>
        <motion.div
          whileHover={{scale: 1.1}}
          className='w-24 text-center cursor-pointer'
          onClick={() => navigate(-1)}>
          <ArrowLeft size={28} variant='Linear' />
        </motion.div>
        <div className='flex flex-row items-center justify-center px-16 pb-4'>
          {product?.productImg ? (
            <div className='w-[400px] h-[400px] text-center object-cover'>
              <img
                src={product.productImg}
                alt='prdIm'
                className='object-contain h-full w-full'
              />
            </div>
          ) : (
            <>
              {images && (
                <div className='w-[400px] h-[400px] text-center object-cover'>
                  <Slider slideImage={images} />
                </div>
              )}
            </>
          )}
          {/* Additional Images */}
        </div>
        <div className=''>
          <span className='font-bold text-lg text-[#2E3ECE] w-full '>
            PRODUCT DETAILS
          </span>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-items-start justify-items-center gap-x-[2rem] gap-y-3 w-full h-auto p-5'>
          {/* First */}
          <div className='flex flex-col items-center justify-center gap-y-9'>
            <div className='space-y-16'>
              <Input
                label='CATEGORY'
                pattern=''
                handleChange={(e: any) => handleInputChange(e)}
                // name='category'
                valueField={formData.category || ''}
              />
              <Input
                label='SUB CATEGORY'
                pattern=''
                handleChange={(e: any) => handleInputChange(e)}
                // name='subCategory'
                valueField={formData.subCategory || ''}
              />
              <Input
                label='BRAND NAME'
                icon={PencilSm}
                pattern=''
                // name='brand'
                handleChange={handleInputChange}
                valueField={formData.brand || ''}
              />

              {/* Hidden on Laptop, show on Tablet  */}
              <div className='xl:hidden block space-y-16'>
                <Input
                  label='APPROVED DATE'
                  icon={PencilSm}
                  pattern=''
                  // name='approvedDate'
                  handleChange={handleInputChange}
                  valueField={moment(formData.approvedDate).format('lll') || ''}
                />
                <Input
                  label='LOCATION'
                  icon={PencilSm}
                  pattern=''
                  handleChange={handleInputChange}
                  // name='location'
                  valueField={formData.location}
                />
                <Input
                  label='WARRANTY'
                  icon={PencilSm}
                  pattern=''
                  handleChange={handleInputChange}
                  // name='name'
                  valueField={formData.warranty}
                />
              </div>
              {/* --------------- End Hidden --------------*/}
              <div className='top-2 left-2 flex flex-col items-start'>
                <span className='text-[#9A9AAA] font-medium pb-2'>
                  PRODUCT DESCRIPTION
                  <span className='text-red-500 text-base pl-1'>*</span>
                </span>
                <div className='w-[18rem] rounded-lg h-[7rem] border-[2px] border-[#E6E8EC] px-3'>
                  <textarea
                    className='outline-none w-full h-full p-3'
                    name='description'
                    value={formData.description}
                    placeholder=''
                    cols={10}
                    rows={4}
                    style={{
                      height: '100%',
                      fontFamily: 'montserrat',
                    }}
                    onChange={(e: any) => handleInputChange(e)}
                    required
                  />
                </div>
              </div>
              {/* <div className='xl:hidden block'>
                      <div className='text-primary pb-2'>Update Status</div>
                      <select
                        value={approvedData.status}
                        name='status'
                        className='outline-none h-10 w-[8rem]  cursor-pointer p-2 rounded-md bg-white border-[1.5px] text-sm'
                        onChange={(e: any) => handleSelectInputChange(e)}>
                        <option value='' className='text-xs'>
                          {' '}
                          Select Status
                        </option>
                        <option value={'approved'} className='text-xs'>
                          Approve
                        </option>
                        <option value={'rejected'} className='text-xs'>
                          Reject
                        </option>
                      </select>
                    </div> */}
            </div>
          </div>
          {/* Second */}
          <div className='space-y-16'>
            <Input
              label='NUMBER IN STOCK'
              icon={PencilSm}
              pattern=''
              handleChange={handleInputChange}
              // name='quantity'
              valueField={formData.quantity}
            />
            <Input
              label='PRICE'
              icon={PencilSm}
              pattern=''
              handleChange={handleInputChange}
              // name='price'
              valueField={formData.price}
            />
            <Input
              label='DISCOUNT'
              icon={PencilSm}
              pattern=''
              handleChange={handleInputChange}
              // name='discount'
              valueField={formData.discount}
            />
            <Input
              label='PRODUCT NAME'
              icon={PencilSm}
              pattern=''
              handleChange={handleInputChange}
              // name='name'
              valueField={formData.name}
            />
            <Input
              label='WARRANTY'
              icon={PencilSm}
              pattern=''
              handleChange={handleInputChange}
              // name='name'
              valueField={formData.warranty}
            />
            {/* Hidden on Laptop, show on Tablet  */}
            <div className='xl:hidden block space-y-16'>
              <Input
                label='STATUS'
                icon={PencilSm}
                pattern=''
                // name='productStatus'
                handleChange={handleInputChange}
                valueField={formData.productStatus || ''}
              />
              <Input
                label='APPROVED BY'
                icon={PencilSm}
                pattern=''
                // name='approvedBy'
                handleChange={handleInputChange}
                valueField={formData.approvedBy || ''}
              />
              <Input
                label='MINIMUM STOCK'
                icon={PencilSm}
                pattern=''
                // name='approvedBy'
                handleChange={handleInputChange}
                valueField={formData.minimumStock || ''}
              />
            </div>
            {/* --------------- End Hidden --------------*/}

            {/* <Button
                    handleSubmit={approveProduct}
                    type='button'
                    className='text-white font-bold bg-primary h-[3rem]'>
                    {isApproveLoading ? 'Loading...' : 'Update Status'}
                  </Button> */}
          </div>

          {/* Third  hidden on Tablet*/}
          <div className='space-y-16 xl:block hidden'>
            <Input
              label='STATUS'
              icon={PencilSm}
              pattern=''
              // name='productStatus'
              handleChange={handleInputChange}
              valueField={formData.productStatus || ''}
            />
            <Input
              label='APPROVED BY'
              icon={PencilSm}
              pattern=''
              // name='approvedBy'
              handleChange={handleInputChange}
              valueField={formData.approvedBy || ''}
            />
            <Input
              label='APPROVED DATE'
              icon={PencilSm}
              pattern=''
              // name='approvedDate'
              handleChange={handleInputChange}
              valueField={moment(formData.approvedDate).format('lll') || ''}
            />
            <Input
              label='LOCATION'
              icon={PencilSm}
              pattern=''
              handleChange={handleInputChange}
              // name='location'
              valueField={formData.location}
            />
            <Input
              label='MINIMUM STOCK'
              icon={PencilSm}
              pattern=''
              // name='approvedBy'
              handleChange={handleInputChange}
              valueField={formData.minimumStock || ''}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
