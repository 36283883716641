import React, {useState} from 'react';
import {motion, AnimatePresence} from 'framer-motion';
import {initialTabs as tabs} from '../../../components/dashboard/reviews/Reviews.interface';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import styled from 'styled-components';
import {COLORS} from '../../../assets/color';

const Reviews = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>REVIEWS</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>REVIEWS</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'></div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Body */}
        <div className='w-full mx-auto p-2 pt-10'>
          <nav>
            <ul className='gap-4'>
              {tabs.map((item) => (
                <li
                  key={item.label}
                  className={
                    item === selectedTab
                      ? 'text-primary 2xl:text-base text-xs font-poppinsRegular border-b-4 border-b-primary'
                      : 'text-gray-400 font-poppinsRegular 2xl:text-base text-xs'
                  }
                  onClick={() => setSelectedTab(item)}>
                  {`${item.label}`}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className='w-full'>
          <AnimatePresence mode='wait'>
            <motion.div
              key={selectedTab ? selectedTab.label : 'empty'}
              initial={{y: 10, opacity: 0}}
              animate={{y: 0, opacity: 1}}
              exit={{y: -10, opacity: 0}}
              transition={{duration: 0.2}}>
              <div className='w-full py-2 text-black'>
                {selectedTab ? selectedTab.route : ''}
              </div>
            </motion.div>
          </AnimatePresence>
        </div>
      </div>
    </AppContainer>
  );
};

export default Reviews;

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
