import {ITransactions} from '../../../components/interfaces/products';
import moment from 'moment';

interface IVendorOrderTable {
  transactions: ITransactions[];
}
const AdminTransactionTable = ({transactions}: IVendorOrderTable) => {
  return (
    <>
      <div className=' sm:block hidden'>
        <table className=' mt-4 w-full overflow-scroll text-xs table-fixed border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-10 '>Order Code</th>
              <th className='w-10 '>Transaction Date</th>
              <th className='w-16'>Email</th>
              <th className='w-10'>Phone Number</th>
              <th className='w-10'>Amount</th>
              <th className='w-16'>Reference</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8FAFC]'>
            {transactions?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={6}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {transactions?.map((transaction: any) => {
                return (
                  <tr
                    className='overflow-x-auto h-[3rem] font-medium'
                    key={transaction.id}>
                    <td className='w-10 text-center'>
                      #{transaction.orderCode}
                    </td>
                    <td className='w-16 text-center'>
                      {moment(transaction.createdAt).format('lll')}
                    </td>
                    <td className='w-10 text-center'>{transaction.email}</td>
                    <td className='w-10 text-center'>
                      {transaction.phoneNumber}
                    </td>
                    <td className='w-10 text-center'>{transaction.amount}</td>
                    <td className='w-16 text-center overflow-hidden'>
                      <div className='w-full h-full mx-auto p-2 whitespace-normal flex items-center justify-center'>
                        <span className='break-words'>
                          {transaction.reference}
                        </span>
                      </div>
                    </td>
                    {/* Action */}
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AdminTransactionTable;
