// rating

import {BiStar} from 'react-icons/bi';

export const renderRatingStars = (rating: number) => {
  const totalStars = 5;
  const fullStars = Math.floor(rating);
  const halfStars = Math.ceil(rating - fullStars);
  const emptyStars = totalStars - fullStars - halfStars;

  const stars = [];
  for (let i = 0; i < fullStars; i++) {
    stars.push(<BiStar key={i} size={20} style={{color: '#FFD700'}} />);
  }
  if (halfStars === 1) {
    stars.push(
      <BiStar key={stars.length} size={20} style={{color: '#FFD700'}}></BiStar>
    );
  }
  for (let i = 0; i < emptyStars; i++) {
    stars.push(
      <BiStar key={stars.length} size={20} style={{color: '#D3D3D3'}}></BiStar>
    );
  }
  return stars;
};
