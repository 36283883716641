import {useNavigate} from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import {toast} from 'react-toastify';
import {regexValidation} from '../../components/Helpers/regexValidation';
import {useAppDispatch} from '../../app/hooks';
import {AuthServices} from '../../redux/services/admin/auths';
import {axiosError} from '../../components/Helpers';
import InputField from '../../components/forms/inputs/inputField/InputField';
import {BsInfo} from 'react-icons/bs';
import Button from '../../components/ui/Button';
import {Loader} from '../../assets/svg';
import {LoginAnimate} from '../../assets/images';
import {
  setBuyerSupportCredentials,
  setSupportCredentials,
} from '../../redux/slices/support/userSlice';
import CustomDropDown from '../../components/forms/CustomDropDown';

const LoginScreen = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [validEmailPhone, setValidEmailPhone] = useState(false);
  const [emailPhoneFocus, setEmailPhoneFocus] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const emailPhoneRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState({
    emailPhone: '',
    password: '',
  });

  // Check Validation
  useEffect(() => {
    emailPhoneRef.current?.focus();
  }, []);

  // REGEX Validations
  useEffect(() => {
    const isEmailPhoneValid = regexValidation.validEmailPhone(state.emailPhone);
    const isPasswordValid = regexValidation.adminValidatePassword(
      state.password
    );
    setValidEmailPhone(isEmailPhoneValid);
    setValidPwd(isPasswordValid);
  }, [state.emailPhone, state.password]);

  // HandleChange
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setState((p: any) => ({
      ...p,
      [name]: value,
    }));
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Handle Drop Down
  const options = [
    {value: 'Vendor Management', label: 'Vendor Management'},
    {value: 'Customer Management', label: 'Customer Management'},
  ];
  const handleDropdownChange = (selectedValue: string) => {
    // console.log('Selected Value:', selectedValue);
    // Implement your logic here when the dropdown value changes
    setSelectedStatus(selectedValue);
  };

  const handleSubmit = async () => {
    if (!selectedStatus) {
      toast.error('Please select your role');
      return;
    }
    setIsLoading(true);
    const data = {
      emailPhone: state.emailPhone,
      password: state.password,
    };
    try {
      const response = await AuthServices.supportLogin(data);
      if (response.data.status === 'success') {
        const res = response.data;
        // console.log(res);
        if (selectedStatus === 'Vendor Management') {
          if (res.data.details.role === 'Vendor Management') {
            dispatch(setSupportCredentials(res.data));
            navigate('../support-desk/chat');
            toast.success(res.data.message || 'Login Successfully');
            // console.log(`Response`);
            setIsLoading(false);
          } else {
            toast.error('Invalid Credentials for selected role');
            setIsLoading(false);
          }
        } else {
          if (res.data.details.role === 'Buyer Management') {
            dispatch(setBuyerSupportCredentials(res.data));
            navigate('../support-desk/chat-customer');
            toast.success(res.data.message || 'Login Successfully');
            // console.log(`Response`);
            setIsLoading(false);
          } else {
            toast.error('Invalid Credentials for selected role');
            setIsLoading(false);
          }
        }
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  const isMobile = window.matchMedia(
    'only screen and (max-width: 760px)'
  ).matches;
  if (isMobile) {
    return (
      <div className='text-lg text-center font-poppinsRegular bg-primary text-white flex justify-center items-center p-8 h-screen'>
        Sorry, this admin app is only accessible by tablet, TV or desktop users.
        Kindly use the supported devices.
      </div>
    );
  }

  return (
    <div className='grid place-items-center pt-2 w-full h-full bg-blue-50 py-4'>
      <div className='w-full flex lg:flex-row flex-col lg:justify-between justify-center items-center h-full lg:container mx-auto px-24 gap-10'>
        <div className='lg:w-[60%] w-full'>
          <div className='lg:h-[800px] lg:w-[800px] w-[500px] h-[300px] mx-auto'>
            <img
              src={LoginAnimate}
              alt='animate-imag'
              className='w-full h-full object-contain'
            />
          </div>
        </div>
        <div className='lg:w-[40%] w-full'>
          <form className='w-[80%] mx-auto'>
            <h3 className='text-base md:text-lg text-center pb-3 font-poppinsSemibold text-primary underline'>
              SUPPORT DESK
            </h3>
            <InputField
              label='Email or Phone Number'
              name='emailPhone'
              type='text'
              Inputref={emailPhoneRef}
              placeholder='Enter Email or Phone Number'
              value={state.emailPhone}
              required={true}
              ariaInvalid={validEmailPhone ? 'false' : 'true'}
              ariaDescribedby='uiuser'
              onFocus={() => setEmailPhoneFocus(true)}
              onBlur={() => setEmailPhoneFocus(false)}
              onChange={handleChange}
              style={{height: '100%'}}
            />
            <p
              id='uidnote'
              className={
                emailPhoneFocus && !validEmailPhone
                  ? 'instructions'
                  : 'offscreen'
              }>
              <BsInfo size={24} />
              Please enter valid Email or Phone Number
            </p>

            <InputField
              name='password'
              label='Password'
              type='password'
              placeholder='************'
              value={state.password}
              required={true}
              ariaInvalid={validPwd ? 'false' : 'true'}
              ariaDescribedby='uipwd'
              onFocus={() => setPwdFocus(true)}
              onBlur={() => setPwdFocus(false)}
              password
              onChange={handleChange}
              style={{height: '100%'}}
            />
            <p
              id='uipwd'
              className={
                pwdFocus && !validPwd
                  ? 'instructions text-xs'
                  : 'offscreen text-xs'
              }>
              <BsInfo size={24} />
              Minimum of 8 and Maximum of 16 characters.
              <br />
              Must include uppercase and lowercase letters, a number and a
              special character.
              <br />
              <br />
              Must not include whitespace.
              <br />
              Allowed special characters
              <span className='pl-2 tracking-wide'>#?!@$%^&*-</span>
              {/* (?=.*?[#?!@$%^&*-]) */}
            </p>
            <div className='w-full'>
              <CustomDropDown
                options={options}
                onChange={handleDropdownChange}
                placeholder='Select Support Role'
                type='forLogin'
              />
            </div>
            <div className='py-2'></div>
            <Button
              label={
                isLoading ? (
                  <div>
                    <Loader color={'#fff'} />
                  </div>
                ) : (
                  'Sign in'
                )
              }
              width='full'
              onClick={handleSubmit}
              // onClick={() => navigate('../admin/dashboard')}
            />

            {/* Don't have an account */}
            {/* <div className='flex  justify-center text-sm text-center px-12'>
                <p className='cursor-pointer text-menuIcon'>
                  Don't have an account? &nbsp;
                  <span
                    className='font-poppinsSemibold text-primary'
                    onClick={() => {
                      navigate('/admin/reg');
                    }}>
                    Sign up
                  </span>
                </p>
              </div> */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
