/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useRef, useState} from 'react';
import {AnimatePresence, motion} from 'framer-motion';
import {Logo, botAvatar} from '../../../assets/images';
import {MdOutlineClearAll} from 'react-icons/md';
import {ChatContext} from '../../../chat/context/chat';
import Actions from '../../../chat/actions';
import {ChatBox} from '../../../chat/components/vendorChat';
import {AuthContext} from '../../../chat/context/auth';
import {useAppDispatch, useVendor} from '../../../app/hooks';
import {setChatVendors} from '../../../redux/slices/chat/chatVendorSlice';
import {useNavigate} from 'react-router-dom';

const ChatWidget = () => {
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);

  const toggleChatbox = () => {
    setIsChatboxOpen(!isChatboxOpen);
  };

  const vendorUser = useVendor().vendor;
  const {
    updateChatUsers,
    chatUsers,
    onlineUsers,
    updatePotentialChats,
    //  updateCurrentChat,
    currentChat,
    updateChatMessages,
    chatMessages,
    updateAllUsers,
    notifications,
    potentialChats,
  } = useContext(ChatContext);
  const {user, updateUser} = useContext(AuthContext);
  const [loading, setLoading] = useState({
    chatUsersLoading: false,
    potentialUsersLoading: false,
    chatMessagesLoading: false,
  });
  const {chatMessagesLoading} = loading;
  //  const [myLoading, setMyLoading] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const scroll = useRef<HTMLDivElement>();

  // Create Chat
  async function handleAddChat(id: any) {
    const response = await Actions.createChat({secondUser: id});
    if (response?.status === 'success') {
      // console.log('response', response.data);
      updateChatUsers((prev: any) => [...prev, response?.data?.details]);
    } else if (
      (response?.status === 'failed' &&
        response?.message === 'Invalid token or token expired') ||
      response?.message === 'Authorization denied! Token expired'
    ) {
      localStorage.clear();
      window.location.reload();
    } else if (
      response?.status === 'failed' &&
      response?.message === 'ERR no such key'
    ) {
      console.log(response?.message);
    } else {
      console.log(response?.message || 'Connection Error');
    }
    // handleFireChatStraight();
  }

  useEffect(() => {
    updateUser(vendorUser);
    const getChatUsers = async () => {
      try {
        // setMyLoading(true);
        setLoading({...loading, chatUsersLoading: true});
        const response = await Actions.fetchUserChats();
        if (response?.badToken) localStorage.removeItem('vendorInfo');
        if (response?.status === 'success') {
          updateChatUsers(response?.data?.details);
        } else if (
          (response?.status === 'failed' &&
            response?.message === 'Invalid token or token expired') ||
          response?.message === 'Authorization denied! Token expired'
        ) {
          localStorage.clear();
          window.location.reload();
        } else if (
          response?.status === 'failed' &&
          response?.message === 'ERR no such key'
        ) {
          console.log(response?.message);
        } else {
          console.log(response?.message || 'Connection Error');
        }
        // setMyLoading(false);
        setLoading({...loading, chatUsersLoading: false});
      } catch (error) {
        console.log('chat user error: ', error);
        // setMyLoading(false);
      }
    };
    getChatUsers();
  }, [navigate, notifications]);

  useEffect(() => {
    const getPotentialChatUsers = async () => {
      try {
        setLoading({...loading, potentialUsersLoading: true});
        const response = await Actions.fetchAllSupportUsers();
        // console.log('response', response);
        if (response?.badToken) localStorage.removeItem('vendorInfo');
        if (response?.status === 'success') {
          const otherUsers = response?.data?.details?.filter(
            (singleUser: any) => singleUser?.id !== user?.id
          );
          // console.log(otherUsers, 'Others');

          // const arrOfIds = chatUsers?.reduce(
          //   (ids: any, item: any) => [...ids, ...item.members],
          //   []
          // );
          // console.log(arrOfIds, 'Arr');
          // const potentialUsers = otherUsers?.filter((user: any) =>
          //   arrOfIds.includes(user?.id)
          // );
          const potentialUsers = otherUsers?.filter(
            (user: any) =>
              user?.role === 'Vendor Management' &&
              user?.email === 'johndoe@gmail.com'
          );
          localStorage.setItem(
            'supportId',
            JSON.stringify(potentialUsers[0].id)
          );
          updateAllUsers(potentialUsers);
          dispatch(setChatVendors(potentialUsers));
          // Update Potential uSers
          updatePotentialChats(potentialUsers);
          setLoading({...loading, potentialUsersLoading: false});
        } else if (
          (response?.status === 'failed' &&
            response?.message === 'Invalid token or token expired') ||
          response?.message === 'Authorization denied! Token expired'
        ) {
          localStorage.clear();
          window.location.reload();
        } else if (
          response?.status === 'failed' &&
          response?.message === 'ERR no such key'
        ) {
          console.log(response?.message);
        } else {
          console.log(response?.message || 'Connection Error');
        }
      } catch (error) {
        console.log('potential chat user error: ', error);
      }
    };
    getPotentialChatUsers();
  }, [chatUsers]);

  useEffect(() => {
    const getChatMessages = async () => {
      try {
        if (currentChat?._id) {
          setLoading({...loading, chatMessagesLoading: true});
          const response = await Actions.findChatMessages(currentChat?._id);
          if (response?.badToken) localStorage.removeItem('vendorInfo');
          if (response?.status === 'success') {
            updateChatMessages(response?.data?.details);
          } else if (
            (response?.status === 'failed' &&
              response?.message === 'Invalid token or token expired') ||
            response?.message === 'Authorization denied! Token expired'
          ) {
            localStorage.clear();
            window.location.reload();
          } else if (
            response?.status === 'failed' &&
            response?.message === 'ERR no such key'
          ) {
            console.log(response?.message);
          } else {
            console.log(response?.message || 'Connection Error');
          }
        }
        // const response = await Actions.findChatMessages(currentChat?.id)
      } catch (error) {
        console.log('chat message error: ', error);
      }
    };
    getChatMessages();
  }, [currentChat]);

  useEffect(() => {
    scroll?.current?.scrollIntoView({behavior: 'smooth'});
  }, [chatMessages]);

  const supportId = JSON.parse(localStorage.getItem('supportId') || '{}');

  // // Notification
  // const recipientId = chatUsers?.members?.find((id: any) => id === supportId);
  // const unreadNotification = unreadNotifications(notifications);
  // const specificUserNotifications = unreadNotification?.filter(
  //   (notification: any) => notification?.senderId === recipientId
  // );
  // console.log(onlineUsers, 'ddddd');

  // console.log(supportId, 'supportId');
  return (
    <>
      <AnimatePresence>
        {isChatboxOpen && (
          <motion.div
            className=' fixed bottom-24 right-5 z-50 flex h-[70vh] w-[400px] flex-col bg-primary rounded-xl font-poppinsRegular xs:right-0 xs:h-[calc(100%-100px)] xs:w-full shadow-[5px_5px_rgba(0,_98,_90,_0.4),_10px_10px_rgba(0,_98,_90,_0.3),_15px_15px_rgba(0,_98,_90,_0.2),_20px_20px_rgba(0,_98,_90,_0.1),_25px_25px_rgba(0,_98,_90,_0.05)]'
            initial={{y: 60, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: -60, opacity: 0}}
            transition={{type: 'spring', stiffness: 100}}
            key='widget'>
            {/* Header */}
            <div className='flex flex-row items-center justify-between px-2 py-4'>
              {/* Icon */}
              <div className='flex flex-row gap-4 items-center relative'>
                {/* Image */}
                <img src={Logo} className='h-12 w-12' alt='bot' />
                <div className='flex flex-col items-start gap-2'>
                  <p className='text-base font-semibold text-white'>
                    Support Desk
                  </p>
                  <p className='text-xs font-poppinsMedium text-white'>
                    Welcome to Help center
                  </p>
                </div>
                <div className='absolute top-0 right-0'>
                  <span
                    className={
                      onlineUsers?.some(
                        (onlineUser: any) => onlineUser?.userId === supportId
                      )
                        ? 'user-online'
                        : 'user-offline'
                    }></span>
                </div>
              </div>

              {/* icon */}
              <div onClick={toggleChatbox} className='cursor-pointer'>
                <MdOutlineClearAll size={30} color='white' />
              </div>
            </div>

            <div className='bg-white rounded-xl max-w-lg w-full h-full relative'>
              <ChatBox
                loading={chatMessagesLoading}
                scroll={scroll}
                potentialChats={potentialChats}
                handleAddChat={handleAddChat}
                chatUsers={chatUsers}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <motion.div
        animate={{
          scale: [0, 1.1, 1],
        }}
        transition={{type: 'spring', stiffness: 400, damping: 17}}
        className={
          isChatboxOpen
            ? `hidden`
            : 'fixed right-5 bottom-2 mr-2  inline-flex items-center rounded-full p-2 text-center text-sm font-medium text-white xs:right-0 bg-primary cursor-pointer'
        }
        onClick={(e) => {
          e.preventDefault();
          setIsChatboxOpen(!isChatboxOpen);
        }}>
        <AnimatePresence>
          {/* {isChatboxOpen ? (
            <motion.div
              animate={{
                rotate: [0, 90],
              }}>
              <CgClose className='h-12 w-12' />
            </motion.div>
          ) : ( */}
          <motion.div className='relative'>
            <img src={botAvatar} className='h-12 w-12' alt='bot' />
            <div className='absolute top-0 right-0'>
              <span
                className={
                  onlineUsers?.some(
                    (onlineUser: any) => onlineUser?.userId === supportId
                  )
                    ? 'user-online'
                    : 'user-offline'
                }></span>
            </div>
          </motion.div>
          {/* )} */}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default ChatWidget;
