import React from 'react';
import {useAdminOrder} from '../../../../app/hooks';
import styles from '../../../../assets/styles/modals/ConfirmModal.module.css';
import {CloseCircle} from 'iconsax-react';
import {numberFormat} from '../../../../components/Helpers';

interface IModal {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ViewOrder = ({setIsOpen}: IModal) => {
  const order = useAdminOrder().adminOrder;
  return (
    <div>
      <div className={styles.productViewBG} onClick={() => setIsOpen(true)} />
      <div className={styles.productCentered}>
        <div className={styles.productViewModal}>
          <div className={styles.modalContent}>
            {/* Content */}
            <div className='bg-white p-6 relative '>
              <div
                className='absolute top-0 right-0 cursor-pointer'
                onClick={() => setIsOpen(false)}>
                <CloseCircle size={22} variant='Bold' color='red' />
              </div>
              <div className='w-[80%] mx-auto font-poppinsRegular'>
                {/* Body */}
                <div className='text-xl font-poppinsSemibold underline '>
                  ORDER DETAILS
                </div>
                <div className='flex flex-row justify-between items-center  py-4 bg-primary text-white w-full px-2 rounded-md'>
                  <div className='flex flex-row items-center'>
                    <p className=' text-gray-400 font-medium pr-2 uppercase'>
                      Order ID :
                    </p>
                    <p className='text-white font-medium'>
                      {order.id.slice(0, 10)}
                    </p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className=' text-gray-400 font-medium pr-2 uppercase'>
                      Code :
                    </p>
                    <p className='text-white font-medium'>{order.code}</p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className=' text-gray-400 font-medium pr-2 uppercase'>
                      Number Of Product:
                    </p>

                    <p className='text-white font-medium'>
                      {order.orderProducts.length}
                    </p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className=' text-gray-400 font-medium pr-2 uppercase'>
                      Total Amount:
                    </p>
                    <p className='text-white font-medium'>
                      ₦{numberFormat(order.totalAmount)}
                    </p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <p className=' text-gray-400 font-medium pr-2 uppercase'>
                      Status:
                    </p>
                    <p className='text-white font-medium'>{order.status}</p>
                  </div>
                </div>

                <div className='flex flex-col gap-4 items-start justify-center w-full pt-10'>
                  {order?.orderProducts.map((item) => (
                    <div
                      key={item.id}
                      className='flex flex-row gap-10 items-start w-full'>
                      {/* Image */}
                      <div className='w-64 h-64 border rounded-md shadow-lg'>
                        <img
                          src={item.product?.additionalImg[0]}
                          alt='prodImg'
                          className='w-full h-full rounded-md object-contain'
                        />
                      </div>
                      <div className='flex flex-col gap-4'>
                        {/* Product Name */}
                        <div className='flex flex-row items-center'>
                          <p className=' text-gray-400 font-medium pr-2 '>
                            Product Name:
                          </p>
                          <p className='text-black'>{item.product?.name}</p>
                        </div>
                        {/* Quantity */}
                        <div className='flex flex-row items-center'>
                          <p className=' text-gray-400 font-medium pr-2 '>
                            Quantity:
                          </p>
                          <p className='text-black'>{item?.quantity}</p>
                        </div>
                        {/* Price */}
                        <div className='flex flex-row items-center'>
                          <p className=' text-gray-400 font-medium pr-2 '>
                            Price:
                          </p>
                          <p className='text-black'>
                            ₦{numberFormat(item.subTotal)}
                          </p>
                        </div>
                        {/* Status */}
                        <div className='flex flex-row items-center'>
                          <p className=' text-gray-400 font-medium pr-2 '>
                            Status:
                          </p>
                          <p className='text-black capitalize'>
                            {order.status}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOrder;
