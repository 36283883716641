import {COLORS} from '../../assets/color';

interface IButton {
  width: 'full' | 'medium' | 'small';
  label: any;
  onClick?: any;
  disabled?: boolean;
  bgColor?: string;
  textCOlor?: string;
  borderCOlor?: string;
  icon?: boolean;
}
const ButtonAuth = ({
  width,
  label,
  disabled,
  onClick,
  bgColor,
  borderCOlor,
  textCOlor,
}: IButton) => {
  return (
    <>
      {width === 'full' ? (
        <div
          className={
            disabled
              ? 'opacity-40 login_button w-full py-3 my-2 rounded-lg border transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300'
              : 'login_button w-full py-3 my-2 rounded-lg border transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300'
          }
          style={{
            backgroundColor: disabled
              ? COLORS.primary
              : bgColor
              ? bgColor
              : COLORS.primary,
            borderColor: borderCOlor ? borderCOlor : COLORS.primary,
          }}>
          <button
            style={{color: textCOlor ? textCOlor : COLORS.white}}
            type='button'
            className='w-full h-full font-poppinsRegular flex flex-row justify-center items-center gap-2'
            onClick={onClick}
            disabled={disabled}>
            <span className='text-sm font-poppinsSemibold'>{label}</span>
          </button>
        </div>
      ) : width === 'medium' ? (
        <div
          className={
            disabled
              ? ' opacity-40 login_button py-3 mt-4 mb-4 w-5 6rounded-lg border transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300'
              : 'login_button py-3 mt-4 mb-4 w-56 rounded-lg border transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300 '
          }
          // style={disabled ? {opacity: 0.3} : {opacity: 1}}
          style={{
            backgroundColor: disabled
              ? COLORS.primary
              : bgColor
              ? bgColor
              : COLORS.primary,
            borderColor: borderCOlor ? borderCOlor : COLORS.primary,
          }}>
          <button
            style={{color: textCOlor ? textCOlor : COLORS.white}}
            type='button'
            className=' text-sm w-full h-full font-poppinsSemibold'
            onClick={onClick}
            disabled={disabled}>
            <span className=''>{label}</span>
          </button>
        </div>
      ) : (
        width === 'small' && (
          <div
            className={
              disabled
                ? 'opacity-40 py-3 w-36 rounded-lg border transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300'
                : 'py-3 w-36 rounded-lg border transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300'
            }
            style={{
              backgroundColor: disabled
                ? COLORS.primary
                : bgColor
                ? bgColor
                : COLORS.primary,
              borderColor: borderCOlor ? borderCOlor : COLORS.primary,
            }}>
            <button
              style={{color: textCOlor ? textCOlor : COLORS.white}}
              type='button'
              className='w-full h-full font-poppinsSemibold flex flex-row justify-center items-center gap-2'
              onClick={onClick}
              disabled={disabled}>
              <span className='font-poppinsSemibold'>{label}</span>
            </button>
          </div>
        )
      )}
    </>
  );
};

export default ButtonAuth;
