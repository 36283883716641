import {createSlice} from '@reduxjs/toolkit';
import {RootState} from '../../../app/store';
import {IAdminSettleReducer} from '../../../types';

const initialState: IAdminSettleReducer = {
  settlement: localStorage.getItem('#amstl')
    ? JSON.parse(localStorage.getItem('amstl') || '{}')
    : null,
};

const adminSettlementReducer = createSlice({
  name: 'settlement',
  initialState,
  reducers: {
    setSingleAdminSettlement: (state, action) => {
      state.settlement = action.payload;
      localStorage.setItem('amstl', JSON.stringify(action.payload));
    },
  },
});

export const {setSingleAdminSettlement} = adminSettlementReducer.actions;
export const selectAdminSettlement = (state: RootState) =>
  state.settlement.settlement;

export default adminSettlementReducer.reducer;
