import moment from 'moment';
import React from 'react';
import {numberFormat} from './Helpers';
interface IPaymentTable {
  payments: any[];
}
const VendorPaymentTable = ({payments}: IPaymentTable) => {
  return (
    <>
      <div className=' sm:block hidden'>
        <table className=' mt-4 w-full overflow-scroll text-xs border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th className='w-16 rounded-tl-md'>Account Name</th>
              <th className='w-16'>Transaction Date</th>
              <th className='w-10'>Account Number</th>
              <th className='w-10'>Bank Name</th>
              <th className='w-10'>Amount</th>
              <th className='w-10'>Status</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-[#F8FAFC]'>
            {payments?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={6}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {payments?.map((payment: any) => {
                return (
                  <tr
                    className='overflow-x-auto h-[3rem] font-medium'
                    key={payment.id}>
                    <td className='w-10 text-center'>{payment.accountName}</td>
                    <td className='w-16 text-center'>
                      {moment(payment.settlementDate).format('lll')}
                    </td>
                    <td className='w-10 text-center'>{payment.accountNo}</td>
                    <td className='w-10 text-center'>{payment.bankName}</td>
                    <td className='w-10 text-center'>
                      {numberFormat(payment.amount)}
                    </td>
                    <td className='w-10 text-center'>{payment.status}</td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>

      {/* Table Mobile */}
      <div className='sm:hidden flex items-center justify-center w-full'>
        <table className='border-separate border-spacing-y-2 text-sm overflow-x-auto w-full table-fixed'>
          <thead className=''>
            <tr className='bg-orange-100 h-[3.125rem] text-primary'>
              <th className=' rounded-tl-md'>A/Name</th>
              <th className=''> Date</th>
              <th className=''>A/No</th>
              <th className=''>Bank</th>
              <th className=''>Amount</th>
              <th className=''>Status</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-50'>
            {payments?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={6}>
                  <span className='text-base text-primary font-semibold'>
                    No Product available
                  </span>
                </td>
              </tr>
            )}
            <>
              {payments?.map((payment: any) => {
                return (
                  <tr
                    className='overflow-x-auto h-[3rem] font-medium'
                    key={payment.id}>
                    <td className=' text-center td-transClass'>
                      {payment.accountName}
                    </td>
                    <td className=' text-center td-transClass'>
                      {moment(payment.createdAt).format('ll')}
                    </td>
                    <td className=' text-center td-transClass'>
                      ***{payment.accountNo.slice(7, 10)}
                    </td>
                    <td className=' text-center td-transClass'>
                      {payment.bankName}
                    </td>
                    <td className=' text-center td-transClass'>
                      {numberFormat(payment.amount)}
                    </td>
                    <td className=' text-center td-transClass'>
                      {payment.status}
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default VendorPaymentTable;
