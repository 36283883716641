import AllProducts from './AllProducts';
import PendingOrders from './PendingOrders';

export interface IWithdrawal {
  label: string;
  route?: React.ReactNode;
}

export const dashboardSlices: IWithdrawal[] = [
  {
    label: 'All Products',
    route: <AllProducts />,
  },
  {label: 'Pending Order', route: <PendingOrders />},
];

const [AllProduct, PendingOrder] = dashboardSlices;
export const initialTabs = [AllProduct, PendingOrder];

export function getNextWithdrawal(
  slides: IWithdrawal[]
): IWithdrawal | undefined {
  const existing = new Set(slides);
  return dashboardSlices.find((slide) => !existing.has(slide));
}
