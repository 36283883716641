import React, {ChangeEvent, useState} from 'react';
import {PencilSm} from '../../../../assets/images';
import Input from '../../../../components/dashboard/profile/input';
import {CloseCircle} from 'iconsax-react';
import {useAdminVendor} from '../../../../app/hooks';
import {IVendor} from '../../../../components/interfaces/vendors';
import {Loader} from '../../../../assets/svg';
import Button from '../../../../components/ui/Button';

interface IModal {
  handleReset: (id: string, vendorEmail: string) => void;
  isActionLoading: boolean;
  setShowView: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResetVendor = ({setShowView, handleReset, isActionLoading}: IModal) => {
  const vendor = useAdminVendor().adminVendor;
  //   console.log(vendor);
  const [formData, setFormData] = useState<IVendor>({
    email: vendor?.email,
  });

  // Handle Input Change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const vendorId: any = vendor?.id;
  const vendorEmail: any = formData.email;

  return (
    <div>
      <div
        className='bg-[#002668] w-screen h-screen z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fixed opacity-30'
        onClick={() => setShowView(true)}
      />
      <div className='z-50 absolute top-[35%] left-[35%] w-full'>
        {/* Content */}
        <div className='bg-white p-6 relative rounded-lg w-[50%] lg:w-[40%] xl:w-[30%]'>
          <div
            className='absolute top-4 right-4 cursor-pointer'
            onClick={() => setShowView(false)}>
            <CloseCircle size={22} variant='Bold' color='red' />
          </div>
          <div className='w-full text-center'>
            <span className='font-bold text-base text-[#2E3ECE]'>
              Reset for {vendor?.firstName}
            </span>
          </div>
          <div className='flex flex-col gap-6 md:w-[80%] mx-auto'>
            <Input
              label='EMAIL'
              icon={PencilSm}
              name='email'
              handleChange={handleInputChange}
              valueField={formData.email || ''}
              placeholder='Enter New Email'
              required
            />

            {isActionLoading ? (
              <Button
                label={<Loader color='white' />}
                width='full'
                bgColor='#825A3F'
                textCOlor='white'
              />
            ) : (
              <Button
                label={'Reset'}
                width='full'
                bgColor='#825A3F'
                textCOlor='white'
                onClick={() => handleReset(vendorId, vendorEmail)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetVendor;
