import {Navigate, Outlet} from 'react-router-dom';
import {useSupport} from '../app/hooks';

const AdminChatProtectedRoutes = () => {
  const token = useSupport().supportToken;
  return token ? <Outlet /> : <Navigate to='/support-desk' replace />;
};
// const AdminProtectedRoutes = () => {
//   return <Outlet />;
// };

export default AdminChatProtectedRoutes;
