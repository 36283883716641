import {store} from '../../app';
import {api} from '../utils';

// console.log(store.getState().vendor.vendor?.token, 'token');

export const createChat = async (body) => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;

    try {
      const data = await api.req('chat', 'POST', body, token);
      return data;
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};

export const fetchUserChats = async () => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;

    try {
      if (token) {
        const data = await api.req('chat/user_chats', undefined, null, token);
        return data;
      }
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};

export const findChatMessages = async (chatId) => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;

    try {
      const data = await api.req(
        `chat/messages/${chatId}`,
        undefined,
        null,
        token
      );
      return data;
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};

export const sendChatMessage = async (body) => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;

    try {
      const data = await api.req(`chat/message`, 'POST', body, token);
      return data;
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};

export const addNotification = async (body) => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;

    try {
      const data = await api.req(`chat/notification`, 'POST', body, token);
      return data;
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};

export const findNotifications = async () => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;
    // console.log(vendorToken, supportToken);
    // console.log('notificationssss');

    // const tokens = [supportToken, vendorToken];
    try {
      const data = await api.req(`chat/notifications`, undefined, null, token);
      return data;
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};

export const updateNotification = async (body) => {
  try {
    const token = localStorage.getItem('supportInfo')
      ? JSON.parse(localStorage.getItem('supportInfo')).token
      : null || localStorage.getItem('supportBuyerInfo')
      ? JSON.parse(localStorage.getItem('supportBuyerInfo')).token
      : null || store.getState().vendor.vendor?.token;
    try {
      const data = await api.req(`chat/notification`, 'PATCH', body, token);
      return data;
    } catch (error) {
      // If fetching with this token fails, log the error and try the next token
      console.log(`Failed to fetch with token: ${token}`);
      console.log(error?.message);
    }
    // If none of the tokens worked, you can throw an error or return null
    throw new Error('Unable to fetch notifications with any available token');
  } catch (error) {
    console.log(error?.message);
  }
};
