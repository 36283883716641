/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {IProduct} from '../interfaces/products';
import {axiosError, numberFormat} from '../Helpers';
import {useAdmin} from '../../app/hooks';
import {AdminServices} from '../../redux/services/admin/admin';
import TableDataSkeleton from '../ui/skeletonUI/TableDataSkeleton';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {setAllProducts} from '../../redux/slices/product/productSlice';

const AllProducts = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = useAdmin().adminToken;

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.allProducts(token);
      setIsLoading(false);
      // console.log(response);
      if (response.data.status === 'success') {
        const res = response.data.data.products;
        setProducts(res.rows.slice(0, 12));
        dispatch(setAllProducts(res.rows));
        setIsLoading(false);
      }
    } catch (error: any) {
      // console.log(error);
      axiosError(error.response);
      setIsLoading(false);
    }
  };
  return (
    <>
      <div className=' sm:block hidden'>
        {loading ? (
          <div className='w-full'>
            <TableDataSkeleton />
          </div>
        ) : (
          <table className=' mt-4 w-full overflow-scroll text-xs'>
            <thead>
              <tr className='h-[2rem] text-[#6C757D]'>
                <th className='w-10 rounded-tl-md text-start'>Name</th>
                <th className='w-10 text-start'>#Product ID</th>
                <th className='w-10 text-start'>Amount</th>
                <th className='w-10 text-center'>Details</th>
                <th className='w-10 text-center'>Status</th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {products?.length === 0 && (
                <tr>
                  <td className='text-center py-6' colSpan={5}>
                    <span className='text-base text-primary font-semibold'>
                      No Data available
                    </span>
                  </td>
                </tr>
              )}
              <>
                {products?.map((product) => {
                  return (
                    <tr
                      className='overflow-x-auto h-[2rem] font-medium text-[#6C757D]'
                      key={product.id}>
                      <td className='w-10'>
                        {product.name?.slice(0, 15) + '...'}
                      </td>
                      <td className='w-10'>#{product.id.slice(0, 6)}</td>
                      <td className='w-10'>₦{numberFormat(product.price)}</td>
                      <td
                        className='w-10 hover:underline cursor-pointer hover:text-primary text-center'
                        onClick={() =>
                          navigate(`../admin/products/${product.id}`)
                        }>
                        View Details
                      </td>
                      {Number(product.quantity) > 0 ? (
                        <td className='w-10 text-success text-center '>
                          In Stock
                        </td>
                      ) : (
                        <td className='w-10 text-red-500 text-center'>
                          Out of Stock
                        </td>
                      )}
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        )}
      </div>

      {/* Table Mobile */}
      <div className=' block sm:hidden'>
        {loading ? (
          <div className='w-full'>
            <TableDataSkeleton />
          </div>
        ) : (
          <table className=' mt-4 w-full overflow-scroll text-xs'>
            <thead>
              <tr className='h-[2rem] text-[#6C757D]'>
                <th className='w-10 rounded-tl-md'>Name</th>
                <th className='w-16'>Product ID #</th>
                <th className='w-10'>Amount</th>
                <th className='w-10'>Details</th>
              </tr>
            </thead>
            <tbody className='bg-white'>
              {products?.length === 0 && (
                <tr>
                  <td className='text-center py-6' colSpan={5}>
                    <span className='text-base text-primary font-semibold'>
                      No Data available
                    </span>
                  </td>
                </tr>
              )}
              <>
                {products?.map((product: any) => {
                  return (
                    <tr
                      className='overflow-x-auto h-[2rem] font-medium text-[#6C757D]'
                      key={product.id}>
                      <td className='w-10'>{product.name}</td>
                      <td className='w-10 text-center'>
                        #{product.id.slice(0, 6)}
                      </td>
                      <td className='w-16 text-center'>
                        {' '}
                        ₦{numberFormat(product.price)}
                      </td>
                      <td className='w-10 text-center'>View Details</td>
                      {Number(product.quantity) > 0 ? (
                        <td className='w-10 text-success'>In Stock</td>
                      ) : (
                        <td className='w-10 text-red-500'>Out of Stock</td>
                      )}
                    </tr>
                  );
                })}
              </>
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default AllProducts;
