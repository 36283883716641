import styled from 'styled-components';
// import { Screen } from '../../styles';
import { Loader } from '../../assets/svg';

const LoaderContainer = styled.div`
  z-index: 99;
`;
const LoaderWrapper = styled.div`
  height: 20%;
  width: 20%;
`;
const componentLoader = ({ color }: any) => (
  <>
    <LoaderContainer>
      <LoaderWrapper>
        <Loader color={color} />
      </LoaderWrapper>
    </LoaderContainer>
  </>
);

export default componentLoader;
