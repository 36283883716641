/* eslint-disable react-hooks/exhaustive-deps */
import styled from 'styled-components';
import {Helmet} from 'react-helmet';
import NotificationIcon from '../../../components/layouts/header/notification/NotificationIcon';
import ProfileImage from '../../../components/layouts/header/profile/ProfileImage';
import Pagination from '../../../components/ui/Pagination';
import {BiSearch} from 'react-icons/bi';
import {COLORS} from '../../../assets/color';
import {addMonths} from 'date-fns';
import DateRangePicker from '../../../components/forms/inputs/selectInput/DateRangePicker';
import CommissionTable from '../../../components/table/CommissionTable';
import {useEffect, useState} from 'react';
import {AdminServices} from '../../../redux/services/admin/admin';
import {useAdmin} from '../../../app/hooks';
import {axiosError} from '../../../components/Helpers';
import TableDataSkeleton from '../../../components/ui/skeletonUI/TableDataSkeleton';

interface IComissionProduct {
  id: string;
  name: string;
  price: any;
  commission?: any;
  category?: any;
}
export interface ICommission {
  id: string;
  firstName: string;
  lastName: string;
  products: IComissionProduct[];
}
const Commissions = ({show}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const token = useAdmin().adminToken;
  const [commissions, setCommissions] = useState<ICommission[]>([]);

  // Query
  const [query, setQuery] = useState('');
  const keys = ['id', 'name', 'vendorName'];

  const search = (data: any) => {
    return data.filter((item: any) =>
      keys.some((key) =>
        item[key].toLowerCase().includes(query.toLocaleLowerCase())
      )
    );
  };

  // Filter Start and End date
  const [startDate, setStartDate] = useState(new Date('2024-06-01'));
  const [endDate, setEndDate] = useState(addMonths(new Date(), 6));

  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: any) => {
    setEndDate(date);
  };

  // Get Commissions
  useEffect(() => {
    getCommissions();
  }, [startDate, endDate]);

  const getCommissions = async () => {
    setIsLoading(true);
    try {
      const response = await AdminServices.getCommissionsWithDateRange(
        token,
        startDate.toISOString().split('T')[0],
        endDate.toISOString().split('T')[0]
      );
      setIsLoading(false);
      // console.log('Reseponse', response);
      if (response.data.status === 'success') {
        const res = response.data?.data;
        const filterByHasProduct = res.filter(
          (item: any) => item.products.length > 0
        );
        setCommissions(filterByHasProduct);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  // Pagination

  const PageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  // Flatten the products array for pagination
  const allProducts = commissions.flatMap((vendor) =>
    vendor.products.map((product) => ({
      ...product,
      vendorName: `${vendor.firstName} ${vendor.lastName}`,
    }))
  );

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;
  const currentTableData = allProducts.slice(firstPageIndex, lastPageIndex);

  const totalPages = Math.ceil(allProducts.length / PageSize);

  //  Download selected rows to csv
  const downloadSelectedRows = () => {
    // Filter the data based on selectedRows
    const selectedData = commissions;

    // Create CSV headers
    const csvHeaders = [
      'Product ID',
      'Product Name',
      'Vendor Name',
      'Category',
      'Price',
      'Commission',
    ];

    // Convert selectedData to CSV format
    const csvData: string[] = [];
    csvData.push(csvHeaders.join(',')); // Add headers to the CSV data

    // Loop through each vendor and their products
    selectedData.forEach((vendor) => {
      vendor.products.forEach((product) => {
        csvData.push(
          `${product.id},${product.name},${vendor.firstName} ${vendor.lastName}, ${product.category}, ${product.price},${product.commission}`
        );
      });
    });

    // Create a CSV string
    const csvString = csvData.join('\n');

    // Create a Blob containing the CSV data
    const blob = new Blob([csvString], {type: 'text/csv'});

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element to trigger the download
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'vendors.csv');

    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <AppContainer>
      <div className='2xl:w-[90%]  md:w-[95%] w-full sm:pt-4 pt-0 h-full'>
        <Helmet>
          <meta charSet='utf-8' />
          <title>Commissions</title>
        </Helmet>
        <ProductBar>
          <Title>
            <h2>COMMISSIONS</h2>
          </Title>

          <div className='flex flex-row items-center gap-4 justify-end'>
            {/* Search */}
            <div className=' flex flex-row items-center w-full justify-between'>
              {/* Search Input  */}
              <div className='bg-[#F9FBFF] px-4 flex items-center md:w-56 w-full rounded-md shadow-sm lg:shadow-none drop-shadow-sm lg:drop-shadow-none'>
                <div className='input w-full py-[4px] '>
                  <input
                    type='search'
                    placeholder='Search'
                    className='font-circularStdBook p-2 text-[#343A40] w-full border-none focus:border-[#999] focus:shadow-none focus:outline-none bg-transparent placeholder:text-primary'
                    onChange={(e: any) => setQuery(e.target.value)}
                  />
                </div>
                <div className='icon text-gray-200'>
                  <BiSearch size={22} color={COLORS.primary} />
                </div>
              </div>
            </div>
            {/* Notification */}
            <div>
              <NotificationIcon onClick={() => ''} />
            </div>
            {/* Profile Image */}
            <div>
              <ProfileImage onClick={() => ''} />
            </div>
          </div>
        </ProductBar>
        {/* Sort and Date Filter */}
        <div className='flex flex-row items-center justify-end gap-6 pt-4'>
          <button
            onClick={downloadSelectedRows}
            className='bg-primary text-white px-3 py-2 text-center transition-hover rounded-sm'>
            Download CSV
          </button>
          {/* Sort */}

          {/* Date Filter */}

          <DateRangePicker
            endDate={endDate}
            onEndDateChange={handleEndDateChange}
            onStartDateChange={handleStartDateChange}
            startDate={startDate}
          />
        </div>

        {query ? (
          <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
            <div className=''>
              <CommissionTable commissions={search(allProducts)} />
            </div>
          </div>
        ) : (
          <>
            <div className='sm:p-4  p-2 my-4 rounded-md bg-white'>
              <div className=''>
                {/* New Table */}
                {isLoading ? (
                  <TableDataSkeleton />
                ) : (
                  <CommissionTable commissions={currentTableData} />
                )}
              </div>
            </div>

            {/* Pagination */}
            <Pagination
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              totalPages={totalPages}
            />
          </>
        )}
      </div>
    </AppContainer>
  );
};

const AppContainer = styled.div<any>`
  transition: all 0.25s cubic-bezier(0.3, 0.3, 0.3, 0.3);
`;

const ProductBar = styled.div`
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 4px;
  border-radius: 8px;
`;

const Title = styled.div`
  h2 {
    text-transform: uppercase;
    padding-top: 0.5rem;
    color: ${COLORS.primary};
    font-weight: 600;
    font-size: 1rem;
    padding-left: 0.5rem;
  }
`;
export default Commissions;
