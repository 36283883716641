import {Notification} from 'iconsax-react';
import {COLORS} from '../../../../assets/color';

interface INotificationIcon {
  onClick: () => void;
}
const NotificationIcon = ({onClick}: INotificationIcon) => {
  return (
    <div
      onClick={onClick}
      className='flex justify-center items-center flex-row cursor-pointer'
      style={{
        backgroundColor: '#FFFFFF',
        width: 32,
        height: 32,
        borderRadius: 15,
      }}>
      <div onClick={() => ''} className='relative'>
        <Notification size={22} color={COLORS.primary} variant='Bold' />
        <div
          className='animate-ping opacity-75'
          style={{
            backgroundColor: 'red',
            width: 6,
            height: 6,
            position: 'absolute',
            top: 2,
            right: 4,
            borderRadius: 2.5,
          }}></div>
        <div
          style={{
            backgroundColor: 'red',
            width: 6,
            height: 6,
            position: 'absolute',
            top: 2,
            right: 4,
            borderRadius: 2.5,
          }}></div>
      </div>
    </div>
  );
};

export default NotificationIcon;
