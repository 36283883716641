import {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {ArrowUp} from 'iconsax-react';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Function to handle scroll event and toggle visibility of the button
    const handleScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(top > 100); // Show the button when user scrolls down 100px
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div
      className={`back-to-top-btn py-10 relative ${
        isVisible ? 'visible' : 'hidden'
      }`}>
      <motion.div
        onClick={scrollToTop}
        className='absolute right-6 bg-gray-100 rounded-full w-8 h-8 flex justify-center items-center cursor-pointer'
        whileHover={{scale: 1.3}}>
        <ArrowUp size={20} />
      </motion.div>
    </div>
  );
};

export default BackToTopButton;
