/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {useAdmin} from '../../../app/hooks';
import {AdminServices} from '../../../redux/services/admin/admin';
import {axiosError} from '../../Helpers';
import TableDataSkeleton from '../../ui/skeletonUI/TableDataSkeleton';
import moment from 'moment';
import {IReview} from '../../interfaces/vendors';
import {BiSort} from 'react-icons/bi';
import Pagination from '../../ui/Pagination';
import {renderRatingStars} from '../../Helpers/Rating';
const ApprovedReviewsScreen = () => {
  const [reviews, setReviews] = useState<any[]>([]);
  const [loading, setIsLoading] = useState(false);

  const token = useAdmin().adminToken;

  // Pagination
  const pageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Handle Page changes
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    getReviews();
  }, [currentPage, pageSize]);

  const getReviews = async () => {
    setIsLoading(true);
    try {
      const offset = (currentPage - 1) * pageSize;
      const response = await AdminServices.getReviewWithPagination({
        token,
        offset,
        pageSize,
      });
      // console.log('profile', response.data.data);
      if (response.data.status === 'success') {
        // toast.success(response.data.data.message);
        const res = response.data.data.details;
        // console.log(response.data.data.details);
        const pendingReviews = res?.rows.filter(
          (items: any) => items.status === 'approved'
        );
        setReviews(pendingReviews.slice(0, 12));
        setTotalPages(Math.ceil(res.count / pageSize));
        // localStorage.setItem('#prds', JSON.stringify(res?.products));
        setIsLoading(false);
      }
    } catch (error: any) {
      // console.log(error.response);
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  // sorting

  const [sortingOption, setSortingOption] =
    useState<keyof IReview>('createdAt');
  const [isAscending, setIsAscending] = useState(false);

  const handleSortingOptionChange = (newOption: any) => {
    // Toggle sorting order if the same option is selected again
    if (newOption === sortingOption) {
      setIsAscending(!isAscending);
    } else {
      // If a new option is selected, reset sorting order to ascending
      setIsAscending(true);
    }

    // Set the new sorting option
    setSortingOption(newOption);
  };

  const sortedData = [...reviews].sort((a, b) => {
    // Use the selected sorting option
    const sortOrder = isAscending ? 1 : -1;
    const aValue = a[sortingOption];
    const bValue = b[sortingOption];

    // Compare values based on the sorting order
    if (aValue < bValue) return -1 * sortOrder;
    if (aValue > bValue) return 1 * sortOrder;
    return 0;
  });

  return (
    <div className=' sm:block hidden'>
      {loading ? (
        <div className='w-full'>
          <TableDataSkeleton />
        </div>
      ) : (
        <table className=' mt-4 w-full overflow-scroll text-xs table-fixed border-t border-transparent rounded-tr-lg rounded-tl-lg bg-primary'>
          <thead>
            <tr className='h-[3.125rem] text-white'>
              <th
                className=' rounded-tl-md cursor-pointer text-center'
                onClick={() => handleSortingOptionChange('product.name')}>
                <span className='flex flex-row gap-1 items-center justify-center'>
                  <span> Product Name</span>
                  <BiSort size={18} color='white' />
                </span>
              </th>
              <th className=''>Image</th>
              <th className=''>Customer Name</th>
              <th className='w-60'>Review Description</th>
              <th className=''>Rating</th>
              <th
                className=' cursor-pointer text-center'
                onClick={() => handleSortingOptionChange('createdAt')}>
                <span className='flex flex-row gap-1 items-center justify-center'>
                  <span> Date</span>
                  <BiSort size={18} color='white' />
                </span>
              </th>
              <th className=''>Status</th>
            </tr>
          </thead>
          <tbody className='[&>*:nth-child(odd)]:bg-white [&>*:nth-child(even)]:bg-gray-100'>
            {reviews?.length === 0 && (
              <tr>
                <td className='text-center py-6' colSpan={8}>
                  <span className='text-base text-primary font-semibold'>
                    No Data available
                  </span>
                </td>
              </tr>
            )}
            <>
              {sortedData?.map((review) => {
                return (
                  <tr
                    className='overflow-x-auto h-[2rem] font-medium text-[#6C757D]'
                    key={review.id}>
                    <td className=' text-center'>{review?.product?.name}</td>
                    <td className=''>
                      <div className='flex justify-center'>
                        <div className='w-20 h-20 self-center'>
                          <img
                            src={review?.product?.additionalImg[0]}
                            alt=''
                            className='object-contain w-full rounded-md h-full'
                          />
                        </div>
                      </div>
                    </td>
                    <td className=' text-center'>{review.buyerName}</td>
                    <td className='w-60 text-center h-full '>
                      <div className='w-full h-full mx-auto p-2 whitespace-normal flex items-center justify-center'>
                        <span className='break-words'>{review.content}</span>
                      </div>
                    </td>
                    <td className=' text-center'>
                      <span className='flex flex-row gap-1 items-center justify-center'>
                        {renderRatingStars(review.rating)}
                      </span>
                    </td>
                    <td className=' text-center'>
                      {moment(review.createdAt).format('ll')}
                    </td>
                    <td className=' text-center'>
                      <span className='bg-[#E6FCE5] px-3 py-2 text-[#35C655] rounded-md shadow-sm'>
                        {review.status}
                      </span>
                    </td>
                  </tr>
                );
              })}
            </>
          </tbody>
        </table>
      )}
      {/* Pagination */}
      <Pagination
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalPages={totalPages}
      />
    </div>
  );
};

export default ApprovedReviewsScreen;
