import React, {useState} from 'react';
import MultiFormController from './MultiFormController';
import PersonalInfo from './PersonalInfo';
import BusinessInfo from './BusinessInfo';
import OtherInfo from './OtherInfo';
import FormConfirmation from './FormConfirmation';
import {useNavigate} from 'react-router-dom';
import {scrollToTop} from '../../../../../components/Helpers';

const MultiForm = () => {
  const navigate = useNavigate();
  const [activeScreen, setActiveScreen] = useState(0);
  const [vendorInfo, setVendorInfo] = useState({
    firstName: '',
    lastName: '',
    // username: "",
    email: '',
    password: '',
    confirmPassword: '',
    businessName: '',
    businessType: '',
    vendorType: '',
    phone: '',
    regNumber: '',
  });

  const {
    firstName,
    lastName,
    // username,
    email,
    password,
    confirmPassword,
    businessName,
    businessType,
    vendorType,
    phone,
  } = vendorInfo;

  console.log(businessType);
  const r = {
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
      setVendorInfo({
        ...vendorInfo,
        [e.target.name]:
          e.target.name === 'password' || e.target.name === 'username'
            ? e.target.value.replace(' ', '')
            : e.target.value,
      });
    },
    nextStep() {
      setActiveScreen((prevState) => prevState + 1);
    },

    prevStep() {
      setActiveScreen((prevState) => prevState - 1);
    },
  };
  const pages: JSX.Element[] = [
    <PersonalInfo
      email={email}
      firstName={firstName}
      lastName={lastName}
      r={r}
    />,
    <BusinessInfo
      businessName={businessName}
      businessType={businessType}
      vendorType={vendorType}
      setVendorInfo={setVendorInfo}
      r={r}
      //   setActiveScreen={() => activeScreen}
    />,
    <OtherInfo
      phone={phone}
      password={password}
      confirmPassword={confirmPassword}
      r={r}
      //   setActiveScreen={() => activeScreen}
    />,
    <FormConfirmation
      firstName={firstName}
      lastName={lastName}
      // username={username}
      email={email}
      businessName={businessName}
      businessType={businessType}
      vendorType={vendorType}
      phone={phone}
      password={password}
      r={r}
    />,
  ];

  return (
    <div className='h-full grid place-items-center md:my-10'>
      <div className='container mx-auto'>
        {/* Body */}
        <div className='lg:w-[75%] sm:w-[90%] w-full sm:p-10 p-2 sm:rounded-xl sm:bg-[#FAFAFA] m-auto h-full'>
          <div className=' lg:w-[60%] sm:w-[85%] w-full mx-auto h-full px-4'>
            <div className='w-full pt-4 md:px-2 font-poppinsRegular'>
              <div className='w-full h-full flex flex-row items-center justify-center'>
                {/*  LOGIN FORM HERE */}
                <div className='grid place-items-center sm:pt-10 w-full'>
                  <div className='pb-2 w-full'>
                    <h3 className='text-2xl text-center font-poppinsMedium pb-10'>
                      Let's get you started
                    </h3>
                    {/* Controller */}
                    <div className='w-full'>
                      {/* <h1>Add product</h1> */}
                      <MultiFormController
                        setActiveScreen={setActiveScreen}
                        activeScreen={activeScreen}
                      />
                      {/* Pages */}
                      <div className='w-full'>{pages[activeScreen]}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Already have an account */}
            <div className='flex  justify-center text-sm text-center pt-2'>
              <p className='cursor-pointer text-menuIcon'>
                Already have an account? &nbsp;
                <span
                  className='font-poppinsSemibold text-secondary_500'
                  onClick={() => {
                    navigate('/');
                    scrollToTop();
                  }}>
                  Sign in
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiForm;
