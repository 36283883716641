import React, {useEffect, useState} from 'react';
import {regexValidation} from '../../../../components/Helpers/regexValidation';
import {useNavigate, useParams} from 'react-router-dom';
import {AuthService} from '../../../../redux/services/vendor/auth';
import {toast} from 'react-toastify';
import {axiosError} from '../../../../components/Helpers';
import {Loader} from '../../../../assets/svg';
import Button from '../../../../components/ui/Button';
import InputFieldAuth from '../../../../components/forms/inputs/inputField/InputFieldAuth';

const ResetPassword = () => {
  const [userData, setUserData] = useState({
    password: '',
    confirmPassword: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const {token} = useParams();

  //NAVIGATE TO THE DASHBOARD
  const navigate = useNavigate();

  // REGEX Validations
  useEffect(() => {
    const isPasswordValid = regexValidation.validatePassword(userData.password);
    const isConfirmPasswordValid = regexValidation.validatePassword(
      userData.confirmPassword
    );
    setIsValid(isPasswordValid && isConfirmPasswordValid === isPasswordValid);
  }, [userData.password, userData.confirmPassword]);

  // HandleChange
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target;
    setUserData((p: any) => ({
      ...p,
      [name]: value,
    }));
  };
  //   Forgot Password
  const handleSubmit = async () => {
    setIsLoading(true);
    const data = {
      password: userData.password,
    };
    try {
      const response = await AuthService.resetPassword(token, data);
      if (response.data.status === 'success') {
        const res = response.data;
        // console.log(response);
        navigate('/');
        toast.success(
          res.data.message ||
            `Password Reset Successfully, Please continue to login`
        );
        // console.log(`Response`);
        setIsLoading(false);
      }
    } catch (error: any) {
      axiosError(error.response);
      setIsLoading(false);
    }
  };

  return (
    <div className='h-full grid place-items-center md:my-10'>
      <div className='container mx-auto'>
        {/* Body */}
        <div className='lg:w-[75%] sm:w-[90%]  w-full sm:p-10 p-2 sm:rounded-xl sm:bg-[#FAFAFA] m-auto'>
          <div className='xl:w-[40%] md:w-[60%] w-full mx-auto'>
            <div>
              <div className=''>
                <div className='w-full pt-4 md:px-2 px-4 font-poppinsRegular'>
                  <div className='w-full h-full flex flex-row items-center justify-center'>
                    <div className='grid place-items-center sm:py-10 py-4 w-full'>
                      <div className='w-full'>
                        <h3 className='text-3xl font-poppinsSemibold text-center pb-3'>
                          Password Recovery
                        </h3>
                        <h4 className=' text-xs md:text-sm font-poppinsRegular text-center pb-3 text-[#777777]'>
                          Enter your new unique password here
                        </h4>
                        <form className='w-full'>
                          <InputFieldAuth
                            name='password'
                            label='Enter New Password'
                            type='password'
                            placeholder='************'
                            value={userData.password}
                            required={true}
                            ariaDescribedby='uipwd'
                            password
                            onChange={handleChange}
                            style={{
                              height: '100%',
                              fontFamily: 'poppinsRegular',
                            }}
                          />
                          <p className='font-poppinsRegular text-xs text-[#777777]'>
                            Password should be at least 8 characters long and
                            include a mix of uppercase and lowercase letters,
                            numbers, and special characters.
                          </p>
                          {/* Confirm Password */}
                          <InputFieldAuth
                            name='confirmPassword'
                            label='Confirm New Password'
                            type='password'
                            placeholder='************'
                            value={userData.confirmPassword}
                            required={true}
                            ariaDescribedby='uipwd'
                            password
                            onChange={handleChange}
                            style={{
                              height: '100%',
                              fontFamily: 'poppinsRegular',
                            }}
                          />

                          <Button
                            label={
                              isLoading ? (
                                <div>
                                  <Loader color={'#fff'} />
                                </div>
                              ) : (
                                'Reset Password'
                              )
                            }
                            width='full'
                            onClick={handleSubmit}
                            disabled={!isValid ? true : false}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
