/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState} from 'react';
import {initialTabs as tabs} from './productSlice.interface';
import {motion, AnimatePresence} from 'framer-motion';
import '../../assets/styles/withdraw/styles.css';
const ProductListTabs = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <>
      <div className='w-full mx-auto p-2'>
        <nav>
          <ul className='gap-4'>
            {tabs.map((item) => (
              <li
                key={item.label}
                className={
                  item === selectedTab
                    ? 'text-primary 2xl:text-base text-xs font-poppinsRegular border-b-2 border-b-primary'
                    : 'text-gray-400 font-poppinsRegular 2xl:text-base text-xs'
                }
                onClick={() => setSelectedTab(item)}>
                {`${item.label}`}
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className='2xl:w-[90%] w-full mx-auto'>
        <AnimatePresence mode='wait'>
          <motion.div
            key={selectedTab ? selectedTab.label : 'empty'}
            initial={{y: 10, opacity: 0}}
            animate={{y: 0, opacity: 1}}
            exit={{y: -10, opacity: 0}}
            transition={{duration: 0.2}}>
            <div className='w-full py-2 text-black'>
              {selectedTab ? selectedTab.route : ''}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};

export default ProductListTabs;
